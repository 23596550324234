"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newApi = void 0;

var _ams = require("./util/ams.js");

var _http = require("./util/http.js");

var l10nApi = _interopRequireWildcard(require("./l10n/api.js"));

var stationsApi = _interopRequireWildcard(require("./stations/api.js"));

var rentObjectsApi = _interopRequireWildcard(require("./rent_objects/api.js"));

var rentContractsApi = _interopRequireWildcard(require("./rent_contracts/api.js"));

var userApi = _interopRequireWildcard(require("./user/api.js"));

var historyApi = _interopRequireWildcard(require("./history/api.js"));

var cmsApi = _interopRequireWildcard(require("./cms/api.js"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var newApi = function newApi(_ref) {
  var fetch = _ref.fetch,
      config = _ref.config;
  var amsFetch = (0, _ams.newAmsFetch)({
    fetch: fetch,
    config: config
  });
  var amsResponse = {
    contentTypes: _http.contentTypes,
    getRawBody: (0, _ams.newAmsResponseRawBody)(amsFetch),
    getData: (0, _ams.newAmsResponseData)(amsFetch)
  };
  return {
    l10n: l10nApi.newApi({
      amsResponse: amsResponse,
      config: config
    }),
    stations: stationsApi.newApi({
      amsResponse: amsResponse,
      config: config
    }),
    rentObjects: rentObjectsApi.newApi({
      amsResponse: amsResponse,
      config: config
    }),
    rentContracts: rentContractsApi.newApi({
      amsResponse: amsResponse,
      config: config
    }),
    user: userApi.newApi({
      amsResponse: amsResponse,
      config: config
    }),
    history: historyApi.newApi({
      amsResponse: amsResponse,
      config: config
    }),
    cms: cmsApi.newApi({
      amsResponse: amsResponse,
      config: config
    })
  };
};

exports.newApi = newApi;