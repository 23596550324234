"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.collapseFaqEntry = exports.expandFaqEntry = void 0;

var _dom = require("@awe-web/shared/lib/util/dom.js");

var expandFaqEntry = function expandFaqEntry(faqEntry) {
  var isExpanded = faqEntry.classList.contains("faqEntry--is-expanded");

  if (isExpanded) {
    return;
  }

  var answer = faqEntry.querySelector(".answer");
  var answerHeight = answer.clientHeight;

  var onTransitionEnd = function onTransitionEnd() {
    answer.removeEventListener("transitionend", onTransitionEnd, false);
    (0, _dom.scrollElementToTopOfViewportIfNotVisible)(faqEntry);
  };

  answer.addEventListener("transitionend", onTransitionEnd, false);
  answer.style.height = "0";
  setTimeout(function () {
    answer.style.height = "".concat(answerHeight, "px");
  }, 0);
  faqEntry.classList.add("faqEntry--is-expanded");
};

exports.expandFaqEntry = expandFaqEntry;

var collapseFaqEntry = function collapseFaqEntry(faqEntry) {
  var isExpanded = faqEntry.classList.contains("faqEntry--is-expanded");

  if (!isExpanded) {
    return;
  }

  var answer = faqEntry.querySelector(".answer");

  var onTransitionEnd = function onTransitionEnd() {
    answer.removeEventListener("transitionend", onTransitionEnd, false);
    answer.style.position = "";
    answer.style.height = "";
  };

  answer.addEventListener("transitionend", onTransitionEnd, false);
  answer.style.position = "static";
  answer.style.height = "0";
  faqEntry.classList.remove("faqEntry--is-expanded");
};

exports.collapseFaqEntry = collapseFaqEntry;