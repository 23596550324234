import React from "react";
import PropTypes from "prop-types";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../components/screen.js";
import {Button, ScrollToError} from "../../components/form.js";
import {
  FormikSingleChoiceOptions,
  FormikMultiChoiceOptions
} from "../../components/option.js";
import {StrBasePrice, StrTotalPrice} from "./price.js";

import "./str_select_extras_form.scss";

const SELECT_EXTRAS = "FSD0000125";
const SELECT_MILEAGE_B2C = "FSD0000248";
const SELECT_MILEAGE_B2B = "ALG0000251";
const SELECT_DEDUCTIBLE = "FSD0000249";
const SELECT_EQUIPMENT = "FSD0000252";
const CONTINUE = "FSD0000135";
const MORE_INFOS_MILEAGE_HEADING_B2C = "ALG0000237";
const MORE_INFOS_MILEAGE_CONTENT_B2C = "ALG0000238";
const MORE_INFOS_MILEAGE_HEADING_B2B = "ALG0000252";
const MORE_INFOS_MILEAGE_CONTENT_B2B = "ALG0000253";
const MORE_INFOS_DEDUCTIBLE_HEADING = "ALG0000239";
const MORE_INFOS_DEDUCTIBLE_CONTENT = "ALG0000240";

export const StrSelectExtrasForm = (props) => {
  const {
    reservationPath,
    basePriceHint,
    mileageOptionsChangedMessage,
    mileageOptions,
    deductibleOptions,
    equipmentOptionsChangedMessage,
    equipmentOptions,
    translate,
    values,
    formValuesToTotalPrice,
    handleSubmit
  } = props;

  const totalPrice = formValuesToTotalPrice(values);

  const selectMileageTitle = reservationPath === "B2B" ? SELECT_MILEAGE_B2B : SELECT_MILEAGE_B2C;
  const moreInfosMileageHeading = reservationPath === "B2B" ? MORE_INFOS_MILEAGE_HEADING_B2B : MORE_INFOS_MILEAGE_HEADING_B2C;
  const moreInfosMileageContent = reservationPath === "B2B" ? MORE_INFOS_MILEAGE_CONTENT_B2B : MORE_INFOS_MILEAGE_CONTENT_B2C;

  return (
    <form className="str-select-extras-form__grid-wrapper" onSubmit={handleSubmit}>
      <ScrollToError />
        <div className="str-select-extras-form__grid">
          <ScreenSection className="str-select-extras-form__title" dark>
            <div className="str-select-extras-form__type-area">
              <ScreenHeading className="str-select-extras-form__screen-heading">
                <T code={SELECT_EXTRAS} />
              </ScreenHeading>
            </div>
          </ScreenSection>

          <StrBasePrice className="str-select-extras-form__base-price-details" />

          <ScreenSection className="str-select-extras-form__extras" light>
            <div className="str-select-extras-form__type-area str-select-extras-form__type-area--extras">
              <FormikMultiChoiceOptions
                name="mileage"
                showPrices
                className="str-select-extras-form__mileage-options"
                title={translate(selectMileageTitle)}
                notice={mileageOptionsChangedMessage}
                moreInfos={{
                  heading: translate(moreInfosMileageHeading),
                  content: translate(moreInfosMileageContent)
                }}
                options={mileageOptions}
              />
              <FormikSingleChoiceOptions
                name="deductible"
                showPrices
                className="str-select-extras-form__deductible-options"
                title={translate(SELECT_DEDUCTIBLE)}
                moreInfos={{
                  heading: translate(MORE_INFOS_DEDUCTIBLE_HEADING),
                  content: translate(MORE_INFOS_DEDUCTIBLE_CONTENT)
                }}
                options={deductibleOptions}
              />
              <FormikMultiChoiceOptions
                name="equipment"
                showPrices
                className="str-select-extras-form__equipment-options"
                title={translate(SELECT_EQUIPMENT)}
                notice={equipmentOptionsChangedMessage}
                options={equipmentOptions}
              />
            </div>
          </ScreenSection>

          <StrTotalPrice
            reservationPath={reservationPath}
            className="str-select-extras-form__total-price"
            descriptionHint={basePriceHint}
            formattedPrice={totalPrice}
          />

          <ScreenSection className="str-select-extras-form__actions" dark>
            <Button primary continue submit><T code={CONTINUE} /></Button>
          </ScreenSection>
        </div>
    </form>
  );
};
StrSelectExtrasForm.propTypes = {
  reservationPath: PropTypes.oneOf(["B2C", "B2B"]),
  basePriceHint: PropTypes.string,
  mileageOptionsChangedMessage: FormikMultiChoiceOptions.propTypes.notice,
  mileageOptions: FormikMultiChoiceOptions.propTypes.options,
  deductibleOptions: FormikSingleChoiceOptions.propTypes.options,
  equipmentOptionsChangedMessage: FormikMultiChoiceOptions.propTypes.notice,
  equipmentOptions: FormikMultiChoiceOptions.propTypes.options,
  translate: PropTypes.func,
  values: PropTypes.shape({
    mileage: PropTypes.objectOf(PropTypes.bool),
    deductible: PropTypes.number,
    equipment: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]))
  }),
  formValuesToTotalPrice: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};
