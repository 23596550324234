export default function reducer(state, action) {
  switch (action.type) {
    case "DOM/CURRENT_HEADER_HEIGHT_UPDATED":
      return state.set("currentHeaderHeight", action.payload.currentHeaderHeight);

    default:
      return state;
  }
}

export const getCurrentHeaderHeight = state => state.get("currentHeaderHeight");
