import {matchPath} from "react-router";
import {userRoles} from "@awe-web/shared/lib/user/constants.js";
import * as routesObject from "./routes.js";

const routes = Object.values(routesObject)
  .filter((route) => typeof route.path === "function");

const findRoute = (location) =>
  routes.find((route) => matchPath(location.pathname, route.path()));

export const belongsToGroup = (location, group) => {
  const {groups = []} = findRoute(location) || {};
  return groups.includes(group);
};

export const isUserRoleAllowed = (userRole, location) => {
  const {allowedUserRoles = []} = findRoute(location) || {};
  return allowedUserRoles.includes(userRole);
};

export const requiresAuthentication = (location) =>
  !isUserRoleAllowed(userRoles.ANONYMOUS, location);
