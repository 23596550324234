import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {useFormikContext} from "formik";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSectionHeading} from "../../../components/screen.js";
import {
  LabeledRadioButton,
  FormikRadioButton,
  FormikTextInput,
  FormikSelect,
  FormikErrorMessage,
  Button,
  FormikLabeledField
} from "../../../components/form.js";

const BILLING_ADDRESS_SUBMIT = "ALG0000147";
const DELIVERY_HEADING = "ALG0000148";
const DELIVERY_MAIL_LABEL = "ALG0000149";
const DELIVERY_MAIL_TEXT = "ALG0000150";
const DELIVERY_E_MAIL_LABEL = "ALG0000151";
const DELIVERY_E_MAIL_TEXT = "ALG0000152";
const DELIVERY_SUBMIT = "ALG0000153";

export const DeviantBillingAddressForm = (props) => {
  const {
    getFieldLabel,
    isFieldRequired,
    getFieldPlaceholder,
    getSelectFieldOptions,
    handleSubmit
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col
          tablet={{ cols: 4 }}
          desktop={{ cols: 4, offset: 2 }}
        >
          <FormikLabeledField
            name="deviantBillingAddress:surname"
            as={FormikTextInput}
            className="b2b-payment__form-field"
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("deviantBillingAddress:surname")}
          />
        </Col>
      </Row>
      <Row>
        <Col
          tablet={{ cols: 4 }}
          desktop={{ cols: 4, offset: 2 }}
        >
          <FormikLabeledField
            name="deviantBillingAddress:street"
            as={FormikTextInput}
            className="b2b-payment__form-field"
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("deviantBillingAddress:street")}
          />
          <FormikLabeledField
            name="deviantBillingAddress:building"
            as={FormikTextInput}
            className="b2b-payment__form-field"
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("deviantBillingAddress:building")}
          />
        </Col>
        <Col
          tablet={{ cols: 4 }}
          desktop={{ cols: 4 }}
        >
          <div className="b2b-payment__zip-code-and-city">
            <FormikLabeledField
              name="deviantBillingAddress:zipCode"
              as={FormikTextInput}
              className="b2b-payment__form-field b2b-payment__form-field--zip-code"
              getFieldLabel={getFieldLabel}
              isFieldRequired={isFieldRequired}
              placeholder={getFieldPlaceholder("deviantBillingAddress:zipCode")}
            />
            <FormikLabeledField
              name="deviantBillingAddress:city"
              as={FormikTextInput}
              className="b2b-payment__form-field b2b-payment__form-field--city"
              getFieldLabel={getFieldLabel}
              isFieldRequired={isFieldRequired}
              placeholder={getFieldPlaceholder("deviantBillingAddress:city")}
            />
          </div>
          <FormikLabeledField
            name="deviantBillingAddress:countryCode"
            as={FormikSelect}
            className="b2b-payment__form-field b2b-payment__form-field--country-code"
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            options={getSelectFieldOptions("deviantBillingAddress:countryCode")}
          />
        </Col>
      </Row>
      <Row>
        <Col
          tablet={{ cols: 4, offset: 4 }}
          desktop={{ cols: 4, offset: 6 }}
        >
          <Button primary continue submit><T code={BILLING_ADDRESS_SUBMIT} /></Button>
        </Col>
      </Row>
    </form>
  );
};
DeviantBillingAddressForm.propTypes = {
  isFieldRequired: PropTypes.func.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  getFieldPlaceholder: PropTypes.func.isRequired,
  getSelectFieldOptions: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func
};

export const BillDeliveryReadOnly = (props) => {
  const {
    billDeliveryType,
    email
  } = props;

  return billDeliveryType === "MAIL"
    ? (
      <Row>
        <Col desktop={{ cols: 8, offset: 2 }}>
          <T className="b2b-payment__radiobutton__label" code={DELIVERY_MAIL_LABEL} />
          <T className="b2b-payment__radiobutton__text" code={DELIVERY_MAIL_TEXT} />
        </Col>
      </Row>
    )
    : (
      <Row>
        <Col desktop={{ cols: 8, offset: 2 }}>
          <T className="b2b-payment__radiobutton__label" code={DELIVERY_E_MAIL_LABEL} />
          {email}
        </Col>
      </Row>
    );
};
BillDeliveryReadOnly.propTypes = {
  billDeliveryType: PropTypes.oneOf(["MAIL", "EMAIL"]),
  email: PropTypes.string
};

export const BillDeliveryForm = (props) => {
  const {values: {billDeliveryType}, handleSubmit} = props;

  const formik = useFormikContext();
  const formikRef = useRef();
  formikRef.current = formik;
  useEffect(() => {
    if (billDeliveryType === "EMAIL") {
      formikRef.current.setFieldTouched("eBillingMailAddress", false);
    }
  }, [billDeliveryType]);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col desktop={{ cols: 8, offset: 2 }}>
          <ScreenSectionHeading light><T code={DELIVERY_HEADING} /></ScreenSectionHeading>

          <LabeledRadioButton className="b2b-payment__radiobutton--mail">
            <FormikRadioButton name="billDeliveryType" value="MAIL" />
            <div className="b2b-payment__radiobutton__label-wrapper">
              <T className="b2b-payment__radiobutton__label" code={DELIVERY_MAIL_LABEL} />
              <T className="b2b-payment__radiobutton__text" code={DELIVERY_MAIL_TEXT} />
            </div>
          </LabeledRadioButton>
        </Col>
      </Row>

      <Row>
        <Col
          tablet={{ cols: 4 }}
          desktop={{ cols: 4, offset: 2 }}
        >
          <LabeledRadioButton className="b2b-payment__radiobutton--email">
            <FormikRadioButton name="billDeliveryType" value="EMAIL" />
            <div className="b2b-payment__radiobutton__label-wrapper">
              <T className="b2b-payment__radiobutton__label" code={DELIVERY_E_MAIL_LABEL} />
              <T className="b2b-payment__radiobutton__text" code={DELIVERY_E_MAIL_TEXT} />
              {billDeliveryType === "EMAIL"
                ? (
                  <>
                    <FormikTextInput
                      email
                      name="eBillingMailAddress"
                      className="b2b-payment__radiobutton__text-input"
                    />
                    <FormikErrorMessage name="eBillingMailAddress" />
                  </>
                )
                : null
              }
            </div>
          </LabeledRadioButton>
        </Col>
      </Row>
      <Row>
        <Col
          tablet={{ cols: 4, offset: 4 }}
          desktop={{ cols: 4, offset: 6 }}
        >
          <Button primary continue submit><T code={DELIVERY_SUBMIT} /></Button>
        </Col>
      </Row>
    </form>
  );
};
BillDeliveryForm.propTypes = {
  values: PropTypes.shape({
    billDeliveryType: PropTypes.oneOf(["MAIL", "EMAIL"])
  }),
  handleSubmit: PropTypes.func
};
