import {merge} from "@awe-web/shared/lib/util/object.js";
import {toIsoString, dateStringToDateTime} from "@awe-web/shared/lib/util/date.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {loadFormConfiguration, serverValidationFailed} from "@awe-web/shared/lib/user/actions.js";
import {updateUserProfile} from "../../../user/actions.js";
import {MyBmwRentProfileSuccess} from "../../../routing/routes.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";

export const initialize = () => async (dispatch) => {
  await dispatch(loadFormConfiguration("privateChangeUserDataFSD"));
};

const normalizeDate = value => toIsoString(dateStringToDateTime(value));

export const submit = (formValues, {setSubmitting, validateForm}) => async (dispatch, getState) => {
  const mapFormValues = newMapFormValues("privateChangeUserDataFSD")(getState());
  const normalizedValues = mapFormValues(merge(formValues, {
    "Driver:birthdate": normalizeDate(formValues["Driver:birthdate"]),
    "Driver:identCardIssued": normalizeDate(formValues["Driver:identCardIssued"]),
    "Driver:identcardValidTill": normalizeDate(formValues["Driver:identcardValidTill"]),
    "Driver:licenceDateIssued": normalizeDate(formValues["Driver:licenceDateIssued"])
  }));

  try {
    setSubmitting(true);
    await dispatch(updateUserProfile(normalizedValues));
    dispatch(redirect(MyBmwRentProfileSuccess.location()));
  }
  catch (error) {
    if (error.type === "VALIDATION_FAILED") {
      const groupName = "privateChangeUserDataFSD";
      const errors = error.errors;
      dispatch(serverValidationFailed(groupName, formValues, errors));
      validateForm();
      return;
    }

    throw error;
  }
  finally {
    setSubmitting(false);
  }
};
