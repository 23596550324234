"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.provisioningTypes = exports.dynDataValueCodes = exports.calcBases = exports.optionExtCodes = exports.ruleBls = exports.rentContractStates = void 0;
var rentContractStates = {
  REQUEST: "-1",
  PRE_RESERVATION: "02",
  RESERVATION: "03",
  CHECK_OUT: "04",
  CHECK_IN: "05",
  CANCELED_1: "06",
  CANCELED_2: "22"
};
exports.rentContractStates = rentContractStates;
var ruleBls = {
  ADDITIONAL_MILEAGE: "00",
  CDW: "06",
  S_CDW: "09",
  TIME_PERIOD: "10",
  DELIVERY: "16",
  EQUIPMENT: "22",
  NET_SUM_SERVICE: "25",
  MILEAGE_PACKAGE: "MP"
};
exports.ruleBls = ruleBls;
var optionExtCodes = {
  TRANSMISSION: "GWU",
  EQUIPMENT_STR: "OPT",
  EQUIPMENT_LTR: "OPTLZM",
  COLOR: "color",
  CAROUSEL: "carousel"
};
exports.optionExtCodes = optionExtCodes;
var calcBases = {
  ONE_TIME: "1X",
  PER_DAY: "DY",
  PER_MONTH: "MO",
  PER_KM: "KM"
};
exports.calcBases = calcBases;
var dynDataValueCodes = {
  EMPLOYEE_ID: "pcdCustomerNumber",
  COST_CENTER: "pcdCostCentre"
};
exports.dynDataValueCodes = dynDataValueCodes;
var provisioningTypes = {
  PICKUP: "PICKUP",
  DELIVERY_TO_COMPANY_ADDRESS: "DELIVERY_TO_COMPANY_ADDRESS",
  DELIVERY_TO_CUSTOM_ADDRESS: "DELIVERY_TO_CUSTOM_ADDRESS"
};
exports.provisioningTypes = provisioningTypes;