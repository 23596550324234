import {merge} from "@awe-web/shared/lib/util/object.js";
import {toIsoString, dateStringToDateTime} from "@awe-web/shared/lib/util/date.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {loadFormConfiguration, serverValidationFailed, loadCreditCards} from "@awe-web/shared/lib/user/actions.js";
import {extendSignUp} from "../../../user/actions.js";
import {B2C_SignUpExtended} from "../../../routing/routes.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";

export const initialize = () => async (dispatch) => {
  await Promise.all([
    dispatch(loadFormConfiguration("extendRegistration")),
    dispatch(loadCreditCards())
  ]);
};

const normalizeDate = value => toIsoString(dateStringToDateTime(value));

export const submit = (formValues, {setSubmitting, validateForm}) => async (dispatch, getState, context) => {
  const mapFormValues = newMapFormValues("extendRegistration")(getState());
  const {rentContractId, ...values} = formValues;
  const normalizedValues = mapFormValues(merge(values, {
    "Driver:identCardIssued": normalizeDate(formValues["Driver:identCardIssued"]),
    "Driver:identcardValidTill": normalizeDate(formValues["Driver:identcardValidTill"]),
    "Driver:licenceDateIssued": normalizeDate(formValues["Driver:licenceDateIssued"])
  }));

  try {
    setSubmitting(true);
    await dispatch(extendSignUp(normalizedValues));
    dispatch(redirect(B2C_SignUpExtended.location({rentContractId})));
  }
  catch (error) {
    if (error.type === "VALIDATION_FAILED") {
      const groupName = "extendRegistration";
      const errors = error.errors;
      dispatch(serverValidationFailed(groupName, values, errors));
      validateForm();
      return;
    }

    throw error;
  }
  finally {
    setSubmitting(false);
  }
};
