import React, {useRef, useEffect} from "react";
import {showPolicyPage} from "@awe-web/shared/lib/util/consent_controller.js";

export const PolicyPage = () => {
  const rootNode = useRef();
  useEffect(() => {
    showPolicyPage(rootNode.current);
  }, []);

  return (
    <div ref={rootNode} className="consent-manager" />
  );
};
PolicyPage.propTypes = {
};
