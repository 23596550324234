import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import ReactCalendar from "react-calendar";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {subtractYears} from "@awe-web/shared/lib/util/date.js";
import {getCurrentLocale} from "@awe-web/shared/lib/l10n/store.js";
import {getCurrentDateTime} from "@awe-web/shared/lib/time/store.js";
import {dateToAbbreviatedWeekdayName, dateToMonthName} from "../l10n/store.js";

import "./calendar.scss";

const CalendarComponent = (props) => {
  const {
    value,
    defaultView = "month",
    currentLocale,
    currentDateTime,
    startDate,
    minDate: _minDate,
    isDateEnabled = () => true,  // Ensure filter function keeps all dates per default
    onSelect = () => {},
    dateToMonthName,
    dateToAbbreviatedWeekdayName
  } = props;

  const className = classnames("calendar", props.className);
  const activeStartDate = startDate ? startDate : currentDateTime;
  const minDate = _minDate
    ? (startDate ? startDate : _minDate)
    : subtractYears(currentDateTime, 100);
  const formatMonth = (locale, date) => dateToMonthName(date);
  const formatMonthYear = (locale, date) => `${dateToMonthName(date)} ${date.getFullYear()}`;
  const formatShortWeekday = (locale, date) => dateToAbbreviatedWeekdayName(date);

  return (
    <ReactCalendar
      value={value}
      className={className}
      onClickDay={onSelect}
      tileDisabled={({date}) => !isDateEnabled(date)}
      minDetail={defaultView}
      view={defaultView}
      locale={currentLocale}
      activeStartDate={activeStartDate}
      minDate={minDate}
      formatMonth={formatMonth}
      formatMonthYear={formatMonthYear}
      formatShortWeekday={formatShortWeekday}
    />
  );
};
CalendarComponent.propTypes = {
  className: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  defaultView: PropTypes.oneOf(["month", "year", "decade"]),
  currentLocale: PropTypes.string,
  currentDateTime: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  isDateEnabled: PropTypes.func,
  onSelect: PropTypes.func,
  dateToMonthName: PropTypes.func,
  dateToAbbreviatedWeekdayName: PropTypes.func
};

const stateToCalendarProps = createStateToProps({
  currentLocale: getCurrentLocale,
  currentDateTime: getCurrentDateTime,
  dateToMonthName: dateToMonthName,
  dateToAbbreviatedWeekdayName: dateToAbbreviatedWeekdayName
});

export const Calendar = connect(stateToCalendarProps)(CalendarComponent);
