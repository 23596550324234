"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isTimeInRange = exports.isTimeSameOrBefore = exports.isTimeBefore = exports.isTimeSameOrAfter = exports.isTimeAfter = exports.isTimeSame = exports.dateTimeToTime = exports.isoStringToTime = void 0;

var isoStringToTime = function isoStringToTime() {
  var dateTimeString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var match = dateTimeString.match(/T(\d{2}):(\d{2})/);
  return match ? {
    hours: parseInt(match[1], 10),
    minutes: parseInt(match[2], 10)
  } : undefined;
};

exports.isoStringToTime = isoStringToTime;

var dateTimeToTime = function dateTimeToTime(dateTime) {
  return {
    hours: dateTime.getHours(),
    minutes: dateTime.getMinutes()
  };
};

exports.dateTimeToTime = dateTimeToTime;

var isTimeSame = function isTimeSame(timeA, timeB) {
  return timeA.hours === timeB.hours && timeA.minutes === timeB.minutes;
};

exports.isTimeSame = isTimeSame;

var isTimeAfter = function isTimeAfter(timeA, timeB) {
  return timeA.hours > timeB.hours || timeA.hours === timeB.hours && timeA.minutes > timeB.minutes;
};

exports.isTimeAfter = isTimeAfter;

var isTimeSameOrAfter = function isTimeSameOrAfter(timeA, timeB) {
  return isTimeSame(timeA, timeB) || isTimeAfter(timeA, timeB);
};

exports.isTimeSameOrAfter = isTimeSameOrAfter;

var isTimeBefore = function isTimeBefore(timeA, timeB) {
  return timeA.hours < timeB.hours || timeA.hours === timeB.hours && timeA.minutes < timeB.minutes;
};

exports.isTimeBefore = isTimeBefore;

var isTimeSameOrBefore = function isTimeSameOrBefore(timeA, timeB) {
  return isTimeSame(timeA, timeB) || isTimeBefore(timeA, timeB);
};

exports.isTimeSameOrBefore = isTimeSameOrBefore;

var isTimeInRange = function isTimeInRange(dateTime, startTime, endTime) {
  var time = dateTimeToTime(dateTime);
  return isTimeSameOrAfter(time, startTime) && isTimeSameOrBefore(time, endTime);
};

exports.isTimeInRange = isTimeInRange;