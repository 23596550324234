import {loadRentObjects} from "@awe-web/shared/lib/rent_objects/actions.js";
import {navigate, redirect} from "@awe-web/shared/lib/routing/actions.js";
import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {getFixedCoStationId} from "@awe-web/shared/lib/config/store.js";
import {
  getContractTypeForB2bLTR,
  getContractTypeForB2bSTR,
  getContractTypeForB2cLTR,
  getContractTypeForB2cSTR
} from "../../config/store.js";
import {getRentObjectStationId} from "../../rent_contracts/util.js";
import {
  B2C_SelectStationAndTime,
  B2B_SelectStationAndTime,
  B2C_STR_SelectExtras,
  B2C_LTR_SelectExtras,
  B2B_STR_SelectExtras,
  B2B_LTR_SelectExtras
} from "../../routing/routes.js";
import {
  setCoStation,
  setCiStation,
  setCoDateTime,
  setCiDateTime
} from "../../stations/actions.js";

const selectForReservationType = (reservationPath, isLtr, map) =>
  map[`${reservationPath} ${isLtr ? "LTR" : "STR"}`];

const getContractTypeForReservationType = (reservationPath, isLtr) =>
  selectForReservationType(reservationPath, isLtr, {
    "B2C STR": getContractTypeForB2cSTR,
    "B2C LTR": getContractTypeForB2cLTR,
    "B2B STR": getContractTypeForB2bSTR,
    "B2B LTR": getContractTypeForB2bLTR
  });

export const newInitializeSelectRentObjectScreen = ({reservationPath, isLtr}) => () => async (dispatch, getState, context) => {
  const {coStationId, ciStationId, coDateTime, ciDateTime} = context.history.location.state || {};
  if (!coStationId || !ciStationId || !coDateTime || !ciDateTime) {
    const SelectStationAndTime = reservationPath === "B2C"
      ? B2C_SelectStationAndTime
      : B2B_SelectStationAndTime;
    dispatch(redirect(SelectStationAndTime.location()));
    return;
  }

  const getContractType = getContractTypeForReservationType(reservationPath, isLtr);
  const contractType = getContractType(getState());
  const {
    coStationId: rentObjectStationId,
    pickupStationId: rentObjectPickupStationId
  } = getRentObjectStationId(coDateTime, ciDateTime, coStationId, reservationPath, getState());

  await Promise.all([
    dispatch(setCoDateTime(coDateTime)),
    dispatch(setCiDateTime(ciDateTime)),
    dispatch(setCoStation(coStationId)),
    dispatch(setCiStation(ciStationId)),
    dispatch(loadRentObjects(contractType, rentObjectStationId, rentObjectPickupStationId))
  ]);
};

export const newSelectRentObject = ({reservationPath, isLtr}) => (formValues) => async (dispatch, getState, context) => {
  const getContractType = getContractTypeForReservationType(reservationPath, isLtr);
  const contractType = getContractType(getState());
  const fixedCoStationId = getFixedCoStationId(getState());
  const {coStationId: userSelectedCoStationId, coDateTime, ciStationId, ciDateTime, classificationId} = formValues;

  const pickupStationId = fixedCoStationId ? userSelectedCoStationId : undefined;
  const coStationId = fixedCoStationId ? fixedCoStationId : userSelectedCoStationId;

  const rentContract = await dispatch(asyncAction({
    typePrefix: "SELECT_RENT_OBJECT/CREATE_RENT_CONTRACT",
    loadPayload: () => context.api.rentContracts.createRentContract({
      contractType,
      coStationId,
      coDateTime,
      ciStationId,
      ciDateTime,
      pickupStationId,
      classificationId
    })
  }));

  const SelectExtras = selectForReservationType(reservationPath, isLtr, {
    "B2C STR": B2C_STR_SelectExtras,
    "B2C LTR": B2C_LTR_SelectExtras,
    "B2B STR": B2B_STR_SelectExtras,
    "B2B LTR": B2B_LTR_SelectExtras
  });
  dispatch(navigate(SelectExtras.location({rentContractId: rentContract.id})));
};
