import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {loadStations} from "@awe-web/shared/lib/stations/actions.js";
import {loadDeliveryAddress} from "@awe-web/shared/lib/rent_contracts/actions.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {
  loadRentContractData,
  submitRentContract
} from "../../../rent_contracts/actions.js";
import {loadDefaultBankAccount} from "../../../user/actions.js";
import {B2B_LTR_SubmitRentContractSuccess} from "../../../routing/routes.js";

const TOS_ERROR = "ALG0000131";
const PRIVACY_ERROR = "ALG0000132";

export const initialize = () => async (dispatch, getState, context) => {
  const translate = newTranslate(getState());

  await Promise.all([
    dispatch(loadRentContractData({
      reservationPath: "B2B",
      additionalAction: (rentContract) => async (dispatch) => {
        await Promise.all([
          dispatch(loadStations([rentContract.type])),
          dispatch(loadDeliveryAddress(rentContract.id))
        ]);
      }
    })),
    dispatch(loadDefaultBankAccount())
  ]);

  dispatch({
    type: "USER/FORM_CONFIGURATION_LOADED",
    payload: {
      groupName: "submitB2bLtrRentContract",
      formConfiguration: [
        {
          items: [
            {
              name: "contractNote",
              xtype: "textfield",
              allowBlank: true
            },
            {
              name: "agbAccepted",
              invalidText: translate(TOS_ERROR),
              xtype: "checkbox",
              allowBlank: false
            },
            {
              name: "privacyAccepted",
              invalidText: translate(PRIVACY_ERROR),
              xtype: "checkbox",
              allowBlank: false
            }
          ]
        }
      ]
    }
  });
};

export const submit = (formValues) => async (dispatch, getState) => {
  const {contractNote, agbAccepted} = formValues;
  const rentContractId = getRentContractId(getState());
  await dispatch(submitRentContract(rentContractId, {contractNote, agbAccepted}));

  dispatch(navigate(B2B_LTR_SubmitRentContractSuccess.location({rentContractId})));
};
