"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.errorMessage = void 0;

var _immutable = require("immutable");

var _reselect = require("reselect");

function reducer(state, action) {
  switch (action.type) {
    case "ERRORS/GLOBAL_ERROR_RECEIVED":
      return state.update("serverErrors", (0, _immutable.List)(), function (errors) {
        return errors.push((0, _immutable.fromJS)(action.payload.error));
      });

    case "ERRORS/ERROR_DISMISSED":
      return state.update("serverErrors", (0, _immutable.List)(), function (errors) {
        return errors["delete"](0);
      }).set("errorWaitTimePending", true);

    case "ERRORS/WAIT_TIME_ELAPSED":
      return state.set("errorWaitTimePending", false);

    default:
      return state;
  }
}

var getCurrentError = function getCurrentError(state) {
  return state.get("errorWaitTimePending") ? undefined : state.get("serverErrors", (0, _immutable.List)()).first();
};

var errorMessage = (0, _reselect.createSelector)([getCurrentError], function (currentError) {
  return currentError ? currentError.get("displayMessage") : undefined;
});
exports.errorMessage = errorMessage;