import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {trim} from "@awe-web/shared/lib/util/string.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {Button} from "../../../components/form.js";
import {B2C_STR_SubmitRentContract, B2C_SelectStationAndTime} from "../../../routing/routes.js";

const REGISTRATION_EXTENDED = "FSD0000309";
const DATA_SUCCESSFULLY_SAVED = "FSD0000310";
const RESUME_RESERVATION = "FSD0000211";
const REGISTRATION_EXTENDED_MOOD_PICTURE = "FSD0000348";

const SignUpExtendedComponent = (props) => {
  const {location: {state}, translate} = props;
  const {rentContractId} = state || {};
  const signUpExtendedHeadImage = trim(translate(REGISTRATION_EXTENDED_MOOD_PICTURE));

  const resumeButtonTarget = rentContractId
    ? B2C_STR_SubmitRentContract.location({rentContractId})
    : B2C_SelectStationAndTime.location();

  return (
    <div className="sign-up-extended">
      <ScreenSection main dark>
        <TypeArea>
            <Row>
              <Col desktop={{cols: 8, offset: 2}}>
                <ScreenHeading><T code={REGISTRATION_EXTENDED} /></ScreenHeading>
                <p><T code={DATA_SUCCESSFULLY_SAVED} /></p>
              </Col>
            </Row>
            <Row alignBottom>
              <Col desktop={{cols: 4, offset: 2}} tablet={{cols: 4}}>
                <img className="sign-up-confirmed__head-image" src={signUpExtendedHeadImage} alt="" />
              </Col>
              <Col desktop={{cols: 4}} tablet={{cols: 4}}>
                <Button secondary continue to={resumeButtonTarget}><T code={RESUME_RESERVATION} /></Button>
              </Col>
            </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SignUpExtendedComponent.propTypes = {
  translate: PropTypes.func
};

const stateToSignUpExtendedProps = createStateToProps({
  translate: newTranslate
});

export default connect(stateToSignUpExtendedProps)(SignUpExtendedComponent);
