import {Map, fromJS} from "immutable";
import {createSelector} from "reselect";

export default function reducer(state, action) {
  switch (action.type) {
    case "APP/OPEN_MODAL":
      return state.set("modal", fromJS(action.payload));

    case "APP/CLOSE_MODAL":
      return state.delete("modal");

    case "APP/ADD_FLASH_MESSAGE":
      return state.set("flashMessage", fromJS(action.payload));

    case "APP/REMOVE_FLASH_MESSAGE":
      return state.delete("flashMessage");

    default: {
      const match = action.type.match(/(.*)_(LOADING|DONE)$/);
      return match
        ? state.setIn(["loading", match[1]], match[2] === "LOADING")
        : state;
    }
  }
}

export const isModalClosed = (state) => state.get("modal") === undefined;

export const getModalTitle = (state) => state.getIn(["modal", "title"]);

export const getModalMessage = (state) => state.getIn(["modal", "message"]);

export const getModalButtonLabel = (state) => state.getIn(["modal", "buttonLabel"]);

export const getFlashMessageCode = (state) => state.getIn(["flashMessage", "code"]);

const getLoadingMap = (state) => state.get("loading", Map());

export const isLoading = createSelector(
  [getLoadingMap, (_, props) => props.typePrefix],
  (loading, typePrefix) => loading.get(typePrefix, false)
);

export const getBlockingMessage = createSelector(
  [getLoadingMap, (_, props) => props.blockingActions],
  (loading, blockingActions = []) => {
    // List of action types that are currently loading
    const loadingTypes = Array.from(loading.entries()).filter(([, value]) => value).map(([key]) => key);

    // First entry in blockingActions object whose key matches a
    // loading action type
    const blockingAction = Object.entries(blockingActions).find(([key]) => loadingTypes.includes(key));

    return blockingAction ? blockingAction[1] : undefined;
  }
);
