"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cms = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _url = require("../util/url.js");

var _constants = require("../user/constants.js");

var _templateObject;

// ======================================================================
// Static CMS pages
// ======================================================================
var Cms = {
  path: function path() {
    return "/cms/:pageName";
  },
  location: function location(_ref) {
    var pageName = _ref.pageName;
    return {
      pathname: (0, _url.urlPath)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2["default"])(["/cms/", ""])), pageName),
      state: undefined
    };
  },
  allowedUserRoles: [_constants.userRoles.ANONYMOUS, _constants.userRoles.B2C, _constants.userRoles.B2B]
};
exports.Cms = Cms;