export default function reducer(state, action) {
  switch (action.type) {
    case "WINDOW/CURRENT_SCROLL_POSITION_UPDATED":
      return state.set("currentScrollPosition", action.payload.currentScrollPosition);

    case "WINDOW/CURRENT_VIEWPORT_WIDTH_UPDATED":
      return state.set("currentViewportWidth", action.payload.currentViewportWidth);

    default:
      return state;
  }
}

export const getCurrentScrollPosition = state => state.get("currentScrollPosition");
export const getCurrentViewportWidth = state => state.get("currentViewportWidth");
