import React from "react";
import classnames from "classnames";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {Button} from "../../../components/form.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TextLinkList} from "../../../components/text_link_list.js";
import {LoginForm} from "../../../user/components/login_form.js";
import {login} from "../../../user/actions.js";
import {
  B2C_RequestResetPasswordToken,
  B2C_SignUp,
  B2B_Login
} from "../../../routing/routes.js";
import {initialize} from "./actions.js";

import "./login.scss";

const HEADING = "ALG0000198";
const INTRODUCTION = "ALG0000199";
const LINK_RECOVER_PASSWORD = "ALG0000200";
const LINK_SIGN_UP = "ALG0000201";
const LINK_B2B_LOGIN = "ALG0000217";

const Login = (props) => {
  const {initialize, onSubmit, location: {state}} = props;
  const {rentContractId} = state || {};

  const isReady = useInitialize(initialize);
  const className = classnames("b2c-login", {"b2c-login--ready": isReady});

  return (
    <div className={className}>
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col
              tablet={{cols: 4, offset: 2}}
              desktop={{cols: 4, offset: 4}}
            >
              <ScreenHeading>
                <T code={HEADING} />
              </ScreenHeading>

              <p>
                <T code={LINK_B2B_LOGIN} to={B2B_Login.location()} />
              </p>

              <p className="b2c-login__introduction">
                <T code={INTRODUCTION} />
              </p>

              <LoginForm
                onSubmit={onSubmit}
                rentContractId={rentContractId}
              />

              <TextLinkList>
                <Button tertiary to={B2C_RequestResetPasswordToken.path()}>
                  <T code={LINK_RECOVER_PASSWORD} />
                </Button>
                <Button tertiary to={B2C_SignUp.location({rentContractId})}>
                  <T code={LINK_SIGN_UP} />
                </Button>
              </TextLinkList>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
Login.propTypes = {
  initialize: PropTypes.func,
  onSubmit: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      rentContractId: PropTypes.number
    })
  })
};

const stateToLoginProps = createStateToProps({
});

const dispatchToLoginProps = (dispatch, props) => {
  const {location: {state}} = props;
  const {rentContractId} = state || {};

  return {
    initialize: () => dispatch(initialize()),
    onSubmit: ({username, password}) => dispatch(login({username, password, rentContractId}))
  };
};

export default connect(stateToLoginProps, dispatchToLoginProps)(Login);
