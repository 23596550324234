import React, {useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik, useFormikContext} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions
} from "@awe-web/shared/lib/user/store.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {B2C_LTR_SelectExtras} from "../../../routing/routes.js";
import {Row, Col} from "../../../components/grid.js";
import {
  ScreenSection,
  ScreenHeading,
  ScreenSectionHeading,
  ScreenLoading
} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {
  Button,
  RequiredNotice,
  FormikLabeledField,
  FormikTextInput,
  FormikSelect
} from "../../../components/form.js";
import {SelectedStationAndTime} from "../../../stations/components/selected_station_and_time.js";
import {SelectedRentObject} from "../../../rent_objects/components/selected_rent_object.js";
import {getCoStation} from "../../../stations/store.js";
import {
  getProvisioningTypeB2c,
  getFormattedPickupPrice,
  getFormattedDeliveryPrice,
  getIsDeliveryEnabled
} from "../../../rent_contracts/store.js";
import {ProvisioningSelection} from "../../../rent_contracts/components/provisioning_selection.js";
import {StationAddress} from "../../../stations/components/station_card.js";
import {initialize, submit} from "./actions.js";

import "./select_provisioning.scss";

const SCREEN_HEADING = "ALG0000257";
const BACK = "ALG0000030";
const PROVISIONING_HEADING = "ALG0000067";
const OPTION_PICKUP = "ALG0000070";
const OPTION_DELIVERY_TO_CUSTOM_ADDRESS = "ALG0000258";
const IMAGE ="ALG0000247";
const SUBMIT = "ALG0000072";
const NOTICE = "FSD0000209";
const PRICES_NOTICE = "ALG0000244";

const SelectProvisioningForm = (props) => {
  const {
    translate,
    values: {provisioningType},
    handleSubmit,

    coStation,
    formattedDeliveryPrice,
    formattedPickupPrice,
    isDeliveryEnabled,

    isDeliveryFieldRequired,
    getDeliveryFieldLabel,
    getDeliverySelectFieldOptions,
    getDeliveryFieldPlaceholder
  } = props;
  const formik = useFormikContext();

  // Store formik in ref to avoid being forced to put it in the
  // dependency array which runs the effect below more often than it
  // needs to.
  const formikRef = useRef();
  formikRef.current = formik;

  // Untouch fields in delivery form every time it is opened in order to remove
  // previous error messages.
  useEffect(() => {
    if (provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS) {
      [
        "DeliveryContactPerson:name",
        "DeliveryContactPerson:surname",
        "DeliveryAddress:street",
        "DeliveryAddress:building",
        "DeliveryAddress:zipCode",
        "DeliveryAddress:city",
        "DeliveryAddress:countryCode",
        "DeliveryContactPerson:phone"
      ]
        .filter((name) => formikRef.current.touched[name])
        .forEach((name) => {
          formikRef.current.setFieldTouched(name, false);
        });
    }
  }, [provisioningType]);

  return (
    <form
      className="b2c-ltr-select-provisioning-form"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <ScreenHeading><T code={SCREEN_HEADING} /></ScreenHeading>
        </Col>
      </Row>

      <Row className="b2c-ltr-select-provisioning__form-row">
        <Col desktop={{cols: 4, offset: 2}}>
          <ScreenSectionHeading light>
            <T code={PROVISIONING_HEADING} />
          </ScreenSectionHeading>

          <div>
            <ProvisioningSelection
              name="provisioningType"
              value={provisioningTypes.PICKUP}
              label={<T code={OPTION_PICKUP} />}
              formattedPrice={formattedPickupPrice}
            >
              <StationAddress
                className="b2c-ltr-select-provisioning-form__station-address"
                stationNameClassName="b2c-ltr-select-provisioning-form__station-address__name"
                openingTimesHeadingClassName="b2c-ltr-select-provisioning-form__station-address__opening-times-heading"
                station={coStation}
              />
            </ProvisioningSelection>

            <ProvisioningSelection
              name="provisioningType"
              value={provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS}
              label={<T code={OPTION_DELIVERY_TO_CUSTOM_ADDRESS} />}
              formattedPrice={formattedDeliveryPrice}
              disabled={!isDeliveryEnabled}
            >
              <div
                className="b2c-ltr-select-provisioning-form__fieldset"
              >
                <FormikLabeledField
                  name="DeliveryContactPerson:name"
                  as={FormikTextInput}
                  isFieldRequired={isDeliveryFieldRequired}
                  getFieldLabel={getDeliveryFieldLabel}
                  placeholder={getDeliveryFieldPlaceholder("DeliveryContactPerson:name")}
                />

                <FormikLabeledField
                  name="DeliveryContactPerson:surname"
                  as={FormikTextInput}
                  isFieldRequired={isDeliveryFieldRequired}
                  getFieldLabel={getDeliveryFieldLabel}
                  placeholder={getDeliveryFieldPlaceholder("DeliveryContactPerson:surname")}
                />

                <FormikLabeledField
                  name="DeliveryAddress:street"
                  as={FormikTextInput}
                  isFieldRequired={isDeliveryFieldRequired}
                  getFieldLabel={getDeliveryFieldLabel}
                  placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:street")}
                />

                <FormikLabeledField
                  name="DeliveryAddress:building"
                  as={FormikTextInput}
                  isFieldRequired={isDeliveryFieldRequired}
                  getFieldLabel={getDeliveryFieldLabel}
                  placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:building")}
                />

                <div
                  className="b2c-ltr-select-provisioning-form__zip-code-and-city"
                >
                  <FormikLabeledField
                    className="b2c-ltr-select-provisioning-form__zip-code-and-city__zip-code"
                    name="DeliveryAddress:zipCode"
                    as={FormikTextInput}
                    isFieldRequired={isDeliveryFieldRequired}
                    getFieldLabel={getDeliveryFieldLabel}
                    placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:zipCode")}
                  />

                  <FormikLabeledField
                    className="b2c-ltr-select-provisioning-form__zip-code-and-city__city"
                    name="DeliveryAddress:city"
                    as={FormikTextInput}
                    isFieldRequired={isDeliveryFieldRequired}
                    getFieldLabel={getDeliveryFieldLabel}
                    placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:city")}
                  />
                </div>

                <FormikLabeledField
                  name="DeliveryAddress:countryCode"
                  as={FormikSelect}
                  isFieldRequired={isDeliveryFieldRequired}
                  getFieldLabel={getDeliveryFieldLabel}
                  options={getDeliverySelectFieldOptions("DeliveryAddress:countryCode")}
                />

                <FormikLabeledField
                  name="DeliveryContactPerson:phone"
                  as={FormikTextInput}
                  isFieldRequired={isDeliveryFieldRequired}
                  getFieldLabel={getDeliveryFieldLabel}
                  placeholder={getDeliveryFieldPlaceholder("DeliveryContactPerson:phone")}
                />
              </div>
            </ProvisioningSelection>
          </div>
        </Col>
        <Col className="b2c-ltr-select-provisioning__image-col" desktop={{cols: 4}}>
          <img src={translate(IMAGE)} alt="" />
        </Col>
      </Row>

      <Row className="b2c-ltr-select-provisioning__form-row">
        <Col desktop={{cols: 4, offset: 6}}>
          <Button primary submit continue><T code={SUBMIT} /></Button>
        </Col>
      </Row>
    </form>
  );
};
SelectProvisioningForm.propTypes = {
  translate: PropTypes.func.isRequired,
  values: PropTypes.shape({
    provisioningType: PropTypes.oneOf(Object.values(provisioningTypes))
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,

  coStation: StationAddress.propTypes.station,
  formattedDeliveryPrice: PropTypes.string,
  formattedPickupPrice: PropTypes.string,
  isDeliveryEnabled: PropTypes.bool,

  isDeliveryFieldRequired: PropTypes.func,
  getDeliveryFieldLabel: PropTypes.func,
  getDeliverySelectFieldOptions: PropTypes.func,
  getDeliveryFieldPlaceholder: PropTypes.func
};

const SelectProvisioningComponent = (props) => {
  const {
    translate,
    initialize,
    onSubmit,

    rentContractId,
    provisioningType,
    coStation,
    formattedDeliveryPrice,
    formattedPickupPrice,
    isDeliveryEnabled,

    getDeliveryFormFieldDefaultValues,
    validateDelivery,
    isDeliveryFieldRequired,
    getDeliveryFieldLabel,
    getDeliverySelectFieldOptions,
    getDeliveryFieldPlaceholder
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialValues = {
    ...getDeliveryFormFieldDefaultValues({
      "DeliveryContactPerson:name": "",
      "DeliveryContactPerson:surname": "",
      "DeliveryAddress:street": "",
      "DeliveryAddress:building": "",
      "DeliveryAddress:zipCode": "",
      "DeliveryAddress:city": "",
      "DeliveryAddress:countryCode": "",
      "DeliveryContactPerson:phone": ""
    }),
    provisioningType
  };
  const validate = (values) => values.provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS
    ? validateDelivery(values)
    : {};

  return (
    <div className="b2c-ltr-select-provisioning">
      <ScreenSection dark>
        <TypeArea>
          <SelectedStationAndTime reservationPath="B2C" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <SelectedRentObject
            className="b2c-ltr-select-provisioning__selected-rent-object"
            selectedBasicProperties="B2C"
            action={(
              <Button
                secondary
                back
                to={B2C_LTR_SelectExtras.location({rentContractId})}
              >
                <T code={BACK} />
              </Button>
            )}
          />
        </TypeArea>
      </ScreenSection>

      <ScreenSection>
        <TypeArea>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps) => (
              <SelectProvisioningForm
                {...formikProps}
                translate={translate}

                coStation={coStation}
                formattedDeliveryPrice={formattedDeliveryPrice}
                formattedPickupPrice={formattedPickupPrice}
                isDeliveryEnabled={isDeliveryEnabled}

                isDeliveryFieldRequired={isDeliveryFieldRequired}
                getDeliveryFieldLabel={getDeliveryFieldLabel}
                getDeliverySelectFieldOptions={getDeliverySelectFieldOptions}
                getDeliveryFieldPlaceholder={getDeliveryFieldPlaceholder}
              />
            )}
          </Formik>

          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <RequiredNotice className="b2c-ltr-select-provisioning__required-notice">
                <T code={NOTICE} />
              </RequiredNotice>
              <div><T code={PRICES_NOTICE} /></div>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SelectProvisioningComponent.propTypes = {
  translate: PropTypes.func,
  initialize: PropTypes.func,
  onSubmit: PropTypes.func,

  rentContractId: PropTypes.number,
  provisioningType: PropTypes.string,
  coStation: SelectProvisioningForm.propTypes.coStation,
  formattedDeliveryPrice: SelectProvisioningForm.propTypes.formattedDeliveryPrice,
  formattedPickupPrice: SelectProvisioningForm.propTypes.formattedPickupPrice,
  isDeliveryEnabled: SelectProvisioningForm.propTypes.isDeliveryEnabled,

  getDeliveryFormFieldDefaultValues: PropTypes.func,
  validateDelivery: PropTypes.func,
  isDeliveryFieldRequired: SelectProvisioningForm.propTypes.isDeliveryFieldRequired,
  getDeliveryFieldLabel: SelectProvisioningForm.propTypes.getDeliveryFieldLabel,
  getDeliverySelectFieldOptions: SelectProvisioningForm.propTypes.getDeliverySelectFieldOptions,
  getDeliveryFieldPlaceholder: SelectProvisioningForm.propTypes.getDeliveryFieldPlaceholder
};

const stateToSelectProvisioningProps = createStateToProps({
  translate: newTranslate,
  rentContractId: getRentContractId,
  provisioningType: getProvisioningTypeB2c,
  coStation: getCoStation,
  formattedDeliveryPrice: getFormattedDeliveryPrice,
  formattedPickupPrice: getFormattedPickupPrice,
  isDeliveryEnabled: getIsDeliveryEnabled,

  validateDelivery: newValidate("deliveryAddress12"),
  getDeliveryFormFieldDefaultValues: newGetInitialValues("deliveryAddress12"),
  isDeliveryFieldRequired: newIsFieldRequired("deliveryAddress12"),
  getDeliveryFieldLabel: newGetFieldLabel("deliveryAddress12"),
  getDeliveryFieldPlaceholder: newGetFieldPlaceholder("deliveryAddress12"),
  getDeliverySelectFieldOptions: newGetSelectFieldOptions("deliveryAddress12")
});

const dispatchToSelectProvisioningProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmit: (formValues) => dispatch(submit(formValues))
});

export default connect(
  stateToSelectProvisioningProps,
  dispatchToSelectProvisioningProps
)(SelectProvisioningComponent);
