import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {getCmsPageTitle, getCmsPageContent} from "@awe-web/shared/lib/cms/store.js";
import {ScreenSection, ScreenHeading} from "../../components/screen.js";
import {TypeArea} from "../../components/layout.js";
import {Row, Col} from "../../components/grid.js";
import {CmsContent} from "../../cms/components/cms_content.js";
import {PolicyPage} from "../../consent_controller/components/consent_controller.js";
import {loadCmsPage} from "../../cms/actions.js";

import "./cms.scss";

class CmsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false
    };
    this.isUnmounted = false;

    this.initialize = async () => {
      await this.props.loadCmsPage();
      if (!this.isUnmounted) {
        this.setState(() => ({isReady: true}));
      }
    };
  }

  async componentDidMount() {
    await this.initialize();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.pageName !== prevProps.pageName) {
      await this.initialize();
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {isReady} = this.state;
    const {pageTitle, pageName, pageContent} = this.props;

    return (
      <div>
        {isReady
          ? (
            <>
              <ScreenSection dark>
                <TypeArea>
                  <Row>
                    <Col desktop={{cols: 8, offset: 2}}>
                      <ScreenHeading className="cms__screen-heading">
                        {pageTitle}
                      </ScreenHeading>
                    </Col>
                  </Row>
                </TypeArea>
              </ScreenSection>
              <ScreenSection main>
                <TypeArea>
                  <Row>
                    <Col desktop={{cols: 8, offset: 2}}>
                      <CmsContent className="cms__content" pageName={pageName} pageContent={pageContent} />
                    </Col>
                  </Row>
                </TypeArea>
              </ScreenSection>

              {pageName === "cookies"
                ? (
                  <TypeArea>
                    <Row>
                      <Col>
                        <PolicyPage />
                      </Col>
                    </Row>
                  </TypeArea>
                )
                : null
              }
            </>
          )
          : null
        }
      </div>
    );
  }
}
CmsComponent.propTypes = {
  loadCmsPage: PropTypes.func,
  pageName: PropTypes.string,
  pageTitle: PropTypes.string,
  pageContent: PropTypes.string
};

const stateToProps = createStateToProps({
  pageName: (state, props) => props.match.params.pageName,
  pageTitle: (state, props) => getCmsPageTitle(state, {pageName: props.match.params.pageName}),
  pageContent: (state, props) => getCmsPageContent(state, {pageName: props.match.params.pageName})
});

const dispatchToProps = (dispatch, props) => ({
  loadCmsPage: () => dispatch(loadCmsPage(props.match.params.pageName))
});

export default connect(stateToProps, dispatchToProps)(CmsComponent);
