import React, {useCallback} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {useToggleVisibility} from "@awe-web/shared/lib/util/react.js";
import {withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Button} from "./form.js";
import closeIcon from "./assets/close.svg";

import "./more_infos_button.scss";

const MORE_INFOS = "ALG0000036";

let closeLastOverlay = () => {};

const MoreInfosButtonComponent = (props) => {
  const {translate, heading, content} = props;

  const {
    isComponentVisible,
    setComponentVisible
  } = useToggleVisibility();

  const handleButtonClick = useCallback(() => {
    if (isComponentVisible) {
      setComponentVisible(false);
      closeLastOverlay = () => {};
    }
    else {
      closeLastOverlay();
      closeLastOverlay = () => setComponentVisible(false);
      setComponentVisible(true);
    }
  }, [isComponentVisible, setComponentVisible]);
  const closeOverlay = useCallback(() => {
    setComponentVisible(false);
    closeLastOverlay = () => {};
  }, [setComponentVisible]);
  const preventEventBubbling = (event) => {
    event.preventDefault();
  };

  if (!heading || !content) {
    return null;
  }

  const className = classnames("more-infos-button", props.className);

  return (
    <div className={className} onClick={preventEventBubbling}>
      <Button
        quaternary
        onClick={handleButtonClick}
      >
        {translate(MORE_INFOS)}
      </Button>
      {isComponentVisible
        ? (
          <div className="more-infos-button__overlay">
            <div className="more-infos-button__overlay-title">
              {heading}
            </div>
            <div className="more-infos-button__overlay-content">
              {content}
            </div>
            <button className="more-infos-button__close-button" onClick={closeOverlay}>
              <img className="more-infos-button__close-icon" src={closeIcon} alt="Close" />
            </button>
          </div>
        )
        : null
      }
    </div>
  );
};
MoreInfosButtonComponent.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.node
};

export const MoreInfosButton = withTranslation(MoreInfosButtonComponent);
