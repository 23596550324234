import React from "react";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {Row, Col} from "../../../components/grid.js";
import {TypeArea} from "../../../components/layout.js";
import {Button} from "../../../components/form.js";
import {MyBmwRentDashboard} from "../../../routing/routes.js";

const MY_PROFILE = "FSD0000305";
const CONFIRMATION = "FSD0000334";
const TO_DASHBOARD = "FSD0000335";

const MyBmwRentProfileSuccess = (props) => {
  return (
    <div className="my-bmw-rent-profile">
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading><T code={MY_PROFILE} /></ScreenHeading>
            </Col>
          </Row>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <p><T code={CONFIRMATION} /></p>
            </Col>
          </Row>
          <Row>
            <Col desktop={{cols: 4, offset: 2}}>
              <Button secondary back to={MyBmwRentDashboard.location()}><T code={TO_DASHBOARD} /></Button>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
MyBmwRentProfileSuccess.propTypes = {
};

export default MyBmwRentProfileSuccess;
