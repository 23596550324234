import {numberOfPartialDays} from "@awe-web/shared/lib/util/date.js";
import {getFixedCoStationId} from "@awe-web/shared/lib/config/store.js";
import {
  rentContractToCiDateTime,
  rentContractToCoDateTime,
  rentContractToSelectedCoStationId
} from "@awe-web/shared/lib/rent_contracts/util.js";
import {
  getMinDaysForB2bLtr,
  getMinDaysForB2cLtr,
  getRentObjectStationIdForB2bLtr,
  getRentObjectStationIdForB2cLtr
} from "../config/store.js";

export const isLtr = (coDateTime, ciDateTime, thresholdInDays) => {
  return numberOfPartialDays(coDateTime, ciDateTime) >= thresholdInDays;
};

export const getRentObjectStationId = (coDateTime, ciDateTime, selectedCoStationId, reservationPath, state) => {
  const thresholdInDays = reservationPath === "B2B"
    ? getMinDaysForB2bLtr(state)
    : getMinDaysForB2cLtr(state);

  // The fixedCoStationId is introduced for the italian market. They want the
  // rentObjects to be fetched from a central station, but unlike the german
  // market we need to send the selected coStationId as pickupStationId so that
  // the prices are based on this station.
  const fixedCoStationId = getFixedCoStationId(state);
  if (fixedCoStationId) {
    return {
      coStationId: fixedCoStationId,
      pickupStationId: selectedCoStationId
    };
  }

  if (isLtr(coDateTime, ciDateTime, thresholdInDays)) {
    const rentObjectStationId = reservationPath === "B2B"
      ? getRentObjectStationIdForB2bLtr(state)
      : getRentObjectStationIdForB2cLtr(state);
    return {
      coStationId: rentObjectStationId ?? selectedCoStationId,
      pickupStationId: undefined
    };
  }
  else {
    return {
      coStationId: selectedCoStationId,
      pickupStationId: undefined
    };
  }
};

export const getRentObjectStationIdFromRentContract = (rentContract, reservationPath, state) => {
  const coDateTime = rentContractToCoDateTime(rentContract);
  const ciDateTime = rentContractToCiDateTime(rentContract);
  const selectedCoStationId = rentContractToSelectedCoStationId(rentContract);

  return getRentObjectStationId(coDateTime, ciDateTime, selectedCoStationId, reservationPath, state);
};
