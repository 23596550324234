import React, {createElement} from "react";
import {connect} from "react-redux";
import {Route as BaseRoute, Redirect} from "react-router";
import PropTypes from "prop-types";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {userRoles} from "@awe-web/shared/lib/user/constants.js";
import {B2C_Login, B2B_Login, B2B_SelectStationAndTime, B2C_SelectStationAndTime} from "../routes.js";
import {getUserRole} from "../../user/store.js";
import {isUserRoleAllowed} from "../util.js";
import {openModal} from "../../app/actions.js";

const TITLE_B2C_INSTEAD_OF_B2B = "ALG0000205";
const MESSAGE_B2C_INSTEAD_OF_B2B = "ALG0000206";

const TITLE_B2B_INSTEAD_OF_B2C = "ALG0000207";
const MESSAGE_B2B_INSTEAD_OF_B2C = "ALG0000208";

const TITLE_B2C_INSTEAD_OF_ANONYMOUS = "ALG0000211";
const MESSAGE_B2C_INSTEAD_OF_ANONYMOUS = "ALG0000212";

const TITLE_B2B_INSTEAD_OF_ANONYMOUS = "ALG0000209";
const MESSAGE_B2B_INSTEAD_OF_ANONYMOUS = "ALG0000210";

const BUTTON_LABEL = "ALG0000213";

const RouteComponent = (props) => {
  const {
    translate,
    userRole,
    component,
    render,
    children,
    openModal,
    ...rest
  } = props;

  const renderComponent = render || children || (routeProps => createElement(component, routeProps, null));

  return (
    <BaseRoute
      {...rest}
      render={
        (routeProps) => {
          if (isUserRoleAllowed(userRole, routeProps.location)) {
            return renderComponent(routeProps);
          }

          switch (userRole) {
            case userRoles.ANONYMOUS: {
              const state = routeProps.location.state || {};
              const {rentContractId} = state;

              return isUserRoleAllowed(userRoles.B2C, routeProps.location)
                ? <Redirect to={B2C_Login.location({redirectTo: routeProps.location, rentContractId})} />
                : <Redirect to={B2B_Login.location({redirectTo: routeProps.location, rentContractId})} />;
            }

            case userRoles.B2C: {
              if (isUserRoleAllowed(userRoles.B2B, routeProps.location)) {
                openModal(
                  translate(TITLE_B2C_INSTEAD_OF_B2B),
                  translate(MESSAGE_B2C_INSTEAD_OF_B2B),
                  translate(BUTTON_LABEL)
                );
              }
              else {
                openModal(
                  translate(TITLE_B2C_INSTEAD_OF_ANONYMOUS),
                  translate(MESSAGE_B2C_INSTEAD_OF_ANONYMOUS),
                  translate(BUTTON_LABEL)
                );
              }

              return <Redirect to={B2C_SelectStationAndTime.location()} />;
            }

            case userRoles.B2B: {
              if (isUserRoleAllowed(userRoles.B2C, routeProps.location)) {
                openModal(
                  translate(TITLE_B2B_INSTEAD_OF_B2C),
                  translate(MESSAGE_B2B_INSTEAD_OF_B2C),
                  translate(BUTTON_LABEL)
                );
              }
              else {
                openModal(
                  translate(TITLE_B2B_INSTEAD_OF_ANONYMOUS),
                  translate(MESSAGE_B2B_INSTEAD_OF_ANONYMOUS),
                  translate(BUTTON_LABEL)
                );
              }

              return <Redirect to={B2B_SelectStationAndTime.location()} />;
            }

            default:
              return;
          }
        }
      }
    />
  );
};
RouteComponent.propTypes = {
  ...BaseRoute.propTypes,
  translate: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  userRole: PropTypes.oneOf(Object.values(userRoles)).isRequired
};

const stateToRouteProps = createStateToProps({
  translate: newTranslate,
  userRole: getUserRole
});

const dispatchToRouteProps = (dispatch) => ({
  openModal: (title, message, buttonLabel) => dispatch(openModal(title, message, buttonLabel))
});

export const Route = connect(stateToRouteProps, dispatchToRouteProps)(RouteComponent);
