import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading, ScreenLoading} from "../../components/screen.js";
import {Row, Col} from "../../components/grid.js";
import {TypeArea} from "../../components/layout.js";
import {ResetPasswordEntryForm} from "./reset_password_entry_form.js";
import {initialize} from "./actions.js";

const SET_NEW_PASSWORD = "FSD0000351";

const ResetPasswordEntryComponent = (props) => {
  const {initialize} = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div className="my-bmw-rent-profile">
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 4, offset: 4}}>
              <ScreenHeading><T code={SET_NEW_PASSWORD} /></ScreenHeading>
            </Col>
          </Row>

          <Row>
            <Col desktop={{cols: 4, offset: 4}}>
              <ResetPasswordEntryForm />
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
ResetPasswordEntryComponent.propTypes = {
  initialize: PropTypes.func
};

const stateToResetPasswordEntryProps = createStateToProps({
});

const dispatchToResetPasswordEntryProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToResetPasswordEntryProps,
  dispatchToResetPasswordEntryProps
)(ResetPasswordEntryComponent);
