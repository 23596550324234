"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.getCmsPageContent = exports.getCmsPageTitle = exports.isValidPageName = void 0;

var _immutable = require("immutable");

var _reselect = require("reselect");

function reducer(state, action) {
  switch (action.type) {
    case "CMS/CMS_PAGES_LIST_LOADED":
      return state.set("cmsPages", (0, _immutable.fromJS)(action.payload.cmsPages));

    case "CMS/CMS_PAGE_LOADED":
      return state.setIn(["cms", action.payload.pageName], (0, _immutable.fromJS)(action.payload.cmsPage));

    default:
      return state;
  }
}

var getCmsPages = function getCmsPages(state) {
  return state.get("cmsPages", (0, _immutable.List)());
};

var getCmsPageNames = (0, _reselect.createSelector)([getCmsPages], function (cmsPages) {
  return cmsPages.map(function (page) {
    return page.get("name");
  });
});
var isValidPageName = (0, _reselect.createSelector)([getCmsPageNames], function (cmsPageNames) {
  return function (pageName) {
    return cmsPageNames.includes(pageName);
  };
});
exports.isValidPageName = isValidPageName;

var getCmsPagesByName = function getCmsPagesByName(state) {
  return state.get("cms", (0, _immutable.Map)());
};

var getPageName = function getPageName(state, _ref) {
  var pageName = _ref.pageName;
  return pageName;
};

var getCmsPageTitle = (0, _reselect.createSelector)([getCmsPagesByName, getPageName], function (cmsPagesByName, pageName) {
  return cmsPagesByName.getIn([pageName, "title"], "");
});
exports.getCmsPageTitle = getCmsPageTitle;
var getCmsPageContent = (0, _reselect.createSelector)([getCmsPagesByName, getPageName], function (cmsPagesByName, pageName) {
  return cmsPagesByName.getIn([pageName, "content"], "");
});
exports.getCmsPageContent = getCmsPageContent;