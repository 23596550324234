"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.isSidebarMenuOpen = void 0;

var _immutable = require("redux-burger-menu/immutable");

function reducer(state, action) {
  return state.update("burgerMenu", function (menuState) {
    return (0, _immutable.reducer)(menuState, action);
  });
}

var isSidebarMenuOpen = function isSidebarMenuOpen(state) {
  return state.getIn(["burgerMenu", "isOpen"], false);
};

exports.isSidebarMenuOpen = isSidebarMenuOpen;