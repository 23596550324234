import {Map, List, fromJS, is} from "immutable";
import {createSelector} from "reselect";
import {formatCurrency} from "@awe-web/shared/lib/util/number.js";
import {formatDate} from "@awe-web/shared/lib/util/date.js";
import {rentContractStates} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {getAmsBaseUrl} from "@awe-web/shared/lib/config/store.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {historyTabs} from "@awe-web/shared/lib/history/constants.js";
import {getHistoryPageSize} from "../config/store.js";

const RESERVATION = "FSD0000337";
const CHECK_OUT = "FSD0000338";
const CANCELLED = "FSD0000340";
const COMPLETED = "FSD0000339";

export default function reducer(state, action) {
  switch (action.type) {
    case "HISTORY/RESERVATION_HISTORY_LOADED": {
      const expectedParams = Map([
        ["page", getCurrentPage(state)],
        ["tab", getActiveTab(state)],
        ["filterValues", getFilterValues(state)]
      ]);
      const actualParams = fromJS(action.payload.params || {});
      return is(expectedParams, actualParams)
        ? state
            .set("reservationHistory", fromJS(action.payload.history))
            .set("numberOfHistoryItems", action.payload.totalNumberOfItems)
        : state;
    }

    case "USER/USER_LOGGED_OUT":
      return state
        .delete("reservationHistory")
        .delete("numberOfHistoryItems")
        .delete("historyPage")
        .delete("historyTab")
        .delete("historyFilter");

    case "HISTORY/PAGE_SELECTED":
      return state.set("historyPage", action.payload.page);

    case "HISTORY/TAB_SELECTED":
      return state.set("historyTab", action.payload.tab);

      case "HISTORY/FILTER_SELECTED":
        return state.set("historyFilter", fromJS(action.payload.filterValues));

    default:
      return state;
  }
}

const getRawReservationHistory = (state) =>
  state.get("reservationHistory", List());

export const getCurrentPage = (state) =>
  state.get("historyPage", 1);

export const getNumberOfPages = createSelector(
  [getHistoryPageSize, (state) => state.get("numberOfHistoryItems", 0)],
  (pageSize, numberOfItems) => Math.ceil(numberOfItems / pageSize)
);

export const getCsvExportUrl = createSelector(
  [getAmsBaseUrl],
  (amsBaseUrl) => `${amsBaseUrl}/v1.2/rentcontract/bookinghistorycsv`
);

export const getActiveTab = (state) =>
  state.get("historyTab", historyTabs.ACTIVE);

export const getFilterValues = createSelector(
  [
    (state) => state.getIn(["historyFilter", "registrationNumber"]),
    (state) => state.getIn(["historyFilter", "driverName"])
  ],
  (registrationNumber = "", driverName = "") =>
    Map([
      ["registrationNumber", registrationNumber],
      ["driverName", driverName]
    ])
);

const isCanceled = (reservationHistoryItem) =>
  Boolean(reservationHistoryItem.get("cancellationType"));

const isCancelable = (reservationHistoryItem) => [
  rentContractStates.PRE_RESERVATION,
  rentContractStates.RESERVATION
].includes(reservationHistoryItem.get("state")) && !isCanceled(reservationHistoryItem);

const stateToCode = (state) => ({
  [rentContractStates.PRE_RESERVATION]: RESERVATION,
  [rentContractStates.RESERVATION]: RESERVATION,
  [rentContractStates.CHECK_OUT]: CHECK_OUT,
}[state] || COMPLETED);

const newTranslateReservationState = createSelector(
  [newTranslate],
  (translate) => (reservationHistoryItem) => translate(
    isCanceled(reservationHistoryItem)
      ? CANCELLED
      : stateToCode(reservationHistoryItem.get("state"))
  )
);

export const getReservationHistory = createSelector(
  [getRawReservationHistory, newTranslateReservationState],
  (reservationHistory, translateReservationState) => reservationHistory
    .map(item => item
      .set("isCancelable", isCancelable(item))
      .set("stateDescription", translateReservationState(item))
      .set("formattedCoDateTime", formatDate(item.get("calcCheckOutTimestamp"), "dd.MM.yyyy, HH:mm"))
      .set("formattedCiDateTime", item.get("state") !== rentContractStates.CHECK_OUT
        ? formatDate(item.get("calcCheckInTimestamp"), "dd.MM.yyyy, HH:mm")
        : undefined
      )
      .set("formattedListPriceGross", formatCurrency(item.get("listPriceGross")))
      .set("formattedInvoiceAmountGross", formatCurrency(item.get("invoiceAmountGross"))))
);
