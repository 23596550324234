import "./layout.scss";

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const TypeArea = (props) => {
  const {children} = props;
  const className = classnames("type-area", props.className);

  return (
    <div className={className}>{children}</div>
  );
};
TypeArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
