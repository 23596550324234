import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {fieldsetProps} from "@awe-web/shared/lib/util/form.js";
import {merge} from "@awe-web/shared/lib/util/object.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  AddressFieldset,
  CreditCardsFieldset,
  IdentificationAndDriversLicenseFieldset
} from "../../../user/components/form.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions
} from "@awe-web/shared/lib/user/store.js";
import {Row, Col} from "../../../components/grid.js";
import {Button, ScrollToError} from "../../../components/form.js";
import {getCreditCardsEnabled} from "../../../config/store.js";
import {submit} from "./actions.js";

import "./sign_up_confirmed_form.scss";

const SUBMIT = "FSD0000236";

const Form = (props) => {
  const {
    creditCardsEnabled,
    handleSubmit
  } = props;

  return (
    <form className="sign-up-confirmed-form" onSubmit={handleSubmit}>
      <ScrollToError />
      <AddressFieldset {...fieldsetProps(props)} />
      {creditCardsEnabled && (
        <CreditCardsFieldset {...fieldsetProps(props)} />
      )}
      <IdentificationAndDriversLicenseFieldset
        className="sign-up-confirmed-form__identification-and-drivers-license-fieldset"
        {...fieldsetProps(props)}
      />

      <Row>
        <Col tablet={{cols: 4, offset: 4}} desktop={{cols: 4, offset: 6}}>
          <Button primary continue submit className="sign-up-confirmed-form__submit-button"><T code={SUBMIT} /></Button>
        </Col>
      </Row>
    </form>
  );
};
Form.valuesShape = merge(
  AddressFieldset.valuesShape,
  IdentificationAndDriversLicenseFieldset.valuesShape
);
Form.propTypes = {
  creditCardsEnabled: PropTypes.bool,
  values: PropTypes.shape(Form.valuesShape),
  getSelectFieldOptions: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func
};

const SignUpConfirmedFormComponent = (props) => {
  const {
    rentContractId,
    creditCardsEnabled,
    isFieldRequired,
    getInitialValues,
    getSelectFieldOptions,
    getFieldLabel,
    getFieldPlaceholder,
    validate,
    onSubmit
  } = props;
  const initialValues = getInitialValues({
    rentContractId,
    [AddressFieldset.STREET]: "",
    [AddressFieldset.BUILDING]: "",
    [AddressFieldset.ZIP_CODE]: "",
    [AddressFieldset.CITY]: "",
    [AddressFieldset.COUNTRY_CODE]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_TYPE]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_NO]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_COUNTRY]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_AUTHORITY]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_DATE_ISSUED]: undefined,
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_VALID_TILL]: undefined,
    [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_NO]: "",
    [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_COUNTRY]: "",
    [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_DATE_ISSUED]: undefined
  });

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {(formikProps) => (
        <Form
          {...formikProps}
          creditCardsEnabled={creditCardsEnabled}
          isFieldRequired={isFieldRequired}
          getFieldLabel={getFieldLabel}
          getFieldPlaceholder={getFieldPlaceholder}
          getSelectFieldOptions={getSelectFieldOptions}
        />
      )}
    </Formik>
  );
};
SignUpConfirmedFormComponent.propTypes = {
  rentContractId: PropTypes.number,
  creditCardsEnabled: Form.propTypes.creditCardsEnabled,
  validate: PropTypes.func,
  getInitialValues: PropTypes.func,
  isFieldRequired: PropTypes.func,
  getFieldLabel: Form.propTypes.getFieldLabel,
  getFieldPlaceholder: Form.propTypes.getFieldPlaceholder,
  getSelectFieldOptions: Form.propTypes.getSelectFieldOptions,
  onSubmit: PropTypes.func
};

const stateToSignUpConfirmedFormProps = createStateToProps({
  creditCardsEnabled: getCreditCardsEnabled,
  validate: newValidate("extendRegistration"),
  getInitialValues: newGetInitialValues("extendRegistration"),
  isFieldRequired: newIsFieldRequired("extendRegistration"),
  getFieldLabel: newGetFieldLabel("extendRegistration"),
  getFieldPlaceholder: newGetFieldPlaceholder("extendRegistration"),
  getSelectFieldOptions: newGetSelectFieldOptions("extendRegistration")
});

const dispatchToSignUpConfirmedFormProps = (dispatch) => ({
  onSubmit: (values, formikProps) => dispatch(submit(values, formikProps))
});

export const SignUpConfirmedForm = connect(
  stateToSignUpConfirmedFormProps,
  dispatchToSignUpConfirmedFormProps
)(SignUpConfirmedFormComponent);
