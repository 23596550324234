import React, {Component, createRef, forwardRef} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import {substitute} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {getCmsPageContent, getCmsPageTitle} from "@awe-web/shared/lib/cms/store.js";
import {Overlay, OverlayHeading, OverlaySection} from "./overlay.js";
import {Button} from "./form.js";
import {loadCmsPage} from "../cms/actions.js";

import "./cms_overlay.scss";

const BACK = "FSD0000105";

class CmsOverlayComponent extends Component {
  async componentDidMount() {
    await this.props.loadCmsPage();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.pageName !== prevProps.pageName) {
      await this.props.loadCmsPage();
    }
  }

  render() {
    // react-redux `connect` messes up ref forwarding when the prop is
    // named `forwardedRef`!
    const {myForwardedRef, pageTitle, pageContent} = this.props;

    return (
      <Overlay ref={myForwardedRef} className="cms-overlay">
        {({closeOverlay}) => (
          <>
            <OverlayHeading>{pageTitle}</OverlayHeading>
            <OverlaySection>
              <div className="cms-overlay__content" dangerouslySetInnerHTML={{__html: pageContent}} />
            </OverlaySection>
            <OverlaySection>
              <Button
                  secondary
                  back
                  className="cms-overlay__close-button"
                  onClick={closeOverlay}>
                <T code={BACK} />
              </Button>
            </OverlaySection>
          </>
        )}
      </Overlay>
    );
  }
}
CmsOverlayComponent.propTypes = {
  myForwardedRef: PropTypes.shape({current: PropTypes.object}),
  pageName: PropTypes.string,
  pageTitle: PropTypes.string,
  pageContent: PropTypes.string
};

const stateToProps = createStateToProps({
  pageTitle: getCmsPageTitle,
  pageContent: getCmsPageContent
});

const dispatchToProps = (dispatch, props) => ({
  loadCmsPage: () => dispatch(loadCmsPage(props.pageName))
});

const CmsOverlayConnected = connect(stateToProps, dispatchToProps)(CmsOverlayComponent);

export const CmsOverlay = forwardRef((props, ref) => (
  <CmsOverlayConnected {...props} myForwardedRef={ref} />
));

export class TextWithCmsLink extends Component {
  constructor(props) {
    super(props);

    this.overlayRef = createRef();
    this.openOverlay = (event) => {
      event.preventDefault();
      const overlay = this.overlayRef.current;
      if (overlay) {
        overlay.openOverlay();
      }
    };
  }

  render() {
    const {openOverlay} = this;
    const {children, pageName, data} = this.props;
    const className = classnames("text-with-cms-link", this.props.className);

    return (
      <>
        <span className={className}>{substitute(children, data, {onLinkedTextClick: openOverlay})}</span>
        <CmsOverlay ref={this.overlayRef} pageName={pageName} />
      </>
    );
  }
}
TextWithCmsLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  pageName: PropTypes.string,
  data: PropTypes.object
};
