import {createSelector} from "reselect";
import {createDynamicListSelector} from "@awe-web/shared/lib/l10n/store.js";
import {isValidPageName} from "@awe-web/shared/lib/cms/store.js";

const getPromotionCards = (cardListSelector) => createSelector(
  [cardListSelector, isValidPageName],
  (promotionCardList, isValidPageName) => promotionCardList
    // Remove invalid `pageName` property
    .map(promotionCard => !isValidPageName(promotionCard.get("pageName"))
           ? promotionCard.remove("pageName")
           : promotionCard)
    // Keep items that have a 'heading', 'text', 'image' and either
    // an existing 'pageName' or a 'url'
    .filter(promotionCard =>
              promotionCard.get("heading")
                && promotionCard.get("text")
                && (isValidPageName(promotionCard.get("pageName")) || promotionCard.get("link"))
                && promotionCard.get("image"))
);

export const getStrPromotionCards = getPromotionCards(createDynamicListSelector({
  "FSDTeaserHeader": "heading",
  "FSDTeaserText": "text",
  "FSDTeaserLink": "link",
  "FSDTeaserPage": "pageName",
  "FSDTeaserPicture": "image"
}));

export const getLtrPromotionCards = getPromotionCards(createDynamicListSelector({
  "LtrTeaserHeader": "heading",
  "LtrTeaserText": "text",
  "LtrTeaserLink": "link",
  "LtrTeaserPage": "pageName",
  "LtrTeaserPicture": "image"
}));
