import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useToggleVisibility} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {fieldsetProps} from "@awe-web/shared/lib/util/form.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  newGetSelectFieldOptions,
  newValidate,
  newGetInitialValues,
  newGetFieldLabel,
  newIsFieldRequired,
  newGetFieldPlaceholder,
  newHasField
} from "@awe-web/shared/lib/user/store.js";
import {Checkbox} from "@awe-web/shared/lib/components/form.js";
import {ScreenSectionHeading} from "../../../components/screen.js";
import {
  ScrollToError,
  FormikTextInput,
  FormikSelect,
  LabeledCheckbox,
  LabeledRadioButton,
  FormikRadioButton,
  FormikErrorMessage,
  Button,
  RequiredNotice,
  FormikLabeledField
} from "../../../components/form.js";
import {Row, Col} from "../../../components/grid.js";
import {ConsentFieldset} from "../../../user/components/form.js";
import {submit} from "./actions.js";

import "./sign_up_request_form.scss";

const COMPANY_ADDRESS_FORM_HEADING = "ALG0000049";
const CONTACT_PERSON_FORM_HEADING = "ALG0000050";
const MOBILITY_NEEDS_FORM_HEADING = "ALG0000051";
const PAYMENT_METHOD_FORM_HEADING = "ALG0000052";
const LEGAL_FORM_HEADING = "ALG0000053";
const BUTTON_LABEL = "ALG0000054";
const NOTICE = "FSD0000209";
const KEY_ACCOUNT_NUMBER_LABEL = "ALG0000057";

const LabeledRadioButtons = (props) => {
  const {name, options} = props;

  return options.map(({key, value}) =>
    <LabeledRadioButton key={key}>
      <FormikRadioButton
        name={name}
        value={key}
      />
      <div>{value}</div>
    </LabeledRadioButton>
  );
};
LabeledRadioButtons.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  }))
};

const Form = (props) => {
  const {
    values,
    isFieldRequired,
    getFieldLabel,
    getFieldPlaceholder,
    getSelectFieldOptions,
    handleChange,
    handleSubmit
  } = props;

  const {
    isComponentVisible: isKeyAccountVisible,
    setComponentVisible: setKeyAccountVisible
  } = useToggleVisibility();

  const newOnKeyAccountClick = useCallback((name) => (event) => {
    setKeyAccountVisible(event.target.checked);
    if (!event.target.checked) {
      // Set value of text input
      handleChange({target: {name, value: ""}});
    }
  }, [setKeyAccountVisible, handleChange]);

  const hasField = (name) => name in values;

  return (
    <form className="b2b-sign-up-request-form" onSubmit={handleSubmit}>
      <ScrollToError />
      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <ScreenSectionHeading light className="b2b-sign-up-request-form__heading">
            <T code={COMPANY_ADDRESS_FORM_HEADING} />
          </ScreenSectionHeading>
        </Col>
        {/* CompanyAddressFieldset */}
        <Col
          desktop={{cols: 4, offset: 2}}
          className="b2b-sign-up-request-form__row"
        >
          <FormikLabeledField
            name="Customer:surname"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Customer:surname")}
          />
        </Col>

        {hasField("Customer:taxident") && (
          <Col
            desktop={{cols: 4}}
            className="b2b-sign-up-request-form__row"
          >
            <div className="b2b-sign-up-request-form__tax-ident-and-ito-ident">
              <FormikLabeledField
                className="b2b-sign-up-request-form__tax-ident"
                name="Customer:taxident"
                as={FormikTextInput}
                getFieldLabel={getFieldLabel}
                isFieldRequired={isFieldRequired}
                placeholder={getFieldPlaceholder("Customer:taxident")}
              />

              <FormikLabeledField
                className="b2b-sign-up-request-form__ito-ident"
                name="Customer:itoident"
                as={FormikTextInput}
                getFieldLabel={getFieldLabel}
                isFieldRequired={isFieldRequired}
                placeholder={getFieldPlaceholder("Customer:itoident")}
              />
            </div>
          </Col>
        )}
      </Row>

      <Row>
        <Col
          desktop={{cols: 4, offset: 2}}
          className="b2b-sign-up-request-form__row"
        >
          <FormikLabeledField
            name="Address:street"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Address:street")}
          />

          <FormikLabeledField
            name="Address:building"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Address:building")}
          />
        </Col>

        <Col
          desktop={{cols: 4}}
          className="b2b-sign-up-request-form__row"
        >
          <div className="b2b-sign-up-request-form__zip-code-and-city">
            <FormikLabeledField
              className="b2b-sign-up-request-form__zip-code"
              name="Address:zipCode"
              as={FormikTextInput}
              getFieldLabel={getFieldLabel}
              isFieldRequired={isFieldRequired}
              placeholder={getFieldPlaceholder("Address:zipCode")}
            />

            <FormikLabeledField
              className="b2b-sign-up-request-form__city"
              name="Address:city"
              as={FormikTextInput}
              getFieldLabel={getFieldLabel}
              isFieldRequired={isFieldRequired}
              placeholder={getFieldPlaceholder("Address:city")}
            />
          </div>

          <FormikLabeledField
            name="Address:countryCode"
            as={FormikSelect}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            options={getSelectFieldOptions("Address:countryCode")}
          />
        </Col>
      </Row>

      <Row>
        <Col
          desktop={{cols: 4, offset: 2}}
          className="b2b-sign-up-request-form__row"
        >
          <LabeledCheckbox>
            <Checkbox
              defaultChecked={isKeyAccountVisible}
              onChange={newOnKeyAccountClick("bmwCorporateCustomerNumber")}
              className="b2b-sign-up-request-form__key-account-number-checkbox"
            />
            <T code={KEY_ACCOUNT_NUMBER_LABEL} />
          </LabeledCheckbox>
          {isKeyAccountVisible
            ? (
              <FormikTextInput
                name="bmwCorporateCustomerNumber"
                placeholder={getFieldPlaceholder("bmwCorporateCustomerNumber")}
              />
            )
            : null
          }
        </Col>
      </Row>

      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <hr className="b2b-sign-up-request-form__separator" />
        </Col>
      </Row>

      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <ScreenSectionHeading light className="b2b-sign-up-request-form__heading">
            <T code={CONTACT_PERSON_FORM_HEADING} />
          </ScreenSectionHeading>
        </Col>
        {/* ContactPersonFieldset */}
        <Col
          desktop={{cols: 4, offset: 2}}
          className="b2b-sign-up-request-form__row"
        >
          <FormikLabeledField
            name="Person:prefixKey"
            as={FormikSelect}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            options={getSelectFieldOptions("Person:prefixKey")}
          />
        </Col>
      </Row>
      <Row>
        <Col
          desktop={{cols: 4, offset: 2}}
          className="b2b-sign-up-request-form__row"
        >
          <FormikLabeledField
            name="Person:name"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Person:name")}
          />

          <FormikLabeledField
            name="Person:surname"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Person:surname")}
          />
        </Col>
        <Col
          desktop={{cols: 4}}
          className="b2b-sign-up-request-form__row"
        >
          <FormikLabeledField
            name="Person:email"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Person:email")}
          />

          <FormikLabeledField
            name="Person:phone"
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder("Person:phone")}
          />
        </Col>
      </Row>

      {hasField("rentalsPerYear") && (
        <>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <hr className="b2b-sign-up-request-form__separator" />
            </Col>
          </Row>

          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenSectionHeading light className="b2b-sign-up-request-form__heading">
                <T code={MOBILITY_NEEDS_FORM_HEADING} />
              </ScreenSectionHeading>
            </Col>
            <Col
              desktop={{cols: 4, offset: 2}}
              className="b2b-sign-up-request-form__row"
            >
              <FormikLabeledField
                name="rentalsPerYear"
                as={FormikSelect}
                getFieldLabel={getFieldLabel}
                isFieldRequired={isFieldRequired}
                options={getSelectFieldOptions("rentalsPerYear")}
              />
              <FormikLabeledField
                name="averageRentalDuration"
                as={FormikSelect}
                getFieldLabel={getFieldLabel}
                isFieldRequired={isFieldRequired}
                options={getSelectFieldOptions("averageRentalDuration")}
              />
            </Col>
            <Col
              desktop={{cols: 4}}
              className="b2b-sign-up-request-form__row"
            >
              <FormikLabeledField
                name="fleetSize"
                as={FormikSelect}
                getFieldLabel={getFieldLabel}
                isFieldRequired={isFieldRequired}
                options={getSelectFieldOptions("fleetSize")}
              />
              <FormikLabeledField
                name="preferredVehicleCategory"
                as={FormikTextInput}
                getFieldLabel={getFieldLabel}
                isFieldRequired={isFieldRequired}
                placeholder={getFieldPlaceholder("preferredVehicleCategory")}
              />
            </Col>
          </Row>
        </>
      )}

      {hasField("paymentMethod") && (
        <>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <hr className="b2b-sign-up-request-form__separator" />
            </Col>
          </Row>

          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenSectionHeading light className="b2b-sign-up-request-form__heading">
                <T code={PAYMENT_METHOD_FORM_HEADING} />
              </ScreenSectionHeading>
            </Col>
            <Col
              desktop={{cols: 4, offset: 2}}
              className="b2b-sign-up-request-form__row"
            >
              <LabeledRadioButtons
                name="paymentMethod"
                options={getSelectFieldOptions(
                  "paymentMethod",
                  {includeEmptyOption: false}
                )}
              />
              <FormikErrorMessage name="paymentMethod" />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <hr className="b2b-sign-up-request-form__separator" />
        </Col>
      </Row>

      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <ScreenSectionHeading light className="b2b-sign-up-request-form__heading">
            <T code={LEGAL_FORM_HEADING} />
          </ScreenSectionHeading>
        </Col>
        {/* ConsentFieldset */}
      </Row>

      <ConsentFieldset
        {...fieldsetProps(props)}
      />

      <Row className="b2b-sign-up-request-form__actions">
        <Col desktop={{cols: 4, offset: 6}}>
          <Button submit primary continue>
            <T code={BUTTON_LABEL} />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <RequiredNotice><T code={NOTICE} /></RequiredNotice>
        </Col>
      </Row>
    </form>
  );
};
Form.propTypes = {
  values: PropTypes.object,
  isFieldRequired: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  getSelectFieldOptions: PropTypes.func,
  handleSubmit: PropTypes.func
};

const SignUpRequestFormComponent = (props) => {
  const {
    getInitialValues,
    hasField,
    isFieldRequired,
    validate,
    getFieldLabel,
    getFieldPlaceholder,
    getSelectFieldOptions,
    onSubmit
  } = props;
  const initialValues = getInitialValues({
    "Customer:surname": "",
    "Address:street": "",
    "Address:building": "",
    "Address:zipCode": "",
    "Address:city": "",
    "Address:countryCode": "",
    "bmwCorporateCustomerNumber": "",
    "Person:prefixKey": "",
    "Person:name": "",
    "Person:surname": "",
    "Person:email": "",
    "Person:phone": "",
    [ConsentFieldset.MAIL]: false,
    [ConsentFieldset.EMAIL]: false,
    [ConsentFieldset.PHONE]: false,
    ...(hasField("Customer:taxident") ? {"Customer:taxident": ""} : {}),
    ...(hasField("Customer:itoident") ? {"Customer:itoident": ""} : {}),
    ...(hasField("paymentMethod") ? {"paymentMethod": ""} : {}),
    ...(hasField("rentalsPerYear") ? {"rentalsPerYear": ""} : {}),
    ...(hasField("averageRentalDuration") ? {"averageRentalDuration": ""} : {}),
    ...(hasField("fleetSize") ? {"fleetSize": ""} : {}),
    ...(hasField("preferredVehicleCategory") ? {"preferredVehicleCategory": ""} : {})
  });

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form
          {...formikProps}
          isFieldRequired={isFieldRequired}
          getFieldLabel={getFieldLabel}
          getFieldPlaceholder={getFieldPlaceholder}
          getSelectFieldOptions={getSelectFieldOptions}
        />
      )}
    </Formik>
  );
};
SignUpRequestFormComponent.props = {
  getInitialValues: PropTypes.func,
  hasField: PropTypes.func,
  isFieldRequired: PropTypes.func,
  validate: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  getSelectFieldOptions: PropTypes.func,
  onSubmit: PropTypes.func
};

const stateToSignUpRequestFormProps = createStateToProps({
  validate: newValidate("registerApplicant"),
  getInitialValues: newGetInitialValues("registerApplicant"),
  hasField: newHasField("registerApplicant"),
  isFieldRequired: newIsFieldRequired("registerApplicant"),
  getFieldLabel: newGetFieldLabel("registerApplicant"),
  getFieldPlaceholder: newGetFieldPlaceholder("registerApplicant"),
  getSelectFieldOptions: newGetSelectFieldOptions("registerApplicant")
});

const dispatchToSignUpRequestFormProps = (dispatch) => ({
  onSubmit: (values) => dispatch(submit(values))
});

export const SignUpRequestForm = connect(
  stateToSignUpRequestFormProps,
  dispatchToSignUpRequestFormProps
)(SignUpRequestFormComponent);
