import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  getUserEmail,
  newValidate,
  newGetInitialValues,
  newGetFieldLabel,
  newIsFieldRequired,
  newHasField
} from "@awe-web/shared/lib/user/store.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSection, ScreenHeading, ScreenSectionHeading, ScreenLoading} from "../../../components/screen.js";
import {
  FormikLabeledField,
  Button,
  FormikPasswordInput,
  FormikTextInput
} from "../../../components/form.js";
import {ConsentFieldset} from "../../../user/components/form.js";
import {getCompanyAddress, getSalesTaxId, getContactPerson, getIsProfileReadOnly} from "../../../user/store.js";
import {initialize, submitPassword, submitConsent, submitTaxData} from "./actions.js";

import "./profile.scss";

const PROFILE = "ALG0000120";
const ADDRESS_HEADING = "ALG0000121";
const TAX_DATA_HEADING = "ALG0000267";
const CONTACT_HEADING = "ALG0000122";
const SALES_TAX_ID = "ALG0000123";
const CONTACT_E_MAIL_ADDRESS = "ALG0000124";
const CONTACT_PHONE_NUMBER = "ALG0000125";
const LOGIN_CREDENTIALS_HEADING = "ALG0000126";
const E_MAIL = "ALG0000127";
const LEGAL_HEADING = "ALG0000129";
const SUBMIT = "ALG0000130";

const Line = ({value}) => value
  ? <div>{value}</div>
  : null;

const ProfileComponent = (props) => {
  const {
    initialize,
    translate,
    isReadOnly,
    companyAddress,
    salesTaxId,
    contactPersonDetails,
    userEmail,

    validateTaxData,
    getTaxDataInitialValues,
    isTaxDataFieldRequired,
    getTaxDataFieldLabel,
    hasTaxDataField,
    onSubmitTaxData,

    getConsentInitialValues,
    getConsentFieldLabel,
    validateConsent,
    onSubmitConsent,

    getPasswordInitialValues,
    getPasswordFieldLabel,
    isPasswordFieldRequired,
    validatePassword,
    onSubmitPassword
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialTaxDataValues = getTaxDataInitialValues({
    "configuration": "changeBusinessCustomer",
    ...(hasTaxDataField("Customer:taxident") ? {"Customer:taxident": ""} : {}),
    ...(hasTaxDataField("Customer:itoident") ? {"Customer:itoident": ""} : {})
  });

  const initialConsentValues = getConsentInitialValues({
    "configuration": "BMWChangeCommunicationConfirmation",
    [ConsentFieldset.MAIL]: false,
    [ConsentFieldset.EMAIL]: false,
    [ConsentFieldset.PHONE]: false
  });

  const initialPasswordValues = getPasswordInitialValues({
    "Customer:currentPassword": "",
    "Customer:password": ""
  });

  return (
    <ScreenSection dark className="b2b-profile">
      <TypeArea>
        <Row>
          <Col desktop={{cols: 8, offset: 2}}>
            <ScreenHeading>{translate(PROFILE)}</ScreenHeading>
          </Col>
        </Row>

        <Row>
          <Col tablet={{cols: 4}} desktop={{cols: 3, offset: 2}}>
            <ScreenSectionHeading light>
              {translate(ADDRESS_HEADING)}
            </ScreenSectionHeading>

            <div className="b2b-profile__address">
              <div className="b2b-profile__company-name">
                {companyAddress.companyName}
              </div>
              <Line value={companyAddress.street} />
              <Line value={companyAddress.building} />
              <Line value={`${companyAddress.zipCode} ${companyAddress.city}`} />
              <Line value={companyAddress.country} />
            </div>

            <div className="b2b-profile__sales-tax">
              <div className="b2b-profile__sales-tax-heading">{translate(SALES_TAX_ID)}</div>
              <div>{salesTaxId}</div>
            </div>

            <hr className="b2b-profile__separator b2b-profile__separator--mobile" />
          </Col>

          <Col tablet={{cols: 4}} desktop={{cols: 3}}>
            {contactPersonDetails && (
              <>
                <ScreenSectionHeading light>
                  {translate(CONTACT_HEADING)}
                </ScreenSectionHeading>

                <div className="b2b-profile__contact-person-name">
                  {contactPersonDetails.fullName}
                </div>

                <div className="b2b-profile__contact-detail b2b-profile__contact-detail--gap">
                  <div className="b2b-profile__contact-person-label">
                    {translate(CONTACT_E_MAIL_ADDRESS)}
                  </div>
                  <div>{contactPersonDetails.email}</div>
                </div>

                <div className="b2b-profile__contact-detail">
                  <div className="b2b-profile__contact-person-label">
                    {translate(CONTACT_PHONE_NUMBER)}
                  </div>
                  <div>{contactPersonDetails.phone}</div>
                </div>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col desktop={{cols: 8, offset: 2}}>
            <hr className="b2b-profile__separator" />
          </Col>
        </Row>

        {hasTaxDataField("Customer:taxident") && (
          <>
            <Row>
              <Col tablet={{cols: 4}} desktop={{cols: 3, offset: 2}}>
                <ScreenSectionHeading light>
                  {translate(TAX_DATA_HEADING)}
                </ScreenSectionHeading>
              </Col>
            </Row>

            <Formik
              initialValues={initialTaxDataValues}
              validate={validateTaxData}
              onSubmit={onSubmitTaxData}
            >
              {(formikProps) => (
                <form onSubmit={formikProps.handleSubmit}>
                  <Row className="b2b-profile__tax-data-fields">
                    <Col
                      tablet={{cols: 4}}
                      desktop={{cols: 4, offset: 2}}
                    >
                      <FormikLabeledField
                        className="b2b-profile__form-row"
                        name="Customer:taxident"
                        as={FormikTextInput}
                        isFieldRequired={isTaxDataFieldRequired}
                        getFieldLabel={getTaxDataFieldLabel}
                        disabled={isReadOnly}
                      />
                    </Col>
                    <Col
                      tablet={{cols: 4}}
                      desktop={{cols: 4}}
                    >
                      <FormikLabeledField
                        className="b2b-profile__form-row"
                        name="Customer:itoident"
                        as={FormikTextInput}
                        isFieldRequired={isTaxDataFieldRequired}
                        getFieldLabel={getTaxDataFieldLabel}
                        disabled={isReadOnly}
                      />
                    </Col>
                  </Row>
                  {!isReadOnly && (
                    <Row>
                      <Col
                        tablet={{cols: 4, offset: 4}}
                        desktop={{cols: 4, offset: 6}}
                      >
                        <Button
                          submit
                          primary
                          continue
                          disabled={formikProps.isSubmitting}
                        >
                          {translate(SUBMIT)}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </form>
              )}
            </Formik>

            <Row>
              <Col desktop={{cols: 8, offset: 2}}>
                <hr className="b2b-profile__separator" />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col
            tablet={{cols: 4}}
            desktop={{cols: 4, offset: 2}}
          >
            <ScreenSectionHeading light>
              {translate(LOGIN_CREDENTIALS_HEADING)}
            </ScreenSectionHeading>

            <div className={classNames("b2b-profile__contact-detail", {"b2b-profile__contact-detail--gap": !isReadOnly})}>
              <div className="b2b-profile__login-label">{translate(E_MAIL)}</div>
              <div>{userEmail}</div>
            </div>
          </Col>
        </Row>

        {!isReadOnly && (
          <Formik
            initialValues={initialPasswordValues}
            validate={validatePassword}
            onSubmit={onSubmitPassword}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit}>
                <Row className="b2b-profile__password-fields">
                  <Col
                    tablet={{cols: 4}}
                    desktop={{cols: 4, offset: 2}}
                  >
                    <FormikLabeledField
                      className="b2b-profile__form-row"
                      name="Customer:currentPassword"
                      as={FormikPasswordInput}
                      isFieldRequired={isPasswordFieldRequired}
                      getFieldLabel={getPasswordFieldLabel}
                    />
                  </Col>
                  <Col
                    tablet={{cols: 4}}
                    desktop={{cols: 4}}
                  >
                    <FormikLabeledField
                      className="b2b-profile__form-row"
                      name="Customer:password"
                      as={FormikPasswordInput}
                      isFieldRequired={isPasswordFieldRequired}
                      getFieldLabel={getPasswordFieldLabel}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    tablet={{cols: 4, offset: 4}}
                    desktop={{cols: 4, offset: 6}}
                  >
                    <Button
                      submit
                      primary
                      continue
                      disabled={formikProps.isSubmitting}
                    >
                      {translate(SUBMIT)}
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        )}

        <Row>
          <Col desktop={{cols: 8, offset: 2}}>
            <hr className="b2b-profile__separator" />
          </Col>
        </Row>

        <Row>
          <Col desktop={{cols: 8, offset: 2}}>
            <ScreenSectionHeading light>
              {translate(LEGAL_HEADING)}
            </ScreenSectionHeading>
          </Col>
        </Row>

        <Formik
          initialValues={initialConsentValues}
          validate={validateConsent}
          onSubmit={onSubmitConsent}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <ConsentFieldset
                className="b2b-profile__consent-fieldset"
                {...formikProps}
                getFieldLabel={getConsentFieldLabel}
                isReadOnly={isReadOnly}
              />

              {!isReadOnly && (
                <Row>
                  <Col tablet={{cols: 4, offset: 4}} desktop={{cols: 4, offset: 6}}>
                    <Button
                      submit
                      primary
                      continue
                      disabled={formikProps.isSubmitting}
                    >
                      {translate(SUBMIT)}
                    </Button>
                  </Col>
                </Row>
              )}
            </form>
          )}
        </Formik>
      </TypeArea>
    </ScreenSection>
  );
};
ProfileComponent.propTypes = {
  initialize: PropTypes.func,
  translate: PropTypes.func,
  isReadOnly: PropTypes.bool,
  companyAddress: PropTypes.shape({
    companyName: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string
  }),
  salesTaxesId: PropTypes.string,
  contactPersonDetails: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  userEmail: PropTypes.string,

  validateTaxData: PropTypes.func,
  getTaxDataInitialValues: PropTypes.func,
  isTaxDataFieldRequired: PropTypes.func,
  getTaxDataFieldLabel: PropTypes.func,
  hasTaxDataField: PropTypes.func,
  onSubmitTaxData: PropTypes.func,

  validateConsent: PropTypes.func,
  getConsentInitialValues: PropTypes.func,
  getConsentFieldLabel: PropTypes.func,
  onSubmitConsent: PropTypes.func,

  validatePassword: PropTypes.func,
  getPasswordInitialValues: PropTypes.func,
  isPasswordFieldRequired: PropTypes.func,
  getPasswordFieldLabel: PropTypes.func,
  onSubmitPassword: PropTypes.func
};

const stateToProfileProps = createStateToProps({
  validateTaxData: newValidate("changeBusinessCustomer"),
  getTaxDataInitialValues: newGetInitialValues("changeBusinessCustomer"),
  isTaxDataFieldRequired: newIsFieldRequired("changeBusinessCustomer"),
  getTaxDataFieldLabel: newGetFieldLabel("changeBusinessCustomer"),
  hasTaxDataField: newHasField("changeBusinessCustomer"),

  validateConsent: newValidate("BMWChangeCommunicationConfirmation"),
  getConsentInitialValues: newGetInitialValues("BMWChangeCommunicationConfirmation"),
  getConsentFieldLabel: newGetFieldLabel("BMWChangeCommunicationConfirmation"),

  validatePassword: newValidate("changePassword"),
  getPasswordInitialValues: newGetInitialValues("changePassword"),
  isPasswordFieldRequired: newIsFieldRequired("changePassword"),
  getPasswordFieldLabel: newGetFieldLabel("changePassword"),

  isReadOnly: getIsProfileReadOnly,
  companyAddress: getCompanyAddress,
  salesTaxId: getSalesTaxId,
  contactPersonDetails: getContactPerson,
  userEmail: getUserEmail
});

const dispatchToProfileProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmitTaxData: (formValues, formikProps) => dispatch(submitTaxData(formValues, formikProps)),
  onSubmitConsent: (formValues, formikProps) => dispatch(submitConsent(formValues, formikProps)),
  onSubmitPassword: (formValues, formikProps) => dispatch(submitPassword(formValues, formikProps))
});

export default connect(
  stateToProfileProps,
  dispatchToProfileProps
)(withTranslation(ProfileComponent));
