"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toValidationRule = exports.checkbox = exports.inclusion = exports.format = exports.presence = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _object = require("./object.js");

var _fn = require("./fn.js");

var _string = require("./string.js");

var presence = function presence(_ref) {
  var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      amsRule = _ref2[0],
      validationRuleInput = _ref2[1];

  if (amsRule.allowBlank !== false) {
    return [amsRule, validationRuleInput];
  }

  var validationRule = (0, _object.merge)(validationRuleInput, {
    presence: {
      allowEmpty: false,
      message: prefixValidationMessage(amsRule.emptyText || amsRule.infoText)
    }
  });
  return [amsRule, validationRule];
};

exports.presence = presence;

var format = function format(_ref3) {
  var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
      amsRule = _ref4[0],
      validationRuleInput = _ref4[1];

  if (!amsRule.regex) {
    return [amsRule, validationRuleInput];
  } // Ensure that regex is wrapped in slashes (`/`)


  var regexString = /^\/.*\/[dgimsuy]*/.test(amsRule.regex) ? amsRule.regex : "/".concat(amsRule.regex, "/"); // Split after '/' characters

  var _$exec = /\/(.*)\/(.*)/.exec(regexString),
      _$exec2 = (0, _slicedToArray2["default"])(_$exec, 3),
      regexRule = _$exec2[1],
      regexFlags = _$exec2[2];

  var extendedRegexRule = amsRule.allowBlank ? "^\\s*$|" + regexRule : regexRule; // Build valid regex with initially assigned flag

  var pattern = new RegExp(extendedRegexRule, regexFlags);
  var validationRule = (0, _object.merge)(validationRuleInput, {
    format: {
      pattern: pattern,
      message: prefixValidationMessage(amsRule.regexText || amsRule.invalidText)
    }
  });
  return [amsRule, validationRule];
};

exports.format = format;

var inclusion = function inclusion(_ref5) {
  var _ref6 = (0, _slicedToArray2["default"])(_ref5, 2),
      amsRule = _ref6[0],
      validationRuleInput = _ref6[1];

  if (amsRule.xtype !== "combobox") {
    return [amsRule, validationRuleInput];
  }

  var validValues = (amsRule.data || []).map(function (entry) {
    return (0, _string.trim)(entry.key);
  });
  var extraValues = amsRule.allowBlank === true ? [""] : [];
  var validationRule = (0, _object.merge)(validationRuleInput, {
    inclusion: {
      within: validValues.concat(extraValues),
      message: prefixValidationMessage(amsRule.invalidText || amsRule.emptyText)
    }
  });
  return [amsRule, validationRule];
};

exports.inclusion = inclusion;

var checkbox = function checkbox(_ref7) {
  var _ref8 = (0, _slicedToArray2["default"])(_ref7, 2),
      amsRule = _ref8[0],
      validationRuleInput = _ref8[1];

  if (amsRule.xtype !== "checkbox" || amsRule.allowBlank) {
    return [amsRule, validationRuleInput];
  }

  var validationRule = (0, _object.merge)(validationRuleInput, {
    inclusion: {
      within: [true],
      message: prefixValidationMessage(amsRule.invalidText)
    }
  });
  return [amsRule, validationRule];
};

exports.checkbox = checkbox;

var toValidationRule = function toValidationRule(result, item) {
  if (!item.name) {
    return result;
  }

  var validations = (0, _fn.compose)(format, inclusion, checkbox, presence);

  var _validations = validations([item, {}]),
      _validations2 = (0, _slicedToArray2["default"])(_validations, 2),
      rule = _validations2[1];

  return (0, _object.merge)(result, (0, _defineProperty2["default"])({}, item.name, rule));
};

exports.toValidationRule = toValidationRule;

var prefixValidationMessage = function prefixValidationMessage(message) {
  return "^".concat(message);
};