import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {userRoles} from "@awe-web/shared/lib/user/constants.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {Button} from "../../../components/form.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TextLinkList} from "../../../components/text_link_list.js";
import {RequestResetPasswordTokenForm} from "../../../user/components/request_reset_password_token_form.js";
import {B2B_Login, B2B_SignUpRequest} from "../../../routing/routes.js";
import * as userActions from "../../../user/actions.js";

const HEADING = "FSD0000097";
const LINK_LOGIN = "FSD0000253";
const LINK_SIGN_UP = "ALG0000194";

const RequestResetPasswordToken = (props) => {
  const {onSubmit, location: {state} = {}} = props;
  const {login: defaultLogin} = state || {};

  return (
    <div className="request-reset-password-token">
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col
              tablet={{cols: 4, offset: 2}}
              desktop={{cols: 4, offset: 4}}
            >
              <ScreenHeading>
                <T code={HEADING} />
              </ScreenHeading>

              <RequestResetPasswordTokenForm
                defaultLogin={defaultLogin}
                backlinkLocation={B2B_Login.path()}
                onSubmit={onSubmit}
              >
                <TextLinkList>
                  <Button tertiary to={B2B_Login.path()}>
                    <T code={LINK_LOGIN} />
                  </Button>
                  <Button tertiary to={B2B_SignUpRequest.path()}>
                    <T code={LINK_SIGN_UP} />
                  </Button>
                </TextLinkList>
              </RequestResetPasswordTokenForm>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
RequestResetPasswordToken.propTypes = {
  onSubmit: PropTypes.func
};

const stateToRequestResetPasswordTokenProps = createStateToProps({
});

const dispatchToRequestResetPasswordTokenProps = (dispatch) => ({
  onSubmit: ({login}) => dispatch(userActions.requestNewPassword(login, userRoles.B2B))
});

export default connect(
  stateToRequestResetPasswordTokenProps,
  dispatchToRequestResetPasswordTokenProps
)(RequestResetPasswordToken);
