import {Map, List, fromJS} from "immutable";
import {createSelector} from "reselect";
import {trim} from "@awe-web/shared/lib/util/string.js";
import {newTranslate, createDynamicListSelector} from "@awe-web/shared/lib/l10n/store.js";
import {getUserProfile, getLoggedIn} from "@awe-web/shared/lib/user/store.js";
import {addressTypes, userRoles, accountTypes} from "@awe-web/shared/lib/user/constants.js";
import {getBelongsToScreenGroup} from "../routing/store.js";
import {
  B2C_SelectStationAndTime,
  B2B_SelectStationAndTime,
  B2B_Dashboard,
  MyBmwRentDashboard,
  B2C_SignUp,
  B2B_SignUpRequest,
  B2C_Login,
  B2B_Login
} from "../routing/routes.js";
import {screenGroups} from "../routing/constants.js";
import {userProfileToAccountType} from "./util.js";

const B2C_PROFILE_LABEL = "FSD0000259";
const B2C_SIGN_UP_LABEL = "FSD0000142";
const B2B_SIGN_UP_LABEL = "ALG0000062";

export default function reducer(state, action) {
  switch (action.type) {
    case "USER/CONTACT_PEOPLE_LOADED":
      return state.setIn(["user", "contactPeople"], fromJS(action.payload.contactPeople));

    case "USER/DEFAULT_BANK_ACCOUNT_LOADED":
      return state.setIn(["user", "defaultBankAccount"], fromJS(action.payload.defaultBankAccount));

    case "USER/AUTHORIZED_USERS_LOADED":
      return state.setIn(["user", "authorizedUsers"], fromJS(action.payload.authorizedUsers));

    case "USER/USER_LOGGED_OUT":
      return state
        .deleteIn(["user", "contactPeople"])
        .deleteIn(["user", "defaultBankAccount"])
        .deleteIn(["user", "authorizedUsers"]);

    default:
      return state;
  }
}

export const getAccountType = createSelector(
  [getUserProfile],
  (userProfile) => userProfileToAccountType(userProfile) || accountTypes.B2C
);

export const getUserRole = createSelector(
  [getLoggedIn, getAccountType],
  (isLoggedIn, accountType) => isLoggedIn ? accountType : userRoles.ANONYMOUS
);

const userProfileToCompanyName = (userProfile = Map()) => {
  const name = userProfile.get("name") || "";
  const surname = userProfile.get("surname") || "";
  return trim(`${name} ${surname}`);
};

const userProfileToShortCompanyName = (userProfile) => userProfileToCompanyName(userProfile).split(" ")[0];

export const getCompanyName = createSelector([getUserProfile], userProfileToCompanyName);

export const getShortenedCompanyName = createSelector([getUserProfile], userProfileToShortCompanyName);

export const getStartScreenPath = createSelector(
  [getUserRole, getBelongsToScreenGroup],
  (userRole, belongsToScreenGroup) =>
    userRole === userRoles.B2B || (userRole === userRoles.ANONYMOUS && belongsToScreenGroup(screenGroups.B2B))
      ? B2B_SelectStationAndTime.path()
      : B2C_SelectStationAndTime.path()
);

export const getLoginScreenPath = createSelector(
  [getBelongsToScreenGroup],
  (belongsToScreenGroup) => belongsToScreenGroup(screenGroups.B2B)
    ? B2B_Login.path()
    : B2C_Login.path()
);

export const getProfileScreenLink = createSelector(
  [getUserRole, getShortenedCompanyName, newTranslate],
  (userRole, shortenedCompanyName, translate) => {
    switch (userRole) {
      case userRoles.B2B:
        return Map([
          ["path", B2B_Dashboard.path()],
          ["label", shortenedCompanyName]
        ]);

      case userRoles.B2C:
        return Map([
          ["path", MyBmwRentDashboard.path()],
          ["label", translate(B2C_PROFILE_LABEL)]
        ]);

      default:
        return undefined;
    }
  }
);

export const getSignUpScreenLink = createSelector(
  [newTranslate, getBelongsToScreenGroup],
  (translate, belongsToScreenGroup) => belongsToScreenGroup(screenGroups.B2B)
    ? Map([
      ["path", B2B_SignUpRequest.path()],
      ["label", translate(B2B_SIGN_UP_LABEL)]
    ])
    : Map([
      ["path", B2C_SignUp.path()],
      ["label", translate(B2C_SIGN_UP_LABEL)]
    ])
);

export const getB2bSignUpBenefits = createDynamicListSelector({
  "LtrSignUpRequestBenefit": "description"
});

const getMainAddress = createSelector(
  [getUserProfile],
  (userProfile = Map()) => userProfile
    .get("addresses", List())
    .find((address) => address.get("typeId") === addressTypes.MAIN)
);

export const getUserAddress = createSelector(
  [getMainAddress],
  (mainAddress = Map()) =>
    Map([
      ["street", mainAddress.get("street")],
      ["building", mainAddress.get("building")],
      ["zipCode", mainAddress.get("zipCode")],
      ["city", mainAddress.get("city")],
      ["country", mainAddress.get("country")],
      ["countryCode", trim(mainAddress.get("countryCode"))],
      ["phone", mainAddress.get("mobile")]
    ])
);

export const getCompanyAddress = createSelector(
  [getCompanyName, getMainAddress],
  (companyName, mainAddress = Map()) =>
    Map([
      ["companyName", companyName],
      ["street", mainAddress.get("street")],
      ["building", mainAddress.get("building")],
      ["zipCode", mainAddress.get("zipCode")],
      ["city", mainAddress.get("city")],
      ["country", mainAddress.get("country")],
      ["countryCode", trim(mainAddress.get("countryCode"))]
    ])
);

const getRawAuthorizedUsers = (state) => state.getIn(["user", "authorizedUsers"]) || List();

export const getAuthorizedUsers = createSelector(
  [getRawAuthorizedUsers],
  (authorizedUsers) => authorizedUsers
    .filter((userProfile) => userProfileToAccountType(userProfile) === accountTypes.B2B)
    .map((userProfile) => userProfile.set("label", userProfileToCompanyName(userProfile)))
    .sortBy((userProfile) => userProfile.get("label").toLowerCase())
);

export const getCurrentUserId = createSelector(
  [getUserProfile],
  (userProfile = Map()) => userProfile.get("id")
);

const getCurrentAuthorizedUser = createSelector(
  [getAuthorizedUsers, getCurrentUserId],
  (authorizedUsers, currentUserId) => authorizedUsers.find((user) => user.get("id") === currentUserId)
);

export const getIsProfileReadOnly = createSelector(
  [getCurrentAuthorizedUser],
  (currentAuthorizedUser) => Boolean(currentAuthorizedUser) && !currentAuthorizedUser.get("loggedIn")
);

export const getSalesTaxId = createSelector(
  [getUserProfile],
  (userProfile = Map()) => userProfile.get("salesTaxId")
);

const getContactPeople = (state) => state.getIn(["user", "contactPeople"], List());

const getFirstContactPerson = createSelector(
  [getContactPeople],
  (contactPeople) => contactPeople.first()
);

export const getContactPerson = createSelector(
  [getFirstContactPerson],
  (contactPerson) => contactPerson
    ? Map([
      ["fullName", contactPerson.get("prefix") + " " + contactPerson.get("name") + " " + contactPerson.get("surname")],
      ["email", contactPerson.getIn(["address", "email"])],
      ["phone", contactPerson.getIn(["address", "phone"])]
    ])
    : undefined
);

export const getDeviantBillingAddress = createSelector(
  [getUserProfile],
  (userProfile = Map()) => {
    const deviantBillingAddress = userProfile
      .get("addresses", List())
      .find((address) => address.get("typeId") === addressTypes.BILLING);
    return deviantBillingAddress
      ? deviantBillingAddress
        .set("companyName", deviantBillingAddress.get("surname"))
        .update("countryCode", trim)
      : undefined;
  }
);

export const getEBillingMailAddress = createSelector(
  [getMainAddress],
  (mainAddress = Map()) => mainAddress.get("eBillingMailAddress")
);

export const getBillDeliveryType = createSelector(
  [getEBillingMailAddress],
  (eBillingMailAddress) => eBillingMailAddress
    ? "EMAIL"
    : "MAIL"
);

const TRANSFER_ACCOUNT_HOLDER = "ALG0000142";
const TRANSFER_ACCOUNT_IBAN = "ALG0000143";
const TRANSFER_ACCOUNT_BIC = "ALG0000144";
const TRANSFER_ACCOUNT_BANK = "ALG0000145";

const getDefaultBankAccount = (state) =>
  state.getIn(["user", "defaultBankAccount"]);

export const getPaymentDetails = createSelector(
  [newTranslate, getDefaultBankAccount, getCompanyName],
  (translate, defaultBankAccount, companyName) => defaultBankAccount
    ? Map([
      ["type", "SEPA"],
      ["accountHolder", companyName],
      ["iban", defaultBankAccount.get("iban")],
      ["bic", defaultBankAccount.get("swift")],
      ["bank", defaultBankAccount.get("bankname")]
    ])
    : Map([
      ["type", "ON_ACCOUNT"],
      ["accountHolder", translate(TRANSFER_ACCOUNT_HOLDER)],
      ["iban", translate(TRANSFER_ACCOUNT_IBAN)],
      ["bic", translate(TRANSFER_ACCOUNT_BIC)],
      ["bank", translate(TRANSFER_ACCOUNT_BANK)]
    ])
);
