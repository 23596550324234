"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTimezoneOffset = exports.getTimeSlotsByInterval = exports.isDayInRange = exports.isInRange = exports.isAfterOrSameDay = exports.isBeforeOrSameDay = exports.isAfterDay = exports.isAfter = exports.isBeforeDay = exports.isBefore = exports.isSameDay = exports.numberOfPartialDays = exports.subtractYears = exports.addDays = exports.addMinutes = exports.setTime = exports.setDate = exports.startOfMonth = exports.startOfDay = exports.parseTime = exports.parseDate = exports.dateStringToDateTime = exports.toIsoString = exports.formatDate = exports.isValid = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var dateFns = _interopRequireWildcard(require("date-fns"));

var _fn = require("./fn.js");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// TODO replace all occurences with different library
var destructureDate = function destructureDate(date) {
  return [date.getFullYear(), date.getMonth(), date.getDate()];
};

var isValid = function isValid(dateTimeString) {
  return dateFns.isValid(new Date(dateTimeString));
};

exports.isValid = isValid;

var formatDate = function formatDate(date, format) {
  return dateFns.format(parseDate(date), format);
};

exports.formatDate = formatDate;

var toIsoString = function toIsoString(date) {
  return date && isValid(date) ? date.toISOString() : undefined;
};

exports.toIsoString = toIsoString;

var dateStringToDateTime = function dateStringToDateTime(dateString) {
  return isValid(dateString) ? new Date(dateString) : undefined;
};

exports.dateStringToDateTime = dateStringToDateTime;

var parseDate = function parseDate(dateTimeOrString) {
  return !dateTimeOrString ? undefined : typeof dateTimeOrString === "string" ? dateFns.parseISO(dateTimeOrString) :
  /* else */
  dateFns.toDate(dateTimeOrString);
};

exports.parseDate = parseDate;

var parseTime = function parseTime(timeString) {
  return timeString ? timeString.split(":").map(Number) : [];
};

exports.parseTime = parseTime;

var startOfDay = function startOfDay(date) {
  return dateFns.startOfDay(date);
};

exports.startOfDay = startOfDay;

var startOfMonth = function startOfMonth(date) {
  return dateFns.startOfMonth(date);
};

exports.startOfMonth = startOfMonth;

var setDate = function setDate(date, newDate) {
  var datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
  var match = datePattern.exec(newDate);

  if (!match) {
    throw new Error("New date '".concat(newDate, "' does not match expected pattern 'yyyy-MM-dd'"));
  }

  var _match$slice$map = match.slice(1).map(function (str) {
    return parseInt(str, 10);
  }),
      _match$slice$map2 = (0, _slicedToArray2["default"])(_match$slice$map, 3),
      year = _match$slice$map2[0],
      month = _match$slice$map2[1],
      day = _match$slice$map2[2];

  var clone = dateFns.toDate(date);
  clone.setUTCFullYear(year);
  clone.setUTCMonth(month - 1);
  clone.setUTCDate(day);
  return clone;
};

exports.setDate = setDate;

var setTime = function setTime(date, time) {
  if (!date) {
    return undefined;
  }

  var _parseTime = parseTime(time),
      _parseTime2 = (0, _slicedToArray2["default"])(_parseTime, 4),
      _parseTime2$ = _parseTime2[0],
      hours = _parseTime2$ === void 0 ? 0 : _parseTime2$,
      _parseTime2$2 = _parseTime2[1],
      minutes = _parseTime2$2 === void 0 ? 0 : _parseTime2$2,
      _parseTime2$3 = _parseTime2[2],
      seconds = _parseTime2$3 === void 0 ? 0 : _parseTime2$3,
      _parseTime2$4 = _parseTime2[3],
      milliseconds = _parseTime2$4 === void 0 ? 0 : _parseTime2$4;

  return parseDate(parseDate(date).setHours(hours, minutes, seconds, milliseconds));
};

exports.setTime = setTime;

var addMinutes = function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60 * 1000);
};

exports.addMinutes = addMinutes;

var addDays = function addDays(date, days) {
  return dateFns.addDays(date, days);
};

exports.addDays = addDays;

var subtractYears = function subtractYears(date, years) {
  return dateFns.subYears(new Date(date.getTime()), years);
};

exports.subtractYears = subtractYears;

var numberOfPartialDays = function numberOfPartialDays(date1, date2) {
  return dateFns.differenceInDays(dateFns.subMinutes(parseDate(date2), 1), parseDate(date1)) + 1;
};

exports.numberOfPartialDays = numberOfPartialDays;

var isSameDay = function isSameDay(date, reference) {
  return dateFns.isSameDay(parseDate(date), reference);
};

exports.isSameDay = isSameDay;

var isBefore = function isBefore(date, reference) {
  return date.getTime() < reference.getTime();
};

exports.isBefore = isBefore;

var isBeforeDay = function isBeforeDay(date, reference) {
  var _destructureDate = destructureDate(date),
      _destructureDate2 = (0, _slicedToArray2["default"])(_destructureDate, 3),
      year = _destructureDate2[0],
      month = _destructureDate2[1],
      day = _destructureDate2[2];

  var _destructureDate3 = destructureDate(reference),
      _destructureDate4 = (0, _slicedToArray2["default"])(_destructureDate3, 3),
      refYear = _destructureDate4[0],
      refMonth = _destructureDate4[1],
      refDay = _destructureDate4[2];

  return year < refYear || year === refYear && (month < refMonth || month === refMonth && day < refDay);
};

exports.isBeforeDay = isBeforeDay;

var isAfter = function isAfter(date, reference) {
  return date.getTime() > reference.getTime();
};

exports.isAfter = isAfter;

var isAfterDay = function isAfterDay(date, reference) {
  var _destructureDate5 = destructureDate(date),
      _destructureDate6 = (0, _slicedToArray2["default"])(_destructureDate5, 3),
      year = _destructureDate6[0],
      month = _destructureDate6[1],
      day = _destructureDate6[2];

  var _destructureDate7 = destructureDate(reference),
      _destructureDate8 = (0, _slicedToArray2["default"])(_destructureDate7, 3),
      refYear = _destructureDate8[0],
      refMonth = _destructureDate8[1],
      refDay = _destructureDate8[2];

  return year > refYear || year === refYear && (month > refMonth || month === refMonth && day > refDay);
};

exports.isAfterDay = isAfterDay;
var isBeforeOrSameDay = (0, _fn.not)(isAfterDay);
exports.isBeforeOrSameDay = isBeforeOrSameDay;
var isAfterOrSameDay = (0, _fn.not)(isBeforeDay);
exports.isAfterOrSameDay = isAfterOrSameDay;

var isInRange = function isInRange(date, from, till) {
  return date.getTime() >= from.getTime() && date.getTime() <= till.getTime();
};

exports.isInRange = isInRange;

var isDayInRange = function isDayInRange(date, from, till) {
  return isAfterOrSameDay(date, from) && isBeforeOrSameDay(date, till);
};

exports.isDayInRange = isDayInRange;

var getTimeSlotsByInterval = function getTimeSlotsByInterval(interval) {
  var dateTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : parseDate("1970-01-01 00:00");
  var endOfDay = dateFns.endOfDay(dateTime);
  var isStartOfDay = toIsoString(dateTime) === toIsoString(dateFns.startOfDay(dateTime));
  var minutesPerDay = isStartOfDay // Subtract the last minute so we don't include the last full hour which is "00:00" of the next day
  ? 60 * 24 - 1 : Math.abs(dateFns.differenceInMinutes(dateTime, endOfDay));
  var intervalAmount = minutesPerDay / interval;
  var times = [];
  var time = dateTime;

  for (var i = 0; i <= intervalAmount; i++) {
    times.push(time);
    time = addMinutes(time, interval);
  }

  return times;
};

exports.getTimeSlotsByInterval = getTimeSlotsByInterval;

var formatTimezoneOffset = function formatTimezoneOffset(timezoneOffset) {
  var pad = function pad(value) {
    return String(value).padStart(2, "0");
  };

  var absOffset = Math.abs(timezoneOffset);
  var sign = timezoneOffset <= 0 ? "+" : "-";
  var hours = Math.floor(absOffset / 60);
  var minutes = absOffset - hours * 60;
  return sign + pad(hours) + ":" + pad(minutes);
};

exports.formatTimezoneOffset = formatTimezoneOffset;