"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateTimeError = exports.dateError = void 0;

var _date = require("../util/date.js");

var _util = require("./util.js");

var dateError = function dateError(params) {
  var date = params.date,
      currentDateTime = params.currentDateTime,
      periodStartDateTime = params.periodStartDateTime,
      minPeriodRule = params.minPeriodRule,
      maxPeriodRule = params.maxPeriodRule,
      openingTimes = params.openingTimes,
      translate = params.translate;

  if (!date) {
    return translate("DATE_MISSING");
  }

  if (!(0, _date.isValid)(date)) {
    return translate("DATE_INVALID");
  }

  if ((0, _date.isBeforeDay)(date, currentDateTime)) {
    return translate("DATE_IN_PAST");
  }

  if (periodStartDateTime && (0, _date.isBeforeDay)(date, periodStartDateTime)) {
    return translate("DATE_BEFORE_MIN_DATE");
  }

  if (!(0, _util.isStationOpenAtDate)(openingTimes, date)) {
    return translate("STATION_CLOSED");
  }

  if (periodStartDateTime && minPeriodRule) {
    var minDateTime = (0, _date.addMinutes)(periodStartDateTime, minPeriodRule.get("valueInMinutes"));
    var dateIsOnADayBeforeMinDateTime = (0, _date.isBeforeDay)(date, minDateTime);
    var dateIsOnTheSameDayAsMinDateTimeButStationIsClosed = (0, _date.isSameDay)(date, minDateTime) && !(0, _util.isStationOpenAtDateAfterGivenTime)(openingTimes, minDateTime);

    if (dateIsOnADayBeforeMinDateTime || dateIsOnTheSameDayAsMinDateTimeButStationIsClosed) {
      return translate("DATE_BEFORE_MIN_PERIOD", {
        minPeriod: minPeriodRule.get("shortDescr")
      });
    }
  }

  if (periodStartDateTime && maxPeriodRule) {
    var maxDateTime = (0, _date.addMinutes)(periodStartDateTime, maxPeriodRule.get("valueInMinutes"));
    var dateIsOnADayAfterMaxDateTime = (0, _date.isAfterDay)(date, maxDateTime);
    var dateIsOnTheSameDayAsMaxDateTimeButStationIsClosed = (0, _date.isSameDay)(date, maxDateTime) && !(0, _util.isStationOpenAtDateBeforeGivenTime)(openingTimes, maxDateTime);

    if (dateIsOnADayAfterMaxDateTime || dateIsOnTheSameDayAsMaxDateTimeButStationIsClosed) {
      return translate("DATE_AFTER_MAX_PERIOD", {
        maxPeriod: maxPeriodRule.get("shortDescr")
      });
    }
  }
};

exports.dateError = dateError;

var dateTimeError = function dateTimeError(params) {
  var dateTime = params.dateTime,
      currentDateTime = params.currentDateTime,
      periodStartDateTime = params.periodStartDateTime,
      minPeriodRule = params.minPeriodRule,
      maxPeriodRule = params.maxPeriodRule,
      openingTimes = params.openingTimes,
      translate = params.translate;

  if (!dateTime) {
    return translate("DATE_MISSING");
  }

  if (!(0, _date.isValid)(dateTime)) {
    return translate("DATE_INVALID");
  }

  if (!(0, _date.isAfter)(dateTime, currentDateTime)) {
    return translate("DATE_IN_PAST");
  }

  if (periodStartDateTime && !(0, _date.isAfter)(dateTime, periodStartDateTime)) {
    return translate("DATE_BEFORE_MIN_DATE");
  }

  var dateErrorMessage = dateError({
    date: dateTime,
    currentDateTime: currentDateTime,
    periodStartDateTime: periodStartDateTime,
    minPeriodRule: minPeriodRule,
    maxPeriodRule: maxPeriodRule,
    openingTimes: openingTimes,
    translate: translate
  });

  if (dateErrorMessage) {
    return dateErrorMessage;
  }

  if (!(0, _util.isStationOpen)(openingTimes, dateTime)) {
    return translate("STATION_CLOSED");
  }

  if (periodStartDateTime && minPeriodRule) {
    var minDateTime = (0, _date.addMinutes)(periodStartDateTime, minPeriodRule.get("valueInMinutes"));

    if ((0, _date.isBefore)(dateTime, minDateTime)) {
      return translate("DATE_BEFORE_MIN_PERIOD", {
        minPeriod: minPeriodRule.get("shortDescr")
      });
    }
  }

  if (periodStartDateTime && maxPeriodRule) {
    var maxDateTime = (0, _date.addMinutes)(periodStartDateTime, maxPeriodRule.get("valueInMinutes"));

    if ((0, _date.isAfter)(dateTime, maxDateTime)) {
      return translate("DATE_AFTER_MAX_PERIOD", {
        maxPeriod: maxPeriodRule.get("shortDescr")
      });
    }
  }

  return undefined;
};

exports.dateTimeError = dateTimeError;