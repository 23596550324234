import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./grid.scss";

export const Row = (props) => {
  const {children, alignCenter, alignBottom, stretchVertical} = props;
  const className = classnames("grid-row", props.className, {
    "grid-row--align-center": alignCenter,
    "grid-row--align-bottom": alignBottom,
    "grid-row--stretch-vertical": stretchVertical
  });

  return (
    <div className={className}>{children}</div>
  );
};
Row.propTypes = {
  className: PropTypes.string,
  alignCenter: PropTypes.bool,
  alignBottom: PropTypes.bool,
  stretchVertical: PropTypes.bool,
  children: PropTypes.node
};

export const Col = forwardRef((props, ref) => {
  const {
    children,
    mobile,
    tablet,
    desktop
  } = props;
  const className = classnames(
    "grid-col",
    props.className,
    colClassNames("mobile", mobile),
    colClassNames("tablet", tablet),
    colClassNames("desktop", desktop)
  );

  return (
    <div ref={ref} className={className}>{children}</div>
  );
});
const colsShape = PropTypes.shape({
  cols: PropTypes.number,
  offset: PropTypes.number
});
Col.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  mobile: colsShape,
  tablet: colsShape,
  desktop: colsShape
};

// ======================================================================
// helper functions
// ======================================================================

export const colClassNames = (name, props = {}) => {
  const {cols, offset} = props;
  const classNames = [];

  if (cols !== undefined) {
    classNames.push(`grid-col-${name}-${cols}`);
  }

  if (offset !== undefined) {
    classNames.push(`grid-col-${name}-offset-${offset}`);
  }

  return classNames;
};
