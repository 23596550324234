"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleSidebarMenu = exports.closeSidebarMenu = exports.openSidebarMenu = void 0;

var _immutable = require("redux-burger-menu/immutable");

var _store = require("./store.js");

var openSidebarMenu = function openSidebarMenu() {
  return function (dispatch) {
    dispatch((0, _immutable.action)(true));
  };
};

exports.openSidebarMenu = openSidebarMenu;

var closeSidebarMenu = function closeSidebarMenu() {
  return function (dispatch) {
    dispatch((0, _immutable.action)(false));
  };
};

exports.closeSidebarMenu = closeSidebarMenu;

var toggleSidebarMenu = function toggleSidebarMenu() {
  return function (dispatch, getState) {
    dispatch((0, _immutable.action)(!(0, _store.isSidebarMenuOpen)(getState())));
  };
};

exports.toggleSidebarMenu = toggleSidebarMenu;