import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {isLoading, getBlockingMessage} from "../app/store.js";

import "./loading_indicator.scss";

export const LoadingSpinner = (props) => {
  const {message} = props;
  const className = classnames("loading-spinner", props.className);

  return (
    <div className={className}>
      <div className="loading-spinner__indicator" />
      {message && <div>{message}</div>}
    </div>
  );
};
LoadingSpinner.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node
};

const InlineLoadingIndicatorComponent = (props) => {
  const {isLoading, children, message} = props;
  const className = classnames("loading-indicator", props.className);

  return isLoading
    ? <LoadingSpinner className={className} message={message} />
    : <>{children}</>;
};
InlineLoadingIndicatorComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  message: LoadingSpinner.propTypes.message,
  typePrefix: PropTypes.string,
  typePrefixes: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool
};

const stateToInlineLoadingIndicatorProps = createStateToProps({
  isLoading: isLoading
});

export const InlineLoadingIndicator = connect(stateToInlineLoadingIndicatorProps)(InlineLoadingIndicatorComponent);

const FullScreenLoadingIndicatorComponent = (props) => {
  const {message} = props;

  return message !== undefined
    ? (
      <div className="full-screen-loading-indicator">
        <LoadingSpinner className="full-screen-loading-indicator__indicator" message={message} />
      </div>
    )
    : null;
};
FullScreenLoadingIndicatorComponent.propTypes = {
  className: PropTypes.string,
  message: LoadingSpinner.propTypes.message,
  blockingActions: PropTypes.object,
  isLoading: PropTypes.bool
};

const stateToFullScreenLoadingIndicatorProps = createStateToProps({
  message: getBlockingMessage
});

export const FullScreenLoadingIndicator = connect(stateToFullScreenLoadingIndicatorProps)(FullScreenLoadingIndicatorComponent);
