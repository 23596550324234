"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFixedCoStationId = exports.newGetFormConfiguration = exports.getAnalyticsGeoRegion = exports.getAnalyticsEnv = exports.getErrorPageUrl = exports.getInitialLocale = exports.getLocales = exports.getAmsBaseUrl = void 0;

var _immutable = require("immutable");

var _reselect = require("reselect");

var getAmsBaseUrl = function getAmsBaseUrl(state) {
  return state.getIn(["config", "amsBaseUrl"]);
};

exports.getAmsBaseUrl = getAmsBaseUrl;

var getLocales = function getLocales(state) {
  return state.getIn(["config", "locales"], (0, _immutable.List)());
};

exports.getLocales = getLocales;

var getInitialLocale = function getInitialLocale(state) {
  return state.getIn(["config", "initialLocale"]);
};

exports.getInitialLocale = getInitialLocale;

var getErrorPageUrl = function getErrorPageUrl(state) {
  return state.getIn(["config", "errorPageUrl"]);
};

exports.getErrorPageUrl = getErrorPageUrl;

var getAnalyticsEnv = function getAnalyticsEnv(state) {
  return state.getIn(["config", "analytics", "env"], "dev");
};

exports.getAnalyticsEnv = getAnalyticsEnv;

var getAnalyticsGeoRegion = function getAnalyticsGeoRegion(state) {
  return state.getIn(["config", "analytics", "geoRegion"], "na");
};

exports.getAnalyticsGeoRegion = getAnalyticsGeoRegion;

var newGetFormConfiguration = function newGetFormConfiguration(groupName) {
  return (0, _reselect.createSelector)([function (state) {
    return state.getIn(["config", "formConfigurations"], (0, _immutable.Map)());
  }], function (formConfigurations) {
    return formConfigurations.get(groupName, (0, _immutable.List)());
  });
};

exports.newGetFormConfiguration = newGetFormConfiguration;

var getFixedCoStationId = function getFixedCoStationId(state) {
  return state.getIn(["config", "fixedCoStationId"]) || undefined;
};

exports.getFixedCoStationId = getFixedCoStationId;