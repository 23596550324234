"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.composeReducers = exports.compose = exports.not = void 0;

var not = function not(f) {
  return function () {
    return !f.apply(void 0, arguments);
  };
};

exports.not = not;

var compose = function compose() {
  for (var _len = arguments.length, funcs = new Array(_len), _key = 0; _key < _len; _key++) {
    funcs[_key] = arguments[_key];
  }

  return funcs.reduce(function (a, b) {
    return function () {
      return a(b.apply(void 0, arguments));
    };
  }, function (arg) {
    return arg;
  });
};

exports.compose = compose;

var composeReducers = function composeReducers() {
  for (var _len2 = arguments.length, reducers = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    reducers[_key2] = arguments[_key2];
  }

  return reducers.reduce(function (reducer, func) {
    return function (state, action) {
      return func(reducer(state, action), action);
    };
  }, function (state) {
    return state;
  });
};

exports.composeReducers = composeReducers;