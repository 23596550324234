export const getContractTypeForB2cSTR = (state) =>
  state.getIn(["config", "b2c", "shortTermReservation", "contractType"]);

export const getContractTypeForB2cLTR = (state) =>
  state.getIn(["config", "b2c", "longTermReservation", "contractType"]);

export const getMinDaysForB2cLtr = (state) =>
  state.getIn(["config", "b2c", "longTermReservation", "thresholdInDays"]);

export const getRentObjectStationIdForB2cLtr = (state) =>
  state.getIn(["config", "b2c", "longTermReservation", "fetchRentObjectsFromStationId"]);

export const getContractTypeForB2bSTR = (state) =>
  state.getIn(["config", "b2b", "shortTermReservation", "contractType"]);

export const getContractTypeForB2bLTR = (state) =>
  state.getIn(["config", "b2b", "longTermReservation", "contractType"]);

export const getMinDaysForB2bLtr = (state) =>
  state.getIn(["config", "b2b", "longTermReservation", "thresholdInDays"]);

export const getRentObjectStationIdForB2bLtr = (state) =>
  state.getIn(["config", "b2b", "longTermReservation", "fetchRentObjectsFromStationId"]);

export const getTimeSelectionStepSizeInMinutes = (state) =>
  state.getIn(["config", "timeSelectionStepSizeInMinutes"], 15);

export const getCreditCardsEnabled = (state) =>
  state.getIn(["config", "creditCardsEnabled"], true);

export const getHistoryPageSize = (state) =>
  state.getIn(["config", "historyPageSize"], 10);
