import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {loadDeliveryAddress} from "@awe-web/shared/lib/rent_contracts/actions.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {loadStations} from "@awe-web/shared/lib/stations/actions.js";
import {
  loadFormConfiguration,
  setFormConfigurationValues
} from "@awe-web/shared/lib/user/actions.js";
import {getUserProfile, newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {selectKeys} from "@awe-web/shared/lib/util/object.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {getProvisioningTypeB2c, getDeliveryAddress} from "../../../rent_contracts/store.js";
import {
  setProvisioningPickup,
  setProvisioningDelivery,
  loadRentContractData
} from "../../../rent_contracts/actions.js";
import {getUserAddress} from "../../../user/store.js";
import {
  B2C_LTR_SubmitRentContract
} from "../../../routing/routes.js";

export const initialize = () => async (dispatch, getState) => {
  await Promise.all([
    dispatch(loadRentContractData({
      reservationPath: "B2C",
      additionalAction: (rentContract) => async (dispatch) => {
        await Promise.all([
          dispatch(loadStations([rentContract.type])),
          dispatch(loadDeliveryAddress(rentContract.id))
        ]);
      }
    })),
    dispatch(loadFormConfiguration("deliveryAddress12"))
  ]);

  const provisioningType = getProvisioningTypeB2c(getState());
  const userProfile = getUserProfile(getState());
  const userAddress = getUserAddress(getState());

  if (provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS) {
    const deliveryAddress = getDeliveryAddress(getState());
    dispatch(setFormConfigurationValues("deliveryAddress12", {
      "DeliveryContactPerson:name": deliveryAddress.get("name"),
      "DeliveryContactPerson:surname": deliveryAddress.get("surname"),
      "DeliveryAddress:street": deliveryAddress.get("street"),
      "DeliveryAddress:building": deliveryAddress.get("building"),
      "DeliveryAddress:zipCode": deliveryAddress.get("zipCode"),
      "DeliveryAddress:city": deliveryAddress.get("city"),
      "DeliveryAddress:countryCode": deliveryAddress.get("countryCode"),
      "DeliveryContactPerson:phone": deliveryAddress.get("phone")
    }));
  }
  else if (userProfile && userAddress) {
    const userProfile = getUserProfile(getState());
    const userAddress = getUserAddress(getState());

    dispatch(setFormConfigurationValues("deliveryAddress12", {
      "DeliveryContactPerson:name": userProfile.get("name"),
      "DeliveryContactPerson:surname": userProfile.get("surname"),
      "DeliveryAddress:street": userAddress.get("street"),
      "DeliveryAddress:building": userAddress.get("building"),
      "DeliveryAddress:zipCode": userAddress.get("zipCode"),
      "DeliveryAddress:city": userAddress.get("city"),
      "DeliveryAddress:countryCode": userAddress.get("countryCode"),
      "DeliveryContactPerson:phone": userAddress.get("phone")
    }));
  }
};

export const submit = (formValues) => async (dispatch, getState) => {
  const rentContractId = getRentContractId(getState());
  const mapDeliveryFormValues = newMapFormValues("deliveryAddress12")(getState());

  await dispatch(asyncAction({
    typePrefix: "SELECT_PROVISIONING/UPDATE_RENT_CONTRACT",
    loadPayload: async () => {
      switch (formValues.provisioningType) {
        case provisioningTypes.PICKUP: {
          await dispatch(setProvisioningPickup(rentContractId));
          break;
        }

        case provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS: {
          const deliveryAddress = mapDeliveryFormValues(selectKeys(formValues, [
            "DeliveryContactPerson:name",
            "DeliveryContactPerson:surname",
            "DeliveryAddress:street",
            "DeliveryAddress:building",
            "DeliveryAddress:zipCode",
            "DeliveryAddress:city",
            "DeliveryAddress:countryCode",
            "DeliveryContactPerson:phone"
          ]));
          await dispatch(setProvisioningDelivery(rentContractId, deliveryAddress));
          break;
        }

        default:
          // nothing to do
      }
    }
  }));

  dispatch(navigate(B2C_LTR_SubmitRentContract.location({rentContractId})));
};
