import React, {Component, Children, createRef, forwardRef, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import qs from "query-string";
import {Link} from "react-router-dom";
import {
  Field,
  ErrorMessage as FormikErrorMessageWrapper,
  useField,
  useFormikContext
} from "formik";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Checkbox, RadioButton} from "@awe-web/shared/lib/components/form.js";
import {
  formatDate,
  parseDate,
  startOfDay,
  setDate,
  setTime,
  getTimeSlotsByInterval
} from "@awe-web/shared/lib/util/date.js";
import {scrollElementToTopOfViewportIfNotVisible} from "@awe-web/shared/lib/util/dom.js";
import {merge} from "@awe-web/shared/lib/util/object.js";
import {addClassNameToElement} from "@awe-web/shared/lib/util/react.js";
import {
  MobileOverlay,
  MobileOverlaySection,
  MobileOverlayHeading
} from "./overlay.js";
import {Calendar} from "./calendar.js";

import "./form.scss";

const TEXTBOX_REMAINING_CHARACTERS = "ALG0000106";

export const ScrollToError = (props) => {
  const {isSubmitting, isValidating, isValid} = useFormikContext();
  const wasSubmittingRef = useRef(false);

  useEffect(() => {
    if (isSubmitting && isValidating) {
      wasSubmittingRef.current = true;
    }
  }, [isSubmitting, isValidating]);

  useEffect(() => {
    if (wasSubmittingRef.current && !isValid) {
      wasSubmittingRef.current = false;
      const topmostErrorMessage = Array.from(document.querySelectorAll(".error-message"))
        .sort((a, b) => a.offsetTop - b.offsetTop)[0];
      scrollElementToTopOfViewportIfNotVisible(topmostErrorMessage);
    }
  }, [isValid]);

  return null;
};

export const ErrorMessage = (props) => {
  const {error} = props;
  const className = classnames("error-message", props.className);

  return error
    ? <span className={className}>{error}</span>
    : null;
};
ErrorMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string
};

export const FormikErrorMessage = (props) => {
  return (
    <FormikErrorMessageWrapper
      {...props}
    >
      {(error) => <ErrorMessage className={props.className} error={error} />}
    </FormikErrorMessageWrapper>
  );
};

export const LabeledField = (props) => {
  const {className: classNameProp} = props;
  const className = classnames("labeled-field", classNameProp);

  const isInputLabel = node => node.type === InputLabel || node.type === FormikInputLabel;

  const children = Children.map(
    props.children,
    child => isInputLabel(child)
      ? addClassNameToElement(child, "labeled-field__input-label")
      : child);

  return (
    <div className={className}>
      {children}
    </div>
  );
};
LabeledField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const FormikLabeledField = (props) => {
  const {
    name,
    as: Component,
    isFieldRequired = () => false,
    getFieldLabel,
    className,
    ...inputProps
  } = props;

  return (
    <LabeledField className={className}>
      <FormikInputLabel
        name={name}
        required={isFieldRequired(name)}
      >
        <span>{getFieldLabel(name)}</span>
      </FormikInputLabel>
      <Component
        {...inputProps}
        name={name}
      />
      <FormikErrorMessage name={name} />
    </LabeledField>
  );
};
FormikLabeledField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  as: PropTypes.elementType.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  isFieldRequired: PropTypes.func
};

export const LabeledCheckbox = props => {
  const {error} = props;
  const className = classnames("labeled-checkbox", props.className, {
    "labeled-checkbox--error": error
  });

  const isCheckbox = node => node && (node.type === Checkbox || node.type === FormikCheckbox);

  const children = Children.map(
    props.children,
    child => isCheckbox(child)
      ? addClassNameToElement(child, "labeled-checkbox__input")
      : child);

  return (
    <label className={className}>
      {children}
    </label>
  );
};
LabeledCheckbox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.any
};

export const FormikLabeledCheckbox = (props) => {
  const [field, meta] = useField(props.name);
  const error = meta.error && meta.touched;

  return (
    <LabeledCheckbox
      {...field}
      {...props}
      error={error}
    />
  );
};

export const FormikCheckbox = (props) => {
  const [field] = useField(props.name);
  return (
    <Checkbox
      {...field}
      {...props}
    />
  );
};

export const FormikRadioButton = (props) => {
  const [field] = useField(props.name);
  return (
    <RadioButton
      {...field}
      {...props}
      checked={field.value === props.value}
    />
  );
};

export const LabeledRadioButton = (props) => {
  const {children, error} = props;
  const className = classnames("labeled-radio-button", props.className, {
    "labeled-radio-button--error": error
  });

  return (
    <label className={className}>
      {children}
    </label>
  );
};
LabeledRadioButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.any
};

export const InputLabel = (props) => {
  const {children, error, required} = props;
  const className = classnames("input-label", props.className, {
    "input-label--error": error,
    "input-label--required": required
  });

  return (
    <span className={className}>{children}</span>
  );
};
InputLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.any,
  required: PropTypes.bool
};

export const FormikInputLabel = (props) => {
  const [, meta] = useField(props.name);

  return (
    <InputLabel
      {...props}
      error={meta.touched && meta.error}
    />
  );
};

const BorderlessSelection = (props) => {
  const {
    opticallyDisabled,
    name,
    value = "",
    error,
    placeholder,
    onSelect = () => {},
    onBlur = () => {},
    ...buttonProps
  } = props;

  const trimmedValue = value.trim();
  const text = trimmedValue || placeholder;

  const className = classnames("borderless-selection", props.className, {
    "borderless-selection--disabled": opticallyDisabled,
    "borderless-selection--placeholder": !trimmedValue,
    "borderless-selection--error": error
  });

  return (
    <button
        {...buttonProps}
        type="button"
        className={className}
        onClick={() => onSelect()}
        onBlur={() => onBlur({target: {name}})}>
      {text}
    </button>
  );
};
BorderlessSelection.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  opticallyDisabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func
};

export const Selection = (props) => {
  const {
    opticallyDisabled,
    name,
    value = "",
    error,
    placeholder,
    placeholderClassName,
    onSelect = () => {},
    onBlur = () => {},
    ...buttonProps
  } = props;

  const trimmedValue = value.trim();
  const text = trimmedValue || placeholder;

  const className = classnames(
    "selection",
    props.className,
    {
      "selection--disabled": opticallyDisabled,
      "selection--placeholder": !trimmedValue,
      "selection--error": error
    },
    placeholderClassName ? {[placeholderClassName]: !trimmedValue} : {}
  );

  return (
    <button
      {...buttonProps}
      type="button"
      className={className}
      onClick={() => onSelect()}
      onBlur={() => onBlur({target: {name}})}
    >
      {text}
    </button>
  );
};
Selection.propTypes = BorderlessSelection.propTypes;

const CloseRegion = (props) => {
  const {onClick = () => {}} = props;
  const className = classnames("close-region", props.className);

  return (
    <div className={className} onClick={onClick}></div>
  );
};
CloseRegion.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

class DateInput extends Component {
  constructor(props) {
    super(props);
    const {name, onChange = () => {}, onBlur = () => {}} = props;

    this.state = {
      showCalendar: false
    };
    this.openCalendar = () => this.setState(() => ({showCalendar: true}));
    this.closeCalendar = () => {
      this.setState(() => ({showCalendar: false}));
      onBlur({target: {name, value: this.props.value}});
    };
    this.toggleCalendar = () => this.state.showCalendar ? this.closeCalendar() : this.openCalendar();

    this.handleSelect = (date) => {
      this.closeCalendar();
      onChange({target: {name, value: formatDate(parseDate(date), "yyyy-MM-dd")}});
    };
  }

  render() {
    const {
      name,
      value,
      error,
      placeholder = "\xa0", // non-breaking space to prevent height
                            // from collapsing when no text is defined
      defaultView,
      overlayHeading,
      styled = true,
      transientValue,
      transientOnSelect = () => {},
      startDate,
      minDate,
      isDateEnabled,
      onBlur,
      ...selectionProps
    } = this.props;
    const {showCalendar} = this.state;
    const date = value || transientValue;

    const className = classnames("date-input", this.props.className);
    const inputClassName = classnames("date-input__input", {
      "date-input__input--error": error,
      "date-input__input--placeholder": !date,
      "date-input__input--styled": styled,
      "date-input__input--styled--error": styled && error
    });

    const formattedDate = date
      ? formatDate(parseDate(date), "dd.MM.yyyy")
      : undefined;

    const onSelect = transientValue
      ? transientOnSelect
      : this.toggleCalendar;

    const inputProps = merge(selectionProps, {
      className: inputClassName,
      name,
      value: formattedDate,
      error,
      placeholder,
      onSelect
    });

    return (
      <>
        {
          showCalendar
            ? <CloseRegion onClick={this.closeCalendar} />
            : null
        }
        <div className={className}>
          {
            styled
              ? <Selection {...inputProps} />
              : <BorderlessSelection {...inputProps} />
          }

          <MobileOverlay className="date-input__overlay" open={showCalendar} onClose={this.closeCalendar}>
            <MobileOverlayHeading>{overlayHeading}</MobileOverlayHeading>
            <MobileOverlaySection>
              <Calendar
                value={parseDate(value)}
                defaultView={defaultView}
                isDateEnabled={isDateEnabled}
                startDate={startDate}
                minDate={minDate}
                onSelect={this.handleSelect} />
            </MobileOverlaySection>
          </MobileOverlay>
        </div>
      </>
    );
  }
}
DateInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,

  // DateInput uses strings as values to avoid off-by-one errors when
  // parsing and stringifying dates
  value: PropTypes.string,

  error: PropTypes.any,
  placeholder: PropTypes.string,
  overlayHeading: PropTypes.node,
  defaultView: PropTypes.oneOf(["month", "year", "decade"]),
  styled: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  transientValue: PropTypes.string,
  transientOnSelect: PropTypes.func,
  isDateEnabled: PropTypes.func
};

export const FormikDateInput = (props) => {
  const [, meta] = useField(props.name);

  return (
    <Field
      {...props}
      as={DateInput}
      error={meta.touched && meta.error}
    />
  );
};

export class TimeInput extends Component {
  constructor(props) {
    super(props);
    const {name, onChange = () => {}} = props;

    this.state = {
      showTimeSelection: false
    };
    this.openTimeSelection = () => this.setState(() => ({showTimeSelection: true}));
    this.closeTimeSelection = () => {
      this.setState(() => ({showTimeSelection: false}));
      onChange({target: {name, value: this.props.value}});
    };

    this.handleSelect = ({target: {value: time}}) => {
      this.closeTimeSelection();
      onChange({target: {name, value: time}});
    };
  }

  render() {
    const {
      stepSizeInMinutes = 15,
      className: classNameFromProps,
      name,
      value,
      consideredDate,
      error,
      placeholder,
      overlayHeading,
      isDateTimeEnabled = () => true, // Ensure filter function keeps all times per default
      ...selectionProps
    } = this.props;
    const {showTimeSelection} = this.state;
    const className = classnames("time-input", classNameFromProps, {
      "time-input--error": error
    });

    const options = getTimeSlotsByInterval(stepSizeInMinutes, consideredDate && startOfDay(consideredDate))
      .filter(isDateTimeEnabled)
      .map(date => formatDate(date, "HH:mm"))
      .map(time => (
        <li key={time}>
          <button
            type="button"
            className={classnames("time-input__time-selector", {
              "time-input__time-selector--active": time === value
            })}
            onClick={this.handleSelect}
            value={time}
          >
            {time}
          </button>
        </li>
      ));

    return (
      <>
        {
          showTimeSelection
            ? <CloseRegion onClick={this.closeTimeSelection} />
            : null
        }
        <div className={className}>
          <BorderlessSelection
            {...selectionProps}
            name={name}
            error={error}
            value={value}
            onSelect={showTimeSelection ? this.closeTimeSelection : this.openTimeSelection}
          />

          <MobileOverlay className="time-input__overlay" open={showTimeSelection} onClose={this.closeTimeSelection}>
            <MobileOverlayHeading>{overlayHeading}</MobileOverlayHeading>
            <MobileOverlaySection>
              <ul className="time-input__times-list">
                {options}
              </ul>
            </MobileOverlaySection>
          </MobileOverlay>
        </div>
      </>
    );
  }
}
TimeInput.propTypes = {
  className: PropTypes.string,
  stepSizeInMinutes: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  consideredDate: PropTypes.instanceOf(Date),
  error: PropTypes.any,
  placeholder: PropTypes.string,
  overlayHeading: PropTypes.node,
  isDateTimeEnabled: PropTypes.func,
  onChange: PropTypes.func
};

export class DateTimeInput extends Component {
  constructor(props) {
    super(props);
    const {name, onChange = () => {}, onBlur = () => {}} = props;

    this.dateInputRef = createRef();
    this.timeInputRef = createRef();

    this.state = {
      transientDate: undefined
    };

    this.handleDateChange = ({target: {value: date}}) => {
      if (!this.props.value) {
        this.setState(() => ({transientDate: parseDate(date)}));
        this.timeInputRef.current.openTimeSelection();
      }
      else {
        const value = setDate(this.props.value, date);
        onChange({target: {name, value}});

        if (this.props.error) {
          this.timeInputRef.current.openTimeSelection();
        }
      }
    };

    this.handleTimeChange = ({target: {value: time}}) => {
      const date = this.props.value || this.state.transientDate;

      if (!time) {
        this.setState(() => ({transientDate: undefined}));
      }

      if (date && time) {
        onBlur({target: {name}});
        onChange({target: {name, value: setTime(date, time)}});
        this.setState(() => ({transientDate: undefined}));
      }
    };

    this.toggleCalendar = () => this.dateInputRef.current.toggleCalendar();
    this.closeTimeSelection = () => this.timeInputRef.current.closeTimeSelection();
  }

  render() {
    const {
      className: classNameFromProps,
      name,
      value,
      error,
      placeholder,
      overlayHeading,
      startDate,
      minDate,
      timeStepSizeInMinutes,
      isDateEnabled,
      isDateTimeEnabled,
      onBlur,
      ...selectionProps
    } = this.props;
    const hasValue = Boolean(value);
    const isInTransientState = Boolean(this.state.transientDate);
    const hasValueOrIsInTransientState = hasValue || isInTransientState;
    const isDateValid = (hasValue || isInTransientState) && isDateEnabled(value || this.state.transientDate);

    const className = classnames("date-time-input", classNameFromProps, {
      "date-time-input--error": error
    });
    const placeholderClassName = classnames("date-time-input__placeholder", {
      "date-time-input__placeholder--error": error
    });
    const dateInputClassName = classnames("date-time-input__date", {
      "date-time-input__date--optically-disabled": !hasValueOrIsInTransientState
    });
    const timeInputClassName = classnames("date-time-input__time", {
      "date-time-input__time--optically-disabled": !hasValueOrIsInTransientState
    });

    const formattedDate = value
      ? formatDate(value, "yyyy-MM-dd")
      : undefined;
    const formattedTime = value
      ? formatDate(value, "HH:mm")
      : undefined;
    const dateInputName = `${name}_date`;
    const timeInputName = `${name}_time`;

    return (
      <div className={className}>
        {
          !hasValueOrIsInTransientState
            ? (
              <div
                className={placeholderClassName}
                onClick={this.toggleCalendar}
              >
                {placeholder}
              </div>
            )
            : null
        }
        {
          // Force selection of a different day rather than a time when current
          // date is invalid
          !isDateValid
            ? (<div className="date-time-input__force-calendar-overlay" onClick={this.toggleCalendar} />)
            : null
        }
        <DateInput
          {...selectionProps}
          ref={this.dateInputRef}
          className={dateInputClassName}
          name={dateInputName}
          value={formattedDate}
          transientValue={this.state.transientDate && formatDate(this.state.transientDate, "yyyy-MM-dd")}
          transientOnSelect={this.closeTimeSelection}
          error={error}
          overlayHeading={overlayHeading}
          styled={false}
          startDate={startDate}
          minDate={minDate}
          isDateEnabled={isDateEnabled}
          onChange={this.handleDateChange}
        />
        <TimeInput
          ref={this.timeInputRef}
          className={timeInputClassName}
          name={timeInputName}
          value={formattedTime}
          consideredDate={value || this.state.transientDate}
          stepSizeInMinutes={timeStepSizeInMinutes}
          error={error}
          overlayHeading={overlayHeading}
          isDateTimeEnabled={isDateTimeEnabled}
          onChange={this.handleTimeChange}
        />
      </div>
    );
  }
}
DateTimeInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  error: PropTypes.any,
  overlayHeading: PropTypes.node,
  startDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  timeStepSizeInMinutes: TimeInput.propTypes.stepSizeInMinutes,
  isDateEnabled: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export const FormikDateTimeInput = (props) => {
  const [, meta] = useField(props.name);

  return (
    <Field
      {...props}
      as={DateTimeInput}
      error={meta.touched && meta.error}
    />
  );
};

const TextareaWithCharacterCount = (props) => {
  const {
    maxLength,
    className: givenClassName,
    value = "",
    ...textareaProps
  } = props;
  const className = classnames("textarea-with-character-count", givenClassName);
  const remainingCharacters = maxLength - value.length;

  return (
    <div className={className}>
      <textarea
        {...textareaProps}
        value={value}
        className="textarea-with-character-count__textarea"
        maxLength={maxLength}
      />
      <div>
        <T code={TEXTBOX_REMAINING_CHARACTERS} data={{remainingCharacters}} />
      </div>
    </div>
  );
};
TextareaWithCharacterCount.propTypes = {
  maxLength: PropTypes.number.isRequired
};

export const FormikTextareaWithCharacterCount = (props) => {
  return (
    <Field
      {...props}
      as={TextareaWithCharacterCount}
    />
  );
};

export const Button = (props) => {
  const {
    children,
    className: classNameProp,
    primary: isPrimary,
    secondary: isSecondary,
    tertiary: isTertiary,
    quaternary: isQuaternary,
    back: isBackButton,
    continue: isContinueButton,
    submit: isSubmitButton,
    download: isDownloadButton,
    filter: isFilterButton,
    to,
    href,
    nonInteractable,
    ...buttonProps
  } = props;
  const className = classnames("button", classNameProp, {
    "button--primary": isPrimary,
    "button--secondary": isSecondary,
    "button--tertiary": isTertiary,
    "button--quaternary": isQuaternary,
    "button--back": isBackButton,
    "button--continue": isContinueButton,
    "button--download": isDownloadButton,
    "button--filter": isFilterButton
  });

  const Tag = nonInteractable ? "div"
            : to              ? Link
            : href            ? "a"
            : /* else */        "button";

  const toWithQuery = to && to.query
    ? {...to, search: `?${qs.stringify(to.query)}`}
    : to;

  const type = Tag === "button"
    ? isSubmitButton ? "submit" : "button"
    : undefined;

  return (
    <Tag type={type} className={className} to={toWithQuery} href={href} {...buttonProps}>
      {children}
    </Tag>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  back: PropTypes.bool,
  continue: PropTypes.bool,
  submit: PropTypes.bool,
  download: PropTypes.bool,
  filter: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.any
    })
  ]),
  href: PropTypes.string,
  nonInteractable: PropTypes.bool
};

export const TextInput = forwardRef((props, ref) => {
  const {dark, email, error, onChange = () => {}, ...inputProps} = props;
  const className = classnames("text-input", props.className, {
    "text-input--dark": dark,
    "text-input--error": error
  });
  const inputType = email ? "email" : "text";

  return (
    <input
      {...inputProps}
      ref={ref}
      type={inputType}
      className={className}
      onChange={onChange} />
  );
});
TextInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  email: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.any
};

export const FormikTextInput = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <TextInput
      {...props}
      {...field}
      error={meta.touched && meta.error}
    />
  );
};

export class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordRevealed: false
    };

    this.toggleRevealPassword = () => this.setState(() => ({isPasswordRevealed: !this.state.isPasswordRevealed}));
  }

  render() {
    const {name, value, placeholder, disabled, error, onBlur, onChange = () => {}} = this.props;
    const {isPasswordRevealed} = this.state;
    const {toggleRevealPassword} = this;

    const inputClassName = classnames("password-input__input", this.props.className, {
      "password-input__input--error": error
    });
    const revealButtonClassName = classnames("password-input__reveal-button", {
      "password-input__reveal-button--error": error,
      "password-input__reveal-button--revealed": isPasswordRevealed
    });
    const inputType = isPasswordRevealed ? "text" : "password";

    return (
      <div className="password-input">
        <input
          type={inputType}
          className={inputClassName}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
        />

        <button type="button" className={revealButtonClassName} onClick={toggleRevealPassword} />
      </div>
    );
  }
}
PasswordInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.any
};

export const FormikPasswordInput = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <PasswordInput
      {...props}
      {...field}
      error={meta.touched && meta.error}
    />
  );
};

const Select = (props) => {
  const {options, error, ...selectProps} = props;
  const className = classnames("select", props.className, {
    "select--error": error,
    "select--placeholder": selectProps.value === ""
  });

  const optionNodes = options.map(({key, value}) => (
    <option
      key={key}
      value={key}
      className="select__option"
    >
      {value}
    </option>
  ));

  return (
    <select
      {...selectProps}
      className={className}
    >
      {optionNodes}
    </select>
  );
};
Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string
  })),
  error: PropTypes.any
};

export const FormikSelect = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <Select
      {...props}
      {...field}
      error={meta.touched && meta.error}
    />
  );
};

export const ActionButtons = (props) => {
  const {children} = props;
  const className = classnames("action-buttons", props.className);

  const isPrimaryButton = (node) => node.type === Button && node.props.primary;

  return (
    <div className={className}>
    {Children.map(
      children,
      child => {
        if (!child) {
          return child;
        }

        const childWithButtonClass = addClassNameToElement(child, "action-buttons__button");

        return isPrimaryButton(child)
          ? addClassNameToElement(childWithButtonClass, "action-buttons__button--primary")
          : childWithButtonClass;
      }
    )}
    </div>
  );
};
ActionButtons.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const RequiredNotice = (props) => {
  const {children} = props;
  const className = classnames("required-notice", props.className);

  return (
    <div className={className}>{children}</div>
  );
};
RequiredNotice.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
