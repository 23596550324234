import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {getCurrentLocale} from "@awe-web/shared/lib/l10n/store.js";
import {setLocale} from "@awe-web/shared/lib/l10n/actions.js";
import {getLocales} from "@awe-web/shared/lib/config/store.js";

import "./language_switch.scss";

const LanguageSwitchComponent = (props) => {
  const className = classnames("language-switch", props.className);
  const {locales, currentLocale, setLocale} = props;

  return locales.length > 1
    ? (
      <div className={className}>
        {locales.map(locale => {
          const {locale: name, label} = locale;
          const buttonClassName = classnames(
            "language-switch__button",
            {
              "language-switch__button--active": name === currentLocale
            }
          );
          return (
            <span key={name} className="language-switch__item">
              <button className={buttonClassName} onClick={() => setLocale(name)}>{label}</button>
            </span>
          );
        })}
      </div>
    )
    : null;
};
LanguageSwitchComponent.propTypes = {
  className: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.shape({
    locale: PropTypes.string,
    label: PropTypes.node
  })).isRequired,
  currentLocale: PropTypes.string,
  setLocale: PropTypes.func
};

const stateToLanguageSwitchProps = createStateToProps({
  locales: getLocales,
  currentLocale: getCurrentLocale
});

const dispatchToLanguageSwitchProps = (dispatch) => ({
  setLocale: (locale) => dispatch(setLocale(locale))
});

export const LanguageSwitch = connect(stateToLanguageSwitchProps, dispatchToLanguageSwitchProps)(LanguageSwitchComponent);
