"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookingRuleTypes = exports.openingTimeWeekdays = exports.openingTimeTypes = void 0;
var openingTimeTypes = {
  OPEN: "OP",
  CLOSED: "CL"
};
exports.openingTimeTypes = openingTimeTypes;
var openingTimeWeekdays = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7
};
exports.openingTimeWeekdays = openingTimeWeekdays;
var bookingRuleTypes = {
  MIN_LEAD_TIME: "AH",
  MAX_LEAD_TIME: "MA",
  MIN_BOOKING_PERIOD: "MD",
  MAX_BOOKING_PERIOD: "MM"
};
exports.bookingRuleTypes = bookingRuleTypes;