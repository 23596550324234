import React, {useState, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSectionHeading} from "../../components/screen.js";
import {Row, Col} from "../../components/grid.js";
import {Cms} from "../../routing/routes.js";
import {getStrPromotionCards, getLtrPromotionCards} from "../store.js";

import "./promotion.scss";

const HEADING_B2C = "FSD0000292";
const HEADING_B2B = "ALG0000250";

const shouldEnter = elem => {
  const boundingBox = elem.getBoundingClientRect();
  return boundingBox.top <= (window.innerHeight || document.documentElement.clientHeight);
};

const PromotionCardLink = (props) => {
  const {children, className, pageName, link} = props;

  return pageName
    ? <Link className={className} to={Cms.location({pageName})}>{children}</Link>
    : <a className={className} href={link} target="_blank" rel="noopener noreferrer">{children}</a>;
};
PromotionCardLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  pageName: PropTypes.string,
  link: PropTypes.string
};

const PromotionCard = (props) => {
  const {card: {heading, text, pageName, link, image} = {}} = props;

  const [isVisible, setIsVisible] = useState();
  const elementRef = useRef();

  useEffect(() => {
    if (shouldEnter(elementRef.current)) {
      setIsVisible(true);
    }
    else {
      const onScroll = () => {
        if (shouldEnter(elementRef.current)) {
          setIsVisible(true);
          window.removeEventListener("scroll", onScroll, false);
        }
      };

      window.addEventListener("scroll", onScroll, false);

      return () => {
        window.removeEventListener("scroll", onScroll, false);
      };
    }
  }, []);

  const className = classnames("promotion-card", props.className, {
    "promotion-card--visible": isVisible
  });

  return (
    <Col ref={elementRef} tablet={{cols: 4}} desktop={{cols: 4}} className={className}>
      <PromotionCardLink className="promotion-card__container" pageName={pageName} link={link}>
        <div className="promotion-card__image-wrapper">
          <div
            className="promotion-card__image-wrapper__image"
            style={{backgroundImage: `url("${image}")`}}
          />
        </div>
        <div className="promotion-card__body">
          <div className="promotion-card__heading">{heading}</div>
          <div className="promotion-card__text">{text}</div>
        </div>
      </PromotionCardLink>
    </Col>
  );
};
PromotionCard.propTypes = {
  className: PropTypes.string,
  card: PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.string,
    pageName: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string
  })
};

const PromotionCardList = (props) => {
  const {cards = []} = props;
  return (
    <Row className="promotion-card-list">
      {cards.map(card => (
        <PromotionCard key={card.id} card={card} className="promotion-card-list__promotion-card" />
      ))}
    </Row>
  );
};
PromotionCardList.propTypes = {
  cards: PropTypes.arrayOf(PromotionCard.propTypes.card)
};

const PromotionComponent = (props) => {
  const {headingCode, promotionCards} = props;
  const className = classnames("promotion", props.className);

  return promotionCards.length > 0
    ? (
      <aside className={className}>
        <ScreenSectionHeading><T code={headingCode} /></ScreenSectionHeading>
        <PromotionCardList cards={promotionCards} />
      </aside>
    )
    : null;
};
PromotionComponent.propTypes = {
  className: PropTypes.string,
  headingCode: PropTypes.string,
  promotionCards: PromotionCardList.propTypes.cards
};

const stateToStrProps = createStateToProps({
  headingCode: () => HEADING_B2C,
  promotionCards: getStrPromotionCards
});

export const B2cPromotion = connect(stateToStrProps)(PromotionComponent);

const stateToLtrProps = createStateToProps({
  headingCode: () => HEADING_B2B,
  promotionCards: getLtrPromotionCards
});

export const B2bPromotion = connect(stateToLtrProps)(PromotionComponent);
