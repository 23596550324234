import React, {useEffect, useRef} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {useFormikContext} from "formik";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSectionHeading} from "../../../components/screen.js";
import {
  Button,
  FormikLabeledField,
  FormikTextInput,
  FormikSelect,
  ScrollToError
} from "../../../components/form.js";
import {SelectDriverForm} from "../../../rent_contracts/components/select_driver_form.js";
import {ProvisioningSelection} from "../../../rent_contracts/components/provisioning_selection.js";
import {StationAddress} from "../../../stations/components/station_card.js";

import "./select_driver_and_provisioning_form.scss";

const DRIVER_FORM_HEADING = "ALG0000066";
const PROVISIONING_HEADING = "ALG0000067";
const OPTION_PICKUP = "ALG0000070";
const OPTION_DELIVERY_TO_COMPANY_ADDRESS = "ALG0000068";
const OPTION_DELIVERY_TO_CUSTOM_ADDRESS = "ALG0000069";
const SUBMIT = "ALG0000072";

const SelectProvisioningForm = (props) => {
  const {
    values: {provisioningType},
    coStation,
    companyAddress,
    formattedDeliveryPrice,
    formattedPickupPrice,
    isDeliveryEnabled,

    isDeliveryFieldRequired,
    getDeliveryFieldLabel,
    getDeliverySelectFieldOptions,
    getDeliveryFieldPlaceholder
  } = props;
  const className = classnames("select-provisioning-form", props.className);
  const formik = useFormikContext();

  // Store formik in ref to avoid being forced to put it in the
  // dependency array which runs the effect below more often than it
  // needs to.
  const formikRef = useRef();
  formikRef.current = formik;

  // Untouch fields in delivery form every time it is opened in order to remove
  // previous error messages.
  useEffect(() => {
    if (provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS) {
      [
        "DeliveryAddress:surname",
        "DeliveryContactPerson:name",
        "DeliveryContactPerson:surname",
        "DeliveryAddress:street",
        "DeliveryAddress:building",
        "DeliveryAddress:zipCode",
        "DeliveryAddress:city",
        "DeliveryAddress:countryCode",
        "DeliveryContactPerson:phone"
      ]
        .filter((name) => formikRef.current.touched[name])
        .forEach((name) => {
          formikRef.current.setFieldTouched(name, false);
        });
    }
  }, [provisioningType]);

  const populateDeliveryFormRef = useRef(provisioningType !== provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS);
  useEffect(() => {
    if (populateDeliveryFormRef.current && provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS) {
      populateDeliveryFormRef.current = false;
      formikRef.current.setValues({
        ...formikRef.current.values,
        "DeliveryContactPerson:name": formikRef.current.values["Person:name"],
        "DeliveryContactPerson:surname": formikRef.current.values["Person:surname"],
        "DeliveryContactPerson:phone": formikRef.current.values["Address:phone"]
      });
    }
  }, [provisioningType]);

  return (
    <div className={className}>
      <ProvisioningSelection
        name="provisioningType"
        value={provisioningTypes.PICKUP}
        label={<T code={OPTION_PICKUP} />}
        formattedPrice={formattedPickupPrice}
      >
        <StationAddress
          className="b2b-ltr-select-provisioning-form__station-address"
          stationNameClassName="b2b-ltr-select-provisioning-form__station-address__name"
          openingTimesHeadingClassName="b2b-ltr-select-provisioning-form__station-address__opening-times-heading"
          station={coStation}
        />
      </ProvisioningSelection>

      <ProvisioningSelection
        name="provisioningType"
        value={provisioningTypes.DELIVERY_TO_COMPANY_ADDRESS}
        label={<T code={OPTION_DELIVERY_TO_COMPANY_ADDRESS} />}
        formattedPrice={formattedDeliveryPrice}
        disabled={!isDeliveryEnabled}
      >
        <div className="b2b-ltr-select-provisioning-form__company-address">
          {companyAddress.companyName}<br />
          {companyAddress.street}<br />
          {companyAddress.building
            ? <>{companyAddress.building}<br /></>
            : null
          }
          {companyAddress.zipCode} {companyAddress.city}<br />
          {companyAddress.country}
        </div>
      </ProvisioningSelection>

      <ProvisioningSelection
        name="provisioningType"
        value={provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS}
        label={<T code={OPTION_DELIVERY_TO_CUSTOM_ADDRESS} />}
        formattedPrice={formattedDeliveryPrice}
        disabled={!isDeliveryEnabled}
      >
        <div
          className="b2b-ltr-select-provisioning-form__fieldset"
        >
          <FormikLabeledField
            name="DeliveryAddress:surname"
            as={FormikTextInput}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:surname")}
          />

          <FormikLabeledField
            name="DeliveryContactPerson:name"
            as={FormikTextInput}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            placeholder={getDeliveryFieldPlaceholder("DeliveryContactPerson:name")}
          />

          <FormikLabeledField
            name="DeliveryContactPerson:surname"
            as={FormikTextInput}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            placeholder={getDeliveryFieldPlaceholder("DeliveryContactPerson:surname")}
          />

          <FormikLabeledField
            name="DeliveryAddress:street"
            as={FormikTextInput}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:street")}
          />

          <FormikLabeledField
            name="DeliveryAddress:building"
            as={FormikTextInput}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:building")}
          />

          <div
            className="b2b-ltr-select-provisioning-form__zip-code-and-city"
          >
            <FormikLabeledField
              className="b2b-ltr-select-provisioning-form__zip-code-and-city__zip-code"
              name="DeliveryAddress:zipCode"
              as={FormikTextInput}
              isFieldRequired={isDeliveryFieldRequired}
              getFieldLabel={getDeliveryFieldLabel}
              placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:zipCode")}
            />

            <FormikLabeledField
              className="b2b-ltr-select-provisioning-form__zip-code-and-city__city"
              name="DeliveryAddress:city"
              as={FormikTextInput}
              isFieldRequired={isDeliveryFieldRequired}
              getFieldLabel={getDeliveryFieldLabel}
              placeholder={getDeliveryFieldPlaceholder("DeliveryAddress:city")}
            />
          </div>

          <FormikLabeledField
            name="DeliveryAddress:countryCode"
            as={FormikSelect}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            options={getDeliverySelectFieldOptions("DeliveryAddress:countryCode")}
          />

          <FormikLabeledField
            name="DeliveryContactPerson:phone"
            as={FormikTextInput}
            isFieldRequired={isDeliveryFieldRequired}
            getFieldLabel={getDeliveryFieldLabel}
            placeholder={getDeliveryFieldPlaceholder("DeliveryContactPerson:phone")}
          />
        </div>
      </ProvisioningSelection>
    </div>
  );
};
SelectProvisioningForm.propTypes = {
  values: PropTypes.shape({
    provisioningType: PropTypes.oneOf(Object.values(provisioningTypes))
  }).isRequired,
  coStation: StationAddress.propTypes.station,
  companyAddress: PropTypes.shape({
    companyName: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string
  }),
  formattedDeliveryPrice: PropTypes.string,
  formattedPickupPrice: PropTypes.string,
  isDeliveryEnabled: PropTypes.bool,

  isDeliveryFieldRequired: PropTypes.func,
  getDeliveryFieldLabel: PropTypes.func,
  getDeliverySelectFieldOptions: PropTypes.func,
  getDeliveryFieldPlaceholder: PropTypes.func
};

export const SelectDriverAndProvisioningForm = (props) => {
  const {
    values,
    coStation,
    companyAddress,
    formattedDeliveryPrice,
    formattedPickupPrice,
    isDeliveryEnabled,

    isDriverFieldRequired,
    getDriverFieldLabel,
    getDriverSelectFieldOptions,
    getDriverFieldPlaceholder,

    isDeliveryFieldRequired,
    getDeliveryFieldLabel,
    getDeliverySelectFieldOptions,
    getDeliveryFieldPlaceholder,

    handleSubmit
  } = props;

  return (
    <form className="b2b-ltr-select-driver-and-provisioning-form" onSubmit={handleSubmit}>
      <ScrollToError />

      <Row>
        <Col desktop={{cols: 4, offset: 2}}>
          <ScreenSectionHeading light><T code={DRIVER_FORM_HEADING} /></ScreenSectionHeading>

          <SelectDriverForm
            className="b2b-ltr-select-provisioning-form__fieldset"
            isDriverFieldRequired={isDriverFieldRequired}
            getDriverFieldLabel={getDriverFieldLabel}
            getDriverSelectFieldOptions={getDriverSelectFieldOptions}
            getDriverFieldPlaceholder={getDriverFieldPlaceholder}
          />
        </Col>
        <Col
          className="b2b-ltr-select-driver-and-provisioning-form__provisioning"
          desktop={{cols: 4}}
        >
          <ScreenSectionHeading light><T code={PROVISIONING_HEADING} /></ScreenSectionHeading>

          <SelectProvisioningForm
            className="b2b-ltr-select-provisioning-form__provisioning"
            values={values}
            coStation={coStation}
            companyAddress={companyAddress}
            formattedDeliveryPrice={formattedDeliveryPrice}
            formattedPickupPrice={formattedPickupPrice}
            isDeliveryEnabled={isDeliveryEnabled}
            isDeliveryFieldRequired={isDeliveryFieldRequired}
            getDeliveryFieldLabel={getDeliveryFieldLabel}
            getDeliverySelectFieldOptions={getDeliverySelectFieldOptions}
            getDeliveryFieldPlaceholder={getDeliveryFieldPlaceholder}
          />
        </Col>
      </Row>

      <Row>
        <Col desktop={{cols: 4, offset: 6}}>
          <Button primary submit continue><T code={SUBMIT} /></Button>
        </Col>
      </Row>
    </form>
  );
};
SelectDriverAndProvisioningForm.propTypes = {
  isDriverFieldRequired: SelectDriverForm.propTypes.isDriverFieldRequired,
  getDriverFieldLabel: SelectDriverForm.propTypes.getDriverFieldLabel,
  getDriverSelectFieldOptions: SelectDriverForm.propTypes.getDriverSelectFieldOptions,
  getDriverFieldPlaceholder: SelectDriverForm.propTypes.getDriverFieldPlaceholder,

  values: SelectProvisioningForm.propTypes.values,
  coStation: SelectProvisioningForm.propTypes.coStation,
  companyAddress: SelectProvisioningForm.propTypes.companyAddress,
  formattedDeliveryPrice: SelectProvisioningForm.propTypes.formattedDeliveryPrice,
  formattedPickupPrice: SelectProvisioningForm.propTypes.formattedPickupPrice,
  isDeliveryEnabled: SelectProvisioningForm.propTypes.isDeliveryEnabled,

  isDeliveryFieldRequired: SelectProvisioningForm.propTypes.isDeliveryFieldRequired,
  getDeliveryFieldLabel: SelectProvisioningForm.propTypes.getDeliveryFieldLabel,
  getDeliverySelectFieldOptions: SelectProvisioningForm.propTypes.getDeliverySelectFieldOptions,
  getDeliveryFieldPlaceholder: SelectProvisioningForm.propTypes.getDeliveryFieldPlaceholder,

  handleSubmit: PropTypes.func
};
