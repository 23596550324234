import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {getProvisioningTypeB2b, getProvisioningTypeB2c} from "../rent_contracts/store.js";
import {ScreenSectionHeading} from "./screen.js";

import "./pickup_notice.scss";

import driversLicenseIcon from "./assets/drivers-license.svg";
import identityCardIcon from "./assets/identity-card.svg";
import creditCardIcon from "./assets/credit-card.svg";

const PICKUP_NOTICE_TITLE = "FSD0000179";
const PICKUP_REQUIRED_DOCUMENTS = "FSD0000180";
const DELIVERY_NOTICE_TITLE = "ALG0000260";
const DELIVERY_REQUIRED_DOCUMENTS = "ALG0000261";
const DRIVERS_LICENSE = "FSD0000181";
const IDENTITY_CARD = "FSD0000182";
const CREDIT_CARD = "FSD0000183";

const PickupNoticeComponent = (props) => {
  const {reservationPath, provisioningType} = props;
  const className = classnames("pickup-notice", props.className);

  const noticeTitle = {
    [provisioningTypes.PICKUP]: PICKUP_NOTICE_TITLE,
    [provisioningTypes.DELIVERY_TO_COMPANY_ADDRESS]: DELIVERY_NOTICE_TITLE,
    [provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS]: DELIVERY_NOTICE_TITLE
  }[provisioningType];

  const requiredDocumentsLabel = {
    [provisioningTypes.PICKUP]: PICKUP_REQUIRED_DOCUMENTS,
    [provisioningTypes.DELIVERY_TO_COMPANY_ADDRESS]: DELIVERY_REQUIRED_DOCUMENTS,
    [provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS]: DELIVERY_REQUIRED_DOCUMENTS
  }[provisioningType];

  return (
    <div className={className}>
      <ScreenSectionHeading>
        <T code={noticeTitle} />
      </ScreenSectionHeading>

      <div className="pickup-notice__description">
        <T code={requiredDocumentsLabel} />
      </div>

      <ul className="pickup-notice__documents">
        <li className="pickup-notice__drivers-license">
          <img src={driversLicenseIcon} alt="" />
          <T code={DRIVERS_LICENSE} />
        </li>
        <li className="pickup-notice__identity-card">
          <img src={identityCardIcon} alt="" />
          <T code={IDENTITY_CARD} />
        </li>
        {reservationPath === "B2C" && (
          <li className="pickup-notice__credit-card">
            <img src={creditCardIcon} alt="" />
            <T code={CREDIT_CARD} />
          </li>
        )}
      </ul>
    </div>
  );
};
PickupNoticeComponent.propTypes = {
  className: PropTypes.string,
  reservationPath: PropTypes.oneOf(["B2C", "B2B"]).isRequired,
  provisioningType: PropTypes.oneOf(Object.values(provisioningTypes))
};

const stateToPickupNoticeProps = createStateToProps({
  provisioningType: (state, props) => props.reservationPath === "B2B"
    ? getProvisioningTypeB2b(state)
    : getProvisioningTypeB2c(state)
});

export const PickupNotice = connect(stateToPickupNoticeProps)(PickupNoticeComponent);
