import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Button} from "../../components/form.js";
import {SelectedTime} from "./station.js";
import {getFormattedCiDateTime, getFormattedCoDateTime} from "../store.js";

import "./selected_period.scss";

const CHANGE_VALUES = "ALG0000025";
const CHECK_OUT_DATE = "ALG0000023";
const CHECK_IN_DATE = "ALG0000024";

const MinimalSelectedPeriod = (props) => {
  const {
    formattedCoDateTime,
    formattedCiDateTime
  } = props;

  return (
    <div className="minimal-selected-period">
      <div><T className="minimal-selected-period__label" code={CHECK_OUT_DATE} /> {formattedCoDateTime}</div>
      <div><T className="minimal-selected-period__label" code={CHECK_IN_DATE} /> {formattedCiDateTime}</div>
    </div>
  );
};
MinimalSelectedPeriod.propTypes = {
  formattedCoDateTime: PropTypes.string,
  formattedCiDateTime: PropTypes.string
};


const SelectedPeriodWithIconsComponent = (props) => {
  const {translate, formattedCoDateTime, formattedCiDateTime} = props;
  const className = classnames("selected-period-with-icons", props.className);

  const coDateTimeLabel = translate(CHECK_OUT_DATE);
  const ciDateTimeLabel = translate(CHECK_IN_DATE);

  return (
    <div className={className}>
      <SelectedTime
        className="selected-period-with-icons__selected-time"
        label={coDateTimeLabel}
        formattedDateTime={formattedCoDateTime}
      />
      <SelectedTime
        className="selected-period-with-icons__selected-time"
        label={ciDateTimeLabel}
        formattedDateTime={formattedCiDateTime}
      />
    </div>
  );
};
SelectedPeriodWithIconsComponent.propTypes = {
  className: PropTypes.string,
  formattedCoDateTime: PropTypes.string,
  formattedCiDateTime: PropTypes.string,
  translate: PropTypes.func.isRequired
};

const stateToSelectedPeriodWithIconsProps = createStateToProps({
  formattedCoDateTime: getFormattedCoDateTime,
  formattedCiDateTime: getFormattedCiDateTime,
  translate: newTranslate
});

const SelectedPeriodWithIcons = connect(
  stateToSelectedPeriodWithIconsProps
)(SelectedPeriodWithIconsComponent);


const SelectedPeriodComponent = (props) => {
  const {
    editButtonLocation,
    formattedCoDateTime,
    formattedCiDateTime
  } = props;
  const className = classnames("selected-period", props.className);

  const editButton = editButtonLocation
    ? (
      <Button
        className="selected-period__2-of-8-cols"
        secondary
        back
        to={editButtonLocation}
      >
        <T code={CHANGE_VALUES} />
      </Button>
    )
    : null;

  return (
    <div className={className}>
      <MinimalSelectedPeriod
        formattedCoDateTime={formattedCoDateTime}
        formattedCiDateTime={formattedCiDateTime}
      />

      <div className="selected-period__selected-times">
        <SelectedPeriodWithIcons className="selected-period__4-of-8-cols" />
        {editButton}
      </div>
    </div>
  );
};
SelectedPeriodComponent.propTypes = {
  className: PropTypes.string,
  editButtonLocation: Button.propTypes.to,
  formattedCoDateTime: PropTypes.string.isRequired,
  formattedCiDateTime: PropTypes.string.isRequired
};

const stateToSelectedPeriodProps = createStateToProps({
  formattedCoDateTime: getFormattedCoDateTime,
  formattedCiDateTime: getFormattedCiDateTime
});

export const SelectedPeriod = connect(stateToSelectedPeriodProps)(SelectedPeriodComponent);
