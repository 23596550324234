import qs from "query-string";
import {parseDate} from "@awe-web/shared/lib/util/date.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {B2C_SelectStationAndTime} from "../../routing/routes.js";

export const initialize = () => async (dispatch, getState, context) => {
  const params = qs.parse(context.history.location.search);

  dispatch(redirect(B2C_SelectStationAndTime.location({
    coStationId: Number(params.coStationId),
    ciStationId: Number(params.ciStationId),
    coDateTime: parseDate(params.coDateTime),
    ciDateTime: parseDate(params.ciDateTime)
  })));
};
