import React, {useCallback} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Field} from "formik";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {RadioButton, Checkbox} from "@awe-web/shared/lib/components/form.js";
import {LabeledCheckbox, TextInput} from "./form.js";
import {MoreInfosButton} from "./more_infos_button.js";

import "./option.scss";

const INCLUSIVE = "FSD0000126";

const SingleChoiceOption = (props) => {
  const {
    showPrice,
    name,
    checked,
    option: {id, description, formattedPrice, included},
    onChange
  } = props;
  const price = included ? <T code={INCLUSIVE} /> : formattedPrice;

  return (
    <label className="option">
      <RadioButton
        className="option__input"
        checked={checked}
        name={name}
        value={id}
        convertValue={Number}
        onChange={onChange}
      />
      <div className="option__row">
        <span className="option__description radiobutton-label">{description}</span>
        {showPrice
          ? <span className="option__value">{price}</span>
          : null
        }
      </div>
    </label>
  );
};
SingleChoiceOption.propTypes = {
  showPrice: PropTypes.bool,
  name: PropTypes.string,
  checked: PropTypes.bool,
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    formattedPrice: PropTypes.string,
    included: PropTypes.bool
  }),
  onChange: PropTypes.func
};

const SingleChoiceOptions = (props) => {
  const {
    showPrices,
    title,
    notice,
    name,
    value,
    options = [],
    moreInfos = {},
    onChange
  } = props;
  const className = classnames("single-choice-options", props.className);

  if (options.length === 0 && !notice) {
    return null;
  }

  const moreInfosButton = moreInfos.heading && moreInfos.content
    ? (
      <MoreInfosButton
        className="single-choice-options__button"
        heading={moreInfos.heading}
        content={moreInfos.content}
      />
    )
    : null;
  const noticeElement = notice
    ? (
      <div className="single-choice-options__notice">
        {notice}
      </div>
    )
    : null;
  const optionElements = options.map(option => (
    <SingleChoiceOption
      key={option.id}
      showPrice={showPrices}
      name={name}
      checked={value === option.id}
      option={option}
      onChange={onChange}
    />
  ));

  return (
    <div className={className}>
      {title || moreInfosButton
        ? (
          <div className="single-choice-options__heading">
            {title}
            {moreInfosButton}
          </div>
        )
        : null
      }
      {noticeElement}
      {optionElements}
    </div>
  );
};
SingleChoiceOptions.propTypes = {
  className: PropTypes.string,
  showPrices: SingleChoiceOption.propTypes.showPrice,
  title: PropTypes.string,
  notice: PropTypes.string,
  name: SingleChoiceOption.propTypes.name,
  value: PropTypes.any,
  options: PropTypes.arrayOf(SingleChoiceOption.propTypes.option),
  moreInfos: PropTypes.shape({
    heading: MoreInfosButton.propTypes.heading,
    content: MoreInfosButton.propTypes.content
  }),
  onChange: SingleChoiceOption.propTypes.onChange
};

export const FormikSingleChoiceOptions = (props) => {
  return (
    <Field
      {...props}
      as={SingleChoiceOptions}
    />
  );
};
FormikSingleChoiceOptions.propTypes = {
  className: SingleChoiceOptions.propTypes.className,
  showPrices: SingleChoiceOptions.propTypes.showPrices,
  title: SingleChoiceOptions.propTypes.title,
  notice: SingleChoiceOptions.propTypes.notice,
  name: SingleChoiceOptions.propTypes.name,
  value: SingleChoiceOptions.propTypes.value,
  options: SingleChoiceOptions.propTypes.options,
  moreInfos: SingleChoiceOptions.propTypes.moreInfos
};

const MultiChoiceOption = (props) => {
  const {showPrice, checked, name, option: {id, description, formattedPrice}, onChange} = props;

  return (
    <LabeledCheckbox className="option">
      <Checkbox
        className="option__input"
        checked={checked}
        name={name}
        value={id}
        onChange={onChange}
      />
      <div className="option__row">
        <span className="option__description checkbox-label">{description}</span>
        {showPrice
          ? <span className="option__value">{formattedPrice}</span>
          : null
        }
      </div>
    </LabeledCheckbox>
  );
};
MultiChoiceOption.propTypes = {
  showPrice: PropTypes.bool,
  checked: PropTypes.bool,
  name: PropTypes.string,
  option: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    formattedPrice: PropTypes.string
  }),
  onChange: PropTypes.func
};

const MultiChoiceOptionWithTextInput = (props) => {
  const {
    showPrice,
    value: {
      id,
      value
    },
    option: {
      description,
      formattedPrice,
      wwwAnnotationAllowed,
      inputHint,
      additionalWWWInfoText
    },
    onChange
  } = props;

  const checked = wwwAnnotationAllowed
    ? typeof value === "string"
    : Boolean(value);

  const handleCheckboxChange = useCallback((event) => {
    const newValue = wwwAnnotationAllowed
      ? event.target.checked && ""
      : event.target.checked;
    onChange({id, value: newValue});
  }, [id, wwwAnnotationAllowed, onChange]);

  const handleInputChange = useCallback((event) => {
    onChange({id, value: event.target.value});
  }, [id, onChange]);

  return (
    <LabeledCheckbox className="option-with-text-input">
      <Checkbox
        className="option-with-text-input__checkbox"
        checked={checked}
        value={id}
        onChange={handleCheckboxChange}
      />
      <span className="option-with-text-input__label checkbox-label">{description}</span>
        {showPrice
          ? <span className="option-with-text-input__price">{formattedPrice}</span>
          : null
        }
        <MoreInfosButton
          className="option-with-text-input__button"
          heading={description}
          content={additionalWWWInfoText}
        />
        {wwwAnnotationAllowed && checked
          ? (
            <div className="option-with-text-input__input">
              <TextInput value={value} onChange={handleInputChange} />
              <div className="option-with-text-input__input__hint">{inputHint}</div>
            </div>
          )
          : null
        }
    </LabeledCheckbox>
  );
};
MultiChoiceOptionWithTextInput.propTypes = {
  showPrice: PropTypes.bool,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ])
  }).isRequired,
  option: PropTypes.shape({
    description: PropTypes.string.isRequired,
    formattedPrice: PropTypes.string,
    wwwAnnotationAllowed: PropTypes.bool,
    inputHint: PropTypes.string,
    additionalWWWInfoText: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func
};

const MultiChoiceOptions = (props) => {
  const {
    showPrices,
    title,
    notice,
    name,
    value = {},
    options = [],
    moreInfos = {},
    onChange = () => {}
  } = props;

  const handleChange = useCallback((newValue) => {
    onChange({target: {name, value: {...value, [newValue.id]: newValue.value}}});
  }, [name, value, onChange]);

  const className = classnames("multi-choice-options", props.className);

  if (options.length === 0 && !notice) {
    return null;
  }

  const moreInfosButton = moreInfos.heading && moreInfos.content
    ? (
      <MoreInfosButton
        className="multi-choice-options__button"
        heading={moreInfos.heading}
        content={moreInfos.content}
      />
    )
    : null;
  const noticeElement = notice
    ? (
      <div className="multi-choice-options__notice">
        {notice}
      </div>
    )
    : null;
  const optionElements = options.map((option) => (
    <MultiChoiceOptionWithTextInput
      key={option.id}
      showPrice={showPrices}
      value={{id: option.id, value: value[option.id]}}
      option={option}
      onChange={handleChange}
    />
  ));

  return (
    <div className={className}>
      {title || moreInfosButton
        ? (
          <div className="multi-choice-options__heading">
            {title}
            {moreInfosButton}
          </div>
        )
        : null
      }
      {noticeElement}
      {optionElements}
    </div>
  );
};
MultiChoiceOptions.propTypes = {
  className: PropTypes.string,
  showPrices: MultiChoiceOptionWithTextInput.propTypes.showPrice,
  title: PropTypes.string,
  notice: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  options: PropTypes.arrayOf(MultiChoiceOptionWithTextInput.propTypes.option),
  moreInfos: SingleChoiceOptions.propTypes.moreInfos,
  onChange: PropTypes.func
};

export const FormikMultiChoiceOptions = (props) => {
  return (
    <Field
      {...props}
      as={MultiChoiceOptions}
    />
  );
};
FormikMultiChoiceOptions.propTypes = {
  className: MultiChoiceOptions.propTypes.className,
  showPrices: MultiChoiceOptions.propTypes.showPrices,
  title: MultiChoiceOptions.propTypes.title,
  notice: MultiChoiceOptions.propTypes.notice,
  name: MultiChoiceOptions.propTypes.name,
  value: MultiChoiceOptions.propTypes.name,
  moreInfos: MultiChoiceOptions.propTypes.moreInfos,
  options: MultiChoiceOptions.propTypes.options
};
