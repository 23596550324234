import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";

import "./save_time.scss";

const SAVE_TIME_TITLE = "FSD0000184";
const SAVE_TIME_DESCRIPTION = "FSD0000185";

export const SaveTime = (props) => {
  const className = classnames("save-time", props.className);

  return (
    <div className={className}>
      <div className="save-time__title"><T code={SAVE_TIME_TITLE} /></div>
      <div className="save-time__description"><T code={SAVE_TIME_DESCRIPTION} /></div>
    </div>
  );
};
SaveTime.propTypes = {
  className: PropTypes.string
};
