import React from "react";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {ScreenSection, ScreenHeading} from "../../components/screen.js";
import {TypeArea} from "../../components/layout.js";
import {B2bPromotion} from "../../promotion/components/promotion.js";
import {SelectStationAndTimeForm} from "../../stations/components/select_station_and_time_form.js";

const HEADING = "ALG0000001";

const SelectStationAndTime = (props) => {
  return (
    <div className="b2b-ltr-select-period">
      <ScreenSection main dark>
        <TypeArea>
          <ScreenHeading><T code={HEADING} /></ScreenHeading>
          <SelectStationAndTimeForm navigate={navigate} reservationPath="B2B" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <B2bPromotion />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SelectStationAndTime.propTypes = {
};

export default SelectStationAndTime;
