import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Overlay, OverlayHeading, OverlaySection} from "../../components/overlay.js";
import {Button} from "../../components/form.js";
import {isModalClosed, getModalTitle, getModalMessage, getModalButtonLabel} from "../store.js";
import {closeModal} from "../actions.js";

import "./modal.scss";

const ModalComponent = (props) => {
  const {isClosed, title, message, buttonLabel, onClose} = props;

  return (
    <Overlay className="modal" modal open={!isClosed} onClose={onClose}>
      <OverlayHeading>{title}</OverlayHeading>
      <OverlaySection>
        <div className="modal__message">{message}</div>
        <Button className="modal__button" primary continue onClick={onClose}>{buttonLabel}</Button>
      </OverlaySection>
    </Overlay>
  );
};
ModalComponent.propTypes = {
  isClosed: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

const stateToModalProps = createStateToProps({
  isClosed: isModalClosed,
  title: getModalTitle,
  message: getModalMessage,
  buttonLabel: getModalButtonLabel
});

const dispatchToModalProps = (dispatch) => ({
  onClose: () => dispatch(closeModal())
});

export const Modal = connect(stateToModalProps, dispatchToModalProps)(ModalComponent);
