import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {ensureUserLoggedIn} from "../user/actions.js";
import {getHistoryPageSize} from "../config/store.js";
import {
  getActiveTab,
  getCurrentPage,
  getFilterValues,
  getReservationHistory
} from "./store.js";

export const updateHistory = () => async (dispatch, getState, context) => {
  await dispatch(asyncAction({
    typePrefix: "HISTORY/RESERVATION_HISTORY",
    loadPayload: async () => {
      const limit = getHistoryPageSize(getState());
      const page = getCurrentPage(getState());
      const start = (page - 1) * limit;
      const tab = getActiveTab(getState());
      const filter = getFilterValues(getState());
      const registrationNumber = filter.get("registrationNumber");
      const driverName = filter.get("driverName");
      await dispatch(ensureUserLoggedIn());
      const response = await context.api.history.getReservationHistory({
        tab,
        start,
        limit,
        registrationNumber,
        driverName
      });

      return {
        params: {
          page,
          tab,
          filterValues: {
            registrationNumber,
            driverName
          }
        },
        history: response.data,
        totalNumberOfItems: response.totalCount
      };
    }
  }));
};

export const nextPage = () => async (dispatch, getState) => {
  const currentPage = getCurrentPage(getState());
  dispatch({
    type: "HISTORY/PAGE_SELECTED",
    payload: {
      page: currentPage + 1
    }
  });
  await dispatch(updateHistory());
};

export const previousPage = () => async (dispatch, getState) => {
  const currentPage = getCurrentPage(getState());
  dispatch({
    type: "HISTORY/PAGE_SELECTED",
    payload: {
      page: currentPage - 1
    }
  });
  await dispatch(updateHistory());
};

export const selectTab = (tab) => async (dispatch) => {
  dispatch({
    type: "HISTORY/TAB_SELECTED",
    payload: {
      tab
    }
  });
  dispatch({
    type: "HISTORY/PAGE_SELECTED",
    payload: {
      page: 1
    }
  });
  await dispatch(updateHistory());
};

export const setFilter = ({registrationNumber, driverName}) => async (dispatch) => {
  dispatch({
    type: "HISTORY/FILTER_SELECTED",
    payload: {
      filterValues: {
        registrationNumber,
        driverName
      }
    }
  });
  dispatch({
    type: "HISTORY/PAGE_SELECTED",
    payload: {
      page: 1
    }
  });
  await dispatch(updateHistory());
};

export const initializeHistory = () => async (dispatch) => {
  await dispatch(updateHistory());
};

export const cancelReservation = (rentContractId) => async (dispatch, getState, context) => {
  await dispatch(asyncAction({
    typePrefix: "HISTORY/CANCEL_RENT_CONTRACT",
    loadPayload: () => context.api.rentContracts.cancelRentContract({rentContractId})
  }));

  await dispatch(updateHistory());

  const reservations = getReservationHistory(getState());
  const currentPage = getCurrentPage(getState());
  if (reservations.size === 0 && currentPage > 1) {
    await dispatch(previousPage());
  }
};
