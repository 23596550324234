import qs from "query-string";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {userRoles, accountTypes} from "@awe-web/shared/lib/user/constants.js";
import {login, serverValidationFailed} from "@awe-web/shared/lib/user/actions.js";
import {getResetPasswordAccountType} from "@awe-web/shared/lib/user/store.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {resetPassword} from "../../user/actions.js";
import {openModal} from "../../app/actions.js";
import {
  MyBmwRentDashboard,
  B2B_Dashboard,
  B2C_RequestResetPasswordToken,
  B2B_RequestResetPasswordToken
} from "../../routing/routes.js";
import {getUserRole} from "../../user/store.js";

const USERNAME_LABEL = "FSD0000088";
const USERNAME_PLACEHOLDER = "FSD0000089";
const PASSWORD_LABEL = "FSD0000090";
const PASSWORD_PLACEHOLDER = "FSD0000091";

const SUCCESS_TITLE = "FSD0000353";
const SUCCESS_MESSAGE = "FSD0000354";
const MY_BMW_RENT = "FSD0000259";
const RETRY_RECOVER_PASSWORD = "FSD0000355";

const isValidAccountType = (candidate) =>
  Object.values(accountTypes).includes(candidate);

export const initialize = () => async (dispatch, getState, context) => {
  const {
    token: resetPasswordToken,
    classification
  } = qs.parse(context.history.location.search);
  const resetPasswordAccountType = isValidAccountType(classification)
    ? classification
    : accountTypes.B2C;

  dispatch({
    type: "USER/RESET_PASSWORD_TOKEN_LOADED",
    payload: {
      resetPasswordToken,
      resetPasswordAccountType
    }
  });

  const translate = newTranslate(getState());
  dispatch({
    type: "USER/FORM_CONFIGURATION_LOADED",
    payload: {
      groupName: "resetPassword",
      formConfiguration: [
        {
          items: [
            {
              name: "username",
              fieldLabel: translate(USERNAME_LABEL),
              emptyText: translate(USERNAME_PLACEHOLDER),
              xtype: "textfield",
              allowBlank: false
            },
            {
              name: "password",
              fieldLabel: translate(PASSWORD_LABEL),
              emptyText: translate(PASSWORD_PLACEHOLDER),
              xtype: "textfield",
              allowBlank: false
            }
          ]
        }
      ]
    }
  });
};

export const submit = (formValues, {validateForm, setSubmitting}) => async (dispatch, getState) => {
  const translate = newTranslate(getState());

  try {
    setSubmitting(true);
    const {username, password} = formValues;
    await dispatch(resetPassword(username, password));
    await dispatch(login({username, password}));

    const title = translate(SUCCESS_TITLE);
    const message = translate(SUCCESS_MESSAGE);
    const buttonLabel = translate(MY_BMW_RENT);
    await dispatch(openModal(title, message, buttonLabel));

    const dashboardRoute = getUserRole(getState()) === userRoles.B2B
      ? B2B_Dashboard
      : MyBmwRentDashboard;
    dispatch(redirect(dashboardRoute.location()));
  }
  catch (error) {
    switch (error.type) {
      case "VALIDATION_FAILED": {
        const groupName = "resetPassword";
        const errors = error.errors;
        dispatch(serverValidationFailed(groupName, formValues, errors));
        validateForm();
        break;
      }

      case "RESET_PASSWORD_TOKEN_INVALID": {
        const accountType = getResetPasswordAccountType(getState());
        const title = error.title;
        const message = error.displayMessage;
        const buttonLabel = translate(RETRY_RECOVER_PASSWORD);
        await dispatch(openModal(title, message, buttonLabel));

        const RequestResetPasswordToken = accountType === accountTypes.B2B
          ? B2B_RequestResetPasswordToken
          : B2C_RequestResetPasswordToken;
        dispatch(redirect(RequestResetPasswordToken.location({login: formValues.username})));
        break;
      }

      default:
        throw error;
    }
  }
  finally {
    setSubmitting(false);
  }
};
