import {Map, List, fromJS} from "immutable";
import {createSelector} from "reselect";
import {formatCurrency} from "@awe-web/shared/lib/util/number.js";
import {substitute, trim} from "@awe-web/shared/lib/util/string.js";
import {
  ruleBls,
  optionExtCodes,
  calcBases,
  dynDataValueCodes,
  provisioningTypes
} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {
  newTranslate,
  createDynamicListSelector
} from "@awe-web/shared/lib/l10n/store.js";
import {
  getRentContract,
  getMainRentObjectTransaction,
  getEnabledInvoiceItems,
  getMileageInvoiceItems,
  getDeductibleInvoiceItems,
  getDeliveryInvoiceItem,
  getRawDeliveryAddress,
  getRentContractId,
  getInvoiceItems
} from "@awe-web/shared/lib/rent_contracts/store.js";
import {getCoStation} from "../stations/store.js";
import {getCompanyAddress} from "../user/store.js";

const INCLUDED = "FSD0000126";
const INCLUDED_MILEAGE = "FSD0000350";
const MONTH = "ALG0000073";
const OPTION_INPUT_HINT = "ALG0000035";
const SELECTED_EXTRAS_LABEL = "ALG0000038";
const LTR_MILEAGE_OPTION_CHANGED = "ALG0000056";
const STR_MILEAGE_OPTIONS_CHANGED = "ALG0000248";
const EQUIPMENT_OPTIONS_CHANGED = "ALG0000249";
const PROVISIONING_PICKUP = "ALG0000070";
const PROVISIONING_DELIVERY_TO_COMPANY_ADDRESS = "ALG0000068";
const PROVISIONING_DELIVERY_TO_CUSTOM_ADDRESS_B2B = "ALG0000069";
const PROVISIONING_DELIVERY_TO_CUSTOM_ADDRESS_B2C = "ALG0000258";

export default function reducer(state, action) {
  switch (action.type) {
    case "RENT_CONTRACTS/RENT_CONTRACT_LOADED":
      return state
        .delete("promocodeActivationError");

    case "RENT_CONTRACTS/AVAILABLE_RENT_OBJECT_OPTIONS_LOADED":
      return state.set("availableRentObjectProperties", fromJS(action.payload.availableRentObjectProperties));

    case "RENT_CONTRACTS/TARIFF_OPTIONS_LOADED":
      return state.set("tariffOptions", fromJS(action.payload.tariffOptions));

    case "RENT_CONTRACTS/RENT_CONTRACT_ASSIGNED_TO_USER":
      return state.setIn(["updateAfterLogin", action.payload.rentContractId], fromJS({
        tariffOptionChanged: action.payload.tariffOptionChanged || false,
        mileagePackagesRemoved: action.payload.mileagePackagesRemoved || [],
        rentObjectPropertyRemoved: action.payload.rentObjectPropertyRemoved || []
      }));

    case "RENT_CONTRACTS/PROMOCODE_ACTIVATION_FAILED":
      return state.set("promocodeActivationError", fromJS(action.payload.error));

    case "USER/USER_LOGGED_OUT":
      return state.delete("updateAfterLogin");

    default:
      return state;
  }
}

// ======================================================================
// shared (STR/LTR)
// ======================================================================

const getRawAvailableRentObjectProperties = (state) => state.get("availableRentObjectProperties", List());

const getAvailableRentObjectProperties = createSelector(
  [getRawAvailableRentObjectProperties],
  availableRentObjectProperties => availableRentObjectProperties
    .map(rentObjectOption => rentObjectOption
           .set("description", rentObjectOption.get("key"))
           .set("price", rentObjectOption.get("amountTotal"))
           .set("formattedPrice", `+ ${formatCurrency(rentObjectOption.get("amountTotal"))}`))
);

const getSortedAvailableRentObjectProperties = createSelector(
  [getAvailableRentObjectProperties],
  (availableRentObjectProperties) => availableRentObjectProperties
    .sortBy(rentObjectOption => rentObjectOption.get("sortNo"))
);

const getSelectedRentObjectProperties = createSelector(
  [getRentContract],
  (rentContract) => rentContract
    ? rentContract.get("rentContractProperties")
    : List()
);

const rentObjectPropertyValue = (rentObjectProperty) =>
  rentObjectProperty.get("wwwAnnotationAllowed")
    ? rentObjectProperty.get("wwwAnnotation")
    : true;

const getUpdateAfterLogin = createSelector(
  [getRentContractId, (state) => (state.get("updateAfterLogin") || Map())],
  (rentContractId, updateAfterLogin) => updateAfterLogin.get(rentContractId) || Map([
    ["tariffOptionChanged", false],
    ["mileagePackagesRemoved", List()],
    ["rentObjectPropertyRemoved", List()]
  ])
);

const getRentObjectPropertyRemoved = createSelector(
  [getUpdateAfterLogin],
  (updateAfterLogin) => updateAfterLogin.get("rentObjectPropertyRemoved")
    .sortBy((equipmentOption) => equipmentOption.get("sortNo"))
    .map((equipmentOption) => equipmentOption.get("key"))
);

const invoiceItemToOption = (invoiceItem) => invoiceItem
  .set("price", invoiceItem.get("totalPrice"))
  .set("formattedPrice", `+ ${formatCurrency(invoiceItem.get("totalPrice"))}`);

export const getChangedEquipmentOptionsMessage = createSelector(
  [newTranslate, getRentObjectPropertyRemoved],
  (translate, rentObjectPropertyRemoved) => rentObjectPropertyRemoved.size > 0
    ? `${translate(EQUIPMENT_OPTIONS_CHANGED)} ${rentObjectPropertyRemoved.join(", ")}`
    : undefined
);

// ======================================================================
// STR base price
// ======================================================================

const isEnabled = (invoiceItem) => invoiceItem.get("enabled");
const isKmPrice = (invoiceItem) => invoiceItem.get("ruleBL") === ruleBls.ADDITIONAL_MILEAGE && invoiceItem.get("calcBase") === calcBases.PER_KM;

export const getStrBasePriceLineup = createSelector(
  [getInvoiceItems],
  (invoiceItems) => invoiceItems
    .filter((invoiceItem) => isEnabled(invoiceItem) || isKmPrice(invoiceItem))
    .filter((invoiceItem) => ![
                ruleBls.CDW,
                ruleBls.S_CDW,
                ruleBls.EQUIPMENT,
                ruleBls.NET_SUM_SERVICE,
                ruleBls.MILEAGE_PACKAGE
              ].includes(invoiceItem.get("ruleBL")))
    .map((invoiceItem) => invoiceItem.set("formattedPrice", formatCurrency(invoiceItem.get("unitPrice"))))
);

export const getStrBasePrice = createSelector(
  [getStrBasePriceLineup],
  (invoiceItems) => invoiceItems
    .reduce((total, invoiceItem) => total + invoiceItem.get("totalPrice"), 0)
);

export const getStrFormattedBasePrice = createSelector([getStrBasePrice], formatCurrency);

// ======================================================================
// STR mileage options
// ======================================================================

export const getStrMileageOptions = createSelector(
  [getMileageInvoiceItems],
  (invoiceItems) => invoiceItems.map(invoiceItemToOption)
);

const getStrEnabledMileageOptions = createSelector(
  [getStrMileageOptions],
  (mileageOptions) => mileageOptions.filter(mileageOption => mileageOption.get("enabled"))
);

export const getStrSelectedMileageOptions = createSelector(
  [getStrEnabledMileageOptions],
  (mileageOptions) => mileageOptions.reduce((acc, option) => acc.set(option.get("id"), true), Map())
);

const getStrMileagePackagesRemoved = createSelector(
  [getUpdateAfterLogin],
  (updateAfterLogin) => updateAfterLogin.get("mileagePackagesRemoved")
    .sortBy((mileagePackage) => mileagePackage.get("sortNo"))
    .map((mileagePackage) => mileagePackage.get("wwwDescr") || mileagePackage.get("shortDescr"))
);

export const getChangedStrMileageOptionsMessage = createSelector(
  [newTranslate, getStrMileagePackagesRemoved],
  (translate, strMileagePackagesRemoved) => strMileagePackagesRemoved.size > 0
    ? `${translate(STR_MILEAGE_OPTIONS_CHANGED)} ${strMileagePackagesRemoved.join(", ")}`
    : undefined
);

// ======================================================================
// STR deductible options
// ======================================================================

export const getStrDeductibleOptions = createSelector(
  [getDeductibleInvoiceItems],
  (invoiceItems) => invoiceItems.map(invoiceItemToOption)
);

const getStrEnabledDeductibleOptions = createSelector(
  [getStrDeductibleOptions],
  (deductibleOptions) => deductibleOptions
    .filter(deductibleInvoiceItem => deductibleInvoiceItem.get("enabled"))
);

// ======================================================================
// STR equipment options
// ======================================================================

export const getStrEquipmentOptions = createSelector(
  [getSortedAvailableRentObjectProperties],
  (availableRentObjectProperties) => availableRentObjectProperties
    .filter(rentObjectOption => rentObjectOption.get("extCode1") === optionExtCodes.EQUIPMENT_STR)
    .map(rentObjectOption => rentObjectOption.set("formattedPrice", `+ ${formatCurrency(rentObjectOption.get("amountTotal"))}`))
);

export const getStrSelectedEquipmentOptions = createSelector(
  [getSelectedRentObjectProperties],
  (selectedRentObjectProperties) => selectedRentObjectProperties
    .filter((property) => property.get("extCode1") === optionExtCodes.EQUIPMENT_STR)
    .reduce((properties, property) => properties.set(property.get("id"), rentObjectPropertyValue(property)), Map())
);

const getSelectedRentObjectPropertyIds = createSelector(
  [getSelectedRentObjectProperties],
  (selectedRentObjectProperties) => selectedRentObjectProperties.map(property => property.get("id"))
);

const getRentObjectPropertyById = createSelector(
  [getAvailableRentObjectProperties],
  (availableRentObjectProperties) =>
    (id) => availableRentObjectProperties.find(property => property.get("id") === id)
);

// ======================================================================
// STR extras (mileage + deductible + equipment)
// ======================================================================

export const getStrExtrasLineup = createSelector(
  [getSelectedRentObjectPropertyIds, getRentObjectPropertyById, getStrEnabledMileageOptions, getStrEnabledDeductibleOptions],
  (selectedRentObjectPropertyIds, rentObjectPropertyById, selectedMileageOptions, selectedDeductibleOptions) =>
    selectedDeductibleOptions
      .concat(selectedMileageOptions)
      .concat(selectedRentObjectPropertyIds.map(rentObjectPropertyById).filter(Boolean))
);

const getStrExtrasPrice = createSelector(
  [getStrExtrasLineup],
  (selectedOptions) => selectedOptions.reduce((total, option) => total + option.get("price"), 0)
);

export const getStrFormattedExtrasPrice = createSelector(
  [getStrExtrasPrice],
  (selectedOptionsTotalPrice) => `+ ${formatCurrency(selectedOptionsTotalPrice)}`
);

// ======================================================================
// STR total price
// ======================================================================

const getStrTotalPrice = createSelector(
  [getStrBasePrice, getStrExtrasPrice],
  (strBasePrice, strExtrasPrice) => strBasePrice + strExtrasPrice
);

export const getStrFormattedTotalPrice = createSelector([getStrTotalPrice], formatCurrency);

export const newGetStrSelectExtrasFormValuesToTotalPrice = createSelector(
  [getStrBasePrice, getStrMileageOptions, getStrDeductibleOptions, getStrEquipmentOptions],
  (strBasePrice, mileageOptions, deductibleOptions, equipmentOptions) => (formValues) => {
    const deductible = deductibleOptions.find((option) => option.get("id") === formValues.deductible) || Map([["totalPrice", 0]]);
    const deductiblePrice = deductible.get("totalPrice");

    const mileagePrice = mileageOptions
      .filter((option) => formValues.mileage[option.get("id")])
      .reduce((sum, option) => sum + option.get("totalPrice"), 0);

    const equipmentPrice = equipmentOptions
      .filter((option) => formValues.equipment[option.get("id")])
      .reduce((sum, option) => sum + option.get("amountTotal"), 0);

    return formatCurrency(strBasePrice + mileagePrice + deductiblePrice + equipmentPrice);
  }
);

const getStrIncludedMileageInKm = createSelector(
  [getMainRentObjectTransaction],
  (mainRentObjectTransaction) => mainRentObjectTransaction
    ? mainRentObjectTransaction.get("inclusiveMileage")
    : undefined
);

const getB2bStrFixedIncludedBenefits = createDynamicListSelector({
  "B2bStrSelectExtrasBenefit": "description"
});

const getB2cStrFixedIncludedBenefits = createDynamicListSelector({
  "B2cStrSelectExtrasBenefit": "description"
});

const getStrIncludedMileageBenefit = createSelector(
  [newTranslate, getStrIncludedMileageInKm],
  (translate, includedMileage) => includedMileage > 0 && Map([
    [
      "description",
      substitute(translate(INCLUDED_MILEAGE), {
        km: includedMileage
      })
    ]
  ])
);

const mergeBenefits = (includedBenefits, mileageBenefit, displayIncludedMileage) => displayIncludedMileage && mileageBenefit
  ? includedBenefits.unshift(mileageBenefit)
  : includedBenefits;

export const getB2bStrIncludedBenefits = createSelector(
  [getB2bStrFixedIncludedBenefits, getStrIncludedMileageBenefit, (state, props) => props && props.displayIncludedMileage],
  mergeBenefits
);

export const getB2cStrIncludedBenefits = createSelector(
  [getB2cStrFixedIncludedBenefits, getStrIncludedMileageBenefit, (state, props) => props && props.displayIncludedMileage],
  mergeBenefits
);

// ======================================================================
// LTR mileage options (= tariffs)
// ======================================================================

const formatLtrPrice = (translate, amount, calcBase) => {
  const unit = calcBase === calcBases.PER_MONTH
    ? `€/${translate(MONTH)}`
    : "€";

  return formatCurrency(amount, {unit});
};

const getTariffOptions = (state) => state.get("tariffOptions", List());

export const getLtrMileageOptions = createSelector(
  [newTranslate, getTariffOptions],
  (translate, tariffOptions) => tariffOptions
    .sortBy(tariffOption => tariffOption.get("price"))
    .map(tariffOption => tariffOption
           .set("description", tariffOption.get("shortDescr"))
           .set("totalPrice", tariffOption.get("price"))
           .set("formattedPrice", formatLtrPrice(translate, tariffOption.get("price"), tariffOption.get("priceCalcBase"))))
);

const getLtrSelectedMileageInvoiceItem = createSelector(
  [getEnabledInvoiceItems],
  (invoiceItems) => invoiceItems.find((item) => item.get("ruleBL") === ruleBls.TIME_PERIOD)
);

export const getLtrSelectedMileageOptionId = createSelector(
  [getLtrSelectedMileageInvoiceItem],
  (invoiceItem) => invoiceItem
    ? invoiceItem.get("tarifOptionId")
    : undefined
);

const getLtrMileageOptionChanged = createSelector(
  [getUpdateAfterLogin],
  (updateAfterLogin) => updateAfterLogin.get("tariffOptionChanged")
);

export const getChangedLtrMileageOptionMessage = createSelector(
  [newTranslate, getLtrMileageOptionChanged],
  (translate, ltrMileageOptionChanged) => ltrMileageOptionChanged
    ? translate(LTR_MILEAGE_OPTION_CHANGED)
    : undefined
);

// ======================================================================
// LTR deductible options
// ======================================================================

export const getLtrDeductibleOptions = createSelector(
  [newTranslate, getDeductibleInvoiceItems],
  (translate, invoiceItems) => invoiceItems
    .sortBy(invoiceItem => invoiceItem.get("unitPrice"))
    .map(invoiceItem => invoiceItem
           .set("formattedPrice", "+ " + formatLtrPrice(translate, invoiceItem.get("unitPrice"), invoiceItem.get("calcBase"))))
);

// ======================================================================
// LTR equipment options
// ======================================================================

export const getLtrEquipmentOptions = createSelector(
  [newTranslate, getSortedAvailableRentObjectProperties],
  (translate, availableRentObjectProperties) => availableRentObjectProperties
    .filter(rentObjectOption => rentObjectOption.get("extCode1") === optionExtCodes.EQUIPMENT_LTR)
    .map(rentObjectOption => rentObjectOption
           .set("inputHint", translate(OPTION_INPUT_HINT))
           .set("formattedPrice", "+ " + formatLtrPrice(translate, rentObjectOption.get("amountTotal"), rentObjectOption.get("calcBase"))))
);

export const getLtrSelectedEquipmentOptions = createSelector(
  [getSelectedRentObjectProperties],
  (selectedRentObjectProperties) => selectedRentObjectProperties
    .filter((property) => property.get("extCode1") === optionExtCodes.EQUIPMENT_LTR)
    .reduce((properties, property) => properties.set(property.get("id"), rentObjectPropertyValue(property)), Map())
);

// ======================================================================
// LTR total price
// ======================================================================

const monthlyPricesLineupRuleBlOrder = [
  ruleBls.TIME_PERIOD,
  ruleBls.ADDITIONAL_MILEAGE,
  ruleBls.CDW,
  ruleBls.S_CDW,
  ruleBls.EQUIPMENT
];
export const getLtrMonthlyPricesLineup = createSelector(
  [newTranslate, getInvoiceItems],
  (translate, invoiceItems) => invoiceItems
    .filter((item) => (item.get("enabled") && item.get("calcBase") === calcBases.PER_MONTH) || isKmPrice(item))
    .sortBy((item) =>
      monthlyPricesLineupRuleBlOrder.includes(item.get("ruleBL"))
        ? monthlyPricesLineupRuleBlOrder.indexOf(item.get("ruleBL"))
        : Number.POSITIVE_INFINITY)
    .map((item) => {
      if (item.get("included")) {
        return item.set("formattedPrice", translate(INCLUDED));
      }
      else if (isKmPrice(item)) {
        return item.set("formattedPrice", formatLtrPrice(translate, item.get("unitPrice"), calcBases.ONE_TIME));
      }
      else {
        return item.set("formattedPrice", formatLtrPrice(translate, item.get("totalPrice"), item.get("calcBase")));
      }
    })
);

const getLtrMonthlyTotalPrice = createSelector(
  [getLtrMonthlyPricesLineup],
  (invoiceItems) => invoiceItems.reduce((sum, item) => sum + (item.get("totalPrice") || 0), 0)
);

export const getLtrMonthlyFormattedTotalPrice = createSelector([getLtrMonthlyTotalPrice], formatCurrency);

export const getLtrOneTimePricesLineup = createSelector(
  [newTranslate, getEnabledInvoiceItems],
  (translate, invoiceItems) => invoiceItems
    .filter((item) => item.get("calcBase") === calcBases.ONE_TIME)
    .map((item) =>
      item.set("formattedPrice", item.get("included")
        ? translate(INCLUDED)
        : formatCurrency(item.get("totalPrice"))))
);

const getLtrOneTimeTotalPrice = createSelector(
  [getLtrOneTimePricesLineup],
  (invoiceItems) => invoiceItems.reduce((sum, item) => sum + (item.get("totalPrice") || 0), 0)
);

export const getLtrOneTimeFormattedTotalPrice = createSelector([getLtrOneTimeTotalPrice], formatCurrency);

export const getB2bLtrIncludedBenefits = createDynamicListSelector({
  "B2bLtrSelectExtrasBenefit": "description"
});

export const getB2cLtrIncludedBenefits = createDynamicListSelector({
  "B2cLtrSelectExtrasBenefit": "description"
});

// ======================================================================
// LTR extras
// ======================================================================

const hasId = (id) => (option) => option.get("id") === id;
const getSelectExtrasFormValuesToBasePrice = createSelector(
  [getLtrMileageOptions],
  (mileageOptions) => (values) => {
    const selectedMileageOption = mileageOptions.find(hasId(values.mileage));
    return selectedMileageOption ? selectedMileageOption.get("price") : 0;
  }
);
const getSelectExtrasFormValuesToExtrasPrice = createSelector(
  [getLtrDeductibleOptions, getLtrEquipmentOptions],
  (deductibleOptions, equipmentOptions) => (values) => {
    const selectedDeductibleOption = deductibleOptions.find(hasId(values.deductible));
    const selectedDeductiblePrice = selectedDeductibleOption ? selectedDeductibleOption.get("unitPrice") : 0;

    const selectedEquipmentIds = Object.entries(values.equipment || {})
      .filter(([, value]) => Boolean(value))
      .map(([key]) => Number(key));
    const selectedEquipmentPrice = selectedEquipmentIds
      .map((id) => equipmentOptions.find(hasId(id)))
      .reduce((sum, option) => sum + option.get("amountTotal"), 0);

    return selectedDeductiblePrice + selectedEquipmentPrice;
  }
);

export const newGetLtrSelectExtrasFormValuesToPrices = createSelector(
  [newTranslate, getLtrMonthlyPricesLineup, getLtrMileageOptions, getSelectExtrasFormValuesToExtrasPrice],
  (translate, ltrMonthlyPricesLineup, ltrMileageOptions, valuesToExtrasPrice) => (formValues) => {
    const selectedMileageOption = ltrMileageOptions.find(hasId(formValues.mileage));
    const mileageLineup = selectedMileageOption
      ? List([Map([
        ["description", selectedMileageOption.get("description")],
        ["formattedPrice", selectedMileageOption.get("formattedPrice")]
      ])])
      : List();

    const pricesWithoutExtrasLineup = ltrMonthlyPricesLineup
      .filter((item) => ![
          ruleBls.TIME_PERIOD,
          ruleBls.CDW,
          ruleBls.S_CDW,
          ruleBls.DELIVERY,
          ruleBls.EQUIPMENT
        ].includes(item.get("ruleBL")));

    const extrasLineupItem = List([Map([
      ["description", translate(SELECTED_EXTRAS_LABEL)],
      ["formattedPrice", formatLtrPrice(translate, valuesToExtrasPrice(formValues), calcBases.PER_MONTH)]
    ])]);

    return mileageLineup
      .concat(pricesWithoutExtrasLineup)
      .concat(extrasLineupItem)
      .toJS();
  }
);

export const newGetLtrSelectExtrasFormValuesToTotalPrice = createSelector(
  [getSelectExtrasFormValuesToBasePrice, getSelectExtrasFormValuesToExtrasPrice],
  (valuesToBasePrice, valuesToExtrasPrice) => (values) => {
    const basePrice = valuesToBasePrice(values);
    const extrasPrice = valuesToExtrasPrice(values);

    return formatCurrency(basePrice + extrasPrice);
  }
);

// ======================================================================
// LTR provisioning
// ======================================================================

export const getFormattedPickupPrice = createSelector(
  [newTranslate],
  (translate) => translate(INCLUDED)
);

const getDeliveryPrice = createSelector(
  [getDeliveryInvoiceItem],
  (deliveryInvoiceItem) => deliveryInvoiceItem
    ? deliveryInvoiceItem.get("totalPrice", 0)
    : 0
);

export const getFormattedDeliveryPrice = createSelector(
  [newTranslate, getDeliveryPrice],
  (translate, deliveryPrice) => deliveryPrice > 0
    ? formatCurrency(deliveryPrice)
    : translate(INCLUDED)
);

export const getIsDeliveryEnabled = createSelector(
  [getDeliveryInvoiceItem],
  (deliveryInvoiceItem) => deliveryInvoiceItem !== undefined
);

const fullName = (deliveryAddress) => {
  const name = deliveryAddress.getIn(["contact", "name"]) || "";
  const surname = deliveryAddress.getIn(["contact", "surname"]) || "";
  return trim(`${name} ${surname}`);
};

export const getDeliveryAddress = createSelector(
  [getRawDeliveryAddress],
  (deliveryAddress) => deliveryAddress
    ? Map([
      ["companyName", deliveryAddress.getIn(["address", "surname"])],
      ["name", deliveryAddress.getIn(["contact", "name"])],
      ["surname", deliveryAddress.getIn(["contact", "surname"])],
      ["fullName", fullName(deliveryAddress)],
      ["phone", deliveryAddress.getIn(["contact", "phone"])],
      ["street", deliveryAddress.getIn(["address", "street"])],
      ["building", deliveryAddress.getIn(["address", "building"])],
      ["zipCode", deliveryAddress.getIn(["address", "zipCode"])],
      ["city", deliveryAddress.getIn(["address", "city"])],
      ["country", deliveryAddress.getIn(["address", "country"])],
      ["countryCode", trim(deliveryAddress.getIn(["address", "countryCode"]))]
    ])
    : undefined
);

// ======================================================================
// B2B LTR provisioning
// ======================================================================

const isDeliveryAddressEqualToCompanyAddress = (deliveryAddress, companyAddress) =>
  deliveryAddress.get("companyName") === companyAddress.get("companyName") &&
  deliveryAddress.get("street") === companyAddress.get("street") &&
  deliveryAddress.get("building") === companyAddress.get("building") &&
  deliveryAddress.get("zipCode") === companyAddress.get("zipCode") &&
  deliveryAddress.get("city") === companyAddress.get("city") &&
  deliveryAddress.get("country") === companyAddress.get("country") &&
  deliveryAddress.get("name") === undefined &&
  deliveryAddress.get("surname") === undefined &&
  deliveryAddress.get("phone") === undefined;

export const getProvisioningTypeB2b = createSelector(
  [getDeliveryAddress, getCompanyAddress],
  (deliveryAddress, companyAddress) => {
    if (deliveryAddress) {
      if (isDeliveryAddressEqualToCompanyAddress(deliveryAddress, companyAddress)) {
        return provisioningTypes.DELIVERY_TO_COMPANY_ADDRESS;
      }
      else {
        return provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS;
      }
    }
    else {
      return provisioningTypes.PICKUP;
    }
  }
);

const getProvisioningLabelB2b = createSelector(
  [newTranslate, getProvisioningTypeB2b],
  (translate, provisioningType) => {
    switch (provisioningType) {
      case provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS:
        return translate(PROVISIONING_DELIVERY_TO_CUSTOM_ADDRESS_B2B);

      case provisioningTypes.DELIVERY_TO_COMPANY_ADDRESS:
        return translate(PROVISIONING_DELIVERY_TO_COMPANY_ADDRESS);

      case provisioningTypes.PICKUP:
        return translate(PROVISIONING_PICKUP);

      default:
        throw new Error(`Invalid B2B provisioning type '${provisioningType}'`);
    }
  }
);

const formatProvisioningDetails = (provisioningType, provisioningLabel, deliveryAddress, coStation) => Map([
  ["provisioningType", provisioningType],
  ["label", provisioningLabel],
  ["deliveryAddress", deliveryAddress],
  ["pickupStation", coStation]
]);

export const getProvisioningDetailsB2b = createSelector(
  [getProvisioningTypeB2b, getProvisioningLabelB2b, getDeliveryAddress, getCoStation],
  formatProvisioningDetails
);

// ======================================================================
// B2C LTR provisioning
// ======================================================================

export const getProvisioningTypeB2c = createSelector(
  [getDeliveryAddress],
  (deliveryAddress) => {
    if (deliveryAddress) {
      return provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS;
    }
    else {
      return provisioningTypes.PICKUP;
    }
  }
);

const getProvisioningLabelB2c = createSelector(
  [newTranslate, getProvisioningTypeB2c],
  (translate, provisioningType) => {
    switch (provisioningType) {
      case provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS:
        return translate(PROVISIONING_DELIVERY_TO_CUSTOM_ADDRESS_B2C);

      case provisioningTypes.PICKUP:
        return translate(PROVISIONING_PICKUP);

      default:
        throw new Error(`Invalid B2C provisioning type '${provisioningType}'`);
    }
  }
);

export const getProvisioningDetailsB2c = createSelector(
  [getProvisioningTypeB2c, getProvisioningLabelB2c, getDeliveryAddress, getCoStation],
  formatProvisioningDetails
);

// ======================================================================
// B2C promocode
// ======================================================================

export const getPromocodeActivationErrorMessage = (state) =>
  state.getIn(["promocodeActivationError", "displayMessage"]);

// ======================================================================
// B2B driver
// ======================================================================

const getRawDriver = createSelector(
  [getMainRentObjectTransaction],
  (mainTransaction = Map()) => mainTransaction
    .get("drivers", List())
    .filter((driver) => driver.get("customerId") === 0)
    .sortBy((driver) => driver.get("id"))
    .last()
);

const getDynDataValue = (code) => createSelector(
  [getRentContract],
  (rentContract = Map()) => rentContract
    .get("dynDataValues", List())
    .find((dataValue) => dataValue.get("code") === code, null, Map())
    .get("value")
);

export const getDriver = createSelector(
  [
    getRawDriver,
    getDynDataValue(dynDataValueCodes.EMPLOYEE_ID),
    getDynDataValue(dynDataValueCodes.COST_CENTER)
  ],
  (driver, employeeId, costCenter) => driver
    ? driver
        .set("prefixKey", driver.get("calcPrefixKey"))
        .set("prefix", driver.get("calcPrefix"))
        .set("name", driver.get("prename"))
        .set("surname", driver.get("lastname"))
        .set("fullName", driver.get("calcPrefix") + " " + driver.get("prename") + " " + driver.get("lastname"))
        .set("phone", driver.get("phone"))
        .set("email", driver.get("eMail"))
        .set("employeeId", employeeId)
        .set("costCenter", costCenter)
    : undefined
);
