import React, {useCallback, useRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {mapValues} from "@awe-web/shared/lib/util/object.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {getCurrentDateTime} from "@awe-web/shared/lib/time/store.js";
import {
  getNewDateContainsValidCoDateTime,
  getNewIsValidCoDateTime,
  getNewDateContainsValidCiDateTime,
  getNewIsValidCiDateTime
} from "@awe-web/shared/lib/stations/store.js";
import {
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetInitialValues
} from "@awe-web/shared/lib/user/store.js";
import {Checkbox} from "@awe-web/shared/lib/components/form.js";
import {FormikStationSelection} from "./station_selection.js";
import {ScreenLoading} from "../../components/screen.js";
import {Row, Col} from "../../components/grid.js";
import {
  LabeledField,
  LabeledCheckbox,
  FormikInputLabel,
  Button,
  FormikDateTimeInput,
  FormikErrorMessage,
  DateTimeInput
} from "../../components/form.js";
import {getTimeSelectionStepSizeInMinutes} from "../../config/store.js";
import {
  getStations,
  getSelectStationAndTimeFormValidate
} from "../store.js";
import {
  initializeSelectStationAndTimeForm,
  submitSelectStationAndTimeForm
} from "../actions.js";

import "./select_station_and_time_form.scss";

const CO_STATION_OVERLAY_HEADING = "FSD0000104";
const CI_STATION_OVERLAY_HEADING = "FSD0000156";
const CI_STATION_NOTICE = "FSD0000087";
const CO_DATE_TIME_OVERLAY_HEADING = "FSD0000331";
const CI_DATE_TIME_OVERLAY_HEADING = "FSD0000332";
const SUBMIT_LABEL = "FSD0000085";

const Form = (props) => {
  const {
    stations,
    values,
    handleSubmit,
    currentDateTime,
    timeStepSizeInMinutes,
    newIsCoStationOpenAtDate,
    newIsCoStationOpenAtDateTime,
    newIsCiStationOpenAtDate,
    newIsCiStationOpenAtDateTime,
    getFieldLabel,
    getFieldPlaceholder,
    setFieldValue,
    setValues
  } = props;

  const {coStationId, ciStationId, coDateTime} = values;
  const coStations = stations;
  const ciStations = stations.filter(station => station.id !== coStationId);
  const hasCiStationId = ciStationId !== coStationId;
  const className = classnames("select-station-and-time-form", props.className);

  const isCoStationOpenAtDate = newIsCoStationOpenAtDate(coStationId);
  const isCoStationOpenAtDateTime = newIsCoStationOpenAtDateTime(coStationId);
  const isCiStationOpenAtDate = newIsCiStationOpenAtDate(coStationId, ciStationId, coDateTime);
  const isCiStationOpenAtDateTime = newIsCiStationOpenAtDateTime(coStationId, ciStationId, coDateTime);

  const ciStationSelectionRef = useRef();
  const setFieldValueRef = useRef();
  setFieldValueRef.current = setFieldValue;
  const setValuesRef = useRef();
  setValuesRef.current = setValues;

  const handleCheckboxChange = useCallback((event) => {
    if (event.target.checked) {
      ciStationSelectionRef.current.openOverlay();
    }
    else {
      setFieldValueRef.current("ciStationId", coStationId);
    }
  }, [coStationId]);

  const handleCoStationIdChange = useCallback((event) => {
    const nextCoStationId = event.target.value;

    setValuesRef.current({
      ...values,
      coStationId: nextCoStationId,
      ciStationId: !ciStationId || ciStationId === coStationId
        ? nextCoStationId
        : ciStationId
    });
  }, [values, ciStationId, coStationId]);

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Row>
        <Col desktop={{cols: 3}} tablet={{cols: 4}} className="select-station-and-time-form__form-field">
          <LabeledField>
            <FormikInputLabel name="coStationId">{getFieldLabel("coStationId")}</FormikInputLabel>
            <FormikStationSelection
              name="coStationId"
              onChange={handleCoStationIdChange}
              className="select-station-and-time-form__co-station-selection"
              placeholder={getFieldPlaceholder("coStationId")}
              overlayHeading={<T code={CO_STATION_OVERLAY_HEADING} />}
              stations={coStations}
            />
          </LabeledField>
          <FormikErrorMessage
            name="coStationId"
            className="select-station-and-time-form__co-station-id-error"
          />
        </Col>

        <Col desktop={{cols: 3}} tablet={{cols: 4}} className="select-station-and-time-form__form-field">
          <LabeledField>
            <FormikInputLabel name="ciStationId">
              <LabeledCheckbox>
                <Checkbox
                  checked={hasCiStationId}
                  className="select-station-and-time-form__ci-station-checkbox"
                  onChange={handleCheckboxChange}
                />
                {getFieldLabel("ciStationId")}
              </LabeledCheckbox>
            </FormikInputLabel>
            <FormikStationSelection
              name="ciStationId"
              ref={ciStationSelectionRef}
              className="select-station-and-time-form__ci-station-selection"
              opticallyDisabled={!hasCiStationId}
              placeholder={getFieldPlaceholder("ciStationId")}
              overlayHeading={<T code={CI_STATION_OVERLAY_HEADING} />}
              stations={ciStations}
            />
          </LabeledField>
          <FormikErrorMessage name="ciStationId" />
        </Col>

        <Col desktop={{cols: 3}} tablet={{cols: 4}} className="select-station-and-time-form__form-field">
          <LabeledField>
            <FormikInputLabel name="coDateTime">{getFieldLabel("coDateTime")}</FormikInputLabel>
            <FormikDateTimeInput
              name="coDateTime"
              className="select-station-and-time-form__co-date-time-input"
              placeholder={getFieldPlaceholder("coDateTime")}
              minDate={currentDateTime}
              timeStepSizeInMinutes={timeStepSizeInMinutes}
              isDateEnabled={isCoStationOpenAtDate}
              isDateTimeEnabled={isCoStationOpenAtDateTime}
              overlayHeading={<T code={CO_DATE_TIME_OVERLAY_HEADING} />}
            />
          </LabeledField>
          <FormikErrorMessage name="coDateTime" />
        </Col>

        <Col desktop={{cols: 3}} tablet={{cols: 4}} className="select-station-and-time-form__form-field">
          <LabeledField>
            <FormikInputLabel name="ciDateTime">{getFieldLabel("ciDateTime")}</FormikInputLabel>
            <FormikDateTimeInput
              name="ciDateTime"
              className="select-station-and-time-form__ci-date-time-input"
              placeholder={getFieldPlaceholder("ciDateTime")}
              startDate={coDateTime}
              minDate={currentDateTime}
              timeStepSizeInMinutes={timeStepSizeInMinutes}
              isDateEnabled={isCiStationOpenAtDate}
              isDateTimeEnabled={isCiStationOpenAtDateTime}
              overlayHeading={<T code={CI_DATE_TIME_OVERLAY_HEADING} />}
            />
          </LabeledField>
          <FormikErrorMessage name="ciDateTime" />
        </Col>
      </Row>

      <p className="select-station-and-time-form__notice">
        <T code={CI_STATION_NOTICE} />
      </p>

      <Button
        submit
        continue
        primary
        className="select-station-and-time-form__submit-button"
      >
        <T code={SUBMIT_LABEL} />
      </Button>
    </form>
  );
};
Form.propTypes = {
  className: PropTypes.string,
  values: PropTypes.shape({
    coStationId: PropTypes.number,
    ciStationId: PropTypes.number,
    coDateTime: PropTypes.instanceOf(Date),
    ciDateTime: PropTypes.instanceOf(Date)
  }).isRequired,
  stations: FormikStationSelection.propTypes.stations,
  currentDateTime: PropTypes.instanceOf(Date),
  timeStepSizeInMinutes: DateTimeInput.propTypes.timeStepSizeInMinutes,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  newIsCoStationOpenAtDate: PropTypes.func.isRequired,
  newIsCoStationOpenAtDateTime: PropTypes.func.isRequired,
  newIsCiStationOpenAtDate: PropTypes.func.isRequired,
  newIsCiStationOpenAtDateTime: PropTypes.func.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  getFieldPlaceholder: PropTypes.func.isRequired
};


const SelectStationAndTimeFormComponent = (props) => {
  const {
    className,
    initialize,
    getInitialValues,
    getFieldLabel,
    getFieldPlaceholder,
    validate,
    onSubmit,
    currentDateTime,
    timeSelectionStepSizeInMinutes,
    newIsCoStationOpenAtDate,
    newIsCoStationOpenAtDateTime,
    newIsCiStationOpenAtDate,
    newIsCiStationOpenAtDateTime,
    stations
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialValues = getInitialValues({
    coStationId: null,
    ciStationId: null,
    coDateTime: null,
    ciDateTime: null
  });

  // Mark all values as 'touched' that have an initial value so that error
  // messages are shown e.g. for ciDateTime when coStationId changes.
  const initialTouched = mapValues(initialValues, (value) => Boolean(value));

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={initialTouched}
      validate={validate}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form
          {...formikProps}
          className={className}
          stations={stations}
          currentDateTime={currentDateTime}
          timeStepSizeInMinutes={timeSelectionStepSizeInMinutes}
          newIsCoStationOpenAtDate={newIsCoStationOpenAtDate}
          newIsCoStationOpenAtDateTime={newIsCoStationOpenAtDateTime}
          newIsCiStationOpenAtDate={newIsCiStationOpenAtDate}
          newIsCiStationOpenAtDateTime={newIsCiStationOpenAtDateTime}
          getFieldLabel={getFieldLabel}
          getFieldPlaceholder={getFieldPlaceholder}
        />
      )}
    </Formik>
  );
};
SelectStationAndTimeFormComponent.propTypes = {
  className: PropTypes.string,
  initialize: PropTypes.func,
  navigate: PropTypes.func,
  getInitialValues: PropTypes.func.isRequired,
  validate: PropTypes.func,
  onSubmit: PropTypes.func,
  stations: Form.propTypes.stations,
  getFieldLabel: Form.propTypes.getFieldLabel,
  getFieldPlaceholder: Form.propTypes.getFieldPlaceholder,
  currentDateTime: Form.propTypes.currentDateTime,
  timeSelectionStepSizeInMinutes: Form.propTypes.timeStepSizeInMinutes,
  newIsCoStationOpenAtDate: Form.propTypes.newIsCoStationOpenAtDate,
  newIsCoStationOpenAtDateTime: Form.propTypes.newIsCoStationOpenAtDateTime,
  newIsCiStationOpenAtDate: Form.propTypes.newIsCiStationOpenAtDate,
  newIsCiStationOpenAtDateTime: Form.propTypes.newIsCiStationOpenAtDateTime,
  reservationPath: PropTypes.oneOf(["B2B", "B2C"]).isRequired
};

const stateToSelectStationAndTimeFormProps = createStateToProps({
  stations: getStations,
  getInitialValues: newGetInitialValues("selectStationAndTime"),
  getFieldLabel: newGetFieldLabel("selectStationAndTime"),
  getFieldPlaceholder: newGetFieldPlaceholder("selectStationAndTime"),
  validate: getSelectStationAndTimeFormValidate,
  currentDateTime: getCurrentDateTime,
  timeSelectionStepSizeInMinutes: getTimeSelectionStepSizeInMinutes,
  newIsCoStationOpenAtDate: getNewDateContainsValidCoDateTime,
  newIsCoStationOpenAtDateTime: getNewIsValidCoDateTime,
  newIsCiStationOpenAtDate: getNewDateContainsValidCiDateTime,
  newIsCiStationOpenAtDateTime: getNewIsValidCiDateTime
});

const dispatchToSelectStationAndTimeFormProps = (dispatch, props) => ({
  initialize: () => dispatch(initializeSelectStationAndTimeForm({reservationPath: props.reservationPath})),
  onSubmit: (formValues) => dispatch(submitSelectStationAndTimeForm(formValues, {navigate: props.navigate, reservationPath: props.reservationPath}))
});

export const SelectStationAndTimeForm = connect(
  stateToSelectStationAndTimeFormProps,
  dispatchToSelectStationAndTimeFormProps
)(SelectStationAndTimeFormComponent);
