import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {Row, Col} from "./grid.js";

import "./dashboard_item.scss";

const DashboardItem = (props) => {
  const {dashboardItem: {heading, text, image, link}} = props;
  const className = classnames("dashboard-item", props.className);

  return (
    <Col tablet={{cols: 4}} desktop={{cols: 4}} className={className}>
      <Link className="dashboard-item__container" to={link}>
        <div className="dashboard-item__image-wrapper">
          <div
            className="dashboard-item__image-wrapper__image"
            style={{backgroundImage: `url(${image})`}} />
        </div>
        <div className="dashboard-item__body">
          <div className="dashboard-item__heading">{heading}</div>
          <div>{text}</div>
        </div>
      </Link>
    </Col>
  );
};
DashboardItem.propTypes = {
  className: PropTypes.string,
  dashboardItem: PropTypes.shape({
    heading: PropTypes.node,
    text: PropTypes.node,
    image: PropTypes.string,
    link: PropTypes.string
  })
};

export const DashboardItems = (props) => {
  const {dashboardItems} = props;
  const className = classnames("dashboard-items", props.className);

  return (
    <Row stretchVertical className={className}>
      {dashboardItems.map(dashboardItem => (
        <DashboardItem
          className="dashboard-items__item"
          key={dashboardItem.link}
          dashboardItem={dashboardItem} />
      ))}
    </Row>
  );
};
DashboardItems.propTypes = {
  className: PropTypes.string,
  dashboardItems: PropTypes.arrayOf(DashboardItem.propTypes.dashboardItem)
};
