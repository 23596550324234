import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {loadFormConfiguration, serverValidationFailed} from "@awe-web/shared/lib/user/actions.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {openModal} from "../../../app/actions.js";
import {loadContactPeople, ensureUserLoggedIn} from "../../../user/actions.js";

const OLD_PASSWORD_LABEL = "ALG0000128";
const OLD_PASSWORD_REQUIRED = "ALG0000180";
const NEW_PASSWORD_LABEL = "ALG0000133";
const NEW_PASSWORD_REQUIRED = "ALG0000179";
const PASSWORD_SUCCESS_TITLE = "ALG0000181";
const PASSWORD_SUCCESS_MESSAGE = "ALG0000182";
const TAX_DATA_SUCCESS_TITLE = "ALG0000265";
const TAX_DATA_SUCCESS_MESSAGE = "ALG0000266";
const CONSENT_SUCCESS_TITLE = "ALG0000184";
const CONSENT_SUCCESS_MESSAGE = "ALG0000185";
const SUCCESS_BUTTON = "ALG0000183";

const submit = (groupName, formValues, {setSubmitting, validateForm}, submitAction) => async (dispatch) => {
  try {
    setSubmitting(true);
    await dispatch(submitAction);
  }
  catch (error) {
    if (error.type === "VALIDATION_FAILED") {
      const values = formValues;
      const errors = error.errors;
      dispatch(serverValidationFailed(groupName, values, errors));
      validateForm();
    }
    else {
      throw error;
    }
  }
  finally {
    setSubmitting(false);
  }
};

export const initialize = () => async (dispatch, getState) => {
  const translate = newTranslate(getState());

  await Promise.all([
    dispatch(loadContactPeople()),
    dispatch(loadFormConfiguration("BMWChangeCommunicationConfirmation")),
    dispatch(loadFormConfiguration("changeBusinessCustomer"))
  ]);

  dispatch({
    type: "USER/FORM_CONFIGURATION_LOADED",
    payload: {
      groupName: "changePassword",
      formConfiguration: [
        {
          items: [
            {
              name: "Customer.currentPassword.197",
              fieldLabel: translate(OLD_PASSWORD_LABEL),
              emptyText: translate(OLD_PASSWORD_REQUIRED),
              xtype: "textfield",
              allowBlank: false
            },
            {
              name: "Customer.password.198",
              fieldLabel: translate(NEW_PASSWORD_LABEL),
              emptyText: translate(NEW_PASSWORD_REQUIRED),
              xtype: "textfield",
              allowBlank: false
            }
          ]
        }
      ]
    }
  });
};

export const submitTaxData = (formValues, formikProps) => async (dispatch, getState, context) => {
  const translate = newTranslate(getState());
  const groupName = "changeBusinessCustomer";
  const mapFormValues = newMapFormValues(groupName)(getState());
  const normalizedValues = mapFormValues(formValues);

  await dispatch(submit(groupName, formValues, formikProps, async (dispatch) => {
    await dispatch(asyncAction({
      typePrefix: "USER/UPDATE_PROFILE",
      loadPayload: async () => {
        await dispatch(ensureUserLoggedIn());
        await context.api.user.updateUser(normalizedValues);
      }
    }));
    await dispatch(openModal(
      translate(TAX_DATA_SUCCESS_TITLE),
      translate(TAX_DATA_SUCCESS_MESSAGE),
      translate(SUCCESS_BUTTON)
    ));
  }));
};

export const submitPassword = (formValues, formikProps) => async (dispatch, getState, context) => {
  const translate = newTranslate(getState());
  const groupName = "changePassword";
  const mapFormValues = newMapFormValues(groupName)(getState());
  const normalizedValues = mapFormValues(formValues);

  await dispatch(submit(groupName, formValues, formikProps, async (dispatch) => {
    await dispatch(asyncAction({
      typePrefix: "USER/UPDATE_PROFILE",
      loadPayload: async () => {
        await dispatch(ensureUserLoggedIn());
        await context.api.user.changePassword(normalizedValues);
      }
    }));
    formikProps.resetForm();
    await dispatch(openModal(
      translate(PASSWORD_SUCCESS_TITLE),
      translate(PASSWORD_SUCCESS_MESSAGE),
      translate(SUCCESS_BUTTON)
    ));
  }));
};

export const submitConsent = (formValues, formikProps) => async (dispatch, getState, context) => {
  const translate = newTranslate(getState());
  const groupName = "BMWChangeCommunicationConfirmation";
  const mapFormValues = newMapFormValues(groupName)(getState());
  const normalizedValues = mapFormValues(formValues);

  await dispatch(submit(groupName, formValues, formikProps, async (dispatch) => {
    await dispatch(asyncAction({
      typePrefix: "USER/UPDATE_PROFILE",
      loadPayload: async () => {
        await dispatch(ensureUserLoggedIn());
        await context.api.user.updateUser(normalizedValues);
      }
    }));
    await dispatch(openModal(
      translate(CONSENT_SUCCESS_TITLE),
      translate(CONSENT_SUCCESS_MESSAGE),
      translate(SUCCESS_BUTTON)
    ));
  }));
};
