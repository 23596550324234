import {isModalClosed} from "./store.js";
import {getInitialLocale} from "@awe-web/shared/lib/config/store.js";
import {setLocale} from "@awe-web/shared/lib/l10n/actions.js";
import {loadCmsPages} from "@awe-web/shared/lib/cms/actions.js";
import {loadAuthorizedUsers, setLoginState} from "../user/actions.js";

export const initialize = () => async (dispatch, getState) => {
  try {
    const initialLocale = getInitialLocale(getState());
    await Promise.all([
      dispatch(setLocale(initialLocale)),
      dispatch(setLoginState()),
      dispatch(loadAuthorizedUsers()),
      dispatch(loadCmsPages())
    ]);
  }
  catch (error) {
    // When initialization fails we must assume that we could not load the
    // translations from the server. By removing the `displayMessage`
    // property from the exception we trigger the ERROR state in <App>
    // instead of letting the `errorHandler` opening an error overlay with
    // broken texts.
    delete error.displayMessage;
    throw error;
  }
};

export const openModal = (title, message, buttonLabel, options = {}) => (dispatch, getState) => {
  const {timeout, interval = 100} = options;

  const hasTimedOut = (() => {
    const maxWaitTime = timeout !== undefined ? new Date().getTime() + timeout : undefined;
    return () => maxWaitTime && maxWaitTime < new Date().getTime();
  })();

  return new Promise(resolve => {
    dispatch({
      type: "APP/OPEN_MODAL",
      payload: {title, message, buttonLabel}
    });

    const intervalId = setInterval(() => {
      if (isModalClosed(getState()) || hasTimedOut()) {
        clearInterval(intervalId);
        resolve();
      }
    }, interval);
  });
};

export const closeModal = () => ({
  type: "APP/CLOSE_MODAL",
  payload: {}
});

export const addFlashMessageCode = (code) => ({
  type: "APP/ADD_FLASH_MESSAGE",
  payload: {code}
});

export const removeFlashMessage = () => ({
  type: "APP/REMOVE_FLASH_MESSAGE",
  payload: {}
});
