import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {MenuLink} from "@awe-web/shared/lib/components/navigation.js";
import {getHeaderLinks} from "../l10n/store.js";
import {getStartScreenPath} from "../user/store.js";
import {screenGroups} from "../routing/constants.js";
import {getBelongsToScreenGroup} from "../routing/store.js";

import "./app_navigation.scss";

const BOOK_RENT_OBJECT = "FSD0000137";

const AppNavigationLinksComponent = (props) => {
  const {headerLinks, startScreenPath, belongsToScreenGroup} = props;

  const className = classnames("app-navigation-links", props.className);
  const itemClassName = classnames("app-navigation-links__item", props.itemClassName);
  const linkClassName = classnames("app-navigation-links__link", props.linkClassName);
  const activeLinkClassName = classnames("app-navigation-links__link--active", props.activeLinkClassName);
  const linkTextClassName = classnames("app-navigation-links__link-text", props.linkTextClassName);

  const isReservationScreen = () => belongsToScreenGroup(screenGroups.RESERVATION);

  return (
    <ul className={className}>
      <li className={itemClassName}>
        <NavLink
          className={linkClassName}
          activeClassName={activeLinkClassName}
          isActive={isReservationScreen}
          to={startScreenPath}
        >
          <T className={linkTextClassName} code={BOOK_RENT_OBJECT} />
        </NavLink>
      </li>
      {headerLinks.map((link) => (
        <li className={itemClassName} key={link.id}>
          <MenuLink
            className={linkClassName}
            activeClassName={activeLinkClassName}
            pageName={link.pageName}
            url={link.url}
          >
            <span className={linkTextClassName}>{link.text}</span>
          </MenuLink>
        </li>
      ))}
    </ul>
  );
};
AppNavigationLinksComponent.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  activeLinkClassName: PropTypes.string,
  linkTextClassName: PropTypes.string,
  headerLinks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: MenuLink.propTypes.text,
    pageName: MenuLink.propTypes.pageName,
    url: MenuLink.propTypes.url
  })),
  startScreenPath: NavLink.propTypes.to,
  belongsToScreenGroup: PropTypes.func.isRequired
};

const stateToAppNavigationLinksProps = createStateToProps({
  headerLinks: getHeaderLinks,
  // getStartScreenPath selector requires withRouter for access to
  // location prop
  startScreenPath: getStartScreenPath,
  // belongsToScreenGroup selector requires withRouter for access to
  // location prop
  belongsToScreenGroup: getBelongsToScreenGroup
});

export const AppNavigationLinks = withRouter(connect(
  stateToAppNavigationLinksProps
)(AppNavigationLinksComponent));

export const AppNavigation = (props) => {
  const {collapsed} = props;
  const className = classnames("app-navigation", props.className);
  const itemClassName = "app-navigation__item";
  const linkClassName = classnames("app-navigation__link", {
    "app-navigation__link--collapsed": collapsed
  });
  const activeLinkClassName = "app-navigation__link--active";
  const linkTextClassName = "app-navigation__link-text";

  return (
    <nav className={className}>
      <AppNavigationLinks
        itemClassName={itemClassName}
        linkClassName={linkClassName}
        activeLinkClassName={activeLinkClassName}
        linkTextClassName={linkTextClassName}
      />
    </nav>
  );
};
AppNavigation.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool
};
