import {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {initialize} from "./actions.js";

class ReservationEntryComponent extends Component {
  componentDidMount() {
    const {initialize = () => {}} = this.props;
    initialize();
  }

  render() {
    return null;
  }
}
ReservationEntryComponent.propTypes = {
  initialize: PropTypes.func
};

const stateToReservationEntryProps = createStateToProps({
});

const dispatchToReservationEntryProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(stateToReservationEntryProps, dispatchToReservationEntryProps)(ReservationEntryComponent);
