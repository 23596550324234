import * as routes from "../routing/routes.js";

export const routesTrackingData = {

  // ======================================================================
  // CMS pages
  // ======================================================================

  [routes.Cms.path()]: ({pageName}) => ({
    faq: {
      id: "rent > bmw rent > faq",
      name: "rent > faq",
      subCategory1: "help",
      subCategory2: "",
      type: "faq"
    },

    agb: {
      id: "rent > bmw rent > downloads",
      name: "rent > downloads",
      subCategory1: "downloads",
      subCategory2: "",
      type: "overview"
    },

    contact: {
      id: "rent > bmw rent > contact",
      name: "rent > contact",
      subCategory1: "contact",
      subCategory2: "",
      type: "overview"
    },

    legalnotice: {
      id: "rent > bmw rent > legal notice",
      name: "rent > legal notice",
      subCategory1: "legal notice",
      subCategory2: "",
      type: "overview"
    },

    imprint: {
      id: "rent > bmw rent > imprint",
      name: "rent > imprint",
      subCategory1: "imprint",
      subCategory2: "",
      type: "overview"
    },

    privacy: {
      id: "rent > bmw rent > privacy",
      name: "rent > privacy",
      subCategory1: "privacy",
      subCategory2: "",
      type: "overview"
    },

    cookies: {
      id: "rent > bmw rent > cookies",
      name: "rent > cookies",
      subCategory1: "cookies",
      subCategory2: "",
      type: "overview"
    }
  }[pageName] || {
    id: `rent > bmw rent > ${pageName}`,
    name: `rent > ${pageName}`,
    subCategory1: pageName,
    subCategory2: "",
    type: "na"
  }),

  // ======================================================================
  // B2C Select station and time
  // ======================================================================

  [routes.B2C_SelectStationAndTime.path()]: {
    id: "rent > bmw rent > select station and time",
    name: "rent > select station and time",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  // ======================================================================
  // B2C Short term reservation process
  // ======================================================================

  [routes.B2C_STR_SelectRentObject.path()]: {
    id: "rent > bmw rent > select vehicle (short term)",
    name: "rent > select vehicle (short term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_STR_SelectExtras.path()]: {
    id: "rent > bmw rent > select extras (short term)",
    name: "rent > select extras (short term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_STR_SubmitRentContract.path()]: {
    id: "rent > bmw rent > submit reservation overview (short term)",
    name: "rent > submit reservation overview (short term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_STR_SubmitRentContractSuccess.path()]: {
    id: "rent > bmw rent > reservation success (short term)",
    name: "rent > reservation success (short term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  // ======================================================================
  // B2C Long term reservation
  // ======================================================================

  [routes.B2C_LTR_SelectRentObject.path()]: {
    id: "rent > bmw rent > select vehicle (long term)",
    name: "rent > select vehicle (long term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_LTR_SelectExtras.path()]: {
    id: "rent > bmw rent > select extras (long term)",
    name: "rent > select extras (long term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_LTR_SelectProvisioning.path()]: {
    id: "rent > bmw rent > select provisioning (long term)",
    name: "rent > select provisioning (long term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_LTR_SubmitRentContract.path()]: {
    id: "rent > bmw rent > submit reservation overview (long term)",
    name: "rent > submit reservation overview (long term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  [routes.B2C_LTR_SubmitRentContractSuccess.path()]: {
    id: "rent > bmw rent > reservation success (long term)",
    name: "rent > reservation success (long term)",
    subCategory1: "reservation",
    subCategory2: "b2c",
    type: "checkout"
  },

  // ======================================================================
  // B2C signup and login
  // ======================================================================

  [routes.B2C_SignUp.path()]: {
    id: "rent > bmw rent > sign up start",
    name: "rent > sign up start",
    subCategory1: "sign up",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.B2C_SignUpSuccess.path()]: {
    id: "rent > bmw rent > sign up submit",
    name: "rent > sign up submit",
    subCategory1: "sign up",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.B2C_SignUpConfirmed.path()]: {
    id: "rent > bmw rent > sign up confirmed",
    name: "rent > sign up confirmed",
    subCategory1: "sign up",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.B2C_SignUpExtended.path()]: {
    id: "rent > bmw rent > sign up extended",
    name: "rent > sign up extended",
    subCategory1: "sign up",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.B2C_Login.path()]: {
    id: "rent > bmw rent > login",
    name: "rent > login",
    subCategory1: "user login",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.B2C_RequestResetPasswordToken.path()]: {
    id: "rent > bmw rent > recover password",
    name: "rent > recover password",
    subCategory1: "login",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.ResetPasswordEntry.path()]: {
    id: "rent > bmw rent > reset password",
    name: "rent > reset password",
    subCategory1: "login",
    subCategory2: "b2c",
    type: "customer"
  },

  // ======================================================================
  // B2C dashboard
  // ======================================================================

  [routes.MyBmwRentDashboard.path()]: {
    id: "rent > bmw rent > dashboard",
    name: "rent > dashboard",
    subCategory1: "dashboard",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.MyBmwRentProfile.path()]: {
    id: "rent > bmw rent > profile",
    name: "rent > profile",
    subCategory1: "user account",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.MyBmwRentProfileSuccess.path()]: {
    id: "rent > bmw rent > profile update success",
    name: "rent > profile update success",
    subCategory1: "user account",
    subCategory2: "b2c",
    type: "customer"
  },

  [routes.MyBmwRentRentContracts.path()]: {
    id: "rent > bmw rent > rent contracts",
    name: "rent > rent contracts",
    subCategory1: "T&C",
    subCategory2: "b2c",
    type: "overview"
  },

  [routes.MyBmwRentPaymentMethods.path()]: {
    id: "rent > bmw rent > payment methods",
    name: "rent > payment methods",
    subCategory1: "user account",
    subCategory2: "b2c",
    type: "customer"
  },

  // ======================================================================
  // B2B Select station and time
  // ======================================================================

  [routes.B2B_SelectStationAndTime.path()]: {
    id: "rent > bmw rent > select period",
    name: "rent > select period",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  // ======================================================================
  // B2B Short term reservation process
  // ======================================================================

  [routes.B2B_STR_SelectRentObject.path()]: {
    id: "rent > bmw rent > select vehicle (short term)",
    name: "rent > select vehicle (short term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_STR_SelectExtras.path()]: {
    id: "rent > bmw rent > select extras (short term)",
    name: "rent > select extras (short term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_STR_SelectExtrasConfirm.path()]: {
    id: "rent > bmw rent > select extras confirm (short term)",
    name: "rent > select extras confirm (short term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_STR_SelectDriver.path()]: {
    id: "rent > bmw rent > select driver (short term)",
    name: "rent > select driver (short term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_STR_SubmitRentContract.path()]: {
    id: "rent > bmw rent > submit reservation overview (short term)",
    name: "rent > submit reservation overview (short term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_STR_SubmitRentContractSuccess.path()]: {
    id: "rent > bmw rent > reservation success (short term)",
    name: "rent > reservation success (short term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  // ======================================================================
  // B2B Long term reservation
  // ======================================================================

  [routes.B2B_LTR_SelectRentObject.path()]: {
    id: "rent > bmw rent > select vehicle (long term)",
    name: "rent > select vehicle (long term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_LTR_SelectExtras.path()]: {
    id: "rent > bmw rent > select extras (long term)",
    name: "rent > select extras (long term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_LTR_SelectExtrasConfirm.path()]: {
    id: "rent > bmw rent > select extras confirm (long term)",
    name: "rent > select extras confirm (long term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_LTR_SelectDriverAndProvisioning.path()]: {
    id: "rent > bmw rent > select provisioning (long term)",
    name: "rent > select provisioning (long term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_LTR_SubmitRentContract.path()]: {
    id: "rent > bmw rent > submit reservation overview (long term)",
    name: "rent > submit reservation overview (long term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  [routes.B2B_LTR_SubmitRentContractSuccess.path()]: {
    id: "rent > bmw rent > reservation success (long term)",
    name: "rent > reservation success (long term)",
    subCategory1: "reservation",
    subCategory2: "b2b",
    type: "checkout"
  },

  // ======================================================================
  // B2B signup and login
  // ======================================================================

  [routes.B2B_SignUpRequest.path()]: {
    id: "rent > bmw rent > sign up start",
    name: "rent > sign up start",
    subCategory1: "sign up",
    subCategory2: "b2b",
    type: "customer"
  },

  [routes.B2B_SignUpRequestSuccess.path()]: {
    id: "rent > bmw rent > sign up submit",
    name: "rent > sign up submit",
    subCategory1: "sign up",
    subCategory2: "b2b",
    type: "customer"
  },

  [routes.B2B_Login.path()]: {
    id: "rent > bmw rent > login",
    name: "rent > login",
    subCategory1: "user login",
    subCategory2: "b2b",
    type: "customer"
  },

  [routes.B2B_RequestResetPasswordToken.path()]: {
    id: "rent > bmw rent > recover password",
    name: "rent > recover password",
    subCategory1: "login",
    subCategory2: "b2b",
    type: "customer"
  },

  // ======================================================================
  // B2B dashboard
  // ======================================================================

  [routes.B2B_Dashboard.path()]: {
    id: "rent > bmw rent > dashboard",
    name: "rent > dashboard",
    subCategory1: "dashboard",
    subCategory2: "b2b",
    type: "customer"
  },

  [routes.B2B_Profile.path()]: {
    id: "rent > bmw rent > profile",
    name: "rent > profile",
    subCategory1: "user account",
    subCategory2: "b2b",
    type: "customer"
  },

  [routes.B2B_Payment.path()]: {
    id: "rent > bmw rent > payment methods",
    name: "rent > payment methods",
    subCategory1: "user account",
    subCategory2: "b2b",
    type: "customer"
  },

  [routes.B2B_History.path()]: {
    id: "rent > bmw rent > customer history",
    name: "rent > customer history",
    subCategory1: "user account",
    subCategory2: "b2b",
    type: "customer"
  }
};
