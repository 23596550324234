import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions
} from "@awe-web/shared/lib/user/store.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {B2B_LTR_SelectExtras} from "../../../routing/routes.js";
import {Row, Col} from "../../../components/grid.js";
import {
  ScreenSection,
  ScreenHeading,
  ScreenLoading
} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {Button, RequiredNotice} from "../../../components/form.js";
import {SelectedStationAndTime} from "../../../stations/components/selected_station_and_time.js";
import {SelectedRentObject} from "../../../rent_objects/components/selected_rent_object.js";
import {SelectDriverAndProvisioningForm} from "./select_driver_and_provisioning_form.js";
import {getCoStation} from "../../../stations/store.js";
import {getCompanyAddress} from "../../../user/store.js";
import {
  getProvisioningTypeB2b,
  getFormattedPickupPrice,
  getFormattedDeliveryPrice,
  getIsDeliveryEnabled
} from "../../../rent_contracts/store.js";
import {initialize, submit} from "./actions.js";

import "./select_driver_and_provisioning.scss";

const SCREEN_HEADING = "ALG0000065";
const BACK = "ALG0000030";
const NOTICE = "FSD0000209";
const PRICES_NOTICE = "ALG0000029";

const SelectDriverAndProvisioningComponent = (props) => {
  const {
    initialize,
    onSubmit,
    rentContractId,
    provisioningType,
    coStation,
    companyAddress,
    formattedDeliveryPrice,
    formattedPickupPrice,
    isDeliveryEnabled,

    getDriverFormFieldDefaultValues,
    validateDriver,
    isDriverFieldRequired,
    getDriverFieldLabel,
    getDriverSelectFieldOptions,
    getDriverFieldPlaceholder,

    getDeliveryFormFieldDefaultValues,
    validateDelivery,
    isDeliveryFieldRequired,
    getDeliveryFieldLabel,
    getDeliverySelectFieldOptions,
    getDeliveryFieldPlaceholder
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialValues = {
    ...getDriverFormFieldDefaultValues({
      "Person:prefixKey": "",
      "Person:name": "",
      "Person:surname": "",
      "Address:email": "",
      "Address:phone": "",
      "employeeId": "",
      "costCenter": ""
    }),
    ...getDeliveryFormFieldDefaultValues({
      "DeliveryAddress:surname": "",
      "DeliveryContactPerson:name": "",
      "DeliveryContactPerson:surname": "",
      "DeliveryAddress:street": "",
      "DeliveryAddress:building": "",
      "DeliveryAddress:zipCode": "",
      "DeliveryAddress:city": "",
      "DeliveryAddress:countryCode": "",
      "DeliveryContactPerson:phone": ""
    }),
    provisioningType
  };
  const validate = (values) => {
    const driverFormErrors = validateDriver(values);
    const deliveryFormErrors = values.provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS
      ? validateDelivery(values)
      : {};

    return {
      ...driverFormErrors,
      ...deliveryFormErrors
    };
  };

  return (
    <div className="b2b-ltr-select-driver-and-provisioning">
      <ScreenSection dark>
        <TypeArea>
          <SelectedStationAndTime reservationPath="B2B" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <SelectedRentObject
            className="b2b-ltr-select-driver-and-provisioning__selected-rent-object"
            selectedBasicProperties="B2B"
            action={(
              <Button
                secondary
                back
                to={B2B_LTR_SelectExtras.location({rentContractId})}
              >
                <T code={BACK} />
              </Button>
            )}
          />
        </TypeArea>
      </ScreenSection>

      <ScreenSection>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading><T code={SCREEN_HEADING} /></ScreenHeading>
            </Col>
          </Row>

          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps) => (
              <SelectDriverAndProvisioningForm
                {...formikProps}
                coStation={coStation}

                companyAddress={companyAddress}
                formattedDeliveryPrice={formattedDeliveryPrice}
                formattedPickupPrice={formattedPickupPrice}
                isDeliveryEnabled={isDeliveryEnabled}

                isDriverFieldRequired={isDriverFieldRequired}
                getDriverFieldLabel={getDriverFieldLabel}
                getDriverSelectFieldOptions={getDriverSelectFieldOptions}
                getDriverFieldPlaceholder={getDriverFieldPlaceholder}

                isDeliveryFieldRequired={isDeliveryFieldRequired}
                getDeliveryFieldLabel={getDeliveryFieldLabel}
                getDeliverySelectFieldOptions={getDeliverySelectFieldOptions}
                getDeliveryFieldPlaceholder={getDeliveryFieldPlaceholder}
              />
            )}
          </Formik>

          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <RequiredNotice className="b2b-ltr-select-driver-and-provisioning__required-notice"><T code={NOTICE} /></RequiredNotice>
              <div><T code={PRICES_NOTICE} /></div>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SelectDriverAndProvisioningComponent.propTypes = {
  initialize: PropTypes.func,
  onSubmit: PropTypes.func,

  getDriverFormFieldDefaultValues: PropTypes.func,
  validateDriver: PropTypes.func,
  isDriverFieldRequired: PropTypes.func,
  getDriverFieldLabel: PropTypes.func,
  getDriverSelectFieldOptions: PropTypes.func,
  getDriverFieldPlaceholder: PropTypes.func,

  rentContractId: PropTypes.number,
  provisioningType: PropTypes.string,
  coStation: SelectDriverAndProvisioningForm.propTypes.coStation,
  companyAddress: PropTypes.shape({
    companyName: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string
  }),
  formattedDeliveryPrice: PropTypes.string,
  formattedPickupPrice: PropTypes.string,
  isDeliveryEnabled: PropTypes.bool,

  getDeliveryFormFieldDefaultValues: PropTypes.func,
  validateDelivery: PropTypes.func,
  isDeliveryFieldRequired: PropTypes.func,
  getDeliveryFieldLabel: PropTypes.func,
  getDeliverySelectFieldOptions: PropTypes.func,
  getDeliveryFieldPlaceholder: PropTypes.func
};

const stateToSelectDriverAndProvisioningProps = createStateToProps({
  getDriverFormFieldDefaultValues: newGetInitialValues("personContractDriver"),
  validateDriver: newValidate("personContractDriver"),
  isDriverFieldRequired: newIsFieldRequired("personContractDriver"),
  getDriverFieldLabel: newGetFieldLabel("personContractDriver"),
  getDriverFieldPlaceholder: newGetFieldPlaceholder("personContractDriver"),
  getDriverSelectFieldOptions: newGetSelectFieldOptions("personContractDriver"),

  rentContractId: getRentContractId,
  provisioningType: getProvisioningTypeB2b,
  coStation: getCoStation,
  companyAddress: getCompanyAddress,
  formattedDeliveryPrice: getFormattedDeliveryPrice,
  formattedPickupPrice: getFormattedPickupPrice,
  isDeliveryEnabled: getIsDeliveryEnabled,

  validateDelivery: newValidate("deliveryAddress07"),
  getDeliveryFormFieldDefaultValues: newGetInitialValues("deliveryAddress07"),
  isDeliveryFieldRequired: newIsFieldRequired("deliveryAddress07"),
  getDeliveryFieldLabel: newGetFieldLabel("deliveryAddress07"),
  getDeliveryFieldPlaceholder: newGetFieldPlaceholder("deliveryAddress07"),
  getDeliverySelectFieldOptions: newGetSelectFieldOptions("deliveryAddress07")
});

const dispatchToSelectDriverAndProvisioningProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmit: (formValues) => dispatch(submit(formValues))
});

export default connect(
  stateToSelectDriverAndProvisioningProps,
  dispatchToSelectDriverAndProvisioningProps
)(SelectDriverAndProvisioningComponent);
