import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {useField} from "formik";
import {RadioButton} from "@awe-web/shared/lib/components/form.js";

import "./provisioning_selection.scss";

export const ProvisioningSelection = (props) => {
  const {name, value, label, formattedPrice, disabled, children} = props;
  const [field] = useField(name);
  const checked = value === field.value;

  const labelClassName = classnames("provisioning-selection__control", {
    "provisioning-selection__control--disabled": disabled
  });

  return (
    <div className="provisioning-selection">
      <label className={labelClassName}>
        <RadioButton
          {...field}
          className="provisioning-selection__input"
          disabled={disabled}
          value={value}
          checked={checked}
        />
        <div className="provisioning-selection__label">
          <span className="provisioning-selection__label-text">{label}</span>
          <span>{!disabled && formattedPrice}</span>
        </div>
      </label>
      {checked
        ? (
          <div className="provisioning-selection__children">
            {children}
          </div>
        )
        : null
      }
    </div>
  );
};
ProvisioningSelection.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  formattedPrice: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};
