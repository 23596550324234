import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";
import {NavLink} from "react-router-dom";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {getLoggedIn} from "@awe-web/shared/lib/user/store.js";
import {isSidebarMenuOpen as getIsSidebarMenuOpen} from "@awe-web/shared/lib/sidebar_panel/store.js";
import {
  B2C_SelectStationAndTime,
  B2B_SelectStationAndTime
} from "../routing/routes.js";
import {
  getProfileScreenLink,
  getSignUpScreenLink,
  getLoginScreenPath,
  getAuthorizedUsers,
  getCurrentUserId
} from "../user/store.js";
import {screenGroups} from "../routing/constants.js";
import {getBelongsToScreenGroup} from "../routing/store.js";
import * as userActions from "../user/actions.js";

import "./user_navigation.scss";

const PRIVATE_CUSTOMERS = "ALG0000008";
const BUSINESS_CUSTOMERS = "ALG0000009";
const LOGOUT = "FSD0000258";
const LOGIN = "FSD0000141";

const UserProfileLink = (props) => {
  const {
    linkClassName,
    activeClassName,
    isActive,
    isSidebarMenuOpen,
    profileScreenLink,
    currentUserId,
    authorizedUsers = [],
    switchToUser
  } = props;

  const hasAuthorizedUsers = authorizedUsers.length > 1;
  const className = classnames("user-profile-link__link", linkClassName, {
    "user-profile-link__link--active": isActive()
  });

  const onLinkClick = isSidebarMenuOpen && hasAuthorizedUsers
    ? (event) => {
      // Prevents default behavior of sidebar panel to keep it open while select
      // options are open
      if (event.preventDefault && typeof event.preventDefault === "function") {
        event.preventDefault();
      }
    }
    : () => {};
  const onAuthorizedUserChange = (event) => {
    switchToUser(Number(event.target.value));
    event.target.value = -1;
  };

  const userSelection = (
    <select
      className="user-profile-link__select"
      onChange={onAuthorizedUserChange}
      value={-1}
    >
      <option key={-1} value={-1} disabled />
      {authorizedUsers.map((authorizedUser) => (
        <option key={authorizedUser.id} value={authorizedUser.id}>
          {authorizedUser.label}
        </option>
      ))}
    </select>
  );

  const usersOverlay = (
    <div className="user-profile-link__overlay">
      <ul className="user-profile-link__authorized-users">
        {authorizedUsers
          .filter((authorizedUser) => authorizedUser.id !== currentUserId)
          .map((authorizedUser) => (
            <li
              key={authorizedUser.id}
              className="user-profile-link__authorized-user"
            >
              <button
                className="user-profile-link__authorized-user-button"
                onClick={() => onAuthorizedUserChange({
                  target: {value: authorizedUser.id}
                })}
              >
                {authorizedUser.label}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );

  return (
    <div className="user-profile-link">
      <NavLink
        className={className}
        activeClassName={activeClassName}
        to={profileScreenLink.path}
        onClick={onLinkClick}
        isActive={isActive}
      >
        {isSidebarMenuOpen && hasAuthorizedUsers && userSelection}
        {profileScreenLink.label}
      </NavLink>

      {!isSidebarMenuOpen && hasAuthorizedUsers && usersOverlay}
    </div>
  );
};
UserProfileLink.propTypes = {
  linkClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  isActive: PropTypes.func,
  isSidebarMenuOpen: PropTypes.bool,
  profileScreenLink: PropTypes.shape({
    path: NavLink.propTypes.to,
    label: PropTypes.node
  }),
  currentUserId: PropTypes.number,
  authorizedUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  })),
  switchToUser: PropTypes.func.isRequired
};

const UserNavigationComponent = (props) => {
  const className = classnames("user-navigation", props.className);
  const {
    loggedIn,
    isSidebarMenuOpen,
    profileScreenLink,
    signUpScreenLink,
    onLogout,
    belongsToScreenGroup,
    loginScreenPath,
    currentUserId,
    authorizedUsers,
    switchToUser
  } = props;

  const isB2bScreen = () => belongsToScreenGroup(screenGroups.B2B);
  const isB2cScreen = () => belongsToScreenGroup(screenGroups.B2C);
  const isLoginScreen = () => belongsToScreenGroup(screenGroups.LOGIN);
  const isProfileScreen = () => belongsToScreenGroup(screenGroups.PROFILE);
  const isSignUpScreen = () => belongsToScreenGroup(screenGroups.SIGN_UP);

  return (
    <div className={className}>
      {loggedIn
        ? (
          <div className="user-navigation__row">
            <span className="user-navigation__item user-navigation__item--profile">
              <UserProfileLink
                linkClassName="user-navigation__link user-navigation__link--profile"
                activeClassName="user-navigation__link--profile--active"
                isActive={isProfileScreen}
                isSidebarMenuOpen={isSidebarMenuOpen}
                profileScreenLink={profileScreenLink}
                currentUserId={currentUserId}
                authorizedUsers={authorizedUsers}
                switchToUser={switchToUser}
              />
            </span>
            <span className="user-navigation__item">
              <button className="user-navigation__link user-navigation__link--logout" onClick={onLogout}>
                <T code={LOGOUT} />
              </button>
            </span>
          </div>
        ) : (
          <>
            <div className="user-navigation__row">
              <span className="user-navigation__item">
                <NavLink
                  className="user-navigation__link"
                  activeClassName="user-navigation__link--active"
                  to={B2C_SelectStationAndTime.path()}
                  isActive={isB2cScreen}
                >
                  <T code={PRIVATE_CUSTOMERS} />
                </NavLink>
              </span>

              <span className="user-navigation__item">
                <NavLink
                  className="user-navigation__link"
                  activeClassName="user-navigation__link--active"
                  to={B2B_SelectStationAndTime.path()}
                  isActive={isB2bScreen}
                >
                  <T code={BUSINESS_CUSTOMERS} />
                </NavLink>
              </span>
            </div>
            <div className="user-navigation__row">
              <span className="user-navigation__item">
                <NavLink
                  className="user-navigation__link user-navigation__link--login"
                  activeClassName="user-navigation__link--login--active"
                  to={loginScreenPath}
                  isActive={isLoginScreen}
                >
                  <T code={LOGIN} />
                </NavLink>
              </span>
              <span className="user-navigation__item">
                <NavLink
                  className="user-navigation__link user-navigation__link"
                  activeClassName="user-navigation__link--active"
                  to={signUpScreenLink.path}
                  isActive={isSignUpScreen}
                >
                  {signUpScreenLink.label}
                </NavLink>
              </span>
            </div>
          </>
      )}
    </div>
  );
};
UserNavigationComponent.propTypes = {
  className: PropTypes.string,
  loggedIn: PropTypes.bool,
  onLogout: PropTypes.func,
  isSidebarMenuOpen: PropTypes.bool,
  profileScreenLink: UserProfileLink.propTypes.profileScreenLink,
  signUpScreenLink: PropTypes.shape({
    path: NavLink.propTypes.to,
    label: PropTypes.node
  }),
  belongsToScreenGroup: PropTypes.func.isRequired,
  loginScreenPath: NavLink.propTypes.to,
  currentUserId: UserProfileLink.propTypes.currentUserId,
  authorizedUsers: UserProfileLink.propTypes.authorizedUsers,
  switchToUser: UserProfileLink.propTypes.switchToUser
};

const stateToUserNavigationProps = createStateToProps({
  loggedIn: getLoggedIn,
  isSidebarMenuOpen: getIsSidebarMenuOpen,
  profileScreenLink: getProfileScreenLink,
  // getLoginScreenPath selector requires withRouter for access to
  // location prop
  loginScreenPath: getLoginScreenPath,
  // getBelongsToScreenGroup selector requires withRouter for access to
  // location prop
  belongsToScreenGroup: getBelongsToScreenGroup,
  // getSignUpScreenLink selector requires withRouter for access to
  // location prop
  signUpScreenLink: getSignUpScreenLink,
  currentUserId: getCurrentUserId,
  authorizedUsers: getAuthorizedUsers
});

const dispatchToUserNavigationProps = (dispatch) => ({
  onLogout: () => dispatch(userActions.logoutAndRedirect()),
  switchToUser: (userId) => dispatch(userActions.switchToUser(userId))
});

export const UserNavigation = withRouter(connect(
  stateToUserNavigationProps,
  dispatchToUserNavigationProps
)(UserNavigationComponent));
