"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localeToLang = exports.normalizeLocale = exports.mappedTranslate = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _object = require("../util/object.js");

var applyMapping = function applyMapping(mapping) {
  return function (key) {
    return key in mapping ? mapping[key] : key;
  };
};

var mappedTranslate = function mappedTranslate(translate) {
  var codeMapping = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var dataMapping = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return function (code) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var mappedCode = applyMapping(codeMapping)(code);
    var mappedData = (0, _object.mapKeys)(data, applyMapping(dataMapping));
    return translate(mappedCode, mappedData);
  };
};

exports.mappedTranslate = mappedTranslate;

var normalizeLocale = function normalizeLocale(locale) {
  var _split = (locale || "").split("-"),
      _split2 = (0, _slicedToArray2["default"])(_split, 2),
      lang = _split2[0],
      region = _split2[1];

  return lang && region ? lang.toLowerCase() + "_" + region.toUpperCase() : undefined;
};

exports.normalizeLocale = normalizeLocale;

var localeToLang = function localeToLang(locale) {
  var _split3 = (locale || "").split("-"),
      _split4 = (0, _slicedToArray2["default"])(_split3, 1),
      lang = _split4[0];

  return lang ? lang.toLowerCase() : undefined;
};

exports.localeToLang = localeToLang;