import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Link, withRouter} from "react-router-dom";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {getStartScreenPath} from "../user/store.js";
import logo from "./assets/logo.png";

import "./brand.scss";

const BRAND_TITLE_BMW = "FSD0000136";
const BRAND_TITLE_MINI = "FSD0000333";

const BrandComponent = (props) => {
  const {startScreenPath} = props;
  const className = classnames("brand", props.className);

  return (
    <div className={className}>
      <Link className="brand__title" to={startScreenPath}>
        <T className="brand__title__row brand__title__row--bmw" code={BRAND_TITLE_BMW} />
        <T className="brand__title__row brand__title__row--mini" code={BRAND_TITLE_MINI} />
      </Link>
      <img className="brand__logo" src={logo} alt="" />
    </div>
  );
};
BrandComponent.propTypes = {
  className: PropTypes.string,
  startScreenPath: Link.propTypes.to
};

const stateToBrandProps = createStateToProps({
  // getStartScreenPath selector requires withRouter for access to
  // location prop
  startScreenPath: getStartScreenPath
});

export const Brand = withRouter(connect(stateToBrandProps)(BrandComponent));
