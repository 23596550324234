import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newFieldProps} from "@awe-web/shared/lib/util/form.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {getLoginErrorMessage} from "@awe-web/shared/lib/user/store.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {
  LabeledField,
  InputLabel,
  Button,
  TextInput,
  PasswordInput,
  RequiredNotice,
  ErrorMessage,
  ScrollToError
} from "../../components/form.js";

import "./login_form.scss";

const LABEL_USERNAME = "FSD0000088";
const LABEL_PASSWORD = "FSD0000090";
const PLACEHOLDER_USERNAME = "FSD0000089";
const PLACEHOLDER_PASSWORD = "FSD0000091";
const BUTTON_LOGIN = "FSD0000092";
const NOTICE = "FSD0000209";
const ERROR_ENTER_USERNAME = "FSD0000256";
const ERROR_ENTER_PASSWORD = "FSD0000257";

export const validate = translate => values => {
  const errors = {};

  if (!values.username) {
    errors.username = translate(ERROR_ENTER_USERNAME);
  }
  if (!values.password) {
    errors.password = translate(ERROR_ENTER_PASSWORD);
  }

  return errors;
};

const Form = (props) => {
  const {loginErrorMessage, translate, handleSubmit} = props;
  const fieldProps = newFieldProps(props);
  const error = name => fieldProps(name).error;

  return (
    <form
      className="login-form"
      onSubmit={handleSubmit}
    >
      <ScrollToError />
      <div className="login-form__fields">
        <LabeledField className="login-form__labeled-field">
          <InputLabel required error={error("username") || loginErrorMessage}>
            <T code={LABEL_USERNAME} />
          </InputLabel>
          <TextInput
            {...fieldProps("username")}
            error={error("username") || loginErrorMessage}
            placeholder={translate(PLACEHOLDER_USERNAME)} />
          <ErrorMessage error={error("username")} />
        </LabeledField>

        <LabeledField className="login-form__labeled-field">
          <InputLabel required error={error("password") || loginErrorMessage}>
            <T code={LABEL_PASSWORD} />
          </InputLabel>
          <PasswordInput
            {...fieldProps("password")}
            error={error("password") || loginErrorMessage}
            placeholder={translate(PLACEHOLDER_PASSWORD)} />
          <ErrorMessage error={error("password")} />
        </LabeledField>

        <ErrorMessage
          className="login-form__login-error-message"
          error={loginErrorMessage}
        />
      </div>

      <Button
        submit
        continue
        primary
        className="login-form__submit-button"
      >
        <T code={BUTTON_LOGIN} />
      </Button>

      <RequiredNotice className="login-form__required-notice">
        <T code={NOTICE} />
      </RequiredNotice>
    </form>
  );
};
Form.propTypes = {
  loginErrorMessage: PropTypes.string,
  translate: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};

const LoginFormComponent = (props) => {
  const {rentContractId, loginErrorMessage, translate, onSubmit} = props;
  const initialValues = {
    rentContractId,
    username: "",
    password: ""
  };

  return (
    <Formik
      validate={validate(translate)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form
          {...formikProps}
          loginErrorMessage={loginErrorMessage}
          translate={translate}
        />
      )}
    </Formik>
  );
};
LoginFormComponent.propTypes = {
  className: PropTypes.string,
  rentContractId: PropTypes.number,
  translate: PropTypes.func.isRequired,
  loginErrorMessage: PropTypes.string,
  onSubmit: PropTypes.func
};

const stateToLoginFormProps = createStateToProps({
  translate: newTranslate,
  loginErrorMessage: getLoginErrorMessage
});

export const LoginForm = connect(stateToLoginFormProps)(LoginFormComponent);
