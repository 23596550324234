"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.getRawDeliveryAddress = exports.getFormattedPromocodeSavedAmount = exports.getPromocodeSavedAmount = exports.getIsPromocodeActivated = exports.getDeliveryInvoiceItem = exports.getDeductibleInvoiceItems = exports.getMileageInvoiceItems = exports.getEnabledInvoiceItems = exports.getInvoiceItems = exports.getMainRentObjectTransaction = exports.getReservationNo = exports.getRentContractId = exports.getRentContract = void 0;

var _immutable = require("immutable");

var _reselect = require("reselect");

var _number = require("../util/number.js");

var _constants = require("./constants.js");

var MILEAGE_PACKAGE = _constants.ruleBls.MILEAGE_PACKAGE,
    CDW = _constants.ruleBls.CDW,
    S_CDW = _constants.ruleBls.S_CDW,
    DELIVERY = _constants.ruleBls.DELIVERY;

function reducer(state, action) {
  switch (action.type) {
    case "RENT_CONTRACTS/RENT_CONTRACT_LOADED":
      return state.set("rentContract", (0, _immutable.fromJS)(action.payload.rentContract));

    case "RENT_CONTRACTS/DELIVERY_ADDRESS_LOADED":
      return state.set("deliveryAddress", (0, _immutable.fromJS)(action.payload));

    default:
      return state;
  }
}

var getRentContract = function getRentContract(state) {
  return state.get("rentContract");
};

exports.getRentContract = getRentContract;
var getRentContractId = (0, _reselect.createSelector)([getRentContract], function (rentContract) {
  return rentContract ? rentContract.get("id") : undefined;
});
exports.getRentContractId = getRentContractId;
var getReservationNo = (0, _reselect.createSelector)([getRentContract], function (rentContract) {
  return rentContract && rentContract.get("reservationNo") ? String(rentContract.get("reservationNo")) : undefined;
});
exports.getReservationNo = getReservationNo;
var getMainRentObjectTransaction = (0, _reselect.createSelector)([getRentContract], function (rentContract) {
  return rentContract ? rentContract.get("rentObjTransacts", (0, _immutable.List)()).find(function (transaction) {
    return transaction.get("transactType") === "RC";
  }) : undefined;
});
exports.getMainRentObjectTransaction = getMainRentObjectTransaction;
var getRawInvoiceItems = (0, _reselect.createSelector)([getRentContract], function (rentContract) {
  return rentContract ? rentContract.get("invoiceItems", (0, _immutable.List)()) : (0, _immutable.List)();
});

var invoiceItemDescription = function invoiceItemDescription(invoiceItem) {
  return invoiceItem.get("wwwDescr") || invoiceItem.get("shortDescr");
};

var getInvoiceItems = (0, _reselect.createSelector)([getRawInvoiceItems], function (invoiceItems) {
  return invoiceItems.sortBy(function (invoiceItem) {
    return invoiceItem.get("sortNo");
  }).map(function (invoiceItem) {
    return invoiceItem.update("included", function (included) {
      return included === "YE";
    }).set("description", invoiceItemDescription(invoiceItem));
  });
});
exports.getInvoiceItems = getInvoiceItems;
var getEnabledInvoiceItems = (0, _reselect.createSelector)([getInvoiceItems], function (invoiceItems) {
  return invoiceItems.filter(function (invoiceItem) {
    return invoiceItem.get("enabled");
  });
});
exports.getEnabledInvoiceItems = getEnabledInvoiceItems;
var getMileageInvoiceItems = (0, _reselect.createSelector)([getInvoiceItems], function (invoiceItems) {
  return invoiceItems.filter(function (invoiceItem) {
    return invoiceItem.get("ruleBL") === MILEAGE_PACKAGE;
  });
});
exports.getMileageInvoiceItems = getMileageInvoiceItems;
var getDeductibleInvoiceItems = (0, _reselect.createSelector)([getInvoiceItems], function (invoiceItems) {
  return invoiceItems.filter(function (invoiceItem) {
    return [CDW, S_CDW].includes(invoiceItem.get("ruleBL"));
  });
});
exports.getDeductibleInvoiceItems = getDeductibleInvoiceItems;
var getDeliveryInvoiceItem = (0, _reselect.createSelector)([getInvoiceItems], function (invoiceItems) {
  return invoiceItems.find(function (invoiceItem) {
    return invoiceItem.get("ruleBL") === DELIVERY;
  });
});
exports.getDeliveryInvoiceItem = getDeliveryInvoiceItem;
var getIsPromocodeActivated = (0, _reselect.createSelector)([getRentContract], function (rentContract) {
  return rentContract ? rentContract.get("priceWithoutPromocode") !== 0 : false;
});
exports.getIsPromocodeActivated = getIsPromocodeActivated;
var getPromocodeSavedAmount = (0, _reselect.createSelector)([getRentContract, getIsPromocodeActivated], function (rentContract, isPromocodeActivated) {
  return isPromocodeActivated && rentContract ? rentContract.get("priceWithoutPromocode", 0) - rentContract.get("amountSumGross", 0) : 0;
});
exports.getPromocodeSavedAmount = getPromocodeSavedAmount;
var getFormattedPromocodeSavedAmount = (0, _reselect.createSelector)([getPromocodeSavedAmount], function (promocodeSavedAmount) {
  return (0, _number.formatCurrency)(-1 * promocodeSavedAmount);
});
exports.getFormattedPromocodeSavedAmount = getFormattedPromocodeSavedAmount;
var getRawDeliveryAddress = (0, _reselect.createSelector)([getRentContractId, function (state) {
  return state.get("deliveryAddress");
}], function (rentContractId, deliveryAddress) {
  return deliveryAddress && deliveryAddress.get("rentContractId") === rentContractId ? deliveryAddress.get("deliveryAddress") : undefined;
});
exports.getRawDeliveryAddress = getRawDeliveryAddress;