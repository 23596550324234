import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Button} from "../../components/form.js";

import "./station_card.scss";

const OPENING_TIMES = "FSD0000106";
const NAVIGATE_TO_STATION_LINK = "FSD0000449";

export const StationAddress = (props) => {
  const {
    station: {
      shortdescr,
      address: {
        street,
        zipCode,
        city
      } = {},
      openingInfos = []
    }
  } = props;
  const className = classnames("station-address", props.className);
  const stationNameClassName = classnames("station-address__name", props.stationNameClassName);
  const openingTimesHeadingClassName = classnames("station-address__opening-times-heading", props.openingTimesHeadingClassName);

  const openingInfoComponent = openingInfos.length > 0
    ? (
      <>
        <div className={openingTimesHeadingClassName}><T code={OPENING_TIMES} /></div>
        <ul className="station-address__opening-times">
          {openingInfos.map(({id, text}) => (<li key={id}>{text}</li>))}
        </ul>
      </>
    )
    : null;

  return (
    <div className={className}>
      <div className={stationNameClassName}>{shortdescr}</div>
      <div className="station-address__address">
        <div>{street}</div>
        <div>{zipCode} {city}</div>
      </div>
      {openingInfoComponent}
    </div>
  );
};
StationAddress.propTypes = {
  className: PropTypes.string,
  stationNameClassName: PropTypes.string,
  openingTimesHeadingClassName: PropTypes.string,
  station: PropTypes.shape({
    id: PropTypes.number,
    shortdescr: PropTypes.string,
    imageUrl: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string
    }),
    openingInfos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string
    }))
  })
};

const StationCard = (props) => {
  const {station, onClick = () => {}} = props;
  const className = classnames(
    "station-card",
    props.className,
    {"station-card--clickable": typeof onClick === "function"}
  );

  const onMapClick = (event) => event.stopPropagation();

  return (
    <div className={className} onClick={() => onClick(station.id)}>
      <div className="station-card__image-wrapper">
        <div
          className="station-card__image-wrapper__image"
          style={{backgroundImage: `url(${station.imageUrl})`}}
        />
      </div>

      <div className="station-card__body">
        <StationAddress className="station-card__info" station={station} />

        <a href={station.googleMapsLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onMapClick}
          className="station-card__map-link"
        >
          <img className="station-card__map-link__map" src={station.mapUrl} alt="" />
          <Button nonInteractable tertiary className="station-card__map-link__text">
            <T code={NAVIGATE_TO_STATION_LINK} />
          </Button>
        </a>
      </div>
    </div>
  );
};
StationCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  station: StationAddress.propTypes.station
};

export const StationCardList = (props) => {
  const {stations = [], onClick} = props;
  const className = classnames("station-card-list", props.className);

  return (
    <div className={className}>
      {stations.map(station => (
        <StationCard
          key={station.id}
          className="station-card-list__station-card"
          onClick={onClick}
          station={station} />
      ))}
    </div>
  );
};
StationCardList.propTypes = {
  className: PropTypes.string,
  stations: PropTypes.arrayOf(StationCard.propTypes.station),
  onClick: StationCard.propTypes.onClick
};
