import React from "react";
import PropTypes from "prop-types";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";

const YES = "FSD0000122";
const NO = "FSD0000123";

export const Flag = (props) => <T code={props.flag ? YES : NO} />;
Flag.propTypes = {
  flag: PropTypes.bool
};
