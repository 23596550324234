"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validate = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _validate = _interopRequireDefault(require("validate.js"));

var _object = require("./object.js");

var validate = function validate(rules) {
  return function (values) {
    // Discard rules that do not apply to given values. We define
    // initialValues for every form, so all relevant keys should be
    // present in values.
    var filteredRules = (0, _object.removeEntries)(rules, function (_ref) {
      var _ref2 = (0, _slicedToArray2["default"])(_ref, 1),
          key = _ref2[0];

      return !(key in values);
    });
    var errors = (0, _validate["default"])(values, filteredRules) || {};
    return (0, _object.mapValues)(errors, function (messages) {
      return messages[0];
    });
  };
};

exports.validate = validate;