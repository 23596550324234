import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {errorMessage} from "@awe-web/shared/lib/errors/store.js";
import {dismissError} from "@awe-web/shared/lib/errors/actions.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Overlay, OverlayHeading, OverlaySection} from "../../components/overlay.js";
import {Button} from "../../components/form.js";

import "./error_overlay.scss";

const AN_ERROR_OCCURED = "FSD0000312";
const CLOSE_OVERLAY = "FSD0000313";

const ErrorOverlayComponent = (props) => {
  const {errorMessage, dismissError} = props;
  return (
    <Overlay className="error-overlay" open={Boolean(errorMessage)} onClose={dismissError}>
      {({closeOverlay}) => (
        <>
          <OverlayHeading><T code={AN_ERROR_OCCURED} /></OverlayHeading>
          <OverlaySection>
            <div className="error-overlay__message">
              {errorMessage}
            </div>
            <Button className="error-overlay__button" primary continue onClick={closeOverlay}>
              <T code={CLOSE_OVERLAY} />
            </Button>
          </OverlaySection>
        </>
      )}
    </Overlay>
  );
};
ErrorOverlayComponent.propTypes = {
  errorMessage: PropTypes.string,
  dismissError: PropTypes.func.isRequired
};

const stateToErrorOverlayProps = createStateToProps({
  errorMessage: errorMessage
});

const dispatchToErrorOverlayProps = (dispatch) => ({
  dismissError: () => dispatch(dismissError())
});

export const ErrorOverlay = connect(stateToErrorOverlayProps, dispatchToErrorOverlayProps)(ErrorOverlayComponent);
