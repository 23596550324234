"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripEmptyValues = exports.stripUndefinedValues = exports.removeKeys = exports.selectKeys = exports.removeEntries = exports.mapValues = exports.mapKeys = exports.deepmerge = exports.merge = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _deepmerge = _interopRequireDefault(require("deepmerge"));

var merge = function merge() {
  for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
    objects[_key] = arguments[_key];
  }

  return Object.assign.apply(Object, [Object.create(null)].concat(objects));
};

exports.merge = merge;

var deepmerge = function deepmerge() {
  for (var _len2 = arguments.length, objects = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    objects[_key2] = arguments[_key2];
  }

  var objectsToMerge = objects.filter(function (object) {
    return Boolean(object);
  });

  if (objectsToMerge.length === 0) {
    return merge();
  }

  if (objectsToMerge.length === 1) {
    return merge(objectsToMerge[0]);
  }

  return merge(_deepmerge["default"].all(objectsToMerge));
};

exports.deepmerge = deepmerge;

var applyFn = function applyFn(value, f) {
  return f(value);
};

var applyFns = function applyFns(value, fns) {
  return fns.reduce(applyFn, value);
};

var mapEntries = function mapEntries(object, mapKeyFns, mapValueFns) {
  return Object.entries(object).reduce(function (result, _ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return merge(result, (0, _defineProperty2["default"])({}, applyFns(key, mapKeyFns), applyFns(value, mapValueFns)));
  }, {});
};

var mapKeys = function mapKeys(object) {
  for (var _len3 = arguments.length, fns = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
    fns[_key3 - 1] = arguments[_key3];
  }

  return mapEntries(object, fns, []);
};

exports.mapKeys = mapKeys;

var mapValues = function mapValues(object) {
  for (var _len4 = arguments.length, fns = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
    fns[_key4 - 1] = arguments[_key4];
  }

  return mapEntries(object, [], fns);
};

exports.mapValues = mapValues;

var removeEntries = function removeEntries(object, predicate) {
  return Object.entries(object).reduce(function (result, _ref3) {
    var _ref4 = (0, _slicedToArray2["default"])(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];

    return predicate([key, value]) ? result : merge(result, (0, _defineProperty2["default"])({}, key, value));
  }, {});
};

exports.removeEntries = removeEntries;

var selectKeys = function selectKeys(object, keys) {
  return removeEntries(object, function (_ref5) {
    var _ref6 = (0, _slicedToArray2["default"])(_ref5, 1),
        key = _ref6[0];

    return !keys.includes(key);
  });
};

exports.selectKeys = selectKeys;

var removeKeys = function removeKeys(object, keys) {
  return removeEntries(object, function (_ref7) {
    var _ref8 = (0, _slicedToArray2["default"])(_ref7, 1),
        key = _ref8[0];

    return keys.includes(key);
  });
};

exports.removeKeys = removeKeys;

var stripUndefinedValues = function stripUndefinedValues(object) {
  return removeEntries(object, function (_ref9) {
    var _ref10 = (0, _slicedToArray2["default"])(_ref9, 2),
        value = _ref10[1];

    return value === undefined;
  });
};

exports.stripUndefinedValues = stripUndefinedValues;

var stripEmptyValues = function stripEmptyValues(object) {
  return removeEntries(object, function (_ref11) {
    var _ref12 = (0, _slicedToArray2["default"])(_ref11, 2),
        value = _ref12[1];

    return value === undefined || value === "";
  });
};

exports.stripEmptyValues = stripEmptyValues;