import React from "react";
import {TypeArea} from "../../components/layout.js";
import {Row, Col} from "../../components/grid.js";

import "./initialization_error.scss";

export const InitializationError = (props) => {
  return (
    <TypeArea className="initialization-error">
      <Row className="initialization-error__row">
        <Col desktop={{cols: 6, offset: 3}} tablet={{cols: 6, offset: 1}}>
          <p>
            Liebe BMW Rent | MINI Rent Interessentin,<br />
            lieber BMW Rent | MINI Rent Interessent,
          </p>
          <p>
            wir bedanken uns für Ihr Interesse an unseren BMW und MINI Rent Produkten.
          </p>
          <p>
            Aktuell sind unsere Angebot für zu viele von Ihnen attraktiv, sodass die Serverkapazitäten überlastet sind.<br />
            Wir bitten vielmals um Entschuldigung.
          </p>
          <p>
            Bitte besuchen Sie uns zu einem späteren Zeitpunkt wieder.<br />
            Wir stehen Ihnen weiterhin gern per E-Mail zur Verfügung: <a href="mailto:BMW-Rent@BMW.de">BMW-Rent@BMW.de</a>
          </p>
          <p>
            Vielen Dank für Ihr Verständnis<br />
            Ihr BMW Rent | MINI Rent Team
          </p>

          <hr className="initialization-error__line" />

          <p>
            Dear BMW Rent | MINI Rent prospect,
          </p>
          <p>
            Thank you for your interest in our BMW and MINI Rent products.
          </p>
          <p>
            Our offers are currently attractive for too many of you, so that the server capacities are overloaded.<br />
            We ask for your apology.
          </p>
          <p>
            Please return at a later point in time.<br />
            We are still available by email: <a href="mailto:BMW-Rent@BMW.de">BMW-Rent@BMW.de</a>
          </p>
          <p>
            Thank you for your understanding<br />
            Your BMW Rent | MINI Rent Team
          </p>
        </Col>
      </Row>
    </TypeArea>
  );
};
InitializationError.propTypes = {
};
