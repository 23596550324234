"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toKebabCase = exports.substitute = exports.abbreviate = exports.trim = void 0;
var substitutePattern = /\{(.*?)\}/g;

var trim = function trim(string) {
  return string && typeof string.trim === "function" ? string.trim() : string;
};

exports.trim = trim;

var abbreviate = function abbreviate(string, length) {
  return string.substring(0, length);
};

exports.abbreviate = abbreviate;

var substitute = function substitute() {
  var template = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return template.replace(substitutePattern, function (_, key) {
    return data[key] || "";
  });
};

exports.substitute = substitute;

var toKebabCase = function toKebabCase(string) {
  return string.replace(/[A-Z]/g, function (match, offset) {
    return offset > 0 ? "-".concat(match.toLowerCase()) : match.toLowerCase();
  });
};

exports.toKebabCase = toKebabCase;