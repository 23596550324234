import React from "react";
import PropTypes from "prop-types";
import {trim} from "@awe-web/shared/lib/util/string.js";
import {Translation as T, withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {DashboardItems} from "../../../components/dashboard_item.js";
import {
  MyBmwRentProfile,
  MyBmwRentRentContracts,
  MyBmwRentPaymentMethods
} from "../../../routing/routes.js";

const TITLE = "FSD0000302";
const MY_RESERVATIONS_HEADING = "FSD0000303";
const MY_RESERVATIONS_TEXT = "FSD0000304";
const MY_RESERVATIONS_IMAGE = "FSD0000344";
const USER_PROFILE_HEADING = "FSD0000305";
const USER_PROFILE_TEXT = "FSD0000306";
const USER_PROFILE_IMAGE = "FSD0000345";
const PAYMENT_METHODS_HEADING = "FSD0000307";
const PAYMENT_METHODS_TEXT = "FSD0000308";
const PAYMENT_METHODS_IMAGE = "FSD0000346";

const MyBmwRentDashboardComponent = (props) => {
  const {translate} = props;
  const dashboardItems = [
    {
      heading: <T code={MY_RESERVATIONS_HEADING} />,
      text: <T code={MY_RESERVATIONS_TEXT} />,
      image: trim(translate(MY_RESERVATIONS_IMAGE)),
      link: MyBmwRentRentContracts.path()
    },
    {
      heading: <T code={USER_PROFILE_HEADING} />,
      text: <T code={USER_PROFILE_TEXT} />,
      image: trim(translate(USER_PROFILE_IMAGE)),
      link: MyBmwRentProfile.path()
    },
    {
      heading: <T code={PAYMENT_METHODS_HEADING} />,
      text: <T code={PAYMENT_METHODS_TEXT} />,
      image: trim(translate(PAYMENT_METHODS_IMAGE)),
      link: MyBmwRentPaymentMethods.path()
    }
  ];

  return (
    <div className="my-bmw-rent-dashboard">
      <ScreenSection main dark>
        <TypeArea>
          <ScreenHeading>
            <T code={TITLE} />
          </ScreenHeading>

          <DashboardItems dashboardItems={dashboardItems} />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
MyBmwRentDashboardComponent.propTypes = {
  translate: PropTypes.func
};

export default withTranslation(MyBmwRentDashboardComponent);
