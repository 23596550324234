"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newFieldProps = exports.errorMessage = exports.fieldsetProps = void 0;

var fieldsetProps = function fieldsetProps(props) {
  return {
    values: props.values,
    touched: props.touched,
    errors: props.errors,
    handleChange: props.handleChange,
    handleBlur: props.handleBlur,
    isFieldRequired: props.isFieldRequired,
    getFieldLabel: props.getFieldLabel,
    getFieldPlaceholder: props.getFieldPlaceholder,
    getSelectFieldOptions: props.getSelectFieldOptions,
    hasField: function hasField(name) {
      return name in props.values;
    }
  };
};

exports.fieldsetProps = fieldsetProps;

var errorMessage = function errorMessage(errors, touched) {
  return function (name) {
    return touched[name] && errors[name] ? errors[name] : "";
  };
};

exports.errorMessage = errorMessage;

var newFieldProps = function newFieldProps(_ref) {
  var values = _ref.values,
      errors = _ref.errors,
      touched = _ref.touched,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur;
  return function (name) {
    return {
      name: name,
      value: values[name],
      error: errorMessage(errors, touched)(name),
      onChange: handleChange,
      onBlur: handleBlur
    };
  };
};

exports.newFieldProps = newFieldProps;