import React, {Children, cloneElement} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Flag} from "../../components/flag.js";

import "./rent_object.scss";

const AIR_CONDITION = "FSD0000115";
const NAVIGATION_SYSTEM = "FSD0000116";
const SEATS = "FSD0000117";
const MINIMUM_AGE = "FSD0000118";
const CELL_PHONE_INTEGRATION = "ALG0000028";

const extraEquipmentShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string
});

export const rentObjectShape = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  costPerPeriod: PropTypes.string,
  imageUrl1: PropTypes.string,
  hasAirCondition: PropTypes.bool,
  hasNavigationSystem: PropTypes.bool,
  numberOfSeats: PropTypes.string,
  minDriverAge: PropTypes.string,
  classificationId: PropTypes.number,
  extraEquipment: PropTypes.arrayOf(extraEquipmentShape)
});

export const RentObjectNameAndPrice = ({className, rentObject: {description, costPerPeriod}}) => (
  <div className={classnames("rent-object-name-and-price", className)}>
    <div className="rent-object-name-and-price__name">
      {description}
    </div>
    <div className="rent-object-name-and-price__price">
      {costPerPeriod}
    </div>
  </div>
);
RentObjectNameAndPrice.propTypes = {
  className: PropTypes.string,
  rentObject: rentObjectShape
};

export const RentObjectBasicProperties = ({className, rentObject, selectedBasicProperties}) => (
  <ul className={classnames("rent-object-basic-properties", className)}>
    <li className="rent-object-basic-properties__item">
      <div className="rent-object-basic-properties__feature rent-object-basic-properties__feature--air-condition"><T code={AIR_CONDITION} /></div>
      <div className="rent-object-basic-properties__value"><Flag flag={rentObject.hasAirCondition} /></div>
    </li>
    <li className="rent-object-basic-properties__item">
      <div className="rent-object-basic-properties__feature rent-object-basic-properties__feature--navigation-system"><T code={NAVIGATION_SYSTEM} /></div>
      <div className="rent-object-basic-properties__value"><Flag flag={rentObject.hasNavigationSystem} /></div>
    </li>
    <li className="rent-object-basic-properties__item">
      <div className="rent-object-basic-properties__feature rent-object-basic-properties__feature--seats"><T code={SEATS} /></div>
      <div className="rent-object-basic-properties__value">{rentObject.numberOfSeats}</div>
    </li>
    {selectedBasicProperties === "B2C" && (
      <li className="rent-object-basic-properties__item">
        <div className="rent-object-basic-properties__feature rent-object-basic-properties__feature--minimum-age"><T code={MINIMUM_AGE} /></div>
        <div className="rent-object-basic-properties__value">{rentObject.minDriverAge}</div>
      </li>
    )}
    {selectedBasicProperties === "B2B" && (
      <li className="rent-object-basic-properties__item">
        <div className="rent-object-basic-properties__feature rent-object-basic-properties__feature--cell-phone-integration"><T code={CELL_PHONE_INTEGRATION} /></div>
        <div className="rent-object-basic-properties__value"><Flag flag={rentObject.hasCellPhoneIntegration} /></div>
      </li>
    )}
  </ul>
);
RentObjectBasicProperties.propTypes = {
  className: PropTypes.string,
  rentObject: rentObjectShape,
  selectedBasicProperties: PropTypes.oneOf(["B2C", "B2B"]).isRequired
};

export const ExtraEquipment = (props) => {
  const {extraEquipment} = props;

  return (
    <ul className="extra-equipment">
      {extraEquipment.map(({key, value}) => (
        typeof value === "string"
          ? <li className="extra-equipment__item" key={key}>{key}: {value}</li>
          : <li className="extra-equipment__item" key={key}>{key}</li>
      ))}
    </ul>
  );
};
ExtraEquipment.propTypes = {
  extraEquipment: PropTypes.arrayOf(extraEquipmentShape)
};

export const RentObjectActions = ({className, children}) => (
  <div className={classnames("rent-object-actions", className)}>
    {Children.map(
      children,
      child => child && cloneElement(child, {
        className: classnames(child.props.className, "rent-object-actions__button")
      }))}
  </div>
);
RentObjectActions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
