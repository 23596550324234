import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {withTranslation, Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {initialize} from "./actions.js";
import {getB2bSignUpBenefits} from "../../../user/store.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenHeading, ScreenSection, ScreenLoading} from "../../../components/screen.js";
import {B2C_SignUp} from "../../../routing/routes.js";
import {SignUpRequestForm} from "./sign_up_request_form.js";

import "./sign_up_request.scss";

const HERO_IMAGE = "ALG0000042";
const HEADING = "ALG0000043";
const INTRODUCTION = "ALG0000044";
const BENEFITS_IMAGE = "ALG0000045";
const BENEFITS_HEADING = "ALG0000046";
const WELCOME_DISCOUNT_HEADING = "ALG0000047";
const WELCOME_DISCOUNT_TEXT = "ALG0000048";
const LINK_B2C_SIGN_UP = "ALG0000214";

const SignUpRequestComponent = (props) => {
  const {benefits, initialize, translate} = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div className="b2b-sign-up-request">
      <div
        className="b2b-sign-up-request__hero-image"
        style={{backgroundImage: `url(${translate(HERO_IMAGE)})`}}
      />

      <ScreenSection>
        <TypeArea className="b2b-sign-up-request__header">
          <ScreenHeading>{translate(HEADING)}</ScreenHeading>
          <p>
            <T code={LINK_B2C_SIGN_UP} to={B2C_SignUp.location()} />
          </p>
          <p>{translate(INTRODUCTION)}</p>
        </TypeArea>

        <TypeArea>
          <Row className="b2b-sign-up-request__benefits-stage">
            <Col tablet={{cols: 4}} desktop={{cols: 6}}>
              <img className="b2b-sign-up-request__benefits-image" src={translate(BENEFITS_IMAGE)} alt="" />
            </Col>
            <Col tablet={{cols: 4}} desktop={{cols: 6}}>
              <h2 className="b2b-sign-up-request__benefits-heading">{translate(BENEFITS_HEADING)}</h2>
              <ul className="b2b-sign-up-request__benefits-list">
                {benefits.map(benefit =>
                  <li
                    key={benefit.id}
                    className="b2b-sign-up-request__benefit"
                  >
                    {benefit.description}
                  </li>
                )}
              </ul>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>

      <ScreenSection main className="b2b-sign-up-request__form">
        <TypeArea>
          <Row className="b2b-sign-up-request__form-introduction">
            <Col desktop={{cols: 8, offset: 2}}>
              <h2>{translate(WELCOME_DISCOUNT_HEADING)}</h2>
              <p>{translate(WELCOME_DISCOUNT_TEXT)}</p>
            </Col>
          </Row>

          {/* SignUpForm */}
          <SignUpRequestForm />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SignUpRequestComponent.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string
  })).isRequired,
  translate: PropTypes.func,
  initialize: PropTypes.func.isRequired
};

const stateToSignUpRequestProps = createStateToProps({
  benefits: getB2bSignUpBenefits
});

const dispatchToSignUpRequestProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToSignUpRequestProps,
  dispatchToSignUpRequestProps
)(withTranslation(SignUpRequestComponent));
