"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.getProcessedRentObjects = void 0;

var _immutable = require("immutable");

var _reselect = require("reselect");

var _number = require("../util/number.js");

function reducer(state, action) {
  switch (action.type) {
    case "RENT_OBJECTS/RENT_OBJECTS_LOADING":
      return state["delete"]("classifications")["delete"]("rentObjects");

    case "RENT_OBJECTS/RENT_OBJECTS_LOADED":
      return state.set("classifications", (0, _immutable.fromJS)(action.payload.classifications)).set("rentObjects", (0, _immutable.fromJS)(action.payload.rentObjects));

    default:
      return state;
  }
}

var getRawRentObjects = function getRawRentObjects(state) {
  return state.get("rentObjects") || (0, _immutable.List)();
};

var getRawClassifications = function getRawClassifications(state) {
  return state.get("classifications") || (0, _immutable.List)();
};

var getClassificationsById = (0, _reselect.createSelector)([getRawClassifications], function (classifications) {
  return (0, _immutable.Map)(classifications.map(function (c) {
    return [c.get("id"), c];
  }));
});

var rentObjectClassificationId = function rentObjectClassificationId(rentObject) {
  return rentObject.getIn(["classification4", "id"]);
};

var rentObjectDescription = function rentObjectDescription(rentObject) {
  return rentObject.getIn(["classification4", "wwwDescr"]) || rentObject.getIn(["classification4", "shortDescr"]);
};

var rentObjectFormattedBaseCost = function rentObjectFormattedBaseCost(rentObject) {
  return (0, _number.formatCurrency)(Number(rentObject.get("baseCost"))) || undefined;
};

var getProcessedRentObjects = (0, _reselect.createSelector)([getRawRentObjects, getClassificationsById], function (rentObjects, classifications) {
  return rentObjects // inline classification objects
  .map(function (rentObject) {
    return rentObject.set("classification4", classifications.get(rentObject.get("classific4"))).set("classification11", classifications.get(rentObject.get("classific11")));
  }) // remove rentObjects that don't have valid classific4 and classific11 references
  .filter(function (rentObject) {
    return rentObject.get("classification4") && rentObject.get("classification11");
  }) // inline classification properties
  .map(function (rentObject) {
    return rentObject.set("classificationId", rentObjectClassificationId(rentObject)).set("description", rentObjectDescription(rentObject)).set("formattedBaseCost", rentObjectFormattedBaseCost(rentObject));
  }) // remove rentObjects with incomplete data
  .filter(function (rentObject) {
    return rentObject.get("classificationId") !== undefined && rentObject.get("description") !== undefined;
  });
});
exports.getProcessedRentObjects = getProcessedRentObjects;