import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {
  loadFormConfiguration,
  setFormConfigurationValues
} from "@awe-web/shared/lib/user/actions.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {getDriver} from "../../../rent_contracts/store.js";
import {
  loadRentContractData,
  setDriver
} from "../../../rent_contracts/actions.js";
import {B2B_STR_SubmitRentContract} from "../../../routing/routes.js";

export const initialize = () => async (dispatch, getState) => {
  await dispatch(loadRentContractData({
    reservationPath: "B2B",
    additionalAction: (rentContract) => async (dispatch) => {
      await dispatch(loadFormConfiguration("personContractDriver"));

      const driver = getDriver(getState());
      if (driver) {
        dispatch(setFormConfigurationValues("personContractDriver", {
          "Person:prefixKey": driver.get("prefixKey"),
          "Person:name": driver.get("name"),
          "Person:surname": driver.get("surname"),
          "Address:email": driver.get("email"),
          "Address:phone": driver.get("phone"),
          "employeeId": driver.get("employeeId"),
          "costCenter": driver.get("costCenter")
        }));
      }
    }
  }));
};

export const submit = (formValues) => async (dispatch, getState, context) => {
  const rentContractId = getRentContractId(getState());

  await dispatch(asyncAction({
    typePrefix: "SELECT_PROVISIONING/UPDATE_RENT_CONTRACT",
    loadPayload: async () => {
      await dispatch(setDriver(rentContractId, formValues));
    }
  }));

  dispatch(navigate(B2B_STR_SubmitRentContract.location({rentContractId})));
};
