import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect, Provider} from "react-redux";
import {Router, Switch, Route as BaseRoute, Redirect} from "react-router";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {getCurrentLocale} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScrollToTop} from "@awe-web/shared/lib/routing/components/scrolling.js";
import {userRoles} from "@awe-web/shared/lib/user/constants.js";
import {getUserRole} from "../../user/store.js";
import {initialize} from "../actions.js";

import {Route} from "../../routing/components/route.js";
import {Screen, ScreenHeader, ScreenFooter} from "../../components/screen.js";
import {SidebarMenu} from "../../components/sidebar_menu.js";
import {FullScreenLoadingIndicator} from "../../components/loading_indicator.js";

// B2C Long term reservation process
import B2C_LTR_SelectRentObject from "../../screens/b2c_long_term_reservation/select_rent_object/select_rent_object.js";
import B2C_LTR_SelectExtras from "../../screens/b2c_long_term_reservation/select_extras/select_extras.js";
import B2C_LTR_SelectProvisioning from "../../screens/b2c_long_term_reservation/select_provisioning/select_provisioning.js";
import B2C_LTR_SubmitRentContract from "../../screens/b2c_long_term_reservation/submit_rent_contract/submit_rent_contract.js";
import B2C_LTR_SubmitRentContractSuccess from "../../screens/b2c_long_term_reservation/submit_rent_contract_success/submit_rent_contract_success.js";

// B2C Short term reservation process
import B2C_ReservationEntry from "../../screens/reservation_entry/reservation_entry.js";
import B2C_SelectStationAndTime from "../../screens/b2c_short_term_reservation/select_station_and_time/select_station_and_time.js";
import B2C_STR_SelectRentObject from "../../screens/b2c_short_term_reservation/select_rent_object/select_rent_object.js";
import B2C_STR_SelectExtras from "../../screens/b2c_short_term_reservation/select_extras/select_extras.js";
import B2C_STR_SubmitRentContract from "../../screens/b2c_short_term_reservation/submit_rent_contract/submit_rent_contract.js";
import B2C_STR_SubmitRentContractSuccess from "../../screens/b2c_short_term_reservation/submit_rent_contract_success/submit_rent_contract_success.js";

// B2B Long term reservation process
import B2B_SelectStationAndTime from "../../screens/b2b_select_station_and_time/select_station_and_time.js";
import B2B_LTR_SelectRentObject from "../../screens/b2b_long_term_reservation/select_rent_object/select_rent_object.js";
import B2B_LTR_SelectExtras from "../../screens/b2b_long_term_reservation/select_extras/select_extras.js";
import B2B_LTR_SelectDriverAndProvisioning from "../../screens/b2b_long_term_reservation/select_driver_and_provisioning/select_driver_and_provisioning.js";
import B2B_LTR_SubmitRentContract from "../../screens/b2b_long_term_reservation/submit_rent_contract/submit_rent_contract.js";
import B2B_LTR_SubmitRentContractSuccess from "../../screens/b2b_long_term_reservation/submit_rent_contract_success/submit_rent_contract_success.js";

// B2B Short term reservation process
import B2B_STR_SelectRentObject from "../../screens/b2b_short_term_reservation/select_rent_object/select_rent_object.js";
import B2B_STR_SelectExtras from "../../screens/b2b_short_term_reservation/select_extras/select_extras.js";
import B2B_STR_SelectDriver from "../../screens/b2b_short_term_reservation/select_driver/select_driver.js";
import B2B_STR_SubmitRentContract from "../../screens/b2b_short_term_reservation/submit_rent_contract/submit_rent_contract.js";
import B2B_STR_SubmitRentContractSuccess from "../../screens/b2b_short_term_reservation/submit_rent_contract_success/submit_rent_contract_success.js";

// B2C Sign up
import B2C_SignUp from "../../screens/b2c_user/sign_up/sign_up.js";
import B2C_SignUpSuccess from "../../screens/b2c_user/sign_up_success/sign_up_success.js";
import B2C_ConfirmSignUpEntry from "../../screens/b2c_user/confirm_sign_up_entry/confirm_sign_up_entry.js";
import B2C_SignUpConfirmed from "../../screens/b2c_user/sign_up_confirmed/sign_up_confirmed.js";
import B2C_SignUpExtended from "../../screens/b2c_user/sign_up_extended/sign_up_extended.js";

// B2B Sign up
import B2B_SignUpRequest from "../../screens/b2b_user/sign_up_request/sign_up_request.js";
import B2B_SignUpRequestSuccess from "../../screens/b2b_user/sign_up_request_success/sign_up_request_success.js";

// B2C profile
import B2C_Login from "../../screens/b2c_user/login/login.js";
import B2C_RequestResetPasswordToken from "../../screens/b2c_user/request_reset_password_token/request_reset_password_token.js";
import ResetPasswordEntry from "../../screens/reset_password_entry/reset_password_entry.js";
import MyBmwRentDashboard from "../../screens/b2c_user/my_bmw_rent_dashboard/my_bmw_rent_dashboard.js";
import MyBmwRentProfile from "../../screens/b2c_user/my_bmw_rent_profile/my_bmw_rent_profile.js";
import MyBmwRentProfileSuccess from "../../screens/b2c_user/my_bmw_rent_profile_success/my_bmw_rent_profile_success.js";
import MyBmwRentRentContracts from "../../screens/b2c_user/my_bmw_rent_rent_contracts/my_bmw_rent_rent_contracts.js";
import MyBmwRentPaymentMethods from "../../screens/b2c_user/my_bmw_rent_payment_methods/my_bmw_rent_payment_methods.js";

// B2B profile
import B2B_Login from "../../screens/b2b_user/login/login.js";
import B2B_RequestResetPasswordToken from "../../screens/b2b_user/request_reset_password_token/request_reset_password_token.js";
import B2B_Dashboard from "../../screens/b2b_user/dashboard/dashboard.js";
import B2B_Profile from "../../screens/b2b_user/profile/profile.js";
import B2B_Payment from "../../screens/b2b_user/payment/payment.js";
import B2B_History from "../../screens/b2b_user/history/history.js";

// Other
import Cms from "../../screens/cms/cms.js";

import {ErrorOverlay} from "../../errors/components/error_overlay.js";
import {ScreenFlashMessage} from "./flash_message.js";
import {Modal} from "./modal.js";
import {InitializationError} from "./initialization_error.js";
import * as routes from "../../routing/routes.js";

const CREATE_RENT_CONTRACT = "FSD0000360";
const UPDATE_RENT_CONTRACT = "FSD0000361";
const SUBMIT_RENT_CONTRACT = "FSD0000363";
const UPDATE_USER_PROFILE = "FSD0000364";
const CANCEL_RENT_CONTRACT = "FSD0000365";
const DELETE_CREDIT_CARD = "FSD0000366";
const SIGN_UP = "FSD0000367";
const EXTEND_SIGN_UP = "FSD0000368";
const LOGIN = "FSD0000369";
const RESET_PASSWORD = "FSD0000370";

const routeToComponent = (route, component) =>
  <Route path={route.path()} component={component} />;

const AppContentComponent = (props) => {
  const {history, locale, userRole} = props;

  const rootRoute = userRole === userRoles.B2B
    ? routes.B2B_SelectStationAndTime
    : routes.B2C_SelectStationAndTime;

  return (
    <Fragment key={locale}>
      <Router history={history}>
        <ScrollToTop />
        <Screen>
          <ScreenHeader />
          <div id={SidebarMenu.outerContainerId}>
            <SidebarMenu />
            <div id={SidebarMenu.pageWrapId}>
              <ScreenFlashMessage />

              <Switch>
                {routeToComponent(routes.B2C_ReservationEntry, B2C_ReservationEntry)}
                {routeToComponent(routes.B2C_SelectStationAndTime, B2C_SelectStationAndTime)}
                {routeToComponent(routes.B2C_LTR_SelectRentObject, B2C_LTR_SelectRentObject)}
                {routeToComponent(routes.B2C_LTR_SelectExtras, B2C_LTR_SelectExtras)}
                {routeToComponent(routes.B2C_LTR_SelectProvisioning, B2C_LTR_SelectProvisioning)}
                {routeToComponent(routes.B2C_LTR_SubmitRentContract, B2C_LTR_SubmitRentContract)}
                {routeToComponent(routes.B2C_LTR_SubmitRentContractSuccess, B2C_LTR_SubmitRentContractSuccess)}

                {routeToComponent(routes.B2C_STR_SelectRentObject, B2C_STR_SelectRentObject)}
                {routeToComponent(routes.B2C_STR_SelectExtras, B2C_STR_SelectExtras)}
                {routeToComponent(routes.B2C_STR_SubmitRentContract, B2C_STR_SubmitRentContract)}
                {routeToComponent(routes.B2C_STR_SubmitRentContractSuccess, B2C_STR_SubmitRentContractSuccess)}

                {routeToComponent(routes.B2B_SelectStationAndTime, B2B_SelectStationAndTime)}
                {routeToComponent(routes.B2B_LTR_SelectRentObject, B2B_LTR_SelectRentObject)}
                {routeToComponent(routes.B2B_LTR_SelectExtras, B2B_LTR_SelectExtras)}
                {routeToComponent(routes.B2B_LTR_SelectExtrasConfirm, B2B_LTR_SelectExtras)}
                {routeToComponent(routes.B2B_LTR_SelectDriverAndProvisioning, B2B_LTR_SelectDriverAndProvisioning)}
                {routeToComponent(routes.B2B_LTR_SubmitRentContract, B2B_LTR_SubmitRentContract)}
                {routeToComponent(routes.B2B_LTR_SubmitRentContractSuccess, B2B_LTR_SubmitRentContractSuccess)}

                {routeToComponent(routes.B2B_STR_SelectRentObject, B2B_STR_SelectRentObject)}
                {routeToComponent(routes.B2B_STR_SelectExtras, B2B_STR_SelectExtras)}
                {routeToComponent(routes.B2B_STR_SelectExtrasConfirm, B2B_STR_SelectExtras)}
                {routeToComponent(routes.B2B_STR_SelectDriver, B2B_STR_SelectDriver)}
                {routeToComponent(routes.B2B_STR_SubmitRentContract, B2B_STR_SubmitRentContract)}
                {routeToComponent(routes.B2B_STR_SubmitRentContractSuccess, B2B_STR_SubmitRentContractSuccess)}

                {routeToComponent(routes.B2C_SignUp, B2C_SignUp)}
                {routeToComponent(routes.B2C_SignUpSuccess, B2C_SignUpSuccess)}
                {routeToComponent(routes.B2C_ConfirmSignUpEntry, B2C_ConfirmSignUpEntry)}
                {routeToComponent(routes.B2C_SignUpConfirmed, B2C_SignUpConfirmed)}
                {routeToComponent(routes.B2C_SignUpExtended, B2C_SignUpExtended)}

                {routeToComponent(routes.B2B_SignUpRequest, B2B_SignUpRequest)}
                {routeToComponent(routes.B2B_SignUpRequestSuccess, B2B_SignUpRequestSuccess)}

                {routeToComponent(routes.B2C_Login, B2C_Login)}
                {routeToComponent(routes.B2C_RequestResetPasswordToken, B2C_RequestResetPasswordToken)}
                {routeToComponent(routes.ResetPasswordEntry, ResetPasswordEntry)}
                {routeToComponent(routes.MyBmwRentDashboard, MyBmwRentDashboard)}
                {routeToComponent(routes.MyBmwRentProfile, MyBmwRentProfile)}
                {routeToComponent(routes.MyBmwRentProfileSuccess, MyBmwRentProfileSuccess)}
                {routeToComponent(routes.MyBmwRentRentContracts, MyBmwRentRentContracts)}
                {routeToComponent(routes.MyBmwRentPaymentMethods, MyBmwRentPaymentMethods)}

                {routeToComponent(routes.B2B_Login, B2B_Login)}
                {routeToComponent(routes.B2B_RequestResetPasswordToken, B2B_RequestResetPasswordToken)}
                {routeToComponent(routes.B2B_Dashboard, B2B_Dashboard)}
                {routeToComponent(routes.B2B_Profile, B2B_Profile)}
                {routeToComponent(routes.B2B_Payment, B2B_Payment)}
                {routeToComponent(routes.B2B_History, B2B_History)}

                {routeToComponent(routes.Cms, Cms)}

                <BaseRoute exact path="/" render={() => <Redirect to={rootRoute.path()} />} />
              </Switch>
              <ScreenFooter />
            </div>
          </div>
        </Screen>
      </Router>
      <ErrorOverlay />
      <Modal />
      <FullScreenLoadingIndicator
        blockingActions={{
          "SELECT_RENT_OBJECT/CREATE_RENT_CONTRACT": <T code={CREATE_RENT_CONTRACT} />,
          "SELECT_EXTRAS/UPDATE_RENT_CONTRACT": <T code={UPDATE_RENT_CONTRACT} />,
          "SELECT_PROVISIONING/UPDATE_RENT_CONTRACT": <T code={UPDATE_RENT_CONTRACT} />,
          "RENT_CONTRACTS/SUBMIT_RENT_CONTRACT": <T code={SUBMIT_RENT_CONTRACT} />,
          "USER/UPDATE_PROFILE": <T code={UPDATE_USER_PROFILE} />,
          "HISTORY/CANCEL_RENT_CONTRACT": <T code={CANCEL_RENT_CONTRACT} />,
          "USER/DELETE_CREDIT_CARD": <T code={DELETE_CREDIT_CARD} />,
          "USER/SIGN_UP": <T code={SIGN_UP} />,
          "USER/EXTEND_SIGN_UP": <T code={EXTEND_SIGN_UP} />,
          "USER/LOGIN": <T code={LOGIN} />,
          "USER/RESET_PASSWORD": <T code={RESET_PASSWORD} />
        }} />
    </Fragment>
  );
};
AppContentComponent.propTypes = {
  history: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  userRole: PropTypes.oneOf([
    userRoles.ANONYMOUS,
    userRoles.B2C,
    userRoles.B2B
  ])
};

const stateToAppContentProps = createStateToProps({
  locale: getCurrentLocale,
  userRole: getUserRole
});

const AppContent = connect(stateToAppContentProps)(AppContentComponent);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: "LOADING"
    };
    this.isUnmounted = false;
  }

  async componentDidMount() {
    try {
      await this.props.store.dispatch(initialize());
      if (!this.isUnmounted) {
        this.setState(() => ({state: "READY"}));
      }
    }
    catch (error) {
      if (!this.isUnmounted) {
        this.setState(() => ({state: "ERROR"}));
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {state} = this.state;
    const {store, history} = this.props;

    if (state === "READY") {
      return (
        <Provider store={store}>
          <AppContent history={history} />
        </Provider>
      );
    }
    else if (state === "ERROR") {
      return <InitializationError />;
    }
    else {
      return null;
    }
  }
}
App.propTypes = {
  store: PropTypes.object.isRequired,
  history: AppContentComponent.propTypes.history
};

export default App;
