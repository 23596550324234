import React, {useRef, useCallback} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  ScreenSection,
  ScreenHeadingWithSupplement,
  ScreenSectionHeading,
  ScreenLoading
} from "../../../components/screen.js";
import {Filter} from "../../../components/filter.js";
import {TypeArea} from "../../../components/layout.js";
import {rentObjectShape} from "../../../rent_objects/components/rent_object.js";
import {RentObjectCardList} from "../../../rent_objects/components/rent_object_card.js";
import {SelectedStationAndTime} from "../../../stations/components/selected_station_and_time.js";
import {getCoDateTime, getCiDateTime, getCoStationId, getCiStationId} from "../../../stations/store.js";
import {getGroupedRentObjectsB2B, getRentObjectGroupsB2B} from "../../../rent_objects/store.js";
import {initialize, selectRentObject} from "./actions.js";

import "./select_rent_object.scss";

const SELECT_RENT_OBJECT = "FSD0000114";
const LTR_NOTICE = "ALG0000263";
const PRICES_NOTICE = "ALG0000029";

const SelectRentObject = (props) => {
  const {
    initialize,
    selectRentObject,
    location,
    coStationId,
    ciStationId,
    coDateTime,
    ciDateTime,
    rentObjectGroups,
    groupedRentObjects
  } = props;

  const rentObjectCardListRef = useRef();
  const handleFilterSelect = useCallback((event) => {
    const {target: {value}} = event;
    rentObjectCardListRef.current.scrollToCard(value);
  }, []);

  const selectRentObjectRef = useRef();
  selectRentObjectRef.current = selectRentObject;
  const submit = useCallback((classificationId) => selectRentObjectRef.current({
    coStationId,
    ciStationId,
    coDateTime,
    ciDateTime,
    classificationId
  }), [coStationId, ciStationId, coDateTime, ciDateTime]);

  const isReady = useInitialize(initialize, [location]);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div className="b2b-ltr-select-rent-object">
      <ScreenSection dark>
        <TypeArea>
          <SelectedStationAndTime
            reservationPath="B2B"
            withEditButton={true}
          />
        </TypeArea>
      </ScreenSection>

      <ScreenSection main light>
        <TypeArea>
          <ScreenHeadingWithSupplement heading={<T code={SELECT_RENT_OBJECT} />}>
            <Filter rentObjectGroups={rentObjectGroups} onSelect={handleFilterSelect} />
          </ScreenHeadingWithSupplement>

          <ScreenSectionHeading light>
            <T code={LTR_NOTICE} />
          </ScreenSectionHeading>

          <RentObjectCardList
            ref={rentObjectCardListRef}
            groupedRentObjects={groupedRentObjects}
            selectedBasicProperties="B2B"
            selectRentObject={submit}
          />

          <div className="b2b-ltr-select-rent-object__prices-notice">
            <T code={PRICES_NOTICE} />
          </div>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SelectRentObject.propTypes = {
  coStationId: PropTypes.number,
  ciStationId: PropTypes.number,
  coDateTime: PropTypes.instanceOf(Date),
  ciDateTime: PropTypes.instanceOf(Date),
  groupedRentObjects: PropTypes.objectOf(PropTypes.arrayOf(rentObjectShape)),
  rentObjectGroups: Filter.propTypes.rentObjectGroups,
  initialize: PropTypes.func.isRequired,
  selectRentObject: PropTypes.func.isRequired
};

const stateToSelectRentObjectProps = createStateToProps({
  coStationId: getCoStationId,
  ciStationId: getCiStationId,
  coDateTime: getCoDateTime,
  ciDateTime: getCiDateTime,
  groupedRentObjects: getGroupedRentObjectsB2B,
  rentObjectGroups: getRentObjectGroupsB2B
});

const dispatchToSelectRentObjectProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  selectRentObject: ({coStationId, ciStationId, coDateTime, ciDateTime, classificationId}) =>
    dispatch(selectRentObject({coStationId, ciStationId, coDateTime, ciDateTime, classificationId}))
});

export default connect(stateToSelectRentObjectProps, dispatchToSelectRentObjectProps)(SelectRentObject);
