import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {MyBmwRentProfileForm} from "./my_bmw_rent_profile_form.js";
import {Row, Col} from "../../../components/grid.js";
import {TypeArea} from "../../../components/layout.js";
import {InlineLoadingIndicator} from "../../../components/loading_indicator.js";
import {initialize} from "./actions.js";

const MY_PROFILE = "FSD0000305";
const PROFILE_LOADING = "FSD0000359";

const MyBmwRentProfileComponent = (props) => {
  const {initialize} = props;

  useInitialize(initialize);

  return (
    <div className="my-bmw-rent-profile">
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading>
                <T code={MY_PROFILE} />
              </ScreenHeading>
            </Col>
          </Row>

          <InlineLoadingIndicator
            typePrefix="USER/FORM_CONFIGURATION"
            message={<T code={PROFILE_LOADING} />}
          >
            <MyBmwRentProfileForm />
          </InlineLoadingIndicator>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
MyBmwRentProfileComponent.propTypes = {
  initialize: PropTypes.func
};

const stateToMyBmwRentProfileProps = createStateToProps({
});

const dispatchToMyBmwRentProfileProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToMyBmwRentProfileProps,
  dispatchToMyBmwRentProfileProps
)(MyBmwRentProfileComponent);
