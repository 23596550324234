"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isStationOpenAtDateBeforeGivenTime = exports.isStationOpenAtDateAfterGivenTime = exports.isStationOpen = exports.isStationOpenAtDate = exports.applyOpeningTime = exports.stationToOpeningTimes = exports.stationToMaxBookingPeriodRule = exports.stationToMinBookingPeriodRule = exports.stationToMaxLeadTimeRule = exports.stationToMinLeadTimeRule = exports.findStation = void 0;

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _immutable = require("immutable");

var _date = require("../util/date.js");

var _time = require("../util/time.js");

var _constants = require("./constants.js");

var MIN_LEAD_TIME = _constants.bookingRuleTypes.MIN_LEAD_TIME,
    MAX_LEAD_TIME = _constants.bookingRuleTypes.MAX_LEAD_TIME,
    MIN_BOOKING_PERIOD = _constants.bookingRuleTypes.MIN_BOOKING_PERIOD,
    MAX_BOOKING_PERIOD = _constants.bookingRuleTypes.MAX_BOOKING_PERIOD; // When a station has no opening times (or no station is selected) we want to
// limit the time selection to values between 06:00—20:00.

var defaultOpeningTime = (0, _immutable.fromJS)({
  type: _constants.openingTimeTypes.OPEN,
  fromTime: (0, _time.isoStringToTime)("1900-01-01T06:00"),
  tillTime: (0, _time.isoStringToTime)("1900-01-01T20:00")
}); // Setting the minBookingPeriod to 1 minute is necessary in order to correctly
// disable a day in the CI calendar when the last possible time slot is selected
// as CO dateTime.

var defaultMinBookingPeriod = (0, _immutable.fromJS)({
  type: MIN_BOOKING_PERIOD,
  valueInMinutes: 1
});

var findStation = function findStation(stations, stationId) {
  return stations.find(function (station) {
    return station.get("id") === stationId;
  });
};

exports.findStation = findStation;

var stationToBookingRule = function stationToBookingRule(type, defaultRule) {
  return function (station) {
    return (station || (0, _immutable.Map)()).get("bookingRules", (0, _immutable.List)()).find(function (rule) {
      return rule.get("type") === type;
    }) || defaultRule;
  };
};

var stationToMinLeadTimeRule = stationToBookingRule(MIN_LEAD_TIME);
exports.stationToMinLeadTimeRule = stationToMinLeadTimeRule;
var stationToMaxLeadTimeRule = stationToBookingRule(MAX_LEAD_TIME);
exports.stationToMaxLeadTimeRule = stationToMaxLeadTimeRule;
var stationToMinBookingPeriodRule = stationToBookingRule(MIN_BOOKING_PERIOD, defaultMinBookingPeriod);
exports.stationToMinBookingPeriodRule = stationToMinBookingPeriodRule;
var stationToMaxBookingPeriodRule = stationToBookingRule(MAX_BOOKING_PERIOD);
exports.stationToMaxBookingPeriodRule = stationToMaxBookingPeriodRule;

var stationToOpeningTimes = function stationToOpeningTimes(station) {
  var openingTimes = (station || (0, _immutable.Map)()).get("openingTimes");
  return openingTimes && openingTimes.size > 0 ? openingTimes : (0, _immutable.List)([defaultOpeningTime]);
};

exports.stationToOpeningTimes = stationToOpeningTimes;

var appliesToWeekday = function appliesToWeekday(dateTime) {
  return function (openingTime) {
    var weekdayFrom = openingTime.get("weekdayFrom");
    var weekdayTill = openingTime.get("weekdayTill");
    var weekday = dateTime.getDay() + 1;
    return weekdayFrom && weekdayTill ? weekday >= weekdayFrom && weekday <= weekdayTill : true;
  };
};

var appliesToDateRange = function appliesToDateRange(dateTime) {
  return function (openingTime) {
    var fromDate = openingTime.get("fromDate");
    var tillDate = openingTime.get("tillDate");
    return !fromDate || !tillDate || (0, _date.isDayInRange)(dateTime, fromDate, tillDate);
  };
};

var minTime = function minTime(t1, t2) {
  return (0, _time.isTimeSameOrBefore)(t1, t2) ? t1 : t2;
};

var maxTime = function maxTime(t1, t2) {
  return (0, _time.isTimeSameOrAfter)(t1, t2) ? t1 : t2;
};

var hasOverlap = function hasOverlap(_ref, _ref2) {
  var _ref3 = (0, _slicedToArray2["default"])(_ref, 2),
      start1 = _ref3[0],
      end1 = _ref3[1];

  var _ref4 = (0, _slicedToArray2["default"])(_ref2, 2),
      start2 = _ref4[0],
      end2 = _ref4[1];

  return (0, _time.isTimeSameOrBefore)(start1, start2) && (0, _time.isTimeSameOrAfter)(end1, start2) || (0, _time.isTimeSameOrBefore)(start2, start1) && (0, _time.isTimeSameOrAfter)(end2, start1);
};

var isContainedWithin = function isContainedWithin(_ref5, _ref6) {
  var _ref7 = (0, _slicedToArray2["default"])(_ref5, 2),
      start1 = _ref7[0],
      end1 = _ref7[1];

  var _ref8 = (0, _slicedToArray2["default"])(_ref6, 2),
      start2 = _ref8[0],
      end2 = _ref8[1];

  return (0, _time.isTimeSameOrAfter)(start1, start2) && (0, _time.isTimeSameOrBefore)(end1, end2);
};

var sortRangesByStartTime = function sortRangesByStartTime(_ref9, _ref10) {
  var _ref11 = (0, _slicedToArray2["default"])(_ref9, 1),
      start1 = _ref11[0];

  var _ref12 = (0, _slicedToArray2["default"])(_ref10, 1),
      start2 = _ref12[0];

  return (0, _time.isTimeSame)(start1, start2) ? 0 : (0, _time.isTimeSameOrBefore)(start1, start2) ? -1 :
  /* else */
  1;
};

var mergeRanges = function mergeRanges(_ref13, _ref14) {
  var _ref15 = (0, _slicedToArray2["default"])(_ref13, 2),
      start1 = _ref15[0],
      end1 = _ref15[1];

  var _ref16 = (0, _slicedToArray2["default"])(_ref14, 2),
      start2 = _ref16[0],
      end2 = _ref16[1];

  return [minTime(start1, start2), maxTime(end1, end2)];
};

var normalizeRanges = function normalizeRanges(ranges) {
  return (0, _toConsumableArray2["default"])(ranges) // make a copy b/c `sort` is destructive
  .sort(sortRangesByStartTime).reduce(function (normalizedRanges, range) {
    var _normalizedRanges = (0, _toArray2["default"])(normalizedRanges),
        last = _normalizedRanges[0],
        rest = _normalizedRanges.slice(1);

    if (!last) {
      return [range];
    } else if (hasOverlap(range, last)) {
      return [mergeRanges(range, last)].concat((0, _toConsumableArray2["default"])(rest));
    } else {
      return [range, last].concat((0, _toConsumableArray2["default"])(rest));
    }
  }, []).reverse();
};

var subtractRange = function subtractRange(ranges, rangeToSubtract) {
  return normalizeRanges(ranges.reduce(function (acc, range) {
    if (!hasOverlap(range, rangeToSubtract)) {
      return [].concat((0, _toConsumableArray2["default"])(acc), [range]);
    } else if (isContainedWithin(range, rangeToSubtract)) {
      return acc;
    } else if (isContainedWithin(rangeToSubtract, range)) {
      return [].concat((0, _toConsumableArray2["default"])(acc), [[range[0], rangeToSubtract[0]], [rangeToSubtract[1], range[1]]]);
    } else {
      return (0, _time.isTimeSameOrBefore)(range[0], rangeToSubtract[0]) ? [].concat((0, _toConsumableArray2["default"])(acc), [[range[0], rangeToSubtract[0]]]) : [].concat((0, _toConsumableArray2["default"])(acc), [[rangeToSubtract[1], range[1]]]);
    }
  }, []));
};

var applyOpeningTime = function applyOpeningTime(timeRanges, openingTime) {
  var fromTime = openingTime.get("fromTime");
  var tillTime = openingTime.get("tillTime");
  var range = [fromTime ? fromTime.toJS() : (0, _time.isoStringToTime)("1900-01-01T00:00Z"), tillTime ? tillTime.toJS() : (0, _time.isoStringToTime)("1900-01-01T24:00Z")];

  switch (openingTime.get("type")) {
    case _constants.openingTimeTypes.OPEN:
      return normalizeRanges([].concat((0, _toConsumableArray2["default"])(timeRanges), [range]));

    case _constants.openingTimeTypes.CLOSED:
      return subtractRange(timeRanges, range);

    default:
      return timeRanges;
  }
};

exports.applyOpeningTime = applyOpeningTime;

var isStationOpenAtDate = function isStationOpenAtDate(openingTimes, date) {
  if (!openingTimes || openingTimes.size === 0) {
    return true;
  }

  var timeRanges = openingTimes.sort(function (a, b) {
    if (a.get("type") === b.get("type")) {
      return 0;
    } // Sort items so that 'OPEN' entries come before 'CLOSED'


    return a.get("type") === _constants.openingTimeTypes.CLOSED ? 1 : -1;
  }).filter(appliesToWeekday(date)).filter(appliesToDateRange(date)).reduce(applyOpeningTime, []);
  return timeRanges.length > 0;
};

exports.isStationOpenAtDate = isStationOpenAtDate;

var isStationOpen = function isStationOpen(openingTimes, dateTime) {
  if (!openingTimes || openingTimes.size === 0) {
    return true;
  }

  var timeRanges = openingTimes.filter(appliesToWeekday(dateTime)).filter(appliesToDateRange(dateTime)).reduce(applyOpeningTime, []);
  return timeRanges.some(function (_ref17) {
    var _ref18 = (0, _slicedToArray2["default"])(_ref17, 2),
        start = _ref18[0],
        end = _ref18[1];

    return (0, _time.isTimeInRange)(dateTime, start, end);
  });
};

exports.isStationOpen = isStationOpen;

var isStationOpenAtDateAfterGivenTime = function isStationOpenAtDateAfterGivenTime(openingTimes, dateTime) {
  if (openingTimes === undefined || openingTimes.size === 0) {
    return true;
  }

  var timeRanges = openingTimes.filter(appliesToWeekday(dateTime)).filter(appliesToDateRange(dateTime)).reduce(applyOpeningTime, []);
  return timeRanges.some(function (_ref19) {
    var _ref20 = (0, _slicedToArray2["default"])(_ref19, 2),
        start = _ref20[0],
        end = _ref20[1];

    return (0, _time.isTimeSameOrBefore)((0, _time.dateTimeToTime)(dateTime), end);
  });
};

exports.isStationOpenAtDateAfterGivenTime = isStationOpenAtDateAfterGivenTime;

var isStationOpenAtDateBeforeGivenTime = function isStationOpenAtDateBeforeGivenTime(openingTimes, dateTime) {
  if (openingTimes === undefined || openingTimes.size === 0) {
    return true;
  }

  var timeRanges = openingTimes.filter(appliesToWeekday(dateTime)).filter(appliesToDateRange(dateTime)).reduce(applyOpeningTime, []);
  return timeRanges.some(function (_ref21) {
    var _ref22 = (0, _slicedToArray2["default"])(_ref21, 2),
        start = _ref22[0],
        end = _ref22[1];

    return (0, _time.isTimeSameOrAfter)((0, _time.dateTimeToTime)(dateTime), start);
  });
};

exports.isStationOpenAtDateBeforeGivenTime = isStationOpenAtDateBeforeGivenTime;