import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {ScreenLoading} from "../../../components/screen.js";
import {initialize} from "./actions.js";

const ConfirmSignUpEntryComponent = (props) => {
  const {initialize} = props;

  useInitialize(initialize);

  return <ScreenLoading />;
};
ConfirmSignUpEntryComponent.propTypes = {
  initialize: PropTypes.func
};

const stateToConfirmSignUpEntryProps = createStateToProps({
});

const dispatchToConfirmSignUpEntryProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToConfirmSignUpEntryProps,
  dispatchToConfirmSignUpEntryProps
)(ConfirmSignUpEntryComponent);
