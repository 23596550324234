import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {getRentContract} from "@awe-web/shared/lib/rent_contracts/store.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {getLoggedIn} from "@awe-web/shared/lib/user/store.js";
import {
  loadTariffOptions,
  loadRentContractData,
  setTariffOptionId,
  setDeductibleOption,
  setRentObjectProperties
} from "../../../rent_contracts/actions.js";
import {
  B2B_LTR_SelectDriverAndProvisioning,
  B2B_LTR_SelectExtrasConfirm
} from "../../../routing/routes.js";

export const initialize = () => async (dispatch, getState, context) => {
  await dispatch(loadRentContractData({
    reservationPath: "B2B",
    additionalAction: (rentContract) => async (dispatch) => {
      await dispatch(loadTariffOptions(rentContract.id));
    }
  }));
};

export const submit = (formValues) => async (dispatch, getState) => {
  const rentContract = getRentContract(getState());
  const rentContractJs = rentContract.toJS();
  const rentContractId = rentContract.get("id");
  const {
    mileage: tariffOptionId,
    deductible: deductibleId,
    equipment: rentObjectPropertiesMap
  } = formValues;

  await dispatch(asyncAction({
    typePrefix: "SELECT_EXTRAS/UPDATE_RENT_CONTRACT",
    loadPayload: async () => {
      // rentcontract/update cannot be performed in parallel
      await dispatch(setTariffOptionId(rentContractId, tariffOptionId));
      await Promise.all([
        dispatch(setDeductibleOption(rentContractJs, deductibleId)),
        dispatch(setRentObjectProperties(rentContractJs, rentObjectPropertiesMap))
      ]);
    }
  }));

  const nextRoute = getLoggedIn(getState())
    ? B2B_LTR_SelectDriverAndProvisioning
    : B2B_LTR_SelectExtrasConfirm;
  dispatch(navigate(nextRoute.location({rentContractId})));
};
