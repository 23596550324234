import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {MenuLink} from "@awe-web/shared/lib/components/navigation.js";

import {TypeArea} from "./layout.js";
import {Brand} from "./brand.js";
import {MenuButton} from "./sidebar_menu.js";
import {UserNavigation} from "./user_navigation.js";
import {AppNavigation} from "./app_navigation.js";
import {LoadingSpinner} from "./loading_indicator.js";
import {LanguageSwitch} from "../l10n/components/language_switch.js";
import {Cms} from "../routing/routes.js";
import {setCurrentHeaderHeight} from "../dom/actions.js";
import {getCurrentHeaderHeight} from "../dom/store.js";
import {getCurrentViewportWidth, getCurrentScrollPosition} from "../window/store.js";
import {getFooterLinks} from "../l10n/store.js";
import {getStartScreenPath} from "../user/store.js";

import "./screen.scss";

export const Screen = (props) => {
  const {children} = props;
  const className = classnames("screen", props.className);

  return (
    <div className={className}>{children}</div>
  );
};
Screen.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

class ScreenHeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: false
    };

    this.headerRef = createRef();
    this.getHeaderHeight = () => this.headerRef.current.offsetHeight;
  }

  componentDidMount() {
    this.props.setCurrentHeaderHeight(this.getHeaderHeight());
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      headerHeight,
      scrollPosition,
      viewportWidth,
      setCurrentHeaderHeight
    } = this.props;
    const {isCollapsed} = this.state;

    const SCROLL_TRESHOLD = 200;
    const viewportChanged = viewportWidth !== prevProps.viewportWidth;
    const scrollPositionChanged = scrollPosition !== prevProps.scrollPosition;
    const headerHeightChanged = isCollapsed !== prevState.isCollapsed;

    if (viewportChanged) {
      setCurrentHeaderHeight(this.getHeaderHeight());
    }

    if (scrollPositionChanged) {
      this.setState({isCollapsed: scrollPosition >= SCROLL_TRESHOLD});
    }
    else if (headerHeightChanged) {
      if (scrollPosition > SCROLL_TRESHOLD + headerHeight) {
        setCurrentHeaderHeight(this.getHeaderHeight());
      }
    }
  }

  render() {
    const {headerHeight} = this.props;
    const {isCollapsed} = this.state;
    const className = classnames("screen-header", this.props.className);

    return (
      <header className={className}>
        <div ref={this.headerRef} className="screen-header__content">
          <TypeArea className="screen-header__main">
            <Brand className="screen-header__brand" />
            <UserNavigation className="screen-header__user-navigation" />
            <LanguageSwitch className="screen-header__language-switch" />
            <MenuButton className="screen-header__menu-button" />
          </TypeArea>

          <TypeArea>
            <AppNavigation collapsed={isCollapsed} className="screen-header__app-navigation" />
          </TypeArea>
        </div>

        <div className="screen-header__padding" style={{paddingTop: headerHeight}} />
      </header>
    );
  }
}
ScreenHeaderComponent.propTypes = {
  className: PropTypes.string
};

const screenHeaderStateToProps = createStateToProps({
  viewportWidth: getCurrentViewportWidth,
  scrollPosition: getCurrentScrollPosition,
  headerHeight: getCurrentHeaderHeight
});

const screeenHeaderDispatchToProps = (dispatch) => ({
  setCurrentHeaderHeight: (height) => dispatch(setCurrentHeaderHeight(height))
});

export const ScreenHeader = withRouter(connect(screenHeaderStateToProps, screeenHeaderDispatchToProps)(ScreenHeaderComponent));

export const ScreenSection = (props) => {
  const {children, main, light, dark} = props;
  const className = classnames("screen-section", props.className, {
    "screen-section--light": light,
    "screen-section--dark": dark
  });
  const Tag = main ? "main" : "section";

  return (
    <Tag className={className}>{children}</Tag>
  );
};
ScreenSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  main: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool
};

export const ScreenSubsection = (props) => {
  const {children} = props;
  const className = classnames("screen-subsection", props.className);

  return (
    <div className={className}>{children}</div>
  );
};
ScreenSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

const BOOK_RENT_OBJECT = "FSD0000137";
const LEGAL_NOTICE = "FSD0000145";
const IMPRESSUM = "FSD0000146";
const PRIVACY = "FSD0000147";
const COOKIES = "FSD0000148";

const ScreenFooterComponent = (props) => {
  const {footerLinks, startScreenPath} = props;
  const className = classnames("screen-footer", props.className);

  return (
    <footer className={className}>
      <nav className="screen-footer__content">
        <div className="screen-footer__promotional">
          <TypeArea className="screen-footer__promotional__content">
            <ul className="screen-footer__promotional__area">
              <li>
                <Link
                  className="screen-footer__promotional__link"
                  to={startScreenPath}
                >
                  <T code={BOOK_RENT_OBJECT} />
                </Link>
              </li>
              {footerLinks.map((link) => (
                <li key={link.id}>
                  <MenuLink
                    className="screen-footer__promotional__link"
                    pageName={link.pageName}
                    url={link.url}
                  >
                    {link.text}
                  </MenuLink>
                </li>
              ))}
            </ul>
          </TypeArea>
        </div>

        <div className="screen-footer__compliance">
          <TypeArea className="screen-footer__compliance__content">
            <ul className="screen-footer__compliance__area">
              <li><Link className="screen-footer__compliance__link" to={Cms.location({pageName: "legalnotice"})}><T code={LEGAL_NOTICE} /></Link></li>
              <li><Link className="screen-footer__compliance__link" to={Cms.location({pageName: "imprint"})}><T code={IMPRESSUM} /></Link></li>
              <li><Link className="screen-footer__compliance__link" to={Cms.location({pageName: "privacy"})}><T code={PRIVACY} /></Link></li>
              <li><Link className="screen-footer__compliance__link" to={Cms.location({pageName: "cookies"})}><T code={COOKIES} /></Link></li>
            </ul>
          </TypeArea>
        </div>
      </nav>
    </footer>
  );
};
ScreenFooterComponent.propTypes = {
  className: PropTypes.string,
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: MenuLink.propTypes.text,
    pageName: MenuLink.propTypes.pageName,
    url: MenuLink.propTypes.url
  })),
  startScreenPath: Link.propTypes.to
};

const stateToScreenFooterProps = createStateToProps({
  footerLinks: getFooterLinks,
  // getStartScreenPath selector requires withRouter for access to
  // location prop
  startScreenPath: getStartScreenPath
});

export const ScreenFooter = withRouter(connect(stateToScreenFooterProps)(ScreenFooterComponent));

export const ScreenHeading = (props) => {
  const {children} = props;
  const className = classnames("screen-heading", props.className);

  return (
    <h1 className={className}>{children}</h1>
  );
};
ScreenHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const ScreenHeadingWithSupplement = (props) => {
  const {children, heading} = props;
  const className = classnames("screen-heading-with-supplement", props.className);

  return (
    <div className={className}>
      <ScreenHeading className="screen-heading-with-supplement__heading">
        {heading}
      </ScreenHeading>

      {children}
    </div>
  );
};
ScreenHeadingWithSupplement.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.node
};

export const ScreenSectionHeading = (props) => {
  const {children, light} = props;
  const className = classnames("screen-section-heading", props.className, {
    "screen-section-heading--light": light
  });

  return (
    <h2 className={className}>{children}</h2>
  );
};
ScreenSectionHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  light: PropTypes.bool
};

export const ScreenSectionSubheading = (props) => {
  const {children} = props;
  const className = classnames("screen-section-subheading", props.className);

  return (
    <h3 className={className}>{children}</h3>
  );
};
ScreenSectionSubheading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const ScreenLoading = (props) => {
  return (
    <LoadingSpinner
      className="screen-loading"
    />
  );
};
ScreenLoading.propTypes = {
};
