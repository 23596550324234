import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {loadFormConfiguration, requestSignUp} from "@awe-web/shared/lib/user/actions.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {B2B_SignUpRequestSuccess} from "../../../routing/routes.js";

export const initialize = () => async (dispatch) => {
  await dispatch(loadFormConfiguration("registerApplicant"));
};

export const submit = (formValues) => async (dispatch, getState, context) => {
  const mapFormValues = newMapFormValues("registerApplicant")(getState());
  const normalizedValues = mapFormValues(formValues);
  await dispatch(requestSignUp(normalizedValues));
  dispatch(navigate(B2B_SignUpRequestSuccess.location({email: formValues["Person:email"]})));
};
