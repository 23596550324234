import React, {useState} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import {Translation as T, withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  Button,
  RequiredNotice,
  ScrollToError,
  FormikLabeledField,
  FormikTextInput
} from "../../components/form.js";

import "./request_reset_password_token_form.scss";

const INTRODUCTION = "FSD0000098";
const LABEL_EMAIL = "FSD0000099";
const PLACEHOLDER_EMAIL = "FSD0000100";
const BUTTON_SUBMIT = "FSD0000101";
const BUTTON_CANCEL = "FSD0000102";
const NOTICE = "FSD0000209";
const ERROR_ENTER_LOGIN = "FSD0000262";
const SUCCESS_MESSAGE = "FSD0000260";
const RESEND_EMAIL = "ALG0000164";

const validate = translate => values => {
  const errors = {};

  if (!values.login) {
    errors.login = translate(ERROR_ENTER_LOGIN);
  }

  return errors;
};

const Form = withTranslation((props) => {
  const {translate, backlinkLocation, handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError />
      <div className="request-reset-password-token-form__fields">
        <FormikLabeledField
          className="request-reset-password-token-form__labeled-field"
          name="login"
          as={FormikTextInput}
          getFieldLabel={() => translate(LABEL_EMAIL)}
          isFieldRequired={() => true}
          email={true}
          placeholder={translate(PLACEHOLDER_EMAIL)}
        />
      </div>

      <Button
        primary
        continue
        submit
        className="request-reset-password-token-form__submit-button"
      >
        <T code={BUTTON_SUBMIT} />
      </Button>

      <Button
        secondary
        back
        to={backlinkLocation}
        className="request-reset-password-token-form__submit-button"
      >
        <T code={BUTTON_CANCEL} />
      </Button>

      <RequiredNotice className="request-reset-password-token-form__required-notice">
        <T code={NOTICE} />
      </RequiredNotice>
    </form>
  );
});
Form.propTypes = {
  touched: PropTypes.object,
  errors: PropTypes.object,
  backlinkLocation: Button.propTypes.to,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func
};

export const RequestResetPasswordTokenForm = withTranslation((props) => {
  const {
    translate,
    children,
    defaultLogin,
    backlinkLocation,
    onSubmit
  } = props;

  const initialValues = {
    login: defaultLogin || ""
  };

  const [submittedEmail, setSubmittedEmail] = useState(undefined);

  const handleSubmit = ({login}) => {
    onSubmit({login});
    setSubmittedEmail(login);
  };

  return submittedEmail
    ? (
      <>
        <div className="request-reset-password-token-form__success-message">
          <T code={SUCCESS_MESSAGE} />
        </div>
        <Button
          secondary
          onClick={() => handleSubmit({login: submittedEmail})}
        >
          <T code={RESEND_EMAIL} />
        </Button>
      </>
    )
    : (
      <div className="request-reset-password-token-form">
        <p className="request-reset-password-token-form__introduction">
          <T code={INTRODUCTION} />
        </p>

        <Formik
          validate={validate(translate)}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form
              {...formikProps}
              backlinkLocation={backlinkLocation}
            />
          )}
        </Formik>

        {children}
      </div>
    );
});
RequestResetPasswordTokenForm.propTypes = {
  children: PropTypes.node,
  defaultLogin: PropTypes.string,
  backlinkLocation: Form.propTypes.backlinkLocation,
  onSubmit: PropTypes.func
};
