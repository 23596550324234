import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading, ScreenLoading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {CreditCardsFieldset} from "../../../user/components/form.js";
import {initialize} from "./actions.js";
import {getCreditCardsEnabled} from "../../../config/store.js";

const PAYMENT_METHODS_HEADING = "FSD0000307";
const PAYMENT_METHODS_DISABLED = "ALG0000264";

const MyBmwRentPaymentMethodsComponent = (props) => {
  const {
    initialize,
    creditCardsEnabled,
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div>
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading><T code={PAYMENT_METHODS_HEADING} /></ScreenHeading>
            </Col>
          </Row>
          {creditCardsEnabled
            ? (
              <CreditCardsFieldset />
            )
            : (
              <Row>
                <Col desktop={{cols: 8, offset: 2}}>
                  <div><T code={PAYMENT_METHODS_DISABLED} /></div>
                </Col>
              </Row>
            )}
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
MyBmwRentPaymentMethodsComponent.propTypes = {
  initialize: PropTypes.func,
  creditCardsEnabled: PropTypes.bool
};

const stateToMyBmwRentPaymentMethodsProps = createStateToProps({
  creditCardsEnabled: getCreditCardsEnabled
});

const dispatchToMyBmwRentPaymentMethodsProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToMyBmwRentPaymentMethodsProps,
  dispatchToMyBmwRentPaymentMethodsProps
)(MyBmwRentPaymentMethodsComponent);
