import "react-app-polyfill/ie9"; // This has to be the first line
import "core-js/features/number/is-nan.js";
import "core-js/features/string/includes.js";
import "core-js/features/string/starts-with.js";
import "core-js/features/object/is.js";
import "core-js/features/object/entries.js";
import "core-js/features/object/values.js";
import "core-js/features/array/find.js";
import "core-js/features/array/includes.js";
import "core-js/features/weak-map/index.js";

import "./index.scss"; // import base styles before any component

import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import {fromJS} from "immutable";
import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk-recursion-detect";
import newErrorHandler from "redux-thunk-error-handler";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";
import WebFont from "webfontloader";
import {newContext} from "@awe-web/shared/lib/context.js";
import {injectScriptSrc, injectScriptText} from "@awe-web/shared/lib/util/dom.js";
import {closeSidebarMenu} from "@awe-web/shared/lib/sidebar_panel/actions.js";
import {setCurrentDateTime} from "@awe-web/shared/lib/time/actions.js";
import {handleError} from "@awe-web/shared/lib/errors/error_handler.js";
import reducer from "./reducer.js";
import App from "./app/components/app.js";
import {ErrorBoundary} from "./app/components/error_boundary.js";
import {removeFlashMessage} from "./app/actions.js";
import {setCurrentScrollPosition, setCurrentViewportWidth} from "./window/actions.js";
import {requiresAuthentication} from "./routing/util.js";
import {newTrackPageView} from "@awe-web/shared/lib/analytics/util.js";
import {normalizeLocale} from "@awe-web/shared/lib/l10n/util.js";
import {onActionMiddleware} from "@awe-web/shared/lib/util/redux.js";
import {setLocale} from "@awe-web/shared/lib/util/consent_controller.js";
import {setLoginState} from "./user/actions.js";
import {routesTrackingData} from "./analytics/routes.js";
import {getDataLayer} from "./analytics/data_layer.js";
import * as serviceWorker from "./serviceWorker";

smoothscroll.polyfill();

// Load the following fonts
// "BMW Group Light" normal (n4)
// "BMW Group Condensed" normal (n4) and bold (n7)
// See https://github.com/typekit/fvd
WebFont.load({custom: {families: [
  "BMW Group Light:n4",
  "BMW Group Condensed:n4,n7"
]}});

const updateConsentControllerLocale = onActionMiddleware("L10N/LOCALE_SET", (action) => {
  setLocale(action.payload.locale);
});

const config = window.config;
const context = newContext(config);
const initialState = fromJS({config});
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(newErrorHandler({onError: handleError}),
                                      thunk.withExtraArgument(context),
                                      updateConsentControllerLocale))
);
const trackPageView = newTrackPageView(getDataLayer, routesTrackingData, store, document);

store.dispatch(setCurrentDateTime());
setInterval(() => {
  store.dispatch(setCurrentDateTime());
}, 30000);
window.addEventListener("scroll", () => {
  store.dispatch(setCurrentScrollPosition());
});
window.addEventListener("resize", ((initialWidth) => {
  let lastWidth = initialWidth;

  return () => {
    store.dispatch(setCurrentViewportWidth());

    // Close the sidebar menu whenever the viewport gets wider and
    // potentially matches the next breakpoint.
    const newWidth = window.innerWidth;
    if (newWidth > lastWidth) {
      store.dispatch(closeSidebarMenu());
    }
    lastWidth = newWidth;
  };
})(window.innerWidth));
context.history.listen(async (location, action) => {
  // track subsequent page views
  trackPageView(context.history.location, {variant: "virtual"});

  store.dispatch(closeSidebarMenu());
  store.dispatch(removeFlashMessage());

  if (requiresAuthentication(location)) {
    await store.dispatch(setLoginState());
  }
});

ReactDOM.render(
  <ErrorBoundary>
    <App
      store={store}
      history={context.history}
    />
  </ErrorBoundary>,
  document.getElementById("root")
);

(async function () {
  // initialize ePaaS Consent Controller
  await injectScriptSrc(config.consentController.scriptSrc);
  window.epaas.api.initialize({
    tenant: config.consentController.tenant,
    locale: normalizeLocale(config.initialLocale),
    stage: config.consentController.stage
  });

  // initialize Adobe Analytics
  await injectScriptSrc(config.analytics.scriptSrc);
  injectScriptText("_satellite.pageBottom();");

  // track initial page view which does not trigger a history event
  trackPageView(context.history.location, {variant: "real"});
}());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
