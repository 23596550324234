import {createSelector} from "reselect";
import {createDynamicListSelector, newDateToAbbreviatedWeekdayName, newDateToMonthName} from "@awe-web/shared/lib/l10n/store.js";
import {isValidPageName as getIsValidPageName} from "@awe-web/shared/lib/cms/store.js";

export const dateToMonthName = newDateToMonthName({
  "JAN": "ALG0000218",
  "FEB": "ALG0000219",
  "MAR": "ALG0000220",
  "APR": "ALG0000221",
  "MAY": "ALG0000222",
  "JUN": "ALG0000223",
  "JUL": "ALG0000224",
  "AUG": "ALG0000225",
  "SEP": "ALG0000226",
  "OCT": "ALG0000227",
  "NOV": "ALG0000228",
  "DEC": "ALG0000229"
});

export const dateToAbbreviatedWeekdayName = newDateToAbbreviatedWeekdayName({
  "MON": "ALG0000230",
  "TUE": "ALG0000231",
  "WED": "ALG0000232",
  "THU": "ALG0000233",
  "FRI": "ALG0000234",
  "SAT": "ALG0000235",
  "SUN": "ALG0000236"
});

const getHeaderLinkList = createDynamicListSelector({
  "FSDHeaderLinkText": "text",
  "FSDHeaderLinkPage": "pageName",
  "FSDHeaderLinkUrl": "url"
});

const removeInvalidPageName = (isValidPageName) => (cmsLink) => !isValidPageName(cmsLink.get("pageName"))
  ? cmsLink.remove("pageName")
  : cmsLink;

export const getHeaderLinks = createSelector(
  [getHeaderLinkList, getIsValidPageName],
  (headerLinkList, isValidPageName) => headerLinkList
    .map(removeInvalidPageName(isValidPageName))
    // Keep items that have a 'text' and either an existing 'pageName'
    // or a 'url'
    .filter(cmsLink => cmsLink.get("text")
              && (isValidPageName(cmsLink.get("pageName")) || cmsLink.get("url")))
);

const getFooterLinkList = createDynamicListSelector({
  "FSDFooterLinkText": "text",
  "FSDFooterLinkPage": "pageName",
  "FSDFooterLinkUrl": "url"
});

export const getFooterLinks = createSelector(
  [getFooterLinkList, getIsValidPageName],
  (footerLinkList, isValidPageName) => footerLinkList
    .map(removeInvalidPageName(isValidPageName))
    // Keep items that have a 'text' and either an existing 'pageName'
    // or a 'url'
    .filter(cmsLink => cmsLink.get("text")
              && (isValidPageName(cmsLink.get("pageName")) || cmsLink.get("url")))
);
