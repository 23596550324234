"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.newMapFormValues = exports.newIsFieldRequired = exports.newGetFieldPlaceholder = exports.newGetFieldLabel = exports.newHasField = exports.newGetInitialValues = exports.newValidate = exports.newGetSelectFieldOptions = exports.getCreditCards = exports.getResetPasswordAccountType = exports.getResetPasswordToken = exports.getUserEmail = exports.getLoginErrorMessage = exports.getLoggedIn = exports.getUserProfile = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _immutable = require("immutable");

var _reselect = require("reselect");

var _object = require("../util/object.js");

var _string = require("../util/string.js");

var _validation_rules = require("../util/validation_rules.js");

var _validation = require("../util/validation.js");

var _ams = require("../util/ams.js");

var _constants = require("./constants.js");

var _store = require("../config/store.js");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function reducer(state, action) {
  switch (action.type) {
    case "USER/LOGIN_LOADED":
      return state["delete"]("loginError").setIn(["user", "loggedIn"], action.payload.loggedIn).setIn(["user", "profile"], (0, _immutable.fromJS)(action.payload.profile));

    case "USER/USER_LOGGED_OUT":
      return state.deleteIn(["user", "profile"])["delete"]("creditCards").setIn(["user", "loggedIn"], false);

    case "USER/LOGIN_FAILED":
      return state.set("loginError", action.payload.error);

    case "USER/RESET_PASSWORD_TOKEN_LOADED":
      return state.set("resetPasswordToken", action.payload.resetPasswordToken).set("resetPasswordAccountType", action.payload.resetPasswordAccountType);

    case "USER/CREDIT_CARDS_LOADED":
      return state.set("creditCards", (0, _immutable.fromJS)(action.payload.creditCards));

    case "USER/FORM_CONFIGURATION_LOADED":
      return state.deleteIn(["formConfigurationValues", action.payload.groupName]).setIn(["formConfiguration", action.payload.groupName], (0, _immutable.fromJS)(action.payload.formConfiguration));

    case "USER/FORM_CONFIGURATION_VALUES":
      return state.setIn(["formConfigurationValues", action.payload.groupName], (0, _immutable.fromJS)(action.payload.values));

    case "USER/SERVER_VALIDATION_FAILED":
      return state.setIn(["serverValidationResult", action.payload.groupName], (0, _immutable.fromJS)({
        values: action.payload.values,
        errors: action.payload.errors
      }));

    default:
      return state;
  }
}

var getUser = function getUser(state) {
  return state.get("user", (0, _immutable.Map)());
};

var getUserProfile = (0, _reselect.createSelector)([getUser], function (user) {
  return user.get("profile");
});
exports.getUserProfile = getUserProfile;

var getLoginError = function getLoginError(state) {
  return state.get("loginError");
};

var getLoggedIn = (0, _reselect.createSelector)(getUser, function (user) {
  return user.get("loggedIn", false);
});
exports.getLoggedIn = getLoggedIn;
var getLoginErrorMessage = (0, _reselect.createSelector)(getLoginError, function (error) {
  return error && error.displayMessage;
});
exports.getLoginErrorMessage = getLoginErrorMessage;
var getUserEmail = (0, _reselect.createSelector)([getUserProfile], function (userProfile) {
  return userProfile && userProfile.get("login");
});
exports.getUserEmail = getUserEmail;

var getResetPasswordToken = function getResetPasswordToken(state) {
  return state.get("resetPasswordToken");
};

exports.getResetPasswordToken = getResetPasswordToken;

var getResetPasswordAccountType = function getResetPasswordAccountType(state) {
  return state.get("resetPasswordAccountType") || _constants.accountTypes.B2C;
};

exports.getResetPasswordAccountType = getResetPasswordAccountType;

var getCreditCards = function getCreditCards(state) {
  return state.get("creditCards", (0, _immutable.List)());
}; // ======================================================================
// Form configuration
// ======================================================================


exports.getCreditCards = getCreditCards;

var newTheirNameToOurName = function newTheirNameToOurName(groupName) {
  return (0, _reselect.createSelector)([(0, _store.newGetFormConfiguration)(groupName)], function (mappings) {
    return function (theirName) {
      var match = mappings.find(function (mapping) {
        return mapping.first() === theirName;
      });
      return match ? match.get(1) : (0, _ams.fromDotNotation)(theirName.replace(/\.\d+$/, ""));
    };
  });
};

var getRawFormConfigurations = function getRawFormConfigurations(state) {
  return state.get("formConfiguration", (0, _immutable.Map)());
};

var newRawFormConfiguration = function newRawFormConfiguration(groupName) {
  return (0, _reselect.createSelector)([getRawFormConfigurations], function (formConfigurations) {
    return formConfigurations.get(groupName, (0, _immutable.List)());
  });
};

var newFormFieldList = function newFormFieldList(groupName) {
  return (0, _reselect.createSelector)([newRawFormConfiguration(groupName), newTheirNameToOurName(groupName)], function (formConfiguration, theirNameToOurName) {
    return formConfiguration.flatMap(function (fieldset) {
      return fieldset.get("items");
    }).map(function (item) {
      return item.set("_nameOriginal", item.get("name")).set("_nameWithSuffix", (0, _ams.fromDotNotation)(item.get("name"))).set("_nameWithoutSuffix", theirNameToOurName(item.get("name"))).update("name", theirNameToOurName);
    });
  });
}; // AMS form field names have the form "Object.property.suffix"
// (e.g. "Customer.name.123"). We need to both map "Customer.password"
// to "Customer.password.107" (SignUp) and "Customer.password.129"
// (SignUpConfirmed) as well as use field names with suffix for the
// boolean flags "ABDynDataValue.valueBoolean.108",
// "ABDynDataValue.valueBoolean.111" etc.
//
// The extended form field list contains each field twice (with and
// without suffix)


var newExtendedFormFieldList = function newExtendedFormFieldList(groupName) {
  return (0, _reselect.createSelector)([newFormFieldList(groupName)], function (formFieldList) {
    return formFieldList.reduce(function (fields, field) {
      return fields.push(field.set("name", field.get("_nameWithSuffix"))).push(field.set("name", field.get("_nameWithoutSuffix")));
    }, (0, _immutable.List)());
  });
};

var newFormFieldMap = function newFormFieldMap(groupName) {
  return (0, _reselect.createSelector)([newExtendedFormFieldList(groupName)], function (formFieldList) {
    return formFieldList.reduce(function (fields, field) {
      return fields.set(field.get("name"), field);
    }, (0, _immutable.Map)());
  });
};

var newGetFormField = function newGetFormField(groupName) {
  return (0, _reselect.createSelector)([newFormFieldMap(groupName)], function (formFieldMap) {
    return function (fieldName) {
      return formFieldMap.get(fieldName, (0, _immutable.Map)());
    };
  });
};

var newGetSelectFieldOptions = function newGetSelectFieldOptions(groupName) {
  return (0, _reselect.createSelector)([newGetFormField(groupName)], function (getFormField) {
    return function (fieldName) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$includeEmptyOpti = _ref.includeEmptyOption,
          includeEmptyOption = _ref$includeEmptyOpti === void 0 ? true : _ref$includeEmptyOpti;

      var field = getFormField(fieldName);
      var options = field.get("data", (0, _immutable.List)()).map(function (option) {
        return option.update("key", _string.trim);
      });
      var emptyText = field.get("emptyText");
      return includeEmptyOption ? options.unshift((0, _immutable.Map)({
        key: "",
        value: emptyText
      })).toJS() : options.toJS();
    };
  });
};

exports.newGetSelectFieldOptions = newGetSelectFieldOptions;

var newGetFormValidationRules = function newGetFormValidationRules(groupName) {
  return (0, _reselect.createSelector)([newExtendedFormFieldList(groupName)], function (formFieldList) {
    return formFieldList.toJS().reduce(_validation_rules.toValidationRule, {});
  });
};

var getRawServerValidationResult = function getRawServerValidationResult(state) {
  return state.get("serverValidationResult", (0, _immutable.Map)());
};

var newGetRawServerValidationResult = function newGetRawServerValidationResult(groupName) {
  return (0, _reselect.createSelector)([getRawServerValidationResult], function (serverValidationResult) {
    return serverValidationResult.get(groupName, (0, _immutable.Map)());
  });
};

var newGetServerValidationErrors = function newGetServerValidationErrors(groupName, nameMap) {
  return (0, _reselect.createSelector)([newGetRawServerValidationResult(groupName), newTheirNameToOurName(groupName)], function (serverValidationResult, theirNameToOurName) {
    return function (values) {
      var errors = serverValidationResult.get("errors", (0, _immutable.Map)()).mapEntries(function (_ref2) {
        var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
            name = _ref3[0],
            value = _ref3[1];

        return [nameMap[(0, _ams.fromDotNotation)(name)] || theirNameToOurName(name), value];
      });
      var oldValues = serverValidationResult.get("values");
      return errors.entrySeq().filter(function (_ref4) {
        var _ref5 = (0, _slicedToArray2["default"])(_ref4, 1),
            name = _ref5[0];

        return name in values && oldValues.get(name) === values[name];
      });
    };
  });
};

var newValidate = function newValidate(groupName) {
  var nameMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return (0, _reselect.createSelector)([newGetFormValidationRules(groupName), newGetServerValidationErrors(groupName, nameMap)], function (validationRules, getServerValidationErrors) {
    return function (values) {
      var serverErrors = getServerValidationErrors(values);
      var clientErrors = (0, _validation.validate)(validationRules)(values);
      var errors = (0, _immutable.Map)().merge(serverErrors).merge(clientErrors).toJS(); // Remove 'undefined' values from errors object because Formik
      // interprets presence of keys as errors regardless of value

      return (0, _object.stripUndefinedValues)(errors);
    };
  });
};

exports.newValidate = newValidate;

var newGetFormFieldProperty = function newGetFormFieldProperty(groupName, property, defaultFallback) {
  var transform = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (value) {
    return value;
  };
  return (0, _reselect.createSelector)([newGetFormField(groupName)], function (getFormField) {
    return function (fieldName, fallback) {
      return transform(getFormField(fieldName).get(property, defaultFallback !== undefined ? defaultFallback : fallback));
    };
  });
};

var newGetFormFieldOverriddenValue = function newGetFormFieldOverriddenValue(groupName) {
  return (0, _reselect.createSelector)([function (state) {
    return state.get("formConfigurationValues", (0, _immutable.Map)());
  }], function (values) {
    return function (fieldName) {
      return values.getIn([groupName, fieldName]);
    };
  });
};

var newGetFormFieldDefaultValue = function newGetFormFieldDefaultValue(groupName) {
  return (0, _reselect.createSelector)([newGetFormFieldProperty(groupName, "value", undefined, _string.trim), newGetFormFieldOverriddenValue(groupName)], function (getFormFieldValue, getFormFieldOverriddenValue) {
    return function (fieldName, fallback) {
      var overriddenValue = getFormFieldOverriddenValue(fieldName);
      return overriddenValue !== undefined ? overriddenValue : getFormFieldValue(fieldName, fallback);
    };
  });
};

var newGetInitialValues = function newGetInitialValues(groupName) {
  return (0, _reselect.createSelector)([newGetFormFieldDefaultValue(groupName)], function (getDefaultValue) {
    return function (valueMap) {
      return Object.entries(valueMap).map(function (_ref6) {
        var _ref7 = (0, _slicedToArray2["default"])(_ref6, 2),
            fieldName = _ref7[0],
            fallback = _ref7[1];

        return [fieldName, getDefaultValue(fieldName, fallback)];
      }).reduce(function (defaultValues, _ref8) {
        var _ref9 = (0, _slicedToArray2["default"])(_ref8, 2),
            fieldName = _ref9[0],
            defaultValue = _ref9[1];

        return _objectSpread(_objectSpread({}, defaultValues), {}, (0, _defineProperty2["default"])({}, fieldName, defaultValue));
      }, {});
    };
  });
};

exports.newGetInitialValues = newGetInitialValues;

var newHasField = function newHasField(groupName) {
  return (0, _reselect.createSelector)([newFormFieldMap(groupName)], function (formFieldMap) {
    return function (fieldName) {
      return formFieldMap.has(fieldName);
    };
  });
};

exports.newHasField = newHasField;

var newGetFieldLabel = function newGetFieldLabel(groupName) {
  return newGetFormFieldProperty(groupName, "fieldLabel", "");
};

exports.newGetFieldLabel = newGetFieldLabel;

var newGetFieldPlaceholder = function newGetFieldPlaceholder(groupName) {
  return newGetFormFieldProperty(groupName, "emptyText");
};

exports.newGetFieldPlaceholder = newGetFieldPlaceholder;

var newIsFieldRequired = function newIsFieldRequired(groupName) {
  return newGetFormFieldProperty(groupName, "allowBlank", true, function (allowBlank) {
    return !allowBlank;
  });
};

exports.newIsFieldRequired = newIsFieldRequired;

var newGetFormFieldNameMap = function newGetFormFieldNameMap(groupName) {
  return (0, _reselect.createSelector)([newFormFieldList(groupName)], function (formFieldList) {
    return formFieldList.map(function (item) {
      return [item.get("_nameWithoutSuffix"), item.get("_nameOriginal")];
    }).reduce(function (fieldNames, _ref10) {
      var _ref11 = (0, _slicedToArray2["default"])(_ref10, 2),
          ourName = _ref11[0],
          theirName = _ref11[1];

      return fieldNames.set(ourName, theirName);
    }, (0, _immutable.Map)());
  });
};

var newMapFormFieldName = function newMapFormFieldName(groupName) {
  return (0, _reselect.createSelector)([newGetFormFieldNameMap(groupName)], function (formFieldNameMap) {
    return function (fieldName) {
      return formFieldNameMap.get(fieldName) || (0, _ams.toDotNotation)(fieldName);
    };
  });
};

var newMapFormValues = function newMapFormValues(groupName) {
  return (0, _reselect.createSelector)([newMapFormFieldName(groupName)], function (mapFormFieldName) {
    return function (formValues) {
      return (0, _object.mapKeys)(formValues, mapFormFieldName);
    };
  });
};

exports.newMapFormValues = newMapFormValues;