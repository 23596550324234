"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openPopup = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var openPopup = function openPopup(url) {
  var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var interval = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 100;
  return function (dispatch, getState, context) {
    var paramsString = Object.entries(params).map(function (_ref) {
      var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return "".concat(key, "=").concat(value);
    }).join(",");
    return new Promise(function (resolve) {
      var popup = context.window.open(url, title, paramsString);
      var intervalId = setInterval(function () {
        if (popup.closed) {
          clearInterval(intervalId);
          resolve();
        }
      }, interval);
    });
  };
};

exports.openPopup = openPopup;