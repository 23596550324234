import React from "react";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {TypeArea} from "../../../components/layout.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {History} from "../../../history/components/history.js";

const HEADING = "ALG0000165";

const HistoryScreen = (props) => {
  return (
    <ScreenSection main dark>
      <TypeArea>
        <ScreenHeading><T code={HEADING} /></ScreenHeading>
        <History />
      </TypeArea>
    </ScreenSection>
  );
};
HistoryScreen.propTypes = {
};

export default HistoryScreen;
