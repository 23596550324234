import React, {Component, forwardRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Modal from "react-responsive-modal";
import {CSSTransition} from "react-transition-group";

import "./overlay.scss";

const ControlledOverlay = (props) => {
  const {open, modal, onClose = () => {}} = props;

  const children = typeof props.children === "function"
    ? props.children({closeOverlay: onClose})
    : props.children;

  const classNames = {
    overlay: "overlay",
    modal: classnames("overlay__content", props.className),
    closeButton: "overlay__close-button"
  };

  // Careful: when changing icon size also do so for class `.mobile-overlay__close-button`
  const closeIconSize = 33;
  const closeIconSvgPath = <svg viewBox="0 0 25 25"><polygon fill="#444" points="19,6.284 17.716,5 12,10.716 6.284,5 5,6.284 10.716,12 5,17.716 6.284,19 12,13.284 17.716,19 19,17.716 13.284,12" /></svg>;

  return (
    <Modal
      open={open}
      center
      closeOnEsc={!modal}
      closeOnOverlayClick={!modal}
      showCloseIcon={!modal}
      classNames={classNames}
      onClose={onClose}
      closeIconSize={closeIconSize}
      closeIconSvgPath={closeIconSvgPath}
    >
      <>
        {/*
           This tag is here to prevent the modal content to scroll
           down to the first focussable element.
        */}
        <div tabIndex="0" className="overlay__focus-element" />
        {children}
      </>
    </Modal>
  );
};
ControlledOverlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  modal: PropTypes.bool,
  onClose: PropTypes.func
};

class UncontrolledOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverlayOpen: false
    };

    this.openOverlay = () => this.setState(() => ({isOverlayOpen: true}));
    this.closeOverlay = (event) => {
      const {onClose = () => {}} = this.props;

      if (event && typeof event.stopPropagation === "function") {
        event.stopPropagation();
      }
      this.setState(() => ({isOverlayOpen: false}));
      onClose();
    };
  }

  render() {
    const {closeOverlay} = this;
    const {isOverlayOpen} = this.state;
    const {modal} = this.props;
    const className = classnames("uncontrolled-overlay", this.props.className);

    const children = typeof this.props.children === "function"
      ? this.props.children({closeOverlay})
      : this.props.children;

    return (
      <ControlledOverlay
          className={className}
          open={isOverlayOpen}
          modal={modal}
          onClose={closeOverlay}>
        {children}
      </ControlledOverlay>
    );
  }
}
UncontrolledOverlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  modal: PropTypes.bool,
  onClose: PropTypes.func
};

export const Overlay = forwardRef((props, ref) => {
  return "open" in props
    ? <ControlledOverlay ref={ref} {...props} />
    : <UncontrolledOverlay ref={ref} {...props} />;
});
Overlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  modal: PropTypes.bool,
  onClose: PropTypes.func
};

export const OverlaySection = ({children, className, dark}) => (
  <div className={classnames("overlay-section", className, {"overlay-section--dark": dark})}>{children}</div>
);
OverlaySection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  dark: PropTypes.bool
};

export const OverlayHeading = (props) => {
  const {children} = props;
  const className = classnames("overlay-heading", props.className);

  return (
    <h2 className={className}>{children}</h2>
  );
};
OverlayHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const OverlaySubHeading = (props) => {
  const {children} = props;
  const className = classnames("overlay-sub-heading", props.className);

  return (
    <h3 className={className}>{children}</h3>
  );
};
OverlaySubHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const MobileOverlay = (props) => {
  const {children, open, onClose = () => {}} = props;
  const className = classnames("mobile-overlay", props.className);

  return (
    <CSSTransition mountOnEnter unmountOnExit classNames="mobile-overlay-transition" in={open} timeout={300}>
      <div className={className}>
        <button
          type="button"
          className="mobile-overlay__close-button"
          onClick={onClose} />
        <div className="mobile-overlay__content">
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
MobileOverlay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export const MobileOverlaySection = ({className, children}) => (
  <div className={classnames("mobile-overlay-section", className)}>{children}</div>
);
MobileOverlaySection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export const MobileOverlayHeading = ({className, children}) => (
  <h2 className={classnames("mobile-overlay-heading", className)}>{children}</h2>
);
MobileOverlayHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
