import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {getResetPasswordToken} from "@awe-web/shared/lib/user/store.js";
import {
  newLoginAndRedirect,
  newLogoutAndRedirect,
  newSetLoginState,
  newEnsureUserLoggedIn,
  newShowCreditCardForm,
  newDeleteCreditCard
} from "@awe-web/shared/lib/user/actions.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {
  accountTypes,
  userRoles
} from "@awe-web/shared/lib/user/constants.js";
import {
  MyBmwRentDashboard,
  B2C_SelectStationAndTime,
  B2B_Dashboard
} from "../routing/routes.js";
import {addFlashMessageCode} from "../app/actions.js";
import {
  getContractTypeForB2cSTR,
  getContractTypeForB2bLTR
} from "../config/store.js";
import {getProfileScreenLink} from "./store.js";
import {userProfileToAccountType} from "./util.js";

export const loadAuthorizedUsers = () => async (dispatch, getState, context) => {
  dispatch({
    type: "USER/AUTHORIZED_USERS_LOADED",
    payload: {
      authorizedUsers: await context.api.user.getAuthorizedUsers()
    }
  });
};

export const login = newLoginAndRedirect({
  defaultRedirectLocation: (userProfile) => userProfileToAccountType(userProfile) === accountTypes.B2B
    ? B2B_Dashboard.location()
    : MyBmwRentDashboard.location(),
  afterLoginAction: loadAuthorizedUsers
});

export const logoutAndRedirect = newLogoutAndRedirect(B2C_SelectStationAndTime.path());

const SESSION_EXPIRED = "FSD0000376";

export const setLoginState = newSetLoginState({
  onSessionExpired: (dispatch) => {
    dispatch(addFlashMessageCode(SESSION_EXPIRED));
  }
});

export const ensureUserLoggedIn = newEnsureUserLoggedIn({setLoginState});

export const switchToUser = (userId) => async (dispatch, getState, context) => {
  await dispatch(asyncAction({
    typePrefix: "USER/LOGIN",
    loadPayload: async () => ({
      loggedIn: true,
      profile: await context.api.user.switchToUser({id: userId})
    })
  }));

  const profileScreenLink = getProfileScreenLink(getState());
  dispatch(navigate(profileScreenLink.get("path")));
};

export const resetPassword = (username, password) => async (dispatch, getState, context) => {
  const token = getResetPasswordToken(getState());
  await dispatch(asyncAction({
    typePrefix: "USER/RESET_PASSWORD",
    loadPayload: () => context.api.user.resetPassword({token, username, password, passwordRepeat: password})
  }));
};

export const deleteCreditCard = newDeleteCreditCard({ensureUserLoggedIn});

export const showCreditCardForm = newShowCreditCardForm({ensureUserLoggedIn});

export const requestNewPassword = (login, userRole) => async (dispatch, getState, context) => {
  try {
    const contractType = userRole === userRoles.B2B
      ? getContractTypeForB2bLTR(getState())
      : getContractTypeForB2cSTR(getState());
    await context.api.user.requestNewPassword({login, contractType});
  }
  catch (error) {
    if (error.type === "API") {
      return;
    }

    throw error;
  }
};

export const extendSignUp = (extendedSignUpValues) => async (dispatch, getState, context) => {
  await dispatch(asyncAction({
    typePrefix: "USER/EXTEND_SIGN_UP",
    loadPayload: () => context.api.user.extendRegistration(extendedSignUpValues)
  }));
};

export const updateUserProfile = (profileData) => async (dispatch, getState, context) => {
  await dispatch(ensureUserLoggedIn());

  await dispatch(asyncAction({
    typePrefix: "USER/UPDATE_PROFILE",
    loadPayload: () => context.api.user.updateCustomer(profileData)
  }));
};

export const loadContactPeople = () => async (dispatch, getState, context) => {
  dispatch({
    type: "USER/CONTACT_PEOPLE_LOADED",
    payload: {
      contactPeople: await context.api.user.getContactPeople()
    }
  });
};

export const loadDefaultBankAccount = () => async (dispatch, getState, context) => {
  dispatch({
    type: "USER/DEFAULT_BANK_ACCOUNT_LOADED",
    payload: {
      defaultBankAccount: await context.api.user.getDefaultBankAccount()
    }
  });
};
