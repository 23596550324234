import {toIsoString, dateStringToDateTime} from "@awe-web/shared/lib/util/date.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {loadFormConfiguration, signUp, serverValidationFailed} from "@awe-web/shared/lib/user/actions.js";
import {B2C_SignUpSuccess} from "../../../routing/routes.js";
import {getContractTypeForB2cSTR} from "../../../config/store.js";

export const initialize = () => async (dispatch) => {
  await dispatch(loadFormConfiguration("register"));
};

const normalizeDate = value => toIsoString(dateStringToDateTime(value));

export const submit = (formValues, {setSubmitting, validateForm}) => async (dispatch, getState) => {
  const contractTypeForMail = getContractTypeForB2cSTR(getState());
  const mapFormValues = newMapFormValues("register")(getState());
  const normalizedValues = mapFormValues({
    ...formValues,
    "Driver:birthdate": normalizeDate(formValues["Driver:birthdate"]),
    contractTypeForMail
  });

  try {
    setSubmitting(true);
    await dispatch(signUp(normalizedValues));
    dispatch(navigate(B2C_SignUpSuccess.location()));
  }
  catch (error) {
    if (error.type === "VALIDATION_FAILED") {
      const groupName = "register";
      const values = formValues;
      const errors = error.errors;
      dispatch(serverValidationFailed(groupName, values, errors));
      validateForm();
      return;
    }

    throw error;
  }
  finally {
    setSubmitting(false);
  }
};
