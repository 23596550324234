import React, {Component} from "react";
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";

import "./filter.scss";

export class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false
    };

    this.collapse = () => this.setState(() => ({isExpanded: false}));
    this.toggle = () => this.setState(({isExpanded}) => ({isExpanded: !isExpanded}));
    this.onSelect = (event) => {
      const {onSelect = () => {}} = this.props;
      this.collapse();
      onSelect(event);
    };
  }

  render() {
    const {onSelect} = this;
    const {rentObjectGroups} = this.props;
    const {isExpanded} = this.state;

    const options = (
      <ul className="filter__options">
        {rentObjectGroups.map(({elementId, name}) => (
          <li className="filter__option" key={elementId}>
            <button className="filter__option__button" onClick={() => onSelect({target: {value: elementId}})}>
              {name}
            </button>
          </li>
        ))}
      </ul>
    );

    return (
      <div className="filter">
        <button className="filter__button" onClick={this.toggle}>FILTER</button>
        <CSSTransition mountOnEnter unmountOnExit classNames="filter__options" in={isExpanded} timeout={300}>
          {options}
        </CSSTransition>
      </div>
    );
  }
}
Filter.propTypes = {
  onSelect: PropTypes.func,
  rentObjectGroups: PropTypes.arrayOf(PropTypes.shape({
    elementId: PropTypes.string,
    name: PropTypes.string
  }))
};
