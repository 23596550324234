"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.historyTabs = void 0;
var historyTabs = {
  ACTIVE: "active",
  PAST: "past"
};
exports.historyTabs = historyTabs;