import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Flag} from "../../components/flag.js";
import {getSelectedRentObject} from "../store.js";

import airConditionIcon from "./assets/air-condition.svg";
import minimumAgeIcon from "./assets/minimum-age.svg";
import navigationSystemIcon from "./assets/navigation-system.svg";
import seatingCapacityIcon from "./assets/seating-capacity.svg";
import cellPhoneIntegrationIcon from "./assets/cell-phone-integration.svg";

import "./selected_rent_object.scss";

const AIR_CONDITIONING = "FSD0000115";
const NAVIGATION_SYSTEM = "FSD0000116";
const SEATING_CAPACITY = "FSD0000117";
const MINIMUM_AGE = "FSD0000118";
const CELL_PHONE_INTEGRATION = "ALG0000028";

const SelectedRentObjectComponent = (props) => {
  const {rentObject = {}, selectedBasicProperties, action} = props;
  const className = classnames("selected-rent-object", props.className);

  const {
    description,
    imageUrl1,
    hasAirCondition,
    hasNavigationSystem,
    hasCellPhoneIntegration,
    numberOfSeats,
    minDriverAge
  } = rentObject;

  return (
    <div className={className}>
      <div className="selected-rent-object__rent-object" >
        <img className="selected-rent-object__rent-object__image" src={imageUrl1} alt={description} />
        <span className="selected-rent-object__rent-object__name">{description}</span>
      </div>

      {
        /*
         * We don't use <RentObjectBasicProperties> here because the
         * positioning is slightly different and the icons have a
         * different size
         */
      }
      <ul className="selected-rent-object__details">
        <li className="selected-rent-object__feature">
          <div className="rent-object-feature-type">
            <img className="rent-object-feature-type__image" src={airConditionIcon} alt="" />
            <T code={AIR_CONDITIONING} />
          </div>
          <div className="rent-object-feature-value"><Flag flag={hasAirCondition} /></div>
        </li>

        <li className="selected-rent-object__feature">
          <div className="rent-object-feature-type">
            <img className="rent-object-feature-type__image" src={navigationSystemIcon} alt="" />
            <T code={NAVIGATION_SYSTEM} />
          </div>
          <div className="rent-object-feature-value"><Flag flag={hasNavigationSystem} /></div>
        </li>

        <li className="selected-rent-object__feature">
          <div className="rent-object-feature-type">
            <img className="rent-object-feature-type__image" src={seatingCapacityIcon} alt="" />
            <T code={SEATING_CAPACITY} />
          </div>
          <div className="rent-object-feature-value">{numberOfSeats}</div>
        </li>

        {selectedBasicProperties === "B2C" && (
          <li className="selected-rent-object__feature">
            <div className="rent-object-feature-type">
              <img className="rent-object-feature-type__image" src={minimumAgeIcon} alt="" />
              <T code={MINIMUM_AGE} />
            </div>
            <div className="rent-object-feature-value">{minDriverAge}</div>
          </li>
        )}

        {selectedBasicProperties === "B2B" && (
          <li className="selected-rent-object__feature">
            <div className="rent-object-feature-type">
              <img className="rent-object-feature-type__image" src={cellPhoneIntegrationIcon} alt="" />
              <T code={CELL_PHONE_INTEGRATION} />
            </div>
            <div className="rent-object-feature-value"><Flag flag={hasCellPhoneIntegration} /></div>
          </li>
        )}
      </ul>

      {action
        ? <div className="selected-rent-object__action">{action}</div>
        : null
      }
    </div>
  );
};
SelectedRentObjectComponent.propTypes = {
  className: PropTypes.string,
  rentObject: PropTypes.shape({
    description: PropTypes.string,
    imageUrl1: PropTypes.string,
    hasAirCondition: PropTypes.bool,
    hasNavigationSystem: PropTypes.bool,
    hasCellPhoneIntegration: PropTypes.bool,
    numberOfSeats: PropTypes.string,
    minDriverAge: PropTypes.string
  }),
  selectedBasicProperties: PropTypes.oneOf(["B2C", "B2B"]).isRequired,
  action: PropTypes.node
};

const stateToProps = createStateToProps({
  rentObject: getSelectedRentObject
});

export const SelectedRentObject = connect(stateToProps)(SelectedRentObjectComponent);
