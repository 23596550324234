import {isValid, parseDate} from "@awe-web/shared/lib/util/date.js";
import {loadStations} from "@awe-web/shared/lib/stations/actions.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {
  getContractTypeForB2bLTR,
  getContractTypeForB2bSTR,
  getContractTypeForB2cLTR,
  getContractTypeForB2cSTR,
  getMinDaysForB2bLtr,
  getMinDaysForB2cLtr
} from "../config/store.js";
import {
  B2B_SelectStationAndTime,
  B2B_LTR_SelectRentObject,
  B2B_STR_SelectRentObject,
  B2C_SelectStationAndTime,
  B2C_STR_SelectRentObject,
  B2C_LTR_SelectRentObject
} from "../routing/routes.js";
import {getSelectStationAndTimeFormValidate} from "./store.js";
import {isLtr} from "../rent_contracts/util.js";

const SELECT_STATION_AND_TIME_FORM_CO_STATION_ID_LABEL = "FSD0000081";
const SELECT_STATION_AND_TIME_FORM_CO_STATION_ID_PLACEHOLDER = "FSD0000153";
const SELECT_STATION_AND_TIME_FORM_CI_STATION_ID_LABEL = "FSD0000082";
const SELECT_STATION_AND_TIME_FORM_CI_STATION_ID_PLACEHOLDER = "FSD0000086";
const SELECT_STATION_AND_TIME_FORM_CO_DATE_TIME_LABEL = "FSD0000083";
const SELECT_STATION_AND_TIME_FORM_CO_DATE_TIME_PLACEHOLDER = "FSD0000154";
const SELECT_STATION_AND_TIME_FORM_CI_DATE_TIME_LABEL = "FSD0000084";
const SELECT_STATION_AND_TIME_FORM_CI_DATE_TIME_PLACEHOLDER = "FSD0000155";

const validateStationId = async (context, stationId) => {
  if (!stationId) {
    return undefined;
  }

  try {
    await context.api.stations.getStation({stationId});
    return stationId;
  }
  catch (error) {
    return undefined;
  }
};

export const initializeSelectStationAndTimeForm = ({reservationPath = "B2C"} = {}) => async (dispatch, getState, context) => {
  const {location} = context.history;
  const state = location.state || {};
  const translate = newTranslate(getState());
  const validate = getSelectStationAndTimeFormValidate(getState());
  const contractTypes = reservationPath === "B2C"
    ? [getContractTypeForB2cSTR(getState()), getContractTypeForB2cLTR(getState())]
    : [getContractTypeForB2bSTR(getState()), getContractTypeForB2bLTR(getState())];

  const [coStationId, ciStationIdCandidate] = await Promise.all([
    validateStationId(context, state.coStationId),
    validateStationId(context, state.ciStationId)
  ]);
  const ciStationId = ciStationIdCandidate || coStationId;
  const coDateTime = parseDate(state.coDateTime);
  const ciDateTime = parseDate(state.ciDateTime);

  const errors = validate({coStationId, ciStationId, coDateTime, ciDateTime});

  await dispatch(loadStations(contractTypes));

  dispatch({
    type: "USER/FORM_CONFIGURATION_LOADED",
    payload: {
      groupName: "selectStationAndTime",
      formConfiguration: [
        {
          items: [
            {
              name: "coStationId",
              value: coStationId,
              fieldLabel: translate(SELECT_STATION_AND_TIME_FORM_CO_STATION_ID_LABEL),
              emptyText: translate(SELECT_STATION_AND_TIME_FORM_CO_STATION_ID_PLACEHOLDER)
            },
            {
              name: "ciStationId",
              value: ciStationId,
              fieldLabel: translate(SELECT_STATION_AND_TIME_FORM_CI_STATION_ID_LABEL),
              emptyText: translate(SELECT_STATION_AND_TIME_FORM_CI_STATION_ID_PLACEHOLDER)
            },
            {
              name: "coDateTime",
              value: errors.coDateTime ? undefined : coDateTime,
              fieldLabel: translate(SELECT_STATION_AND_TIME_FORM_CO_DATE_TIME_LABEL),
              emptyText: translate(SELECT_STATION_AND_TIME_FORM_CO_DATE_TIME_PLACEHOLDER)
            },
            {
              name: "ciDateTime",
              value: errors.ciDateTime ? undefined : ciDateTime,
              fieldLabel: translate(SELECT_STATION_AND_TIME_FORM_CI_DATE_TIME_LABEL),
              emptyText: translate(SELECT_STATION_AND_TIME_FORM_CI_DATE_TIME_PLACEHOLDER)
            }
          ]
        }
      ]
    }
  });
};

export const submitSelectStationAndTimeForm = (formValues, {navigate, reservationPath = "B2C"}) => (dispatch, getState) => {
  const {coStationId, ciStationId, coDateTime, ciDateTime} = formValues;

  if (reservationPath === "B2C") {
    // Redirect in order to store the selected values in the browser history
    dispatch(redirect(B2C_SelectStationAndTime.location({coStationId, ciStationId, coDateTime, ciDateTime})));

    const nextRoute = isLtr(coDateTime, ciDateTime, getMinDaysForB2cLtr(getState()))
      ? B2C_LTR_SelectRentObject
      : B2C_STR_SelectRentObject;
    dispatch(navigate(nextRoute.location({coStationId, ciStationId, coDateTime, ciDateTime})));
  }
  else {
    // Redirect in order to store the selected values in the browser history
    dispatch(redirect(B2B_SelectStationAndTime.location({coStationId, ciStationId, coDateTime, ciDateTime})));

    const nextRoute = isLtr(coDateTime, ciDateTime, getMinDaysForB2bLtr(getState()))
      ? B2B_LTR_SelectRentObject
      : B2B_STR_SelectRentObject;
    dispatch(navigate(nextRoute.location({coStationId, ciStationId, coDateTime, ciDateTime})));
  }
};

export const setCoStation = (stationId) => async (dispatch, getState, context) => {
  const station = await context.api.stations.getStation({stationId});

  dispatch({
    type: "STATIONS/CO_STATION_SET",
    payload: {station}
  });
};

export const setCiStation = (stationId) => async (dispatch, getState, context) => {
  const station = await context.api.stations.getStation({stationId});

  dispatch({
    type: "STATIONS/CI_STATION_SET",
    payload: {station}
  });
};

export const setCoDateTime = (dateTimeString) => (dispatch) => {
  if (isValid(dateTimeString)) {
    dispatch({
      type: "STATIONS/CO_DATE_TIME_SET",
      payload: {
        dateTime: dateTimeString
      }
    });
  }
};

export const setCiDateTime = (dateTimeString) => (dispatch) => {
  if (isValid(dateTimeString)) {
    dispatch({
      type: "STATIONS/CI_DATE_TIME_SET",
      payload: {
        dateTime: dateTimeString
      }
    });
  }
};
