"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.getNewDateContainsValidCiDateTime = exports.getNewDateContainsValidCoDateTime = exports.getNewIsValidCiDate = exports.getValidateCiDate = exports.getNewIsValidCoDate = exports.getValidateCoDate = exports.getNewIsValidCiDateTime = exports.getValidateCiDateTime = exports.getNewIsValidCoDateTime = exports.getValidateCoDateTime = exports.getValidateCoStationId = exports.getRawStations = exports.areStationsLoaded = void 0;

var _immutable = require("immutable");

var _reselect = require("reselect");

var _store = require("../l10n/store.js");

var _util = require("../l10n/util.js");

var _date = require("../util/date.js");

var _store2 = require("../time/store.js");

var _util2 = require("./util.js");

var _validation = require("./validation.js");

function reducer(state, action) {
  switch (action.type) {
    case "STATIONS/STATIONS_LOADED":
      return state.set("stations", (0, _immutable.fromJS)(action.payload.stations)).set("stationsCacheKey", action.payload.cacheKey).set("stationsLocale", action.payload.locale).set("stationsLoaded", true);

    default:
      return state;
  }
}

var areStationsLoaded = function areStationsLoaded(cacheKey) {
  return function (state) {
    return state.get("stationsLoaded", false) && state.get("stationsLocale") === (0, _store.getCurrentLocale)(state) && state.get("stationsCacheKey") === cacheKey;
  };
};

exports.areStationsLoaded = areStationsLoaded;

var getRawStations = function getRawStations(state) {
  return state.get("stations") || (0, _immutable.List)();
};

exports.getRawStations = getRawStations;

var getValidateCoStationId = function getValidateCoStationId(codeMapping) {
  return (0, _reselect.createSelector)([_store.newTranslate, getRawStations], function (baseTranslate, stations) {
    var translate = (0, _util.mappedTranslate)(baseTranslate, codeMapping);
    return function (coStationId) {
      var coStation = (0, _util2.findStation)(stations, coStationId);

      if (!coStation) {
        return translate("CO_STATION_MISSING");
      }

      return undefined;
    };
  });
};

exports.getValidateCoStationId = getValidateCoStationId;

var getValidateCoDateTime = function getValidateCoDateTime(codeMapping, dataMapping) {
  return (0, _reselect.createSelector)([_store.newTranslate, _store2.getCurrentDateTime, getRawStations], function (baseTranslate, currentDateTime, stations) {
    var translate = (0, _util.mappedTranslate)(baseTranslate, codeMapping, dataMapping);
    return function (coDateTime, coStationId) {
      var coStation = (0, _util2.findStation)(stations, coStationId);
      return (0, _validation.dateTimeError)({
        dateTime: coDateTime,
        currentDateTime: currentDateTime,
        periodStartDateTime: currentDateTime,
        minPeriodRule: (0, _util2.stationToMinLeadTimeRule)(coStation),
        maxPeriodRule: (0, _util2.stationToMaxLeadTimeRule)(coStation),
        openingTimes: (0, _util2.stationToOpeningTimes)(coStation),
        translate: translate
      });
    };
  });
}; // Returns true when coStation is open at the given dateTime and given
// dateTime satisfies minLeadTime and maxLeadTime.


exports.getValidateCoDateTime = getValidateCoDateTime;
var getNewIsValidCoDateTime = (0, _reselect.createSelector)([getValidateCoDateTime()], function (validateCoDateTime) {
  return function (coStationId) {
    return function (coDateTime) {
      return validateCoDateTime(coDateTime, coStationId) === undefined;
    };
  };
});
exports.getNewIsValidCoDateTime = getNewIsValidCoDateTime;

var getValidateCiDateTime = function getValidateCiDateTime(codeMapping, dataMapping) {
  return (0, _reselect.createSelector)([_store.newTranslate, _store2.getCurrentDateTime, getRawStations], function (baseTranslate, currentDateTime, stations) {
    var translate = (0, _util.mappedTranslate)(baseTranslate, codeMapping, dataMapping);
    return function (ciDateTime, coStationId, ciStationId, coDateTime) {
      var coStation = (0, _util2.findStation)(stations, coStationId);
      var ciStation = (0, _util2.findStation)(stations, ciStationId);
      return (0, _validation.dateTimeError)({
        dateTime: ciDateTime,
        currentDateTime: currentDateTime,
        periodStartDateTime: coDateTime,
        minPeriodRule: (0, _util2.stationToMinBookingPeriodRule)(coStation),
        maxPeriodRule: (0, _util2.stationToMaxBookingPeriodRule)(coStation),
        openingTimes: (0, _util2.stationToOpeningTimes)(ciStation),
        translate: translate
      });
    };
  });
}; // Returns true when ciStation is open at the given dateTime and given
// dateTime satisfies minBookingPeriod and maxBookingPeriod.


exports.getValidateCiDateTime = getValidateCiDateTime;
var getNewIsValidCiDateTime = (0, _reselect.createSelector)([getValidateCiDateTime()], function (validateCiDateTime) {
  return function (coStationId, ciStationId, coDateTime) {
    return function (ciDateTime) {
      return validateCiDateTime(ciDateTime, coStationId, ciStationId, coDateTime) === undefined;
    };
  };
}); // A coDate is valid when the station is open at any time on this day
// and midnight on this day is between minLeadTime and maxLeadTime.
// This is because we use midnight of the selected day as coDateTime
// when creating the rentContract.
//
// To accomplish this we first use `dateError` to check whether the
// station is open on the given date at all.  Second we call
// `dateTimeError` to check the future coDateTime against minLeadTime
// and maxLeadTime while ignoring the actual openingTimes.

exports.getNewIsValidCiDateTime = getNewIsValidCiDateTime;

var getValidateCoDate = function getValidateCoDate(codeMapping, dataMapping) {
  return (0, _reselect.createSelector)([_store.newTranslate, _store2.getCurrentDateTime, getRawStations], function (baseTranslate, currentDateTime, stations) {
    var translate = (0, _util.mappedTranslate)(baseTranslate, codeMapping, dataMapping);
    return function (coDate, coStationId) {
      var coStation = (0, _util2.findStation)(stations, coStationId);
      return (0, _validation.dateError)({
        date: coDate,
        currentDateTime: currentDateTime,
        openingTimes: (0, _util2.stationToOpeningTimes)(coStation),
        translate: translate
      }) || (0, _validation.dateTimeError)({
        dateTime: (0, _date.setTime)(coDate, "00:00"),
        currentDateTime: currentDateTime,
        periodStartDateTime: currentDateTime,
        minPeriodRule: (0, _util2.stationToMinLeadTimeRule)(coStation),
        maxPeriodRule: (0, _util2.stationToMaxLeadTimeRule)(coStation),
        translate: translate
      });
    };
  });
}; // Returns true when coStation is open at the given date AND midnight
// of the given date satisfies minLeadTime and maxLeadTime.


exports.getValidateCoDate = getValidateCoDate;
var getNewIsValidCoDate = (0, _reselect.createSelector)([getValidateCoDate()], function (validateCoDate) {
  return function (coStationId) {
    return function (coDate) {
      return validateCoDate(coDate, coStationId) === undefined;
    };
  };
}); // A ciDate is valid when the station is open at any time on this day
// and midnight on this day is between minBookingPeriod and
// maxBookingPeriod.  This is because we use midnight of the selected
// day as ciDateTime when creating the rentContract.
//
// To accomplish this we first use `dateError` to check whether the
// station is open on the given date at all.  Second we call
// `dateTimeError` to check the future ciDateTime against
// minBookingPeriod and maxBookingPeriod while ignoring the actual
// openingTimes.

exports.getNewIsValidCoDate = getNewIsValidCoDate;

var getValidateCiDate = function getValidateCiDate(codeMapping, dataMapping) {
  return (0, _reselect.createSelector)([_store.newTranslate, _store2.getCurrentDateTime, getRawStations], function (baseTranslate, currentDateTime, stations) {
    var translate = (0, _util.mappedTranslate)(baseTranslate, codeMapping, dataMapping);
    return function (ciDate, coStationId, ciStationId, coDateTime) {
      var coStation = (0, _util2.findStation)(stations, coStationId);
      var ciStation = (0, _util2.findStation)(stations, ciStationId);
      return (0, _validation.dateError)({
        date: ciDate,
        currentDateTime: currentDateTime,
        openingTimes: (0, _util2.stationToOpeningTimes)(ciStation),
        translate: translate
      }) || (0, _validation.dateTimeError)({
        dateTime: (0, _date.setTime)(ciDate, "23:59:59"),
        currentDateTime: currentDateTime,
        periodStartDateTime: coDateTime,
        minPeriodRule: (0, _util2.stationToMinBookingPeriodRule)(coStation),
        maxPeriodRule: (0, _util2.stationToMaxBookingPeriodRule)(coStation),
        translate: translate
      });
    };
  });
}; // Returns true when ciStation is open at the given date AND midnight
// of the given date satisfies minBookingPeriod and maxBookingPeriod.


exports.getValidateCiDate = getValidateCiDate;
var getNewIsValidCiDate = (0, _reselect.createSelector)([getValidateCiDate()], function (validateCiDate) {
  return function (coStationId, ciStationId, coDateTime) {
    return function (ciDate) {
      return validateCiDate(ciDate, coStationId, ciStationId, coDateTime) === undefined;
    };
  };
}); // Returns true when coStation is open between minLeadTime and
// maxLeadTime on the given date.

exports.getNewIsValidCiDate = getNewIsValidCiDate;
var getNewDateContainsValidCoDateTime = (0, _reselect.createSelector)([getRawStations, _store2.getCurrentDateTime], function (stations, currentDateTime) {
  return function (coStationId) {
    var coStation = (0, _util2.findStation)(stations, coStationId); // It does not matter what the return value of the `translate`
    // function is because we only care if the validation result is
    // `undefined` and not about any particular error message.

    var translate = function translate() {
      return "irrelevant message";
    };

    return function (date) {
      return (0, _validation.dateError)({
        date: date,
        currentDateTime: currentDateTime,
        periodStartDateTime: currentDateTime,
        minPeriodRule: (0, _util2.stationToMinLeadTimeRule)(coStation),
        maxPeriodRule: (0, _util2.stationToMaxLeadTimeRule)(coStation),
        openingTimes: (0, _util2.stationToOpeningTimes)(coStation),
        translate: translate
      }) === undefined;
    };
  };
}); // Returns true when ciStation is open between minBookingPeriod and
// maxBookingPeriod on the given date.

exports.getNewDateContainsValidCoDateTime = getNewDateContainsValidCoDateTime;
var getNewDateContainsValidCiDateTime = (0, _reselect.createSelector)([getRawStations, _store2.getCurrentDateTime], function (stations, currentDateTime) {
  return function (coStationId, ciStationId, coDateTime) {
    var coStation = (0, _util2.findStation)(stations, coStationId);
    var ciStation = (0, _util2.findStation)(stations, ciStationId); // It does not matter what the return value of the `translate`
    // function is because we only care if the validation result is
    // `undefined` and not about any particular error message.

    var translate = function translate() {
      return "irrelevant message";
    };

    return function (date) {
      return (0, _validation.dateError)({
        date: date,
        currentDateTime: currentDateTime,
        periodStartDateTime: coDateTime,
        minPeriodRule: (0, _util2.stationToMinBookingPeriodRule)(coStation),
        maxPeriodRule: (0, _util2.stationToMaxBookingPeriodRule)(coStation),
        openingTimes: (0, _util2.stationToOpeningTimes)(ciStation),
        translate: translate
      }) === undefined;
    };
  };
});
exports.getNewDateContainsValidCiDateTime = getNewDateContainsValidCiDateTime;