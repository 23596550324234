import React from "react";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {History} from "../../../history/components/history.js";

const TITLE = "FSD0000311";

const MyBmwRentRentContracts = (props) => {
  return (
    <div>
      <ScreenSection main dark>
        <TypeArea>
          <ScreenHeading><T code={TITLE} /></ScreenHeading>
          <History />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
MyBmwRentRentContracts.propTypes = {
};

export default MyBmwRentRentContracts;
