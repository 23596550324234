import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {StationAddress} from "../../stations/components/station_card.js";
import {getPaymentDetails} from "../../user/store.js";
import {getCiStation} from "../../stations/store.js";
import {
  getProvisioningDetailsB2b,
  getProvisioningDetailsB2c,
  getDriver
} from "../store.js";

import "./rent_contract.scss";

// ======================================================================
// Helper
// ======================================================================

const Line = ({value}) => value
  ? <div>{value}</div>
  : null;

const LabeledLine = ({label, value}) => value
  ? <div>{label}: {value}</div>
  : null;

// ======================================================================
// PaymentDetails
// ======================================================================

const PAYMENT_SEPA = "ALG0000097";
const PAYMENT_ON_ACCOUNT = "ALG0000098";
const ACCOUNT_HOLDER = "ALG0000099";
const IBAN = "ALG0000100";
const BIC = "ALG0000101";

const PaymentDetailsComponent = (props) => {
  const {
    paymentDetails: {
      type,
      accountHolder,
      iban,
      bic,
      bank
    }
  } = props;

  const typeHeading = type === "SEPA"
    ? <T code={PAYMENT_SEPA} />
    : <T code={PAYMENT_ON_ACCOUNT} />;

  return (
    <div className="payment-details">
      <div className="payment-details__type">
        {typeHeading}
      </div>
      <div>
        <T code={ACCOUNT_HOLDER} />: {accountHolder}
      </div>
      <div>
        <T code={IBAN} />: {iban}
      </div>
      <div>
        <T code={BIC} />: {bic}
      </div>
      <div>
        {bank}
      </div>
    </div>
  );
};
PaymentDetailsComponent.propTypes = {
  paymentDetails: PropTypes.shape({
    type: PropTypes.oneOf(["SEPA", "ON_ACCOUNT"]).isRequired,
    accountHolder: PropTypes.string,
    iban: PropTypes.string,
    bic: PropTypes.string,
    bank: PropTypes.string
  }).isRequired
};

const stateToPaymentDetailsProps = createStateToProps({
  paymentDetails: getPaymentDetails
});

export const PaymentDetails = connect(
  stateToPaymentDetailsProps
)(PaymentDetailsComponent);

// ======================================================================
// DriverDetails
// ======================================================================

const EMPLOYEE_ID = "ALG0000102";
const COST_CENTER = "ALG0000103";

const DriverDetailsComponent = (props) => {
  const {
    driverDetails: {
      fullName,
      email,
      phone,
      employeeId,
      costCenter
    }
  } = props;

  return (
    <div className="driver-details">
      <div className="driver-details__name">
        {fullName}
      </div>
      <Line value={email} />
      <Line value={phone} />
      <LabeledLine label={<T code={EMPLOYEE_ID} />} value={employeeId} />
      <LabeledLine label={<T code={COST_CENTER} />} value={costCenter} />
    </div>
  );
};
DriverDetailsComponent.propTypes = {
  driverDetails: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    employeeId: PropTypes.string,
    costCenter: PropTypes.string
  }).isRequired
};

const stateToDriverDetailsProps = createStateToProps({
  driverDetails: getDriver
});

export const DriverDetails = connect(
  stateToDriverDetailsProps
)(DriverDetailsComponent);

// ======================================================================
// ProvisioningDetails
// ======================================================================

const ProvisioningDetailsComponent = (props) => {
  const {provisioningDetails} = props;
  const {label, provisioningType} = provisioningDetails;

  if (provisioningType === provisioningTypes.PICKUP) {
    const {pickupStation} = provisioningDetails;
    return (
      <div className="provisioning-details">
        <div className="provisioning-details__type">{label}</div>
        <StationAddress
          station={pickupStation}
          stationNameClassName="provisioning-details__station-address__name"
          openingTimesHeadingClassName="provisioning-details__station-address__opening-times-heading"
        />
      </div>
    );
  }
  else {
    const {
      deliveryAddress: {
        companyName,
        fullName,
        street,
        building,
        zipCode,
        city,
        country,
        phonenumber
      }
    } = provisioningDetails;

    return (
      <div className="provisioning-details">
        <div className="provisioning-details__type">
          {label}
        </div>
        <div>
          {companyName}
        </div>
        <Line value={fullName} />
        <div>
          {street}
        </div>
        <Line value={building} />
        <div>
          {zipCode} {city}
        </div>
        <Line value={country} />
        <Line value={phonenumber} />
      </div>
    );
  }
};
ProvisioningDetailsComponent.propTypes = {
  reservationPath: PropTypes.oneOf(["B2C", "B2B"]),
  provisioningDetails: PropTypes.shape({
    provisioningType: PropTypes.oneOf(Object.values(provisioningTypes)).isRequired,
    label: PropTypes.string,
    pickupStation: StationAddress.propTypes.station,
    deliveryAddress: PropTypes.shape({
      companyName: PropTypes.string,
      fullName: PropTypes.string,
      street: PropTypes.string,
      building: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      phonenumber: PropTypes.string
    })
  }).isRequired
};

const stateToProvisioningDetailsProps = createStateToProps({
  provisioningDetails: (state, props) =>
    props.reservationPath === "B2B"
      ? getProvisioningDetailsB2b(state)
      : getProvisioningDetailsB2c(state)
});

export const ProvisioningDetails = connect(
  stateToProvisioningDetailsProps
)(ProvisioningDetailsComponent);

// ======================================================================
// ReturnDetails
// ======================================================================

const RETURN_AT_STATION = "ALG0000242";

const ReturnDetailsComponent = (props) => {
  const {returnStation} = props;

  return (
    <div className="return-details">
      <div className="return-details__type">
        <T code={RETURN_AT_STATION} />
      </div>
      <StationAddress
        station={returnStation}
        stationNameClassName="return-details__station-address__name"
        openingTimesHeadingClassName="return-details__station-address__opening-times-heading"
      />
    </div>
  );
};
ReturnDetailsComponent.propTypes = {
  returnStation: StationAddress.propTypes.station
};

const stateToReturnDetailsProps = createStateToProps({
  returnStation: getCiStation
});

export const ReturnDetails = connect(
  stateToReturnDetailsProps
)(ReturnDetailsComponent);
