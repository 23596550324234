import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {matches, closest} from "@awe-web/shared/lib/util/dom.js";
import {expandFaqEntry, collapseFaqEntry} from "@awe-web/shared/lib/cms/dom.js";

import "./cms_content.scss";

export class CmsContent extends Component {
  constructor(props) {
    super(props);

    this.rootNodeRef = createRef();
    this.handlePageClick = (event) => {
      const {target} = event;

      if (matches(target, ".question *")) {
        event.preventDefault();

        const faqEntry = closest(target, ".faqEntry");
        if (faqEntry) {
          const isExpanded = faqEntry.classList.contains("faqEntry--is-expanded");
          const faqEntries = this.rootNodeRef.current.querySelectorAll(".faqEntry");
          faqEntries.forEach(collapseFaqEntry);

          if (!isExpanded) {
            expandFaqEntry(faqEntry);
          }
          else {
            collapseFaqEntry(faqEntry);
          }
        }
      }
    };
  }

  componentDidMount() {
    this.addPageBehavior();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageContent !== this.props.pageContent) {
      this.removePageBehavior();
      this.addPageBehavior();
    }
  }

  componentWillUnmount() {
    this.removePageBehavior();
  }

  addPageBehavior() {
    this.rootNodeRef.current.addEventListener("click", this.handlePageClick, true);
  }

  removePageBehavior() {
    this.rootNodeRef.current.removeEventListener("click", this.handlePageClick, true);
  }

  render() {
    const {pageContent} = this.props;
    const className = classnames("cms-content", this.props.className);

    return (
      <div ref={this.rootNodeRef} className={className} dangerouslySetInnerHTML={{__html: pageContent}} />
    );
  }
}
CmsContent.propTypes = {
  className: PropTypes.string,
  pageName: PropTypes.string,
  pageContent: PropTypes.string
};
