import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./station.scss";

import locationIcon from "./assets/pin.svg";
import calendarIcon from "./assets/calendar.svg";

export const stationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  shortdescr: PropTypes.string
});

export const SelectedStation = (props) => {
  const {label, shortdescr} = props;
  const className = classnames("selected-station", props.className);

  return (
    <div className={className}>
      <img className="selected-station__icon" src={locationIcon} alt="" />
      <div>
        <div className="selected-station__label">{label}</div>
        <div className="selected-station__value">{shortdescr}</div>
      </div>
    </div>
  );
};
SelectedStation.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  shortdescr: PropTypes.string
};

export const SelectedTime = (props) => {
  const {label, formattedDateTime} = props;
  const className = classnames("selected-time", props.className);

  return (
    <div className={className}>
      <img className="selected-time__icon" src={calendarIcon} alt="" />
      <div>
        <div className="selected-time__label">{label}</div>
        <div className="selected-time__value">{formattedDateTime}</div>
      </div>
    </div>
  );
};
SelectedTime.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  formattedDateTime: PropTypes.string
};
