"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _fn = require("./util/fn.js");

var _store = _interopRequireDefault(require("./errors/store.js"));

var _store2 = _interopRequireDefault(require("./sidebar_panel/store.js"));

var _store3 = _interopRequireDefault(require("./time/store.js"));

var _store4 = _interopRequireDefault(require("./l10n/store.js"));

var _store5 = _interopRequireDefault(require("./cms/store.js"));

var _store6 = _interopRequireDefault(require("./stations/store.js"));

var _store7 = _interopRequireDefault(require("./rent_objects/store.js"));

var _store8 = _interopRequireDefault(require("./rent_contracts/store.js"));

var _store9 = _interopRequireDefault(require("./user/store.js"));

var _default = (0, _fn.composeReducers)(_store["default"], _store2["default"], _store3["default"], _store4["default"], _store5["default"], _store6["default"], _store7["default"], _store8["default"], _store9["default"]);

exports["default"] = _default;