import {Map, List, fromJS} from "immutable";
import {createSelector} from "reselect";
import {stripUndefinedValues} from "@awe-web/shared/lib/util/object.js";
import {parseDate, formatDate} from "@awe-web/shared/lib/util/date.js";
import {buildGoogleMapsLinkUrl} from "@awe-web/shared/lib/util/google_maps.js";
import {
  getRawStations,
  getValidateCoStationId,
  getValidateCoDateTime,
  getValidateCiDateTime
} from "@awe-web/shared/lib/stations/store.js";
import {getCurrentLocale} from "@awe-web/shared/lib/l10n/store.js";

export default function reducer(state, action) {
  switch (action.type) {
    case "STATIONS/CO_STATION_SET":
      return state.set("coStation", fromJS(action.payload.station));

    case "STATIONS/CI_STATION_SET":
      return state.set("ciStation", fromJS(action.payload.station));

    case "STATIONS/CO_DATE_TIME_SET":
      return state.set("coDateTime", action.payload.dateTime);

    case "STATIONS/CI_DATE_TIME_SET":
      return state.set("ciDateTime", action.payload.dateTime);

    default:
      return state;
  }
}

const hasLang = locale => openingInfo => openingInfo.get("lang") === locale;
const filterOpeningInfos = locale => station => station
  .update("openingInfos", openingInfos => openingInfos.filter(hasLang(locale)));
const splitOpeningInfos = station => station
  .update("openingInfos", openingInfos =>
    openingInfos.flatMap(openingInfo =>
      List(openingInfo.get("text", "").split("\n"))
        .map((text, index) => Map({
          id: openingInfo.get("id") + "." + index,
          text
        }))));
const injectGoogleMapsLink = station => station
  .set("googleMapsLink", buildGoogleMapsLinkUrl({
    latitude: station.get("latitude"),
    longitude: station.get("longitude")
  }));
const normalizeStation = (locale) => (station) =>
  injectGoogleMapsLink(splitOpeningInfos(filterOpeningInfos(locale)(station)));

export const getStations = createSelector(
  [getRawStations, getCurrentLocale],
  (stations, locale) => stations
    .sortBy(station => station.get("shortdescr"))
    .map(normalizeStation(locale))
);

// ======================================================================
// SelectStationAndTimeForm (short term reservation)
// ======================================================================

export const getSelectStationAndTimeFormValidate = createSelector(
  [
    getValidateCoStationId({
      CO_STATION_MISSING: "FSD0000150"
    }),
    getValidateCoDateTime({
      "DATE_MISSING": "FSD0000151",
      "DATE_INVALID": "FSD0000264",
      "DATE_IN_PAST": "FSD0000267",
      "DATE_BEFORE_MIN_PERIOD": "FSD0000266",
      "DATE_AFTER_MAX_PERIOD": "FSD0000268",
      "STATION_CLOSED": "FSD0000265"
    }, {
      minPeriod: "minLeadTime",
      maxPeriod: "maxLeadTime"
    }),
    getValidateCiDateTime({
      "DATE_MISSING": "FSD0000152",
      "DATE_INVALID": "FSD0000264",
      "DATE_IN_PAST": "FSD0000270",
      "DATE_BEFORE_MIN_DATE": "FSD0000271",
      "DATE_BEFORE_MIN_PERIOD": "FSD0000272",
      "DATE_AFTER_MAX_PERIOD": "FSD0000293",
      "STATION_CLOSED": "FSD0000269"
    }, {
      minPeriod: "minBookingPeriod",
      maxPeriod: "maxBookingPeriod"
    })
  ],
  (
    validateCoStationId,
    validateCoDateTime,
    validateCiDateTime
  ) => ({coStationId, ciStationId, coDateTime, ciDateTime}) => stripUndefinedValues({
    coStationId: validateCoStationId(coStationId),
    ciStationId: undefined,
    coDateTime: validateCoDateTime(coDateTime, coStationId),
    ciDateTime: validateCiDateTime(ciDateTime, coStationId, ciStationId, coDateTime)
  })
);


export const getCoStation = createSelector(
  [state => state.get("coStation"), getCurrentLocale],
  (coStation, locale) => coStation ? normalizeStation(locale)(coStation) : undefined
);
export const getCoStationId = createSelector(
  [getCoStation],
  (coStation) => coStation && coStation.get("id")
);

export const getCiStation = createSelector(
  [state => state.get("ciStation"), getCurrentLocale],
  (ciStation, locale) => ciStation ? normalizeStation(locale)(ciStation) : undefined
);
export const getCiStationId = createSelector(
  [getCiStation],
  (ciStation) => ciStation && ciStation.get("id")
);

export const getCoDateTime = createSelector(
  [state => state.get("coDateTime")],
  (coDateTime) => coDateTime && parseDate(coDateTime)
);
export const getFormattedCoDateTime = createSelector(
  [getCoDateTime],
  (coDateTime) => formatDate(coDateTime, "dd.MM.yyyy, HH:mm")
);

export const getCiDateTime = createSelector(
  [state => state.get("ciDateTime")],
  (ciDateTime) => ciDateTime && parseDate(ciDateTime)
);
export const getFormattedCiDateTime = createSelector(
  [getCiDateTime],
  (ciDateTime) => formatDate(ciDateTime, "dd.MM.yyyy, HH:mm")
);
