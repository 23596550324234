import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {fieldsetProps, newFieldProps} from "@awe-web/shared/lib/util/form.js";
import {merge} from "@awe-web/shared/lib/util/object.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Checkbox} from "@awe-web/shared/lib/components/form.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions,
  newHasField
} from "@awe-web/shared/lib/user/store.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSectionHeading} from "../../../components/screen.js";
import {
  LabeledCheckbox,
  Button,
  ErrorMessage,
  RequiredNotice,
  ScrollToError
} from "../../../components/form.js";
import {TextWithCmsLink} from "../../../components/cms_overlay.js";
import {AccountFieldset, ConsentFieldset} from "../../../user/components/form.js";
import {submit} from "./actions.js";

import "./sign_up_form.scss";

const TITLE_LEGAL = "FSD0000197";
const TITLE_DATA_PRIVACY_STATEMENT = "FSD0000198";
const ADDITION_TO_TERMS_AND_CONDITIONS = "FSD0000275";
const DATA_PRIVACY_STATEMENT = "FSD0000199";
const BUTTON_FINISH_REGISTRATION = "FSD0000208";
const NOTICE = "FSD0000209";

const Form = (props) => {
  const {getFieldLabel, handleSubmit} = props;
  const fieldProps = newFieldProps(props);
  const error = name => fieldProps(name).error;

  return (
    <form className="sign-up-form" onSubmit={handleSubmit}>
      <ScrollToError />
      <AccountFieldset {...fieldsetProps(props)} />

      <Row>
        <Col
          desktop={{cols: 8, offset: 2}}
          className="sign-up-form__form-section--legal"
        >
          <ScreenSectionHeading light><T code={TITLE_LEGAL} /></ScreenSectionHeading>
          <div className="sign-up-form__field">
            <LabeledCheckbox error={error("tosAccepted")}>
              <Checkbox {...fieldProps("tosAccepted")} />
              <div>
                <TextWithCmsLink className="sign-up-form__terms-and-conditions-label checkbox-label" pageName="agb">
                  {getFieldLabel("tosAccepted")}
                </TextWithCmsLink>
                <T code={ADDITION_TO_TERMS_AND_CONDITIONS} />
              </div>
            </LabeledCheckbox>
            <ErrorMessage error={error("tosAccepted")} />
          </div>

          <div className="sign-up-form__field">
            <div className="sign-up-form__data-privacy-statement-title">
              <T code={TITLE_DATA_PRIVACY_STATEMENT} />
            </div>
            <T code={DATA_PRIVACY_STATEMENT} />
          </div>
        </Col>
      </Row>

      <ConsentFieldset
        className="sign-up-form__consent-fieldset"
        {...fieldsetProps(props)}
      />

      <Row>
        <Col
          tablet={{cols: 4, offset: 4}}
          desktop={{cols: 4, offset: 6}}
          className="sign-up-form__form-buttons__col--submit-button"
        >
          <Button submit continue primary onClick={handleSubmit} className="sign-up-form__submit-button">
            <T code={BUTTON_FINISH_REGISTRATION} />
          </Button>
        </Col>
      </Row>

      <Row className="sign-up-form__required-notice">
        <Col desktop={{cols: 8, offset: 2}}>
          <RequiredNotice><T code={NOTICE} /></RequiredNotice>
        </Col>
      </Row>
    </form>
  );
};
Form.valuesShape = merge(
  AccountFieldset.valuesShape,
  ConsentFieldset.valuesShape,
  {
    "tosAccepted": PropTypes.bool
  }
);
Form.propTypes = {
  values: PropTypes.shape(Form.valuesShape),
  isFieldRequired: PropTypes.func,
  getSelectFieldOptions: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func
};

const SignUpFormComponent = (props) => {
  const {
    rentContractId,
    isFieldRequired,
    getInitialValues,
    hasField,
    getSelectFieldOptions,
    getFieldLabel,
    getFieldPlaceholder,
    validate,
    onSubmit
  } = props;
  const initialValues = getInitialValues({
    "rentcontractId": rentContractId,
    "registrationMode": "register",

    [AccountFieldset.PREFIX_KEY]: "",
    [AccountFieldset.NAME]: "",
    [AccountFieldset.SURNAME]: "",
    [AccountFieldset.BIRTHDATE]: "",
    [AccountFieldset.PHONE]: "",
    [AccountFieldset.USERNAME]: "",
    [AccountFieldset.PASSWORD]: "",
    ...(hasField(AccountFieldset.TAX_IDENT) ? {[AccountFieldset.TAX_IDENT]: ""} : {}),

    "tosAccepted": false,

    [ConsentFieldset.MAIL]: false,
    [ConsentFieldset.EMAIL]: false,
    [ConsentFieldset.PHONE]: false
  });

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {(formikProps) => (
        <Form
          {...formikProps}
          isFieldRequired={isFieldRequired}
          getSelectFieldOptions={getSelectFieldOptions}
          getFieldLabel={getFieldLabel}
          getFieldPlaceholder={getFieldPlaceholder}
        />
      )}
    </Formik>
  );
};
SignUpFormComponent.propTypes = {
  rentContractId: PropTypes.number,
  validate: PropTypes.func,
  getInitialValues: PropTypes.func,
  hasField: PropTypes.func,
  isFieldRequired: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  getSelectFieldOptions: PropTypes.func,
  onSubmit: PropTypes.func
};

const stateToSignUpFormProps = createStateToProps({
  validate: newValidate("register", {
    "Address:email": "Customer:login",
    "AlternativeWebLogin:Login": "Customer:login"
  }),
  getInitialValues: newGetInitialValues("register"),
  hasField: newHasField("register"),
  isFieldRequired: newIsFieldRequired("register"),
  getFieldLabel: newGetFieldLabel("register"),
  getFieldPlaceholder: newGetFieldPlaceholder("register"),
  getSelectFieldOptions: newGetSelectFieldOptions("register")
});

const dispatchToSignUpFormProps = (dispatch) => ({
  onSubmit: (values, formikProps) => dispatch(submit(values, formikProps))
});

export const SignUpForm = connect(
  stateToSignUpFormProps,
  dispatchToSignUpFormProps
)(SignUpFormComponent);
