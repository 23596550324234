import {composeReducers} from "@awe-web/shared/lib/util/fn.js";
import sharedReducer from "@awe-web/shared/lib/reducer.js";
import appReducer from "./app/store.js";
import domReducer from "./dom/store.js";
import stationsReducer from "./stations/store.js";
import rentObjectsReducer from "./rent_objects/store.js";
import rentContractsReducer from "./rent_contracts/store.js";
import userReducer from "./user/store.js";
import historyReducer from "./history/store.js";
import windowReducer from "./window/store.js";

export default composeReducers(
  sharedReducer,
  appReducer,
  domReducer,
  stationsReducer,
  rentObjectsReducer,
  rentContractsReducer,
  userReducer,
  historyReducer,
  windowReducer
);
