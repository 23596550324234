import {getCurrentLocale} from "@awe-web/shared/lib/l10n/store.js";
import {getContractTypeForB2cSTR} from "../config/store.js";

export const loadCmsPage = (pageName) => async (dispatch, getState, context) => {
  const locale = getCurrentLocale(getState());
  const contractType = getContractTypeForB2cSTR(getState());
  const cmsPage = await context.api.cms.getCmsPage({pageName, locale, contractType});

  dispatch({
    type: "CMS/CMS_PAGE_LOADED",
    payload: {
      pageName,
      cmsPage
    }
  });
};
