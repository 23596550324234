"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildGoogleMapsLinkUrl = void 0;

var _queryString = require("query-string");

var GOOGLE_MAPS_BASE_URL = "https://maps.google.com";

var buildGoogleMapsLinkUrl = function buildGoogleMapsLinkUrl(_ref) {
  var latitude = _ref.latitude,
      longitude = _ref.longitude;
  var queryString = (0, _queryString.stringify)({
    q: "".concat(latitude, ",").concat(longitude)
  });
  return "".concat(GOOGLE_MAPS_BASE_URL, "/?").concat(queryString);
};

exports.buildGoogleMapsLinkUrl = buildGoogleMapsLinkUrl;