import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {newFieldProps} from "@awe-web/shared/lib/util/form.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Checkbox} from "@awe-web/shared/lib/components/form.js";
import {Row, Col} from "../../components/grid.js";
import {ScreenSectionHeading} from "../../components/screen.js";
import {
  LabeledCheckbox,
  ErrorMessage,
  FormikLabeledField,
  FormikSelect,
  FormikTextInput,
  FormikDateInput,
  FormikPasswordInput
} from "../../components/form.js";
import {CreditCardList, AddCreditCard} from "../../user/components/credit_cards.js";

import "./form.scss";

const PERSONAL_INFORMATION = "FSD0000189";
const LOGIN_DATA = "FSD0000190";
const ADDRESS = "FSD0000212";
const CREDIT_CARDS = "FSD0000219";
const IDENTIFICATION = "FSD0000223";
const DRIVERS_LICENSE = "FSD0000234";
const TITLE_DATA_PROCESSING_CONSENT = "FSD0000200";
const DATA_PROCESSING_CONSENT = "FSD0000201";
const TITLE_RIGHT_OF_REVOCATION = "FSD0000205";
const RIGHT_OF_REVOCATION = "FSD0000206";

export const AccountFieldset = (props) => {
  const {isFieldRequired, getFieldLabel, getFieldPlaceholder, getSelectFieldOptions, hasField = () => {}} = props;
  const className = classnames("account-fieldset", props.className);

  return (
    <div className={className}>
      <div className="account-fieldset__row">
        {/* ==== personal information ==== */}
        <ScreenSectionHeading
          light
          className="account-fieldset__title-personal"
        >
          <T code={PERSONAL_INFORMATION} />
        </ScreenSectionHeading>
        <FormikLabeledField
          className="account-fieldset__prefix-key"
          name={AccountFieldset.PREFIX_KEY}
          as={FormikSelect}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          options={getSelectFieldOptions(AccountFieldset.PREFIX_KEY)}
        />

        <FormikLabeledField
          className="account-fieldset__name"
          name={AccountFieldset.NAME}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AccountFieldset.NAME)}
        />

        <FormikLabeledField
          className="account-fieldset__surname"
          name={AccountFieldset.SURNAME}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AccountFieldset.SURNAME)}
        />

        <FormikLabeledField
          className={classnames("account-fieldset__birthdate", {"account-fieldset__last-in-col": !hasField("Customer:taxident")})}
          name={AccountFieldset.BIRTHDATE}
          as={FormikDateInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          defaultView="decade"
        />

        {(hasField(AccountFieldset.TAX_IDENT)) && (
          <FormikLabeledField
            className="account-fieldset__tax-ident account-fieldset__last-in-col"
            name={AccountFieldset.TAX_IDENT}
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder(AccountFieldset.TAX_IDENT)}
          />
        )}

        {/* ==== account data ==== */}
        <ScreenSectionHeading
          light
          className="account-fieldset__title-account"
        >
          <T code={LOGIN_DATA} />
        </ScreenSectionHeading>

        <FormikLabeledField
          className="account-fieldset__mobile"
          name={AccountFieldset.PHONE}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AccountFieldset.PHONE)}
        />

        <FormikLabeledField
          className="account-fieldset__login"
          name={AccountFieldset.USERNAME}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AccountFieldset.USERNAME)}
        />

        <FormikLabeledField
          className="account-fieldset__password"
          name={AccountFieldset.PASSWORD}
          as={FormikPasswordInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AccountFieldset.PASSWORD)}
        />
      </div>
    </div>
  );
};
AccountFieldset.PREFIX_KEY = "Customer:prefixKey";
AccountFieldset.NAME = "Customer:name";
AccountFieldset.SURNAME = "Customer:surname";
AccountFieldset.BIRTHDATE = "Driver:birthdate";
AccountFieldset.PHONE = "Address:mobile";
AccountFieldset.USERNAME = "Customer:login";
AccountFieldset.PASSWORD = "Customer:password";
AccountFieldset.TAX_IDENT = "Customer:taxident";
AccountFieldset.valuesShape = {
  [AccountFieldset.PREFIX_KEY]: PropTypes.string,
  [AccountFieldset.NAME]: PropTypes.string,
  [AccountFieldset.SURNAME]: PropTypes.string,
  [AccountFieldset.PHONE]: PropTypes.string,
  [AccountFieldset.USERNAME]: PropTypes.string,
  [AccountFieldset.PASSWORD]: PropTypes.string,
  [AccountFieldset.TAX_IDENT]: PropTypes.string
};
AccountFieldset.propTypes = {
  className: PropTypes.string,
  values: PropTypes.shape(AccountFieldset.valuesShape),
  touched: PropTypes.object,
  errors: PropTypes.object,
  isFieldRequired: PropTypes.func.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  getFieldPlaceholder: PropTypes.func.isRequired,
  getSelectFieldOptions: PropTypes.func.isRequired
};

export const ConsentFieldset = (props) => {
  const {getFieldLabel, isReadOnly} = props;
  const className = classnames("consent-fieldset", props.className);

  const fieldProps = newFieldProps(props);
  const error = name => fieldProps(name).error;

  return (
    <Row className={className}>
      <Col desktop={{cols: 8, offset: 2}}>
        <div className="consent-fieldset__field">
          <div className="consent-fieldset__data-processing-consent-title">
            <T code={TITLE_DATA_PROCESSING_CONSENT} />
          </div>
          <T code={DATA_PROCESSING_CONSENT} />
        </div>

        <div className="consent-fieldset__field">
          <LabeledCheckbox error={error(ConsentFieldset.MAIL)}>
            <Checkbox {...fieldProps(ConsentFieldset.MAIL)} disabled={isReadOnly} />
            <span className="checkbox-label">{getFieldLabel(ConsentFieldset.MAIL)}</span>
            <ErrorMessage error={error(ConsentFieldset.MAIL)} />
          </LabeledCheckbox>
        </div>

        <div className="consent-fieldset__field">
          <LabeledCheckbox error={error(ConsentFieldset.EMAIL)}>
            <Checkbox {...fieldProps(ConsentFieldset.EMAIL)} disabled={isReadOnly} />
            <span className="checkbox-label">{getFieldLabel(ConsentFieldset.EMAIL)}</span>
            <ErrorMessage error={error(ConsentFieldset.EMAIL)} />
          </LabeledCheckbox>
        </div>

        <div className="consent-fieldset__field">
          <LabeledCheckbox error={error(ConsentFieldset.PHONE)}>
            <Checkbox {...fieldProps(ConsentFieldset.PHONE)} disabled={isReadOnly} />
            <span className="checkbox-label">{getFieldLabel(ConsentFieldset.PHONE)}</span>
            <ErrorMessage error={error(ConsentFieldset.PHONE)} />
          </LabeledCheckbox>
        </div>

        <div className="consent-fieldset__field">
          <div className="consent-fieldset__right-of-revocation-title">
            <T code={TITLE_RIGHT_OF_REVOCATION} />
          </div>
          <T code={RIGHT_OF_REVOCATION} />
        </div>
      </Col>
    </Row>
  );
};
ConsentFieldset.MAIL = "consentMail";
ConsentFieldset.EMAIL = "consentEmail";
ConsentFieldset.PHONE = "consentPhone";
ConsentFieldset.valuesShape = {
  [ConsentFieldset.MAIL]: PropTypes.bool,
  [ConsentFieldset.EMAIL]: PropTypes.bool,
  [ConsentFieldset.PHONE]: PropTypes.bool
};
ConsentFieldset.propTypes = {
  className: PropTypes.string,
  values: PropTypes.shape(ConsentFieldset.valuesShape),
  touched: PropTypes.object,
  errors: PropTypes.object,
  getFieldLabel: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export const AddressFieldset = (props) => {
  const {isFieldRequired, getFieldLabel, getFieldPlaceholder, getSelectFieldOptions} = props;
  const className = classnames("address-fieldset", props.className);

  return (
    <div className={className}>
      <div className="address-fieldset__row">
        <ScreenSectionHeading
          light
          className="address-fieldset__title-address"
        >
          <T code={ADDRESS} />
        </ScreenSectionHeading>

        <FormikLabeledField
          className="address-fieldset__street"
          name={AddressFieldset.STREET}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AddressFieldset.STREET)}
        />

        <FormikLabeledField
          className="address-fieldset__building"
          name={AddressFieldset.BUILDING}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(AddressFieldset.BUILDING)}
        />

        <div className="address-fieldset__zip-code-and-city">
          <FormikLabeledField
            className="address-fieldset__zip-code"
            name={AddressFieldset.ZIP_CODE}
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder(AddressFieldset.ZIP_CODE)}
          />

          <FormikLabeledField
            className="address-fieldset__city"
            name={AddressFieldset.CITY}
            as={FormikTextInput}
            getFieldLabel={getFieldLabel}
            isFieldRequired={isFieldRequired}
            placeholder={getFieldPlaceholder(AddressFieldset.CITY)}
          />
        </div>

        <FormikLabeledField
          className="address-fieldset__country-code"
          name={AddressFieldset.COUNTRY_CODE}
          as={FormikSelect}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          options={getSelectFieldOptions(AddressFieldset.COUNTRY_CODE)}
        />
      </div>
    </div>
  );
};
AddressFieldset.STREET = "Address:street";
AddressFieldset.BUILDING = "Address:building";
AddressFieldset.ZIP_CODE = "Address:zipCode";
AddressFieldset.CITY = "Address:city";
AddressFieldset.COUNTRY_CODE = "Address:countryCode";
AddressFieldset.valuesShape = {
  [AddressFieldset.STREET]: PropTypes.string,
  [AddressFieldset.BUILDING]: PropTypes.string,
  [AddressFieldset.ZIP_CODE]: PropTypes.string,
  [AddressFieldset.CITY]: PropTypes.string,
  [AddressFieldset.COUNTRY_CODE]: PropTypes.string,
};
AddressFieldset.propTypes = {
  className: PropTypes.string,
  values: PropTypes.shape(AddressFieldset.valuesShape),
  isFieldRequired: PropTypes.func.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  getFieldPlaceholder: PropTypes.func.isRequired,
  getSelectFieldOptions: PropTypes.func.isRequired
};

export const IdentificationAndDriversLicenseFieldset = (props) => {
  const {isFieldRequired, getFieldLabel, getFieldPlaceholder, getSelectFieldOptions} = props;
  const className = classnames("identification-and-drivers-license-fieldset", props.className);

  return (
    <div className={className}>
      <div className="identification-and-drivers-license-fieldset__row">
        {/* ==== Identification ==== */}
        <ScreenSectionHeading
          light
          className="identification-and-drivers-license-fieldset__title-identification"
        >
          <T code={IDENTIFICATION} />
        </ScreenSectionHeading>

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__ident-card-type"
          name={IdentificationAndDriversLicenseFieldset.IDENT_CARD_TYPE}
          as={FormikSelect}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          options={getSelectFieldOptions(IdentificationAndDriversLicenseFieldset.IDENT_CARD_TYPE)}
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__identification-card-no"
          name={IdentificationAndDriversLicenseFieldset.IDENT_CARD_NO}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(IdentificationAndDriversLicenseFieldset.IDENT_CARD_NO)}
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__identcard-ctry"
          name={IdentificationAndDriversLicenseFieldset.IDENT_CARD_COUNTRY}
          as={FormikSelect}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          options={getSelectFieldOptions(IdentificationAndDriversLicenseFieldset.IDENT_CARD_COUNTRY)}
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__licence-authority"
          name={IdentificationAndDriversLicenseFieldset.IDENT_CARD_AUTHORITY}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(IdentificationAndDriversLicenseFieldset.IDENT_CARD_AUTHORITY)}
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__ident-card-issued"
          name={IdentificationAndDriversLicenseFieldset.IDENT_CARD_DATE_ISSUED}
          as={FormikDateInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(IdentificationAndDriversLicenseFieldset.IDENT_CARD_DATE_ISSUED)}
          defaultView="decade"
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__identcard-valid-till"
          name={IdentificationAndDriversLicenseFieldset.IDENT_CARD_VALID_TILL}
          as={FormikDateInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(IdentificationAndDriversLicenseFieldset.IDENT_CARD_VALID_TILL)}
          defaultView="decade"
        />

        {/* ==== Drivers license ==== */}
        <ScreenSectionHeading
          light
          className="identification-and-drivers-license-fieldset__title-drivers-license"
        >
          <T code={DRIVERS_LICENSE} />
        </ScreenSectionHeading>

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__driver-licence-no"
          name={IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_NO}
          as={FormikTextInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_NO)}
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__licence-ctry"
          name={IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_COUNTRY}
          as={FormikSelect}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          options={getSelectFieldOptions(IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_COUNTRY)}
        />

        <FormikLabeledField
          className="identification-and-drivers-license-fieldset__licence-date-issued"
          name={IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_DATE_ISSUED}
          as={FormikDateInput}
          getFieldLabel={getFieldLabel}
          isFieldRequired={isFieldRequired}
          placeholder={getFieldPlaceholder(IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_DATE_ISSUED)}
          defaultView="decade"
        />
      </div>
    </div>
  );
};
IdentificationAndDriversLicenseFieldset.IDENT_CARD_TYPE = "Driver:identCardType";
IdentificationAndDriversLicenseFieldset.IDENT_CARD_NO = "Driver:identificationCardNo";
IdentificationAndDriversLicenseFieldset.IDENT_CARD_COUNTRY = "Driver:identcardCtry";
IdentificationAndDriversLicenseFieldset.IDENT_CARD_AUTHORITY = "Driver:licenceAuthority";
IdentificationAndDriversLicenseFieldset.IDENT_CARD_DATE_ISSUED = "Driver:identCardIssued";
IdentificationAndDriversLicenseFieldset.IDENT_CARD_VALID_TILL = "Driver:identcardValidTill";
IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_NO = "Driver:driverLicenceNo";
IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_COUNTRY = "Driver:licenceCtry";
IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_DATE_ISSUED = "Driver:licenceDateIssued";
IdentificationAndDriversLicenseFieldset.valuesShape = {
  [IdentificationAndDriversLicenseFieldset.IDENT_CARD_TYPE]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.IDENT_CARD_NO]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.IDENT_CARD_COUNTRY]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.IDENT_CARD_AUTHORITY]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.IDENT_CARD_DATE_ISSUED]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.IDENT_CARD_VALID_TILL]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_NO]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_COUNTRY]: PropTypes.string,
  [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_DATE_ISSUED]: PropTypes.string
};
IdentificationAndDriversLicenseFieldset.propTypes = {
  className: PropTypes.string,
  values: PropTypes.shape(IdentificationAndDriversLicenseFieldset.valuesShape),
  touched: PropTypes.object,
  errors: PropTypes.object,
  isFieldRequired: PropTypes.func.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  getFieldPlaceholder: PropTypes.func.isRequired,
  getSelectFieldOptions: PropTypes.func.isRequired
};

export const CreditCardsFieldset = (props) => {
  const className = classnames("credit-cards-fieldset", props.className);

  return (
    <div className={className}>
      <div className="credit-cards-fieldset__row">
        <ScreenSectionHeading light>
          <T code={CREDIT_CARDS} />
        </ScreenSectionHeading>
        <CreditCardList className="credit-cards-fieldset__credit-card-list" />
        <AddCreditCard
          className="credit-cards-fieldset__add-credit-card"
          buttonClassName="credit-cards-fieldset__add-credit-card-button"
        />
      </div>
    </div>
  );
};
CreditCardsFieldset.propTypes = {
  className: PropTypes.string
};
