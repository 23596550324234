import qs from "query-string";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {activateAccount} from "@awe-web/shared/lib/user/actions.js";
import {B2C_SignUpConfirmed, B2C_SelectStationAndTime} from "../../../routing/routes.js";

export const initialize = () => async (dispatch, getState, context) => {
  try {
    const {token: activationToken, rentContract: rentContractToken} = qs.parse(context.history.location.search);
    const {rentContractId} = await dispatch(activateAccount(activationToken, rentContractToken));
    dispatch(redirect(B2C_SignUpConfirmed.location({rentContractId})));
  }
  catch (error) {
    dispatch(redirect(B2C_SelectStationAndTime.location()));
    throw error;
  }
};
