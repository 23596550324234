"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.getCurrentDateTime = void 0;

var _reselect = require("reselect");

var _date = require("../util/date.js");

function reducer(state, action) {
  switch (action.type) {
    case "TIME/CURRENT_DATE_TIME_UPDATED":
      return state.set("currentDateTime", action.payload.currentDateTime);

    default:
      return state;
  }
}

var getCurrentDateTime = (0, _reselect.createSelector)([function (state) {
  return state.get("currentDateTime");
}], _date.parseDate);
exports.getCurrentDateTime = getCurrentDateTime;