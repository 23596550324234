"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contentTypes = void 0;

var _queryString = _interopRequireDefault(require("query-string"));

var contentTypes = {
  json: {
    header: "application/json",
    stringify: function stringify(data) {
      return JSON.stringify(data);
    }
  },
  form: {
    header: "application/x-www-form-urlencoded",
    stringify: function stringify(data) {
      return _queryString["default"].stringify(data);
    }
  },
  none: {
    header: undefined,
    stringify: function stringify() {
      return undefined;
    }
  }
};
exports.contentTypes = contentTypes;