import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder
} from "@awe-web/shared/lib/user/store.js";
import {
  Button,
  RequiredNotice,
  ScrollToError,
  FormikLabeledField,
  FormikTextInput,
  FormikPasswordInput
} from "../../components/form.js";
import {submit} from "./actions.js";

import "./reset_password_entry_form.scss";

const SUBMIT = "FSD0000352";
const NOTICE = "FSD0000209";

const Form = (props) => {
  const {
    isFieldRequired,
    getFieldLabel,
    getFieldPlaceholder,
    handleSubmit
  } = props;

  return (
    <form
      className="reset-password-entry-form"
      onSubmit={handleSubmit}
    >
      <ScrollToError />
      <FormikLabeledField
        className="reset-password-entry-form__username"
        name="username"
        as={FormikTextInput}
        getFieldLabel={getFieldLabel}
        isFieldRequired={isFieldRequired}
        placeholder={getFieldPlaceholder("username")}
      />

      <FormikLabeledField
        className="reset-password-entry-form__password"
        name="password"
        as={FormikPasswordInput}
        getFieldLabel={getFieldLabel}
        isFieldRequired={isFieldRequired}
        placeholder={getFieldPlaceholder("password")}
      />

      <Button
        primary
        continue
        submit
        className="reset-password-entry-form__submit-button"
      >
        <T code={SUBMIT} />
      </Button>

      <RequiredNotice><T code={NOTICE} /></RequiredNotice>
    </form>
  );
};
Form.propTypes = {
  isFieldRequired: PropTypes.func.isRequired,
  getFieldLabel: PropTypes.func.isRequired,
  getFieldPlaceholder: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const ResetPasswordEntryFormComponent = (props) => {
  const {
    validate,
    getInitialValues,
    isFieldRequired,
    getFieldLabel,
    getFieldPlaceholder,
    onSubmit
  } = props;
  const initialValues = getInitialValues({
    username: "",
    password: ""
  });

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {(formikProps) => (
        <Form
          {...formikProps}
          isFieldRequired={isFieldRequired}
          getFieldLabel={getFieldLabel}
          getFieldPlaceholder={getFieldPlaceholder}
        />
      )}
    </Formik>
  );
};
ResetPasswordEntryFormComponent.propTypes = {
  validate: PropTypes.func,
  getInitialValues: PropTypes.func.isRequired,
  isFieldRequired: Form.propTypes.isFieldRequired,
  getFieldLabel: Form.propTypes.getFieldLabel,
  getFieldPlaceholder: Form.propTypes.getFieldPlaceholder,
  onSubmit: PropTypes.func
};

const stateToResetPasswordEntryFormProps = createStateToProps({
  validate: newValidate("resetPassword"),
  getInitialValues: newGetInitialValues("resetPassword"),
  isFieldRequired: newIsFieldRequired("resetPassword"),
  getFieldLabel: newGetFieldLabel("resetPassword"),
  getFieldPlaceholder: newGetFieldPlaceholder("resetPassword")
});

const dispatchToResetPasswordEntryFormProps = (dispatch) => ({
  onSubmit: (values, formikProps) => dispatch(submit(values, formikProps))
});

export const ResetPasswordEntryForm = connect(
  stateToResetPasswordEntryFormProps,
  dispatchToResetPasswordEntryFormProps
)(ResetPasswordEntryFormComponent);
