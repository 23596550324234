import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./text_link_list.scss";

export const TextLinkList = (props) => {
  const className = classnames("text-link-list", props.className);

  const children = React.Children.map(props.children, child => (
    <li className="text-link-list__item">{child}</li>
  ));

  return (
    <ul className={className}>
      {children}
    </ul>
  );
};
TextLinkList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
