import classnames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createSelector} from "reselect";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {
  getStrFormattedBasePrice,
  getStrBasePriceLineup,
  getB2bStrIncludedBenefits,
  getB2cStrIncludedBenefits,
  getStrFormattedTotalPrice,
  getStrFormattedExtrasPrice,
  getStrExtrasLineup,
  getLtrMonthlyPricesLineup,
  getLtrMonthlyFormattedTotalPrice,
  getB2bLtrIncludedBenefits,
  getB2cLtrIncludedBenefits,
  getLtrOneTimePricesLineup,
  getLtrOneTimeFormattedTotalPrice
} from "../store.js";

import "./price.scss";

const STR_BASE_PRICE = "FSD0000127";
const STR_EXTRAS = "FSD0000164";
const STR_TOTAL_PRICE = "FSD0000132";
const LTR_MONTHLY_TOTAL_PRICE = "FSD0000132";
const LTR_ONE_TIME_TOTAL_PRICE = "ALG0000190";
const PER_MONTH = "ALG0000040";

const fixedText = (code) => createSelector([newTranslate], (translate) => translate(code));
const addClassName = (className) => (state, props) => classnames(className, props.className);

export const PriceBox = (props) => {
  const {children} = props;
  const className = classnames("price-box", props.className);

  return <div className={className}>{children}</div>;
};
PriceBox.propTypes = {
  children: PropTypes.node
};

/*
  Ex.

  *Basismietpreis*     *123,00 €*
  -------------------------------
  3 Tage inkl 250km/Tag   50,00 €
  -------------------------------
  Zus. Kilometer           0,30 €
  -------------------------------
*/
export const PriceLineup = (props) => {
  const {
    description,
    formattedPrice,
    lineup = []
  } = props;
  const className = classnames("price-lineup", props.className);

  return (
    <PriceBox className={className}>
      <div className="price-lineup__line">
        <span className="price-lineup__description price-lineup__description--main">{description}</span>
        <span className="price-lineup__price price-lineup__price--main">{formattedPrice}</span>
      </div>
      {lineup.length > 0 && (
        <ul className="price-lineup__list">
          {lineup.map((line) => (
            <li
              className="price-lineup__line price-lineup__list-item"
              key={`${line.description}-${line.formattedPrice}`}
            >
              <span className="price-lineup__description">{line.description}</span>
              <span className="price-lineup__price">{line.formattedPrice}</span>
            </li>
          ))}
        </ul>
      )}
    </PriceBox>
  );
};
PriceLineup.propTypes = {
  className: PropTypes.string,
  description: PropTypes.node,
  formattedPrice: PropTypes.node,
  lineup: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.node,
    formattedPrice: PropTypes.node
  }))
};

/*
  Ex.

  Basispreis je Monat   123,00 €/Monat
  ------------------------------------
  Zus. Kilometer                0,30 €
  ------------------------------------
  Gewählte Extras        50,00 €/Monat
  ------------------------------------
  *Gesamtmietpreis*         *173,00 €*
  inkl. ges. MwSt.           pro Monat
  ------------------------------------   (a)
  - kostenfreie Umbuchung
  - Haftpflichtversicherung


  (a) line only appears when list above price is empty
*/
export const TotalPriceLineup = (props) => {
  const {
    hideIfLineupIsEmpty,
    description,
    formattedPrice,
    descriptionHint,
    priceHint,
    lineup = [],
    benefits = []
  } = props;
  const hasLineup = lineup.length > 0;
  const hasBenefits = benefits.length > 0;
  const className = classnames("total-price-lineup", props.className);
  const benefitsClassName = classnames("total-price-lineup__benefits", {
    "total-price-lineup__benefits--with-divider": !hasLineup
  });

  if (hideIfLineupIsEmpty && !hasLineup) {
    return null;
  }

  return (
    <PriceBox className={className}>
      {hasLineup && (
        <ul className="total-price-lineup__list">
          {lineup.map((line) => (
            <li
              className="total-price-lineup__line"
              key={`${line.description}-${line.formattedPrice}`}
            >
              <span>{line.description}</span>
              <span>{line.formattedPrice}</span>
            </li>
          ))}
        </ul>
      )}
      <div className="total-price-lineup__total">
        <div>
          <span className="total-price-lineup__total__description">{description}</span>
          <span className="total-price-lineup__total__description-hint">{descriptionHint}</span>
        </div>
        <div>
          <span className="total-price-lineup__total__price">{formattedPrice}</span>
          <span className="total-price-lineup__total__price-hint">{priceHint}</span>
        </div>
      </div>
      {hasBenefits && (
        <ul className={benefitsClassName}>
          {benefits.map((benefit) => (
            <li
              className="total-price-lineup__benefit"
              key={benefit.description}
            >
              {benefit.description}
            </li>
          ))}
        </ul>
      )}
    </PriceBox>
  );
};
TotalPriceLineup.propTypes = {
  hideIfLineupIsEmpty: PropTypes.bool,
  description: PropTypes.node,
  formattedPrice: PropTypes.node,
  descriptionHint: PropTypes.node,
  priceHint: PropTypes.node,
  lineup: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.node,
    formattedPrice: PropTypes.node
  })),
  benefits: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.node
  }))
};

export const StrBasePrice = connect(createStateToProps({
  className: addClassName("str-base-price"),
  description: fixedText(STR_BASE_PRICE),
  formattedPrice: getStrFormattedBasePrice,
  lineup: getStrBasePriceLineup
}))(PriceLineup);

export const StrExtrasPrice = connect(createStateToProps({
  className: addClassName("str-extras-price"),
  description: fixedText(STR_EXTRAS),
  formattedPrice: getStrFormattedExtrasPrice,
  lineup: getStrExtrasLineup
}))(PriceLineup);

export const StrTotalPrice = connect(createStateToProps({
  className: addClassName("str-total-price"),
  description: fixedText(STR_TOTAL_PRICE),
  formattedPrice: (state, props) => props.formattedPrice || getStrFormattedTotalPrice(state),
  benefits: (state, props) => props.reservationPath === "B2B"
    ? getB2bStrIncludedBenefits(state)
    : getB2cStrIncludedBenefits(state)
}))(TotalPriceLineup);

export const LtrMonthlyTotalPrice = connect(createStateToProps({
  className: addClassName("ltr-monthly-total-price"),
  description: fixedText(LTR_MONTHLY_TOTAL_PRICE),
  formattedPrice: (state, props) => props.formattedPrice || getLtrMonthlyFormattedTotalPrice(state),
  priceHint: fixedText(PER_MONTH),
  lineup: (state, props) => props.lineup || getLtrMonthlyPricesLineup(state),
  benefits: (state, props) => props.reservationPath === "B2B"
    ? getB2bLtrIncludedBenefits(state)
    : getB2cLtrIncludedBenefits(state)
}))(TotalPriceLineup);

export const LtrOneTimeTotalPrice = connect(createStateToProps({
  className: addClassName("ltr-one-time-total-price"),
  hideIfLineupIsEmpty: () => true,
  description: fixedText(LTR_ONE_TIME_TOTAL_PRICE),
  formattedPrice: getLtrOneTimeFormattedTotalPrice,
  lineup: getLtrOneTimePricesLineup
}))(TotalPriceLineup);
