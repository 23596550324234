import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {loadCreditCards} from "@awe-web/shared/lib/user/actions.js";
import {getCreditCards} from "@awe-web/shared/lib/user/store.js";
import {
  loadRentContractData,
  submitRentContract
} from "../../../rent_contracts/actions.js";
import {getCreditCardsEnabled} from "../../../config/store.js";
import {B2C_STR_SubmitRentContractSuccess} from "../../../routing/routes.js";

const TOS_ERROR = "FSD0000288";
const PRIVACY_ERROR = "FSD0000289";
const CREDIT_CARD_ERROR = "FSD0000290";

export const initialize = () => async (dispatch, getState) => {
  const translate = newTranslate(getState());
  const creditCardsEnabled = getCreditCardsEnabled(getState());

  await Promise.all([
    dispatch(loadRentContractData({
      reservationPath: "B2C"
    })),
    creditCardsEnabled && dispatch(loadCreditCards())
  ]);

  const creditCards = getCreditCards(getState());

  dispatch({
    type: "USER/FORM_CONFIGURATION_LOADED",
    payload: {
      groupName: "submitB2cStrRentContract",
      formConfiguration: [
        {
          items: [
            {
              name: "contractNote",
              xtype: "textfield",
              allowBlank: true
            },
            {
              name: "agbAccepted",
              invalidText: translate(TOS_ERROR),
              xtype: "checkbox",
              allowBlank: false
            },
            {
              name: "privacyAccepted",
              invalidText: translate(PRIVACY_ERROR),
              xtype: "checkbox",
              allowBlank: false
            }
          ].concat(
            creditCardsEnabled
              ? [{
                name: "creditCardId",
                xtype: "textfield",
                emptyText: translate(CREDIT_CARD_ERROR),
                value: creditCards.first() && creditCards.first().get("id"),
                allowBlank: false
              }]
              : []
          )
        }
      ]
    }
  });
};

export const submit = (formValues) => async (dispatch, getState) => {
  const {creditCardId, contractNote, agbAccepted} = formValues;
  const rentContractId = getRentContractId(getState());
  await dispatch(submitRentContract(rentContractId, {creditCardId, contractNote, agbAccepted}));

  dispatch(navigate(B2C_STR_SubmitRentContractSuccess.location({rentContractId})));
};
