import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading, ScreenSectionHeading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {DashboardItems} from "../../../components/dashboard_item.js";
import {getCompanyName} from "../../../user/store.js";
import {
  B2B_History,
  B2B_Profile,
  B2B_Payment
} from "../../../routing/routes.js";

const TITLE = "ALG0000079";
const RESERVATIONS_HEADING = "ALG0000080";
const RESERVATIONS_TEXT = "ALG0000081";
const RESERVATIONS_IMAGE = "ALG0000082";
const PROFILE_HEADING = "ALG0000083";
const PROFILE_TEXT = "ALG0000084";
const PROFILE_IMAGE = "ALG0000085";
const PAYMENT_DETAILS_HEADING = "ALG0000086";
const PAYMENT_DETAILS_TEXT = "ALG0000087";
const PAYMENT_DETAILS_IMAGE = "ALG0000088";

const DashboardComponent = (props) => {
  const {
    translate,
    companyName
  } = props;
  const dashboardItems = [
    {
      heading: translate(RESERVATIONS_HEADING),
      text: translate(RESERVATIONS_TEXT),
      image: translate(RESERVATIONS_IMAGE),
      link: B2B_History.path()
    },
    {
      heading: translate(PROFILE_HEADING),
      text: translate(PROFILE_TEXT),
      image: translate(PROFILE_IMAGE),
      link: B2B_Profile.path()
    },
    {
      heading: translate(PAYMENT_DETAILS_HEADING),
      text: translate(PAYMENT_DETAILS_TEXT),
      image: translate(PAYMENT_DETAILS_IMAGE),
      link: B2B_Payment.path()
    }
  ];

  return (
    <div className="b2b-dashboard">
      <ScreenSection main dark>
        <TypeArea>
          <ScreenHeading>{translate(TITLE)}</ScreenHeading>
          <ScreenSectionHeading light>{companyName}</ScreenSectionHeading>

          <DashboardItems dashboardItems={dashboardItems} />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
DashboardComponent.propTypes = {
  translate: PropTypes.func,
  companyName: PropTypes.string
};

const stateToDashboardProps = createStateToProps({
  companyName: getCompanyName
});

export default connect(
  stateToDashboardProps
)(withTranslation(DashboardComponent));
