import {Map, List} from "immutable";
import {createSelector} from "reselect";
import {not} from "@awe-web/shared/lib/util/fn.js";
import {substitute} from "@awe-web/shared/lib/util/string.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {getProcessedRentObjects} from "@awe-web/shared/lib/rent_objects/store.js";
import {calcBases} from "@awe-web/shared/lib/rent_contracts/constants.js";

const COST_PER_DAY = "FSD0000278";
const COST_PER_MONTH = "ALG0000111";
const MIN_DRIVER_AGE = "FSD0000279";

export default function reducer(state, action) {
  switch (action.type) {
    case "RENT_OBJECTS/RENT_OBJECT_SELECTED":
      return state.set("selectedClassificationId", action.payload.classificationId);

    default:
      return state;
  }
}

const getRawClassifications = (state) => state.get("classifications") || List();
const getClassificationsById = createSelector(
  [getRawClassifications],
  (classifications) => Map(classifications.map(c => [c.get("id"), c]))
);

const hasExtCode = extCode => property => property.get("extCode1") === extCode;
const rentObjectGroupId = rentObject =>
  rentObject.getIn(["classification11", "id"]);
const rentObjectGroupName = rentObject =>
  rentObject.getIn(["classification11", "shortDescr"]);
const rentObjectCostPerPeriod = (rentObject, translate) => {
  const costPerPeriod = rentObject.get("formattedBaseCost");
  const mlCode = {
    [calcBases.PER_DAY]: COST_PER_DAY,
    [calcBases.PER_MONTH]: COST_PER_MONTH
  }[rentObject.get("baseCostCalcBase")];
  return costPerPeriod && mlCode
    ? substitute(translate(mlCode), {costPerPeriod})
    : undefined;
};
const rentObjectMinDriverAge = (rentObject, translate) => {
  const minDriverAge = rentObject.getIn(["classification4", "minDriverAge"]);
  return minDriverAge
    ? substitute(translate(MIN_DRIVER_AGE), {minDriverAge})
    : undefined;
};
const rentObjectProperty = (rentObject, extCode) =>
  rentObject.get("properties").find(hasExtCode(extCode));
const hasProperty = (rentObject, extCode) =>
  rentObjectProperty(rentObject, extCode) !== undefined;
const propertyValue = (rentObject, extCode) =>
  (rentObjectProperty(rentObject, extCode) || Map()).get("value");
const rentObjectExtraEquipment = (rentObject) =>
  rentObject.get("properties")
    .filter(not(hasExtCode("AS")))
    .filter(not(hasExtCode("AC")))
    .filter(not(hasExtCode("NV")))
    .filter(not(hasExtCode("HV")))
    .sortBy(property => property.get("sortNo"));

export const getRentObjects = createSelector(
  [newTranslate, getProcessedRentObjects, getClassificationsById],
  (translate, rentObjects, classifications) => rentObjects

    // inline classification properties
    .map(
      rentObject => rentObject
        .set("groupId", rentObjectGroupId(rentObject))
        .set("groupName", rentObjectGroupName(rentObject))
        .set("costPerPeriod", rentObjectCostPerPeriod(rentObject, translate))
        .set("minDriverAge", rentObjectMinDriverAge(rentObject, translate))
        .set("hasAirCondition", hasProperty(rentObject, "AC"))
        .set("hasNavigationSystem", hasProperty(rentObject, "NV"))
        .set("hasCellPhoneIntegration", hasProperty(rentObject, "HV"))
        .set("numberOfSeats", propertyValue(rentObject, "AS"))
        .set("extraEquipment", rentObjectExtraEquipment(rentObject)))

    // remove rentObjects with incomplete data
    .filter(
      rentObject =>
        rentObject.get("numberOfSeats") !== undefined &&
        rentObject.get("formattedBaseCost") !== undefined)

    // sort by classific11.sort and classific4.sort
    .sort((roA, roB) => {
      const groupSortA = roA.getIn(["classification11", "sort"]);
      const groupSortB = roB.getIn(["classification11", "sort"]);
      const classSortA = roA.getIn(["classification4", "sort"]);
      const classSortB = roB.getIn(["classification4", "sort"]);

      return groupSortA !== groupSortB
        ? groupSortA - groupSortB
        : classSortA - classSortB;
    })
);

const groupRentObjects = (rentObjects) =>
  rentObjects.groupBy(rentObject => "rent-object-group-" + rentObject.get("groupId"));
export const getGroupedRentObjectsB2C = createSelector(
  [getRentObjects],
  rentObjects => groupRentObjects(
    rentObjects
      .filter(rentObject => rentObject.get("minDriverAge") !== undefined))
);
export const getGroupedRentObjectsB2B = createSelector(
  [getRentObjects],
  groupRentObjects
);

const rentObjectGroups = (groupedRentObjects) => groupedRentObjects
  .entrySeq()
  .map(([elementId, rentObjects]) => Map([
    ["elementId", elementId],
    ["name", rentObjects.first().get("groupName")]
  ]))
  .toList();
export const getRentObjectGroupsB2C = createSelector(
  [getGroupedRentObjectsB2C],
  rentObjectGroups
);
export const getRentObjectGroupsB2B = createSelector(
  [getGroupedRentObjectsB2B],
  rentObjectGroups
);

export const getSelectedRentObject = createSelector(
  [getRentObjects, state => state.get("selectedClassificationId")],
  (rentObjects, classificationId) => rentObjects.find(rentObject => rentObject.get("classificationId") === classificationId));
