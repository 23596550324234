import {ruleBls} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {toIsoString} from "@awe-web/shared/lib/util/date.js";
import {selectKeys} from "@awe-web/shared/lib/util/object.js";
import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {
  loadRentContract,
  activatePromocode as activatePromocodeBase
} from "@awe-web/shared/lib/rent_contracts/actions.js";
import {loadRentObjects} from "@awe-web/shared/lib/rent_objects/actions.js";
import {
  rentContractToSelectedCoStationId,
  rentContractToCiStationId,
  rentContractToCoDateTime,
  rentContractToCiDateTime,
  rentContractToClassificationId
} from "@awe-web/shared/lib/rent_contracts/util.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {
  setCoStation,
  setCiStation,
  setCoDateTime,
  setCiDateTime,
} from "../stations/actions.js";
import {setRentObject} from "../rent_objects/actions.js";
import {ensureUserLoggedIn} from "../user/actions.js";
import {B2C_SelectStationAndTime, B2B_SelectStationAndTime} from "../routing/routes.js";
import {getRentObjectStationIdFromRentContract} from "./util.js";

export const loadTariffOptions = (rentContractId) => async (dispatch, getState, context) => {
  const tariffOptions = await context.api.rentContracts.getTariffOptions({rentContractId});

  dispatch({
    type: "RENT_CONTRACTS/TARIFF_OPTIONS_LOADED",
    payload: {
      tariffOptions
    }
  });
};

export const loadAvailableRentObjectProperties = (rentContractId) => async (dispatch, getState, context) => {
  const availableRentObjectProperties = await context.api.rentContracts.getAvailableRentObjectProperties({rentContractId});

  dispatch({
    type: "RENT_CONTRACTS/AVAILABLE_RENT_OBJECT_OPTIONS_LOADED",
    payload: {
      rentContractId,
      availableRentObjectProperties
    }
  });
};

const loadBasicRentContractData = (rentContract, reservationPath) => async (dispatch, getState, context) => {
  const contractType = rentContract.type;
  const {coStationId, pickupStationId} = getRentObjectStationIdFromRentContract(rentContract, reservationPath, getState());
  const classificationId = rentContractToClassificationId(rentContract);

  await dispatch(loadRentObjects(contractType, coStationId, pickupStationId));
  dispatch(setRentObject(classificationId));
};

export const loadRentContractData = ({
  reservationPath = "B2B",
  additionalAction = () => () => {}
} = {}) => async (dispatch, getState, context) => {
  const {rentContractId} = context.history.location.state || {};
  const redirectLocation = reservationPath === "B2C"
    ? B2C_SelectStationAndTime.location()
    : B2B_SelectStationAndTime.location();

  if (!rentContractId) {
    dispatch(redirect(redirectLocation));
    return;
  }

  const [rentContract] = await Promise.all([
    dispatch(loadRentContract(rentContractId)),
    dispatch(loadAvailableRentObjectProperties(rentContractId))
  ]);

  const coStationId = rentContractToSelectedCoStationId(rentContract);
  const ciStationId = rentContractToCiStationId(rentContract);
  const coDateTime = rentContractToCoDateTime(rentContract);
  const ciDateTime = rentContractToCiDateTime(rentContract);

  await Promise.all([
    dispatch(loadBasicRentContractData(rentContract, reservationPath)),
    dispatch(setCoStation(coStationId)),
    dispatch(setCiStation(ciStationId)),
    dispatch(additionalAction(rentContract))
  ]);
  dispatch(setCoDateTime(toIsoString(coDateTime)));
  dispatch(setCiDateTime(toIsoString(ciDateTime)));
};

const diffProperties = (updatedProps, existingProps) => {
  const _diffProperties = (sortedUpdatedProps, sortedExistingProps, propertiesToAdd = [], propertyIdsToRemove = []) => {
    if (sortedUpdatedProps.length === 0 && sortedExistingProps.length === 0) {
      return {propertiesToAdd, propertyIdsToRemove};
    }

    const nextUpdated = sortedUpdatedProps[0] || {id: Number.POSITIVE_INFINITY};
    const nextExisting = sortedExistingProps[0] || {id: Number.POSITIVE_INFINITY};

    if (nextUpdated.id < nextExisting.id) {
      return _diffProperties(
        sortedUpdatedProps.slice(1),
        sortedExistingProps,
        propertiesToAdd.concat([nextUpdated]),
        propertyIdsToRemove
      );
    }
    else if (nextUpdated.id > nextExisting.id) {
      return _diffProperties(
        sortedUpdatedProps,
        sortedExistingProps.slice(1),
        propertiesToAdd,
        propertyIdsToRemove.concat([nextExisting.id])
      );
    }
    else if (nextUpdated.wwwAnnotation !== nextExisting.wwwAnnotation) {
      return _diffProperties(
        sortedUpdatedProps.slice(1),
        sortedExistingProps.slice(1),
        propertiesToAdd.concat([nextUpdated]),
        propertyIdsToRemove.concat([nextUpdated.id])
      );
    }
    else {
      return _diffProperties(
        sortedUpdatedProps.slice(1),
        sortedExistingProps.slice(1),
        propertiesToAdd,
        propertyIdsToRemove
      );
    }
  };

  return _diffProperties(
    updatedProps.slice().sort((a, b) => a.id - b.id),
    existingProps.slice().sort((a, b) => a.id - b.id),
    [],
    []
  );
};

export const setRentObjectProperties = (rentContract, rentObjectPropertiesMap = {}) => async (dispatch, getState, context) => {
  const {
    addRentObjectPropertiesToRentContract,
    removeRentObjectPropertiesFromRentContract
  } = context.api.rentContracts;

  const rentContractId = rentContract.id;

  const existingProperties = rentContract.rentContractProperties || [];
  const rentObjectProperties = Object.entries(rentObjectPropertiesMap)
    .map(([id, value]) => [Number(id), value])
    .filter(([id, value]) => Boolean(id) && Boolean(value))
    .map(([id, value]) => ({id, wwwAnnotation: typeof value === "string" ? value : undefined}));
  const {
    propertiesToAdd,
    propertyIdsToRemove
  } = diffProperties(rentObjectProperties, existingProperties);

  // We need to first remove and then add the properties b/c AMS does not
  // support updating properties
  if (propertyIdsToRemove.length > 0) {
    await removeRentObjectPropertiesFromRentContract({
      rentContractId,
      rentObjectPropertyIds: propertyIdsToRemove
    });
  }
  if (propertiesToAdd.length > 0) {
    await addRentObjectPropertiesToRentContract({
      rentContractId,
      rentObjectProperties: propertiesToAdd
    });
  }
};

export const setDeductibleAndMileageOptions = (rentContract, deductibleId, mileageIds = []) => async (dispatch, getState, context) => {
  const rentContractId = rentContract.id;
  const enabledItemIds = [...mileageIds, deductibleId];
  const invoiceItems = rentContract.invoiceItems
    .filter(invoiceItem => [ruleBls.CDW, ruleBls.S_CDW, ruleBls.MILEAGE_PACKAGE].includes(invoiceItem.ruleBL));

  await context.api.rentContracts.enableDisableInvoiceItems({rentContractId, invoiceItems, enabledItemIds});
};

export const setDeductibleOption = (rentContract, deductibleId) => async (dispatch) => {
  await dispatch(setDeductibleAndMileageOptions(rentContract, deductibleId, []));
};

export const setTariffOptionId = (rentContractId, tariffOptionId) => async (dispatch, getState, context) => {
  const rentContract = await context.api.rentContracts.getRentContract({rentContractId});
  const updatedRentContract = {
    ...rentContract,
    selectedTariffOptionId: tariffOptionId
  };
  await context.api.rentContracts.updateRentContract({updatedRentContract});
  await dispatch(loadRentContract(rentContractId));
};

export const setDriver = (rentContractId, values) => async (dispatch, getState, context) => {
  const mapFormValues = newMapFormValues("personContractDriver")(getState());
  const driver = mapFormValues(selectKeys(values, [
    "Person:prefixKey",
    "Person:surname",
    "Person:name",
    "Address:email",
    "Address:phone",
    "employeeId",
    "costCenter"
  ]));

  await context.api.rentContracts.setDriver({rentContractId, driver});
};

export const setProvisioningDelivery = (rentContractId, deliveryAddress) => async (dispatch, setState, context) => {
  await context.api.rentContracts.setDeliveryAddress({rentContractId, deliveryAddress});
};

export const setProvisioningPickup = (rentContractId) => async (dispatch, getState, context) => {
  await context.api.rentContracts.deleteDeliveryAddress({rentContractId});
};

export const activatePromocode = (promocode) => async (dispatch, getState, context) => {
  try {
    await dispatch(activatePromocodeBase(promocode));
  }
  catch (error) {
    // TODO Remove custom error handling and replace it with
    // serverValidationFailed action. See mini-flex implementation.
    if (error.displayMessage) {
      dispatch({
        type: "RENT_CONTRACTS/PROMOCODE_ACTIVATION_FAILED",
        payload: {
          error: {
            displayMessage: error.displayMessage
          }
        }
      });
      return;
    }

    throw error;
  }
};

export const submitRentContract = (rentContractId, {creditCardId, contractNote, agbAccepted}) => async (dispatch, getState, context) => {
  await dispatch(ensureUserLoggedIn());

  await dispatch(asyncAction({
    typePrefix: "RENT_CONTRACTS/SUBMIT_RENT_CONTRACT",
    loadPayload: () => context.api.rentContracts.submitRentContract({rentContractId, creditCardId, contractNote, agbAccepted})
  }));
};
