import React, {Component, createRef, forwardRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {
  getIsPromocodeActivated,
  getFormattedPromocodeSavedAmount
} from "@awe-web/shared/lib/rent_contracts/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {TextInput, Button, ErrorMessage, LabeledField, InputLabel} from "../../components/form.js";
import {InlineLoadingIndicator} from "../../components/loading_indicator.js";
import {PriceBox, PriceLineup} from "./price.js";
import {getPromocodeActivationErrorMessage} from "../store.js";
import {activatePromocode} from "../actions.js";

import "./activate_promocode.scss";

const HEADING = "FSD0000165";
const INPUT_PLACEHOLDER = "FSD0000166";
const ACTIVATION_NOTICE = "FSD0000167";
const PROMOCODE_PROCESSING = "FSD0000362";
const PROMOCODE_ACTIVATED = "FSD0000336";
const SUBMIT = "FSD0000168";

const NotActivated = forwardRef((props, ref) => {
  const {errorMessage, translate, onClick} = props;

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <div>
      <LabeledField>
        <InputLabel><T code={HEADING} /></InputLabel>
        <TextInput
          ref={ref}
          placeholder={translate(INPUT_PLACEHOLDER)}
          error={errorMessage}
          onKeyDown={handleKeyDown}
        />
        <ErrorMessage error={errorMessage} />
      </LabeledField>

      <div className="activate-promocode__notice"><T code={ACTIVATION_NOTICE} /></div>
      <Button secondary continue onClick={onClick}><T code={SUBMIT} /></Button>
    </div>
  );
});
NotActivated.propTypes = {
  errorMessage: PropTypes.string,
  translate: PropTypes.func,
  onClick: PropTypes.func
};

const Activated = (props) => {
  const {className, formattedPromocodeSavedAmount} = props;

  return (
    <PriceLineup
      className={className}
      description={<T className="activate-promocode__success" code={PROMOCODE_ACTIVATED} />}
      formattedPrice={formattedPromocodeSavedAmount}
    />
  );
};
Activated.propTypes = {
  className: PropTypes.string,
  formattedPromocodeSavedAmount: PropTypes.string
};

class ActivatePromocodeComponent extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();

    this.handleClick = () => {
      const {activatePromocode = () => {}} = this.props;
      const promocode = this.inputRef.current.value.trim();
      if (promocode) {
        activatePromocode(promocode);
      }
    };
  }

  render() {
    const {handleClick, inputRef} = this;
    const {errorMessage, translate, isPromocodeActivated, formattedPromocodeSavedAmount} = this.props;
    const className = classnames("activate-promocode", this.props.className);

    return isPromocodeActivated
      ? (
        <Activated
          className={className}
          formattedPromocodeSavedAmount={formattedPromocodeSavedAmount}
        />
      )
      : (
        <PriceBox className={className}>
          <InlineLoadingIndicator typePrefix="RENT_CONTRACTS/RENT_CONTRACT" message={<T code={PROMOCODE_PROCESSING} />}>
            <NotActivated
              ref={inputRef}
              errorMessage={errorMessage}
              translate={translate}
              onClick={handleClick}
            />
          </InlineLoadingIndicator>
        </PriceBox>
      );
  }
}
ActivatePromocodeComponent.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  translate: PropTypes.func,
  isPromocodeActivated: PropTypes.bool,
  formattedPromocodeSavedAmount: PropTypes.string,
  activatePromocode: PropTypes.func
};

const stateToActivatePromocodeProps = createStateToProps({
  translate: newTranslate,
  isPromocodeActivated: getIsPromocodeActivated,
  formattedPromocodeSavedAmount: getFormattedPromocodeSavedAmount,
  errorMessage: getPromocodeActivationErrorMessage
});

const dispatchToActivatePromocodeProps = (dispatch) => ({
  activatePromocode: (promocode) => {
    dispatch(activatePromocode(promocode));
  }
});

export const ActivatePromocode = connect(
  stateToActivatePromocodeProps,
  dispatchToActivatePromocodeProps
)(ActivatePromocodeComponent);
