import React, {useState, useCallback, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T, withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {historyTabs} from "@awe-web/shared/lib/history/constants.js";
import {Row, Col} from "../../components/grid.js";
import {
  FormikTextInput,
  Button,
  ActionButtons,
  FormikLabeledField
} from "../../components/form.js";
import {Overlay, OverlayHeading, OverlaySection} from "../../components/overlay.js";
import {Pagination} from "../../components/pagination.js";
import {InlineLoadingIndicator} from "../../components/loading_indicator.js";
import {
  getReservationHistory,
  getActiveTab,
  getCurrentPage,
  getNumberOfPages,
  getCsvExportUrl,
  getFilterValues
} from "../store.js";
import {
  initializeHistory,
  selectTab,
  nextPage,
  previousPage,
  setFilter,
  cancelReservation
} from "../actions.js";

import "./history.scss";

const TAB_ACTIVE_RESERVATIONS = "FSD0000314";
const TAB_PAST_RESERVATIONS = "FSD0000315";

const OPEN_CANCEL_RESERVATION_OVERLAY = "ALG0000176";
const CANCEL_RESERVATION_TITLE = "FSD0000326";
const CANCEL_RESERVATION_CONFIRMATION = "FSD0000327";
const CANCEL_RESERVATION_BUTTON = "FSD0000329";
const CANCEL_OVERLAY_BUTTON = "FSD0000330";

const CLASSIFICATION = "FSD0000316";
const LICENSE_PLATE = "ALG0000170";
const GROSS_LIST_PRICE = "ALG0000171";
const RESERVATION_NO_SHORT = "FSD0000318";
const DRIVER = "ALG0000172";
const CHECK_OUT_TIMESTAMP = "FSD0000319";
const CHECK_IN_TIMESTAMP = "ALG0000243";
const GROSS_PRICE = "ALG0000174";
const STATE = "FSD0000324";
const ACTION = "FSD0000325";
const NO_RESERVATIONS_FOUND = "FSD0000341";

const HISTORY_LOADING = "FSD0000358";
const DOWNLOAD = "ALG0000166";
const FILTER_LICENSE_PLATE_LABEL = "ALG0000186";
const FILTER_LICENSE_PLATE_PLACEHOLDER = "ALG0000187";
const FILTER_DRIVER_NAME_LABEL = "ALG0000188";
const FILTER_DRIVER_NAME_PLACEHOLDER = "ALG0000189";
const FILTER_BUTTON_LABEL = "ALG0000169";
const NOTICE = "ALG0000175";

const reservationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  imageUrl1: PropTypes.string,
  classificationDescr: PropTypes.string,
  registrationNumber: PropTypes.string,
  formattedListPriceGross: PropTypes.string,
  reservationNo: PropTypes.number,
  firstDriverName: PropTypes.string,
  formattedCoDateTime: PropTypes.string,
  formattedInvoiceAmountNet: PropTypes.string,
  formattedInvoiceAmountGross: PropTypes.string,
  stateDescription: PropTypes.string,
  isCancelable: PropTypes.bool
});

const cellValue = (value) => value || "—";

const HistoryTabs = (props) => {
  const {
    selectedTab = historyTabs.ACTIVE,
    onSelectActive,
    onSelectPast
  } = props;
  const className = classnames("history-tabs", props.className);
  const classNameActiveButton = classnames("history-tabs__button", {
    "history-tabs__button--active": selectedTab === historyTabs.ACTIVE
  });
  const classNamePastButton = classnames("history-tabs__button", {
    "history-tabs__button--active": selectedTab === historyTabs.PAST
  });

  return (
    <div className={className}>
      <button
        className={classNameActiveButton}
        type="button"
        onClick={onSelectActive}
      >
        <T code={TAB_ACTIVE_RESERVATIONS} />
      </button>
      <button
        className={classNamePastButton}
        type="button"
        onClick={onSelectPast}
      >
        <T code={TAB_PAST_RESERVATIONS} />
      </button>
    </div>
  );
};
HistoryTabs.propTypes = {
  className: PropTypes.string,
  selectedTab: PropTypes.oneOf([
    historyTabs.ACTIVE,
    historyTabs.PAST
  ]),
  onSelectActive: PropTypes.func,
  onSelectPast: PropTypes.func
};

const CancelReservationOverlayContent = (props) => {
  const {reservation, confirm, closeOverlay} = props;

  const row = (labelCode, value) => (
    <div className="cancel-reservation-overlay-content__row">
      <div>
        <T code={labelCode} />
      </div>
      <div className="cancel-reservation-overlay-content__row__value">
        {cellValue(value)}
      </div>
    </div>
  );

  return (
    <>
      <OverlayHeading><T code={CANCEL_RESERVATION_TITLE} /></OverlayHeading>
      <OverlaySection>
        <p><T code={CANCEL_RESERVATION_CONFIRMATION} /></p>

        <div className="cancel-reservation-overlay-content__rent-object">
          {row(CLASSIFICATION, reservation.classificationDescr)}
          {row(LICENSE_PLATE, reservation.registrationNumber)}
          {row(GROSS_LIST_PRICE, reservation.formattedListPriceGross)}
          {row(RESERVATION_NO_SHORT, reservation.reservationNo)}
          {row(DRIVER, reservation.firstDriverName)}
          {row(CHECK_OUT_TIMESTAMP, reservation.formattedCoDateTime)}
          {row(CHECK_IN_TIMESTAMP, reservation.formattedCiDateTime)}
          {row(GROSS_PRICE, reservation.formattedInvoiceAmountGross)}
          {row(STATE, reservation.stateDescription)}
        </div>

        <ActionButtons>
          <Button primary continue onClick={confirm}>
            <T code={CANCEL_RESERVATION_BUTTON} />
          </Button>
          <Button secondary back onClick={closeOverlay}>
            <T code={CANCEL_OVERLAY_BUTTON} />
          </Button>
        </ActionButtons>
      </OverlaySection>
    </>
  );
};
CancelReservationOverlayContent.propTypes = {
  confirm: PropTypes.func,
  closeOverlay: PropTypes.func,
  reservation: reservationShape.isRequired
};

const CancelReservationButton = (props) => {
  const {reservation} = props;

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const closeOverlay = useCallback(
    () => setIsOverlayOpen(false),
    []
  );
  const cancelReservationRef = useRef();
  cancelReservationRef.current = props.cancelReservation;
  const cancelReservation = useCallback(
    () => {
      closeOverlay();
      cancelReservationRef.current(reservation.id);
    },
    [closeOverlay, reservation.id]
  );

  return (
    <>
      <Button
        tertiary
        className="cancel-reservation-button"
        onClick={() => { setIsOverlayOpen(true); }}
      >
        <T code={OPEN_CANCEL_RESERVATION_OVERLAY} />
      </Button>

      <Overlay
        className="cancel-reservation-button__overlay"
        open={isOverlayOpen}
        onClose={closeOverlay}
      >
        <CancelReservationOverlayContent
          reservation={reservation}
          confirm={cancelReservation}
          closeOverlay={closeOverlay}
        />
      </Overlay>
    </>
  );
};
CancelReservationButton.propTypes = {
  reservation: CancelReservationOverlayContent.propTypes.reservation,
  cancelReservation: PropTypes.func
};

const HistoryTable = withTranslation((props) => {
  const {translate, showActionColumn, reservations, cancelReservation} = props;
  const className = classnames("history-table", props.className);

  const th = (labelCode) => (
    <th className="history-table__column-header">
      <T code={labelCode} />
    </th>
  );
  const td = (labelCode, value, className) => (
    <td
      className={classnames("history-table__cell", className)}
      data-label={translate(labelCode)}
    >
      {cellValue(value)}
    </td>
  );

  return (
    <table className={className}>
      <thead className="history-table__head">
        <tr className="history-table__head__row">
          {th(CLASSIFICATION)}
          {th(LICENSE_PLATE)}
          {th(GROSS_LIST_PRICE)}
          {th(RESERVATION_NO_SHORT)}
          {th(DRIVER)}
          {th(CHECK_OUT_TIMESTAMP)}
          {th(CHECK_IN_TIMESTAMP)}
          {th(GROSS_PRICE)}
          {th(STATE)}
          {showActionColumn
            ? th(ACTION)
            : null
          }
        </tr>
      </thead>
      <tbody className="history-table__body">
        {reservations.length > 0
          ? reservations.map((reservation) => (
              <tr key={reservation.id} className="history-table__body__row">
                {td(CLASSIFICATION, reservation.classificationDescr, "history-table__cell--rent-object-name")}
                {td(LICENSE_PLATE, reservation.registrationNumber)}
                {td(GROSS_LIST_PRICE, reservation.formattedListPriceGross)}
                {td(RESERVATION_NO_SHORT, reservation.reservationNo)}
                {td(DRIVER, reservation.firstDriverName)}
                {td(CHECK_OUT_TIMESTAMP, reservation.formattedCoDateTime)}
                {td(CHECK_IN_TIMESTAMP, reservation.formattedCiDateTime)}
                {td(GROSS_PRICE, reservation.formattedInvoiceAmountGross)}
                {td(STATE, reservation.stateDescription)}
                {showActionColumn
                  ? (
                    <td className="history-table__cell history-table__cell--action">
                      {reservation.isCancelable
                        ? <CancelReservationButton
                            reservation={reservation}
                            cancelReservation={cancelReservation}
                          />
                        : null
                      }
                    </td>
                  )
                  : null
                }
              </tr>
          ))
          : (
            <tr>
              <td
                colSpan={showActionColumn ? 10 : 9}
                className="history-table__cell history-table__cell--empty"
              >
                <T code={NO_RESERVATIONS_FOUND} />
              </td>
            </tr>
          )
        }
      </tbody>
    </table>
  );
});
HistoryTable.propTypes = {
  className: PropTypes.string,
  showActionColumn: PropTypes.bool,
  reservations: PropTypes.arrayOf(reservationShape),
  cancelReservation: CancelReservationButton.propTypes.cancelReservation
};

const FilterForm = (props) => {
  const {translate, handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row alignBottom className="history__filter-form">
        <Col
          className="history__filter-input-col"
          tablet={{cols: 5}}
          desktop={{cols: 3}}
        >
          <FormikLabeledField
            name="registrationNumber"
            getFieldLabel={() => translate(FILTER_LICENSE_PLATE_LABEL)}
            as={FormikTextInput}
            placeholder={translate(FILTER_LICENSE_PLATE_PLACEHOLDER)}
          />
        </Col>
        <Col
          tablet={{cols: 3}}
          desktop={{cols: 0}}
        />
        <Col
          className="history__filter-input-col"
          tablet={{cols: 5}}
          desktop={{cols: 3}}
        >
          <FormikLabeledField
            name="driverName"
            getFieldLabel={() => translate(FILTER_DRIVER_NAME_LABEL)}
            as={FormikTextInput}
            placeholder={translate(FILTER_DRIVER_NAME_PLACEHOLDER)}
          />
        </Col>
        <Col
          tablet={{cols: 3}}
          desktop={{cols: 3}}
        >
          <Button primary filter submit>
            <T code={FILTER_BUTTON_LABEL} />
          </Button>
        </Col>
      </Row>
    </form>
  );
};
FilterForm.propTypes = {};

const HistoryComponent = (props) => {
  const {
    initialize,
    translate,
    currentPage,
    numberOfPages,
    previousPage,
    nextPage,
    selectTab,
    selectedTab,
    filterValues,
    setFilter,
    csvExportUrl,
    cancelReservation
  } = props;
  const reservations = props.reservations;

  useInitialize(initialize);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, [currentPage]);

  return (
    <>
      <Row>
        <Col
          tablet={{cols: 4}}
          desktop={{cols: 6}}
        >
          <HistoryTabs
            selectedTab={selectedTab}
            onSelectActive={() => selectTab(historyTabs.ACTIVE)}
            onSelectPast={() => selectTab(historyTabs.PAST)}
          />
        </Col>
        <Col
          className="history__top-download-button-col"
          tablet={{cols: 3, offset: 1}}
          desktop={{cols: 3, offset: 3}}
        >
          <Button
            secondary
            download
            target="_blank"
            href={csvExportUrl}
          >
            <T code={DOWNLOAD} />
          </Button>
        </Col>
      </Row>

      <Formik
        enableReinitialize
        initialValues={filterValues}
        onSubmit={setFilter}
      >
        {(formikProps) => (
          <FilterForm
            {...formikProps}
            translate={translate}
          />
        )}
      </Formik>

      <InlineLoadingIndicator
        typePrefix="HISTORY/RESERVATION_HISTORY"
        message={<T code={HISTORY_LOADING} />}
      >
        <Row className="history__table-row">
          <Col
            tablet={{cols: 4, offset: 2}}
            desktop={{cols: 12, offset: 0}}
          >
            <HistoryTable
              showActionColumn={selectedTab === historyTabs.ACTIVE}
              reservations={reservations}
              cancelReservation={cancelReservation}
            />
          </Col>
        </Row>

        {numberOfPages > 0
          ? <Row className="history__pagination-row">
              <Col
                tablet={{cols: 2, offset: 3}}
                desktop={{cols: 2, offset: 5}}
              >
                <Pagination
                  currentPage={currentPage}
                  numberOfPages={numberOfPages}
                  onPrevClick={previousPage}
                  onNextClick={nextPage}
                />
              </Col>
            </Row>
          : null
        }
      </InlineLoadingIndicator>

      <Button
        className="history__bottom-download-button"
        secondary
        download
        href={csvExportUrl}
      >
        <T code={DOWNLOAD} />
      </Button>

      <T code={NOTICE} />
    </>
  );
};
HistoryComponent.propTypes = {
  initialize: PropTypes.func,
  selectTab: PropTypes.func,
  translate: PropTypes.func.isRequired,
  currentPage: Pagination.propTypes.currentPage,
  numberOfPages: Pagination.propTypes.numberOfPages,
  previousPage: Pagination.propTypes.onPrevClick,
  nextPage: Pagination.propTypes.onNextClick,
  selectedTab: HistoryTabs.propTypes.selectedTab,
  filterValues: PropTypes.shape({
    driverName: PropTypes.string,
    registrationNumber: PropTypes.string
  }),
  setFilter: PropTypes.func,
  reservations: HistoryTable.propTypes.reservations,
  csvExportUrl: PropTypes.string.isRequired,
  cancelReservation: HistoryTable.propTypes.cancelReservation
};

const stateToHistoryProps = createStateToProps({
  translate: newTranslate,
  currentPage: getCurrentPage,
  numberOfPages: getNumberOfPages,
  selectedTab: getActiveTab,
  filterValues: getFilterValues,
  reservations: getReservationHistory,
  csvExportUrl: getCsvExportUrl
});

const dispatchToHistoryProps = (dispatch) => ({
  initialize: () => dispatch(initializeHistory()),
  previousPage: () => dispatch(previousPage()),
  nextPage: () => dispatch(nextPage()),
  selectTab: (tab) => dispatch(selectTab(tab)),
  setFilter: (formValues) => dispatch(setFilter(formValues)),
  cancelReservation: (rentContractId) => dispatch(cancelReservation(rentContractId))
});

export const History = connect(
  stateToHistoryProps,
  dispatchToHistoryProps
)(HistoryComponent);
