import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions
} from "@awe-web/shared/lib/user/store.js";
import {Row, Col} from "../../../components/grid.js";
import {TypeArea} from "../../../components/layout.js";
import {
  RequiredNotice
} from "../../../components/form.js";
import {
  ScreenSection,
  ScreenHeading,
  ScreenSectionHeading,
  ScreenLoading
} from "../../../components/screen.js";
import {BillDeliveryForm, BillDeliveryReadOnly, DeviantBillingAddressForm} from "./form.js";
import {getPaymentDetails, getDeviantBillingAddress, getIsProfileReadOnly} from "../../../user/store.js";
import {initialize, submitBillDelivery, submitBillingAddress} from "./actions.js";

import "./payment.scss";

const HEADING = "ALG0000134";

const SEPA_HEADING = "ALG0000135";
const SEPA_TEXT_1 = "ALG0000136";
const SEPA_TEXT_2 = "ALG0000137";

const ON_ACCOUNT_HEADING = "ALG0000138";
const ON_ACCOUNT_TEXT_1 = "ALG0000139";
const ON_ACCOUNT_TEXT_2 = "ALG0000140";

const ACCOUNT_HOLDER_LABEL = "ALG0000099";
const IBAN_LABEL = "ALG0000100";
const BIC_LABEL = "ALG0000101";
const BANK_LABEL = "ALG0000141";

const BILLING_ADDRESS_HEADING = "ALG0000146";
const NOTICE = "FSD0000209";

const DeviantBillingAddress = (props) => {
  const {deviantBillingAddress, getFormFieldLabel} = props;

  if (!deviantBillingAddress) {
    return (
      <Row>
        <Col
          tablet={{cols: 4}}
          desktop={{cols: 4, offset: 2}}
        >
          —
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col
          tablet={{cols: 4}}
          desktop={{cols: 4, offset: 2}}
        >
          <div className="b2b-payment__labeled-value">
            <div className="b2b-payment__labeled-value__label">
              {getFormFieldLabel("deviantBillingAddress:surname")}
            </div>
            <div>
              {deviantBillingAddress.companyName}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          tablet={{cols: 4}}
          desktop={{cols: 4, offset: 2}}
        >
          <div className="b2b-payment__labeled-value">
            <div className="b2b-payment__labeled-value__label">
              {getFormFieldLabel("deviantBillingAddress:street")}
            </div>
            <div>
              {deviantBillingAddress.street}
            </div>
          </div>

          <div className="b2b-payment__labeled-value b2b-payment__labeled-value--last-child--tablet">
            <div className="b2b-payment__labeled-value__label">
              {getFormFieldLabel("deviantBillingAddress:building")}
            </div>
            <div>
              {deviantBillingAddress.building}
            </div>
          </div>
        </Col>
        <Col
          tablet={{cols: 4}}
          desktop={{cols: 4}}
        >
          <div className="b2b-payment__zip-code-and-city">
            <div className="b2b-payment__labeled-value b2b-payment__labeled-value--zip-code">
              <div className="b2b-payment__labeled-value__label">
                {getFormFieldLabel("deviantBillingAddress:zipCode")}
              </div>
              <div>
                {deviantBillingAddress.zipCode}
              </div>
            </div>

            <div className="b2b-payment__labeled-value">
              <div className="b2b-payment__labeled-value__label">
                {getFormFieldLabel("deviantBillingAddress:city")}
              </div>
              <div>
                {deviantBillingAddress.city}
              </div>
            </div>
          </div>

          <div className="b2b-payment__labeled-value b2b-payment__labeled-value--last-child">
            <div className="b2b-payment__labeled-value__label">
              {getFormFieldLabel("deviantBillingAddress:countryCode")}
            </div>
            <div>
              {deviantBillingAddress.country}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
DeviantBillingAddress.propTypes = {
  deviantBillingAddress: PropTypes.shape({
    companyName: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string
  }),
  getFormFieldLabel: PropTypes.func.isRequired
};

const PaymentDetails = (props) => {
  const {paymentDetails} = props;

  const {
    PAYMENT_HEADING,
    PAYMENT_TEXT_1,
    PAYMENT_TEXT_2
  } = (() => {
    if (paymentDetails.type === "SEPA") {
      return {
        PAYMENT_HEADING: SEPA_HEADING,
        PAYMENT_TEXT_1: SEPA_TEXT_1,
        PAYMENT_TEXT_2: SEPA_TEXT_2
      };
    }
    else {
      return {
        PAYMENT_HEADING: ON_ACCOUNT_HEADING,
        PAYMENT_TEXT_1: ON_ACCOUNT_TEXT_1,
        PAYMENT_TEXT_2: ON_ACCOUNT_TEXT_2
      };
    }
  })();

  return (
    <>
      <Row>
        <Col desktop={{ cols: 8, offset: 2 }}>
          <ScreenSectionHeading light><T code={PAYMENT_HEADING} /></ScreenSectionHeading>
        </Col>
      </Row>

      <Row>
        <Col
          className="b2b-payment__method-text"
          tablet={{ cols: 5 }}
          desktop={{ cols: 4, offset: 2 }}
        >
          <p><T code={PAYMENT_TEXT_1} /></p>
          <p><T code={PAYMENT_TEXT_2} /></p>
        </Col>
        <Col
          tablet={{ cols: 3 }}
          desktop={{ cols: 4 }}
        >
          <div className="b2b-payment__labeled-value">
            <div className="b2b-payment__labeled-value__label">
              <T code={ACCOUNT_HOLDER_LABEL} />
            </div>
            <div>{paymentDetails.accountHolder}</div>
          </div>

          <div className="b2b-payment__labeled-value">
            <div className="b2b-payment__labeled-value__label">
              <T code={IBAN_LABEL} />
            </div>
            <div>{paymentDetails.iban}</div>
          </div>

          <div className="b2b-payment__labeled-value">
            <div className="b2b-payment__labeled-value__label">
              <T code={BIC_LABEL} />
            </div>
            <div>{paymentDetails.bic}</div>
          </div>

          <div className="b2b-payment__labeled-value">
            <div className="b2b-payment__labeled-value__label">
              <T code={BANK_LABEL} />
            </div>
            <div>{paymentDetails.bank}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};
PaymentDetails.propTypes = {
  paymentDetails: PropTypes.shape({
    type: PropTypes.oneOf(["SEPA", "ON_ACCOUNT"]),
    accountHolder: PropTypes.string,
    iban: PropTypes.string,
    bic: PropTypes.string,
    bank: PropTypes.string
  })
};

const PaymentComponent = (props) => {
  const {
    initialize,
    isReadOnly,
    deviantBillingAddress,
    paymentDetails,

    getBillingAddressInitialValues,
    validateBillingAddress,
    isBillingAddressFieldRequired,
    getBillingAddressFieldLabel,
    getBillingAddressSelectFieldOptions,
    getBillingAddressFieldPlaceholder,
    onSubmitBillingAddress,

    getBillDeliveryInitialValues,
    validateBillDelivery: validateBillDeliveryForm,
    onSubmitBillDelivery
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialValuesBillingAddress = getBillingAddressInitialValues({
    "deviantBillingAddress:surname": "",
    "deviantBillingAddress:street": "",
    "deviantBillingAddress:building": "",
    "deviantBillingAddress:zipCode": "",
    "deviantBillingAddress:city": "",
    "deviantBillingAddress:countryCode": ""
  });

  const initialValuesBillDelivery = getBillDeliveryInitialValues({
    billDeliveryType: "MAIL",
    eBillingMailAddress: ""
  });
  const validateBillDelivery = ({billDeliveryType, eBillingMailAddress}) =>
    billDeliveryType === "EMAIL"
      ? validateBillDeliveryForm({billDeliveryType, eBillingMailAddress})
      : {};

  return (
    <div className="b2b-payment">
      <ScreenSection dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading><T code={HEADING} /></ScreenHeading>
            </Col>
          </Row>

          <PaymentDetails paymentDetails={paymentDetails} />
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <hr className="b2b-payment__separator" />
            </Col>
          </Row>

          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenSectionHeading light><T code={BILLING_ADDRESS_HEADING} /></ScreenSectionHeading>
            </Col>
          </Row>
          {deviantBillingAddress || isReadOnly
            ? (
              <DeviantBillingAddress
                deviantBillingAddress={deviantBillingAddress}
                getFormFieldLabel={getBillingAddressFieldLabel}
              />
            )
            : (
              <Formik
                initialValues={initialValuesBillingAddress}
                validate={validateBillingAddress}
                onSubmit={onSubmitBillingAddress}
              >
                {(formikProps) => (
                  <DeviantBillingAddressForm
                    {...formikProps}
                    isFieldRequired={isBillingAddressFieldRequired}
                    getFieldLabel={getBillingAddressFieldLabel}
                    getFieldPlaceholder={getBillingAddressFieldPlaceholder}
                    getSelectFieldOptions={getBillingAddressSelectFieldOptions}
                  />
                )}
              </Formik>
            )
          }
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <hr className="b2b-payment__separator" />
            </Col>
          </Row>

          {isReadOnly
            ? (
              <BillDeliveryReadOnly
                billDeliveryType={initialValuesBillDelivery.billDeliveryType}
                email={initialValuesBillDelivery.eBillingMailAddress}
              />
            )
            : (
              <Formik
                initialValues={initialValuesBillDelivery}
                validate={validateBillDelivery}
                onSubmit={onSubmitBillDelivery}
              >
                {(formikProps) => (
                  <BillDeliveryForm
                    {...formikProps}
                  />
                )}
              </Formik>
            )}

          {!isReadOnly
            ? (
              <Row className="b2b-payment__required-notice">
                <Col desktop={{cols: 8, offset: 2}}>
                  <RequiredNotice><T code={NOTICE} /></RequiredNotice>
                </Col>
              </Row>
            )
            : null}
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
PaymentComponent.propTypes = {
  initialize: PropTypes.func,
  isReadOnly: PropTypes.bool,
  deviantBillingAddress: DeviantBillingAddress.propTypes.deviantBillingAddress,
  paymentDetails: PaymentDetails.propTypes.paymentDetails,

  validateBillingAddress: PropTypes.func,
  getBillingAddressInitialValues: PropTypes.func,
  isBillingAddressFieldRequired: PropTypes.func,
  getBillingAddressFieldLabel: PropTypes.func,
  getBillingAddressFieldPlaceholder: PropTypes.func,
  getBillingAddressSelectFieldOptions: PropTypes.func,
  onSubmitBillingAddress: PropTypes.func,

  validateBillDelivery: PropTypes.func,
  getBillDeliveryInitialValues: PropTypes.func,
  onSubmitBillDelivery: PropTypes.func
};

const stateToPaymentProps = createStateToProps({
  isReadOnly: getIsProfileReadOnly,
  paymentDetails: getPaymentDetails,
  deviantBillingAddress: getDeviantBillingAddress,

  validateBillingAddress: newValidate("deviantBillingAddress"),
  getBillingAddressInitialValues: newGetInitialValues("deviantBillingAddress"),
  isBillingAddressFieldRequired: newIsFieldRequired("deviantBillingAddress"),
  getBillingAddressFieldLabel: newGetFieldLabel("deviantBillingAddress"),
  getBillingAddressFieldPlaceholder: newGetFieldPlaceholder("deviantBillingAddress"),
  getBillingAddressSelectFieldOptions: newGetSelectFieldOptions("deviantBillingAddress"),

  validateBillDelivery: newValidate("billDelivery"),
  getBillDeliveryInitialValues: newGetInitialValues("billDelivery")
});

const dispatchToPaymentProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmitBillingAddress: (formValues) => dispatch(submitBillingAddress(formValues)),
  onSubmitBillDelivery: (formValues) => dispatch(submitBillDelivery(formValues))
});

export default connect(
  stateToPaymentProps,
  dispatchToPaymentProps
)(PaymentComponent);
