"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressTypes = exports.userRoles = exports.accountTypes = void 0;
var accountTypes = {
  B2B: "01",
  B2C: "02"
};
exports.accountTypes = accountTypes;
var userRoles = {
  B2B: accountTypes.B2B,
  B2C: accountTypes.B2C,
  ANONYMOUS: "anonymous"
};
exports.userRoles = userRoles;
var addressTypes = {
  MAIN: "01",
  BILLING: "02"
};
exports.addressTypes = addressTypes;