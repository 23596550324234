"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navigateExternal = exports.redirectExternal = exports.redirect = exports.navigate = void 0;

var navigate = function navigate(pathOrLocation) {
  return function (dispatch, getState, context) {
    context.history.push(pathOrLocation);
  };
};

exports.navigate = navigate;

var redirect = function redirect(pathOrLocation) {
  return function (dispatch, getState, context) {
    context.history.replace(pathOrLocation);
  };
};

exports.redirect = redirect;

var redirectExternal = function redirectExternal(url) {
  return function (dispatch, getState, context) {
    context.window.location.replace(url);
  };
};

exports.redirectExternal = redirectExternal;

var navigateExternal = function navigateExternal(url) {
  return function (dispatch, getState, context) {
    context.window.location.assign(url);
  };
};

exports.navigateExternal = navigateExternal;