"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rentContractToClassificationId = exports.rentContractToCiDate = exports.rentContractToCiDateTime = exports.rentContractToCoDate = exports.rentContractToCoDateTime = exports.rentContractToCiStationId = exports.rentContractToSelectedCoStationId = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _date = require("../util/date.js");

var rentContractToMainRentObjectTransaction = function rentContractToMainRentObjectTransaction(rentContract) {
  return rentContract.rentObjTransacts.find(function (_ref) {
    var transactType = _ref.transactType;
    return transactType === "RC";
  }) || {};
};

var rentContractToSelectedCoStationId = function rentContractToSelectedCoStationId(rentContract) {
  var mainTransaction = rentContractToMainRentObjectTransaction(rentContract);
  return mainTransaction.pickupStationId || mainTransaction.coStationId;
};

exports.rentContractToSelectedCoStationId = rentContractToSelectedCoStationId;

var rentContractToCiStationId = function rentContractToCiStationId(rentContract) {
  return rentContractToMainRentObjectTransaction(rentContract).planCiStationId;
};

exports.rentContractToCiStationId = rentContractToCiStationId;

var rentContractToCoDateTime = function rentContractToCoDateTime(rentContract) {
  return (0, _date.parseDate)(rentContractToMainRentObjectTransaction(rentContract).coDateTime);
};

exports.rentContractToCoDateTime = rentContractToCoDateTime;

var rentContractToCoDate = function rentContractToCoDate(rentContract) {
  var coDateTimeString = rentContractToMainRentObjectTransaction(rentContract).coDateTime;

  var _ref2 = (0, _date.isValid)(coDateTimeString) ? coDateTimeString.split("T") : [],
      _ref3 = (0, _slicedToArray2["default"])(_ref2, 1),
      date = _ref3[0];

  return date;
};

exports.rentContractToCoDate = rentContractToCoDate;

var rentContractToCiDateTime = function rentContractToCiDateTime(rentContract) {
  return (0, _date.parseDate)(rentContractToMainRentObjectTransaction(rentContract).ciDateTime);
};

exports.rentContractToCiDateTime = rentContractToCiDateTime;

var rentContractToCiDate = function rentContractToCiDate(rentContract) {
  var ciDateTimeString = rentContractToMainRentObjectTransaction(rentContract).ciDateTime;

  var _ref4 = (0, _date.isValid)(ciDateTimeString) ? ciDateTimeString.split("T") : [],
      _ref5 = (0, _slicedToArray2["default"])(_ref4, 1),
      date = _ref5[0];

  return date;
};

exports.rentContractToCiDate = rentContractToCiDate;

var rentContractToClassificationId = function rentContractToClassificationId(rentContract) {
  return rentContractToMainRentObjectTransaction(rentContract).classificationId;
};

exports.rentContractToClassificationId = rentContractToClassificationId;