import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {fieldsetProps} from "@awe-web/shared/lib/util/form.js";
import {merge} from "@awe-web/shared/lib/util/object.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions,
  newHasField
} from "@awe-web/shared/lib/user/store.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSectionHeading} from "../../../components/screen.js";
import {
  RequiredNotice,
  Button,
  ScrollToError
} from "../../../components/form.js";
import {
  AccountFieldset,
  AddressFieldset,
  IdentificationAndDriversLicenseFieldset,
  ConsentFieldset
} from "../../../user/components/form.js";
import {submit} from "./actions.js";

import "./my_bmw_rent_profile_form.scss";

const NOTICE = "FSD0000209";
const SUBMIT = "FSD0000236";
const LEGAL = "FSD0000197";

const Form = (props) => {
  const {handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError />
      <AccountFieldset {...fieldsetProps(props)} />
      <AddressFieldset {...fieldsetProps(props)} />
      <IdentificationAndDriversLicenseFieldset {...fieldsetProps(props)} />

      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <ScreenSectionHeading light><T code={LEGAL} /></ScreenSectionHeading>
        </Col>
      </Row>
      <ConsentFieldset
        className="my-bmw-rent-profile-form__consent-fieldset"
        {...fieldsetProps(props)}
      />

      <Row>
        <Col tablet={{cols: 4, offset: 4}} desktop={{cols: 4, offset: 6}}>
          <Button primary continue submit className="my-bmw-rent-profile-form__submit-button"><T code={SUBMIT} /></Button>
        </Col>
      </Row>

      <Row className="my-bmw-rent-profile-form__required-notice">
        <Col desktop={{cols: 8, offset: 2}}>
          <RequiredNotice><T code={NOTICE} /></RequiredNotice>
        </Col>
      </Row>
    </form>
  );
};
Form.valuesShape = merge(
  AccountFieldset.valuesShape,
  AddressFieldset.valuesShape,
  IdentificationAndDriversLicenseFieldset.valuesShape
);
Form.propTypes = {
  values: PropTypes.shape(Form.valuesShape),
  isFieldRequired: PropTypes.func,
  getSelectFieldOptions: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func
};

const MyBmwRentProfileFormComponent = (props) => {
  const {
    validate,
    getInitialValues,
    hasField,
    isFieldRequired,
    getFieldLabel,
    getFieldPlaceholder,
    getSelectFieldOptions,
    onSubmit
  } = props;
  const initialValues = getInitialValues({
    [AccountFieldset.PREFIX_KEY]: "",
    [AccountFieldset.NAME]: "",
    [AccountFieldset.SURNAME]: "",
    [AccountFieldset.BIRTHDATE]: "",
    [AccountFieldset.PHONE]: "",
    [AccountFieldset.USERNAME]: "",
    [AccountFieldset.PASSWORD]: "",
    ...(hasField(AccountFieldset.TAX_IDENT) ? {[AccountFieldset.TAX_IDENT]: ""} : {}),

    [AddressFieldset.STREET]: "",
    [AddressFieldset.BUILDING]: "",
    [AddressFieldset.ZIP_CODE]: "",
    [AddressFieldset.CITY]: "",
    [AddressFieldset.COUNTRY_CODE]: "",

    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_TYPE]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_NO]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_COUNTRY]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_AUTHORITY]: "",
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_DATE_ISSUED]: undefined,
    [IdentificationAndDriversLicenseFieldset.IDENT_CARD_VALID_TILL]: undefined,
    [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_NO]: "",
    [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_COUNTRY]: "",
    [IdentificationAndDriversLicenseFieldset.DRIVERS_LICENSE_DATE_ISSUED]: undefined,

    [ConsentFieldset.MAIL]: false,
    [ConsentFieldset.EMAIL]: false,
    [ConsentFieldset.PHONE]: false
  });

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {(formikProps) => (
        <Form
          {...formikProps}
          isFieldRequired={isFieldRequired}
          getSelectFieldOptions={getSelectFieldOptions}
          getFieldLabel={getFieldLabel}
          getFieldPlaceholder={getFieldPlaceholder}
        />
      )}
    </Formik>
  );
};
MyBmwRentProfileFormComponent.propTypes = {
  validate: PropTypes.func,
  getInitialValues: PropTypes.func,
  hasField: PropTypes.func,
  isFieldRequired: PropTypes.func,
  getFieldLabel: PropTypes.func,
  getFieldPlaceholder: PropTypes.func,
  getSelectFieldOptions: PropTypes.func,
  onSubmit: PropTypes.func
};

const stateToMyBmwRentProfileFormProps = createStateToProps({
  validate: newValidate("privateChangeUserDataFSD", {
    "Address:email": "Customer:login",
    "AlternativeWebLogin:Login": "Customer:login"
  }),
  getInitialValues: newGetInitialValues("privateChangeUserDataFSD"),
  hasField: newHasField("privateChangeUserDataFSD"),
  isFieldRequired: newIsFieldRequired("privateChangeUserDataFSD"),
  getFieldLabel: newGetFieldLabel("privateChangeUserDataFSD"),
  getFieldPlaceholder: newGetFieldPlaceholder("privateChangeUserDataFSD"),
  getSelectFieldOptions: newGetSelectFieldOptions("privateChangeUserDataFSD")
});

const dispatchToMyBmwRentProfileFormProps = (dispatch) => ({
  onSubmit: (values, formikProps) => dispatch(submit(values, formikProps))
});

export const MyBmwRentProfileForm = connect(stateToMyBmwRentProfileFormProps, dispatchToMyBmwRentProfileFormProps)(MyBmwRentProfileFormComponent);
