import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  MenuButton as MenuButtonBase,
  SidebarPanel
} from "@awe-web/shared/lib/sidebar_panel/components/sidebar_panel.js";
import {UserNavigation} from "./user_navigation.js";
import {AppNavigationLinks} from "./app_navigation.js";
import {LanguageSwitch} from "../l10n/components/language_switch.js";

import "./sidebar_menu.scss";

import closeIcon from "./assets/close.svg";

export const MenuButton = (props) => {
  const className = classnames("menu-button", props.className);
  return <MenuButtonBase className={className} />;
};
MenuButton.propTypes = {
  className: PropTypes.string
};

export const SidebarMenu = () => {
  return (
    <SidebarPanel
      right
      disableAutoFocus
      outerContainerId={SidebarMenu.outerContainerId}
      pageWrapId={SidebarMenu.pageWrapId}
      customCrossIcon={<img src={closeIcon} alt="" />}
      htmlClassName="sidebar-menu__html"
    >
      <LanguageSwitch className="sidebar-menu__language-switch" />
      <UserNavigation className="sidebar-menu__user-navigation" />
      <AppNavigationLinks
        className="sidebar-menu__app-navigation-links"
        itemClassName="sidebar-menu__app-navigation-links__item"
        linkClassName="sidebar-menu__app-navigation-links__link"
        activeLinkClassName="sidebar-menu__app-navigation-links__link--active"
        linkTextClassName="sidebar-menu__app-navigation-links__link-text"
      />
    </SidebarPanel>
  );
};
SidebarMenu.outerContainerId = "sidebar-menu-container";
SidebarMenu.pageWrapId = "sidebar-menu-page";
