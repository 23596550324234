import React, {Component} from "react";
import {InitializationError} from "./initialization_error.js";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  render() {
    return this.state.hasError
      ? <InitializationError />
      : this.props.children;
  }
}
