"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackEvent = void 0;
window.digitals2 = window.digitals2 || {};
window.digitals2.tracking = window.digitals2.tracking || {};
window.digitals2.tracking.api = window.digitals2.tracking.api || {};

window.digitals2.tracking.api.getPageObject = function () {
  return window.digitalData;
};

window.digitals2.tracking.api.getCurrentPageIndex = function () {};

var trackEvent = function trackEvent(eventType, dataLayer) {
  window.digitalData = dataLayer;
  var event = document.createEvent("CustomEvent");
  event.initCustomEvent(eventType);
  window.dispatchEvent(event);
};

exports.trackEvent = trackEvent;