import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {
  getCreditCards,
  newValidate,
  newGetInitialValues
} from "@awe-web/shared/lib/user/store.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {
  ScreenSection,
  ScreenHeading,
  ScreenSectionHeading,
  ScreenSectionSubheading,
  ScreenLoading,
  ScreenSubsection
} from "../../../components/screen.js";
import {
  FormikLabeledCheckbox,
  FormikCheckbox,
  Button,
  RequiredNotice,
  FormikErrorMessage,
  ScrollToError,
  FormikLabeledField,
  FormikTextareaWithCharacterCount
} from "../../../components/form.js";
import {LtrMonthlyTotalPrice, LtrOneTimeTotalPrice} from "../../../rent_contracts/components/price.js";
import {SelectedPeriod} from "../../../stations/components/selected_period.js";
import {SelectedRentObject} from "../../../rent_objects/components/selected_rent_object.js";
import {ActivatePromocode} from "../../../rent_contracts/components/activate_promocode.js";
import {ProvisioningDetails, ReturnDetails} from "../../../rent_contracts/components/rent_contract.js";
import {AddCreditCard, CreditCardRadioButtons} from "../../../user/components/credit_cards.js";
import {TranslationWithCmsLink} from "../../../l10n/components/translation_with_cms_link.js";
import {getCreditCardsEnabled} from "../../../config/store.js";
import {B2C_LTR_SelectProvisioning} from "../../../routing/routes.js";
import {initialize, submit} from "./actions.js";

import "./submit_rent_contract.scss";

const OVERVIEW = "FSD0000159";
const SELECTED_RENT_OBJECT = "FSD0000162";
const SELECTED_TIME = "ALG0000092";
const CHANGE_VALUES = "ALG0000025";
const PROVISIONING_DETAILS = "ALG0000095";
const RETURN_DETAILS = "FSD0000161";
const RETURN_NOTICE = "ALG0000246";
const TEXTBOX_LABEL = "ALG0000104";
const TEXTBOX_PLACEHOLDER = "ALG0000105";
const COSTS = "FSD0000163";
const SELECT_CREDIT_CARD = "FSD0000170";
const ADD_CREDIT_CARD = "FSD0000222";
const I_ACCEPT_THE_TOS = "FSD0000286";
const I_ACCEPT_THE_PRIVACY_POLICY = "FSD0000287";
const SUBMIT_RESERVATION = "FSD0000174";
const NOTICE = "FSD0000209";
const PRICES_NOTICE = "ALG0000244";
const ADDITIONAL_INFORMATION = "FSD0000175";
const INCLUDING_VAT = "FSD0000263";

const SubmitRentContractForm = (props) => {
  const {
    translate,
    creditCardsEnabled,
    creditCards,
    handleSubmit
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <ScrollToError />

      <ScreenSection main>
        <TypeArea className="b2c-ltr-submit-rent-contract__type-area">
          <Row>
            <Col tablet={{cols: 4}} desktop={{cols: 4, offset: 2}}>
              <ScreenSubsection className="b2c-ltr-submit-rent-contract__type-area__mobile">
                <ScreenSectionHeading light>
                  <T code={PROVISIONING_DETAILS} />
                </ScreenSectionHeading>
                <ProvisioningDetails reservationPath="B2C" />
              </ScreenSubsection>

              <ScreenSubsection className="b2c-ltr-submit-rent-contract__type-area__mobile">
                <ScreenSectionHeading light>
                  <T code={RETURN_DETAILS} />
                </ScreenSectionHeading>
                <ReturnDetails />
                <div className="b2c-ltr-submit-rent-contract__return-notice">
                  <T code={RETURN_NOTICE} />
                </div>
              </ScreenSubsection>

              <ScreenSubsection className="b2c-ltr-submit-rent-contract__type-area__mobile">
                <FormikLabeledField
                  name="contractNote"
                  as={FormikTextareaWithCharacterCount}
                  getFieldLabel={() => translate(TEXTBOX_LABEL)}
                  maxLength={500}
                  placeholder={translate(TEXTBOX_PLACEHOLDER)}
                />
              </ScreenSubsection>
            </Col>

            <Col tablet={{cols: 4}} desktop={{cols: 4}}>
              {creditCardsEnabled && (
                <ScreenSubsection className="b2c-ltr-submit-rent-contract__type-area__mobile">
                  <ScreenSectionHeading light>
                    <T code={SELECT_CREDIT_CARD} />
                  </ScreenSectionHeading>
                  <CreditCardRadioButtons
                    name="creditCardId"
                    creditCards={creditCards}
                  />
                  <FormikErrorMessage name="creditCardId" />
                  <T className="b2c-ltr-submit-rent-contract__add-credit-card-heading" code={ADD_CREDIT_CARD} />
                  <AddCreditCard />
                </ScreenSubsection>
              )}

              <ScreenSubsection>
                <ScreenSectionHeading light className="b2c-ltr-submit-rent-contract__type-area__mobile">
                  <T code={COSTS} />
                </ScreenSectionHeading>

                <ActivatePromocode
                  className="b2c-ltr-submit-rent-contract__box"
                />

                <LtrMonthlyTotalPrice
                  className="b2c-ltr-submit-rent-contract__box"
                  descriptionHint={translate(INCLUDING_VAT)}
                />

                <LtrOneTimeTotalPrice
                  className="b2c-ltr-submit-rent-contract__box"
                  descriptionHint={translate(INCLUDING_VAT)}
                />
              </ScreenSubsection>

              <ScreenSubsection className="b2c-ltr-submit-rent-contract__type-area__mobile">
                <div className="b2c-ltr-submit-rent-contract__checkbox-tos">
                  <FormikLabeledCheckbox name="agbAccepted">
                    <FormikCheckbox name="agbAccepted" />
                    <TranslationWithCmsLink
                      className="b2c-ltr-submit-rent-contract__required-checkbox-label checkbox-label"
                      code={I_ACCEPT_THE_TOS}
                      pageName="agb"
                    />
                  </FormikLabeledCheckbox>
                  <FormikErrorMessage name="agbAccepted" />
                </div>
                <div className="b2c-ltr-submit-rent-contract__checkbox-privacy">
                  <FormikLabeledCheckbox name="privacyAccepted">
                    <FormikCheckbox name="privacyAccepted" />
                    <TranslationWithCmsLink
                      className="b2c-ltr-submit-rent-contract__required-checkbox-label checkbox-label"
                      code={I_ACCEPT_THE_PRIVACY_POLICY}
                      pageName="privacy"
                    />
                  </FormikLabeledCheckbox>
                  <FormikErrorMessage name="privacyAccepted" />
                </div>

                <Button
                  primary
                  continue
                  submit
                  className="b2c-ltr-submit-rent-contract__submit"
                >
                  <T code={SUBMIT_RESERVATION} />
                </Button>
              </ScreenSubsection>
            </Col>
          </Row>
          <Row>
            <Col desktop={{cols: 8, offset: 2}} className="b2c-ltr-submit-rent-contract__type-area__mobile">
              <RequiredNotice className="b2c-ltr-submit-rent-contract__required-notice">
                <T code={NOTICE} />
              </RequiredNotice>
              <div className="b2c-ltr-submit-rent-contract__additional-information"><T code={ADDITIONAL_INFORMATION} /></div>
              <div><T code={PRICES_NOTICE} /></div>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </form>
  );
};
SubmitRentContractForm.propTypes = {
  values: PropTypes.shape({
    contractNote: PropTypes.string,
    creditCardId: PropTypes.number,
    agbAccepted: PropTypes.bool,
    privacyAccepted: PropTypes.bool
  }),
  creditCardsEnabled: PropTypes.bool,
  creditCards: CreditCardRadioButtons.propTypes.creditCards,
  translate: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func
};

const SubmitRentContractComponent = (props) => {
  const {
    translate,
    initialize,
    rentContractId,
    creditCardsEnabled,
    creditCards,
    getInitialValues,
    validate,
    onSubmit
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialValues = getInitialValues({
    contractNote: "",
    creditCardId: null,
    agbAccepted: false,
    privacyAccepted: false
  });

  return (
    <div className="b2c-ltr-submit-rent-contract">
      <ScreenSection dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading><T code={OVERVIEW} /></ScreenHeading>
              <div className="b2c-str-submit-rent-contract__selected-rent-object">
                <ScreenSectionSubheading>
                  <T code={SELECTED_RENT_OBJECT} />
                </ScreenSectionSubheading>
                <SelectedRentObject selectedBasicProperties="B2C" />
              </div>

              <div>
                <ScreenSectionSubheading>
                  <T code={SELECTED_TIME} />
                </ScreenSectionSubheading>
                <SelectedPeriod
                  className="b2c-str-submit-rent-contract__selected-period"
                  editButtonLocation={B2C_LTR_SelectProvisioning.location({rentContractId})}
                />
                <Button
                  className="b2c-str-submit-rent-contract__mobile-back-button"
                  secondary
                  back
                  to={B2C_LTR_SelectProvisioning.location({rentContractId})}
                >
                  {<T code={CHANGE_VALUES} />}
                </Button>
              </div>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>

      <Formik
        validate={validate}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <SubmitRentContractForm
            {...formikProps}
            translate={translate}
            creditCardsEnabled={creditCardsEnabled}
            creditCards={creditCards}
          />
        )}
      </Formik>
    </div>
  );
};
SubmitRentContractComponent.propTypes = {
  rentContractId: PropTypes.number,
  creditCardsEnabled: SubmitRentContractForm.propTypes.creditCardsEnabled,
  creditCards: SubmitRentContractForm.propTypes.creditCards,
  translate: PropTypes.func,
  initialize: PropTypes.func,
  getInitialValues: PropTypes.func,
  validate: PropTypes.func,
  onSubmit: PropTypes.func
};

const stateToSubmitRentContractProps = createStateToProps({
  translate: newTranslate,
  rentContractId: getRentContractId,
  creditCardsEnabled: getCreditCardsEnabled,
  creditCards: getCreditCards,
  validate: newValidate("submitB2cLtrRentContract"),
  getInitialValues: newGetInitialValues("submitB2cLtrRentContract")
});

const dispatchToSubmitRentContractProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmit: (formValues) => dispatch(submit(formValues))
});

export default connect(
  stateToSubmitRentContractProps,
  dispatchToSubmitRentContractProps
)(SubmitRentContractComponent);
