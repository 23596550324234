import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {CmsOverlay} from "../../components/cms_overlay.js";

export class TranslationWithCmsLink extends Component {
  constructor(props) {
    super(props);

    this.overlayRef = createRef();
    this.openOverlay = (event) => {
      event.preventDefault();
      const overlay = this.overlayRef.current;
      if (overlay) {
        overlay.openOverlay();
      }
    };
  }

  render() {
    const {openOverlay} = this;
    const {code, pageName} = this.props;
    const className = classnames("translation", this.props.className);

    return (
      <>
        <T className={className} onLinkedTextClick={openOverlay} code={code} />
        <CmsOverlay ref={this.overlayRef} pageName={pageName} />
      </>
    );
  }
}
TranslationWithCmsLink.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  pageName: PropTypes.string
};
