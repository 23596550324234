import {toIsoString} from "@awe-web/shared/lib/util/date.js";
import {userRoles} from "@awe-web/shared/lib/user/constants.js";
import {screenGroups} from "./constants.js";

// ======================================================================
// Entry points (part of the public API)
// ======================================================================

// Part of B2C Short term reservation process
export const B2C_ReservationEntry = {
  path: () => "/reservation",
  location: () => ({
    pathname: B2C_ReservationEntry.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C,
    userRoles.B2B
  ]
};

// Part of B2C Sign up
export const B2C_ConfirmSignUpEntry = {
  path: () => "/confirm-sign-up",
  location: () => ({
    pathname: B2C_ConfirmSignUpEntry.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C,
    userRoles.B2B
  ]
};

// Part of B2C profile
export const ResetPasswordEntry = {
  path: () => "/reset-password",
  location: () => ({
    pathname: ResetPasswordEntry.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C,
    userRoles.B2B
  ]
};

// ======================================================================
// Other
// ======================================================================

export {Cms} from "@awe-web/shared/lib/routing/routes.js";

// ======================================================================
// B2C Long term reservation process
// ======================================================================

export const B2C_SelectStationAndTime = {
  path: () => "/b2c/select-station-and-time",
  location: ({coStationId, ciStationId, coDateTime, ciDateTime} = {}) => ({
    pathname: B2C_SelectStationAndTime.path(),
    state: {coStationId, ciStationId, coDateTime: toIsoString(coDateTime), ciDateTime: toIsoString(ciDateTime)}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C
  ]
};

export const B2C_LTR_SelectRentObject = {
  path: () => "/b2c/ltr/select-vehicle",
  location: ({coStationId, ciStationId, coDateTime, ciDateTime}) => ({
    pathname: B2C_LTR_SelectRentObject.path(),
    state: {coStationId, ciStationId, coDateTime: toIsoString(coDateTime), ciDateTime: toIsoString(ciDateTime)}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C
  ]
};

export const B2C_LTR_SelectExtras = {
  path: () => "/b2c/ltr/select-extras",
  location: ({rentContractId}) => ({
    pathname: B2C_LTR_SelectExtras.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C
  ]
};

export const B2C_LTR_SelectProvisioning = {
  path: () => "/b2c/ltr/select-provisioning",
  location: ({rentContractId}) => ({
    pathname: B2C_LTR_SelectProvisioning.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const B2C_LTR_SubmitRentContract = {
  path: () => "/b2c/ltr/submit-reservation",
  location: ({rentContractId}) => ({
    pathname: B2C_LTR_SubmitRentContract.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const B2C_LTR_SubmitRentContractSuccess = {
  path: () => "/b2c/ltr/reservation-success",
  location: ({rentContractId}) => ({
    pathname: B2C_LTR_SubmitRentContractSuccess.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

// ======================================================================
// B2C Short term reservation process
// ======================================================================

export const B2C_STR_SelectRentObject = {
  path: () => "/b2c/str/select-vehicle",
  location: ({coStationId, ciStationId, coDateTime, ciDateTime}) => ({
    pathname: B2C_STR_SelectRentObject.path(),
    state: {coStationId, ciStationId, coDateTime: toIsoString(coDateTime), ciDateTime: toIsoString(ciDateTime)}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C
  ]
};

export const B2C_STR_SelectExtras = {
  path: () => "/b2c/str/select-extras",
  location: ({rentContractId}) => ({
    pathname: B2C_STR_SelectExtras.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C
  ]
};

export const B2C_STR_SubmitRentContract = {
  path: () => "/b2c/str/submit-reservation",
  location: ({rentContractId}) => ({
    pathname: B2C_STR_SubmitRentContract.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const B2C_STR_SubmitRentContractSuccess = {
  path: () => "/b2c/str/reservation-success",
  location: ({rentContractId}) => ({
    pathname: B2C_STR_SubmitRentContractSuccess.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

// ======================================================================
// B2B Long term reservation process
// ======================================================================

export const B2B_SelectStationAndTime = {
  path: () => "/b2b/select-station-and-time",
  location: ({coStationId, ciStationId, coDateTime, ciDateTime} = {}) => ({
    pathname: B2B_SelectStationAndTime.path(),
    state: {coStationId, ciStationId, coDateTime: toIsoString(coDateTime), ciDateTime: toIsoString(ciDateTime)}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2B
  ]
};

export const B2B_LTR_SelectRentObject = {
  path: () => "/b2b/ltr/select-vehicle",
  location: ({coStationId, ciStationId, coDateTime, ciDateTime}) => ({
    pathname: B2B_LTR_SelectRentObject.path(),
    state: {coStationId, ciStationId, coDateTime: toIsoString(coDateTime), ciDateTime: toIsoString(ciDateTime)}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2B
  ]
};

export const B2B_LTR_SelectExtras = {
  path: () => "/b2b/ltr/select-extras",
  location: ({rentContractId}) => ({
    pathname: B2B_LTR_SelectExtras.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2B
  ]
};

export const B2B_LTR_SelectExtrasConfirm = {
  path: () => "/b2b/ltr/select-extras-confirm",
  location: ({rentContractId}) => ({
    pathname: B2B_LTR_SelectExtrasConfirm.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_LTR_SelectDriverAndProvisioning = {
  path: () => "/b2b/ltr/select-provisioning",
  location: ({rentContractId}) => ({
    pathname: B2B_LTR_SelectDriverAndProvisioning.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_LTR_SubmitRentContract = {
  path: () => "/b2b/ltr/submit-reservation",
  location: ({rentContractId}) => ({
    pathname: B2B_LTR_SubmitRentContract.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_LTR_SubmitRentContractSuccess = {
  path: () => "/b2b/ltr/reservation-success",
  location: ({rentContractId}) => ({
    pathname: B2B_LTR_SubmitRentContractSuccess.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

// ======================================================================
// B2B Short term reservation process
// ======================================================================

export const B2B_STR_SelectRentObject = {
  path: () => "/b2b/str/select-vehicle",
  location: ({coStationId, ciStationId, coDateTime, ciDateTime}) => ({
    pathname: B2B_STR_SelectRentObject.path(),
    state: {coStationId, ciStationId, coDateTime: toIsoString(coDateTime), ciDateTime: toIsoString(ciDateTime)}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2B
  ]
};

export const B2B_STR_SelectExtras = {
  path: () => "/b2b/str/select-extras",
  location: ({rentContractId}) => ({
    pathname: B2B_STR_SelectExtras.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2B
  ]
};

export const B2B_STR_SelectExtrasConfirm = {
  path: () => "/b2b/str/select-extras-confirm",
  location: ({rentContractId}) => ({
    pathname: B2B_STR_SelectExtrasConfirm.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_STR_SelectDriver = {
  path: () => "/b2b/str/select-driver",
  location: ({rentContractId}) => ({
    pathname: B2B_STR_SelectDriver.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_STR_SubmitRentContract = {
  path: () => "/b2b/str/submit-reservation",
  location: ({rentContractId}) => ({
    pathname: B2B_STR_SubmitRentContract.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_STR_SubmitRentContractSuccess = {
  path: () => "/b2b/str/reservation-success",
  location: ({rentContractId}) => ({
    pathname: B2B_STR_SubmitRentContractSuccess.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.RESERVATION
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

// ======================================================================
// B2C user
// ======================================================================

export const B2C_Login = {
  path: () => "/b2c/login",
  location: ({redirectTo, rentContractId} = {}) => ({
    pathname: B2C_Login.path(),
    state: {redirectTo, rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.LOGIN
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2C
  ]
};

export const B2C_RequestResetPasswordToken = {
  path: () => "/b2c/recover-password",
  location: ({login} = {}) => ({
    pathname: B2C_RequestResetPasswordToken.path(),
    state: {login}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.LOGIN
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS
  ]
};

export const B2C_SignUp = {
  path: () => "/sign-up",
  location: ({rentContractId} = {}) => ({
    pathname: B2C_SignUp.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.SIGN_UP
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS
  ]
};

export const B2C_SignUpSuccess = {
  path: () => "/sign-up-success",
  location: () => ({
    pathname: B2C_SignUpSuccess.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.SIGN_UP
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS
  ]
};

export const B2C_SignUpConfirmed = {
  path: () => "/sign-up-confirmed",
  location: ({rentContractId}) => ({
    pathname: B2C_SignUpConfirmed.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.SIGN_UP
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const B2C_SignUpExtended = {
  path: () => "/sign-up-extended",
  location: ({rentContractId}) => ({
    pathname: B2C_SignUpExtended.path(),
    state: {rentContractId}
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.SIGN_UP
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const MyBmwRentDashboard = {
  path: () => "/my-bmw-rent/dashboard",
  location: () => ({
    pathname: MyBmwRentDashboard.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const MyBmwRentProfile = {
  path: () => "/my-bmw-rent/profile",
  location: () => ({
    pathname: MyBmwRentProfile.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const MyBmwRentProfileSuccess = {
  path: () => "/my-bmw-rent/profile-success",
  location: () => ({
    pathname: MyBmwRentProfileSuccess.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const MyBmwRentRentContracts = {
  path: () => "/my-bmw-rent/rent-contracts",
  location: () => ({
    pathname: MyBmwRentRentContracts.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

export const MyBmwRentPaymentMethods = {
  path: () => "/my-bmw-rent/payment-methods",
  location: () => ({
    pathname: MyBmwRentPaymentMethods.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2C,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2C
  ]
};

// ======================================================================
// B2B user
// ======================================================================

export const B2B_Login = {
  path: () => "/b2b/login",
  location: ({redirectTo, rentContractId} = {}) => ({
    pathname: B2B_Login.path(),
    state: {redirectTo, rentContractId}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.LOGIN
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS,
    userRoles.B2B
  ]
};

export const B2B_RequestResetPasswordToken = {
  path: () => "/b2b/recover-password",
  location: ({login} = {}) => ({
    pathname: B2B_RequestResetPasswordToken.path(),
    state: {login}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.LOGIN
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS
  ]
};

export const B2B_SignUpRequest = {
  path: () => "/b2b/sign-up-request",
  location: () => ({
    pathname: B2B_SignUpRequest.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.SIGN_UP
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS
  ]
};

export const B2B_SignUpRequestSuccess = {
  path: () => "/b2b/sign-up-request-success",
  location: ({email}) => ({
    pathname: B2B_SignUpRequestSuccess.path(),
    state: {email}
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.SIGN_UP
  ],
  allowedUserRoles: [
    userRoles.ANONYMOUS
  ]
};

export const B2B_Dashboard = {
  path: () => "/b2b/customer/dashboard",
  location: () => ({
    pathname: B2B_Dashboard.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_Profile = {
  path: () => "/b2b/customer/profile",
  location: () => ({
    pathname: B2B_Profile.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_Payment = {
  path: () => "/b2b/customer/payment",
  location: () => ({
    pathname: B2B_Payment.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};

export const B2B_History = {
  path: () => "/b2b/customer/history",
  location: () => ({
    pathname: B2B_History.path(),
    state: undefined
  }),
  groups: [
    screenGroups.B2B,
    screenGroups.PROFILE
  ],
  allowedUserRoles: [
    userRoles.B2B
  ]
};
