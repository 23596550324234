import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSection, ScreenHeading, ScreenLoading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {SignUpForm} from "./sign_up_form.js";
import {initialize} from "./actions.js";
import {B2B_SignUpRequest} from "../../../routing/routes.js";

const REGISTRATION = "FSD0000187";
const INTRODUCTION = "FSD0000188";
const LINK_B2B_SIGN_UP = "ALG0000215";

const SignUpComponent = (props) => {
  const {initialize, location: {state}} = props;
  const {rentContractId} = state || {};

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div>
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading><T code={REGISTRATION} /></ScreenHeading>
              <p>
                <T code={LINK_B2B_SIGN_UP} to={B2B_SignUpRequest.location()} />
              </p>
              <p><T code={INTRODUCTION} /></p>
            </Col>
          </Row>

          <SignUpForm rentContractId={rentContractId} />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SignUpComponent.propTypes = {
  initialize: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      rentContractId: PropTypes.number
    })
  })
};

const stateToSignUpProps = createStateToProps({
});

const dispatchToSignUpProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToSignUpProps,
  dispatchToSignUpProps
)(SignUpComponent);
