import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  rentObjectShape,
  RentObjectNameAndPrice,
  RentObjectBasicProperties,
  ExtraEquipment,
  RentObjectActions
} from "./rent_object.js";
import {
  Overlay,
  OverlayHeading,
  OverlaySection,
  OverlaySubHeading
} from "../../components/overlay.js";
import {Button} from "../../components/form.js";

import "./rent_object_overlay.scss";

const RENT_OBJECT_DETAILS = "FSD0000119";
const EXTRA_EQUIPMENT = "FSD0000121";
const BACK = "FSD0000120";

const RentObjectOverlayContent = (props) => {
  const {closeOverlay, rentObject, selectedBasicProperties, actionButton} = props;
  const {description, imageUrl1, extraEquipment} = rentObject;

  return (
    <>
      <OverlayHeading><T code={RENT_OBJECT_DETAILS} /></OverlayHeading>
      <OverlaySection>
        <RentObjectNameAndPrice rentObject={rentObject} />
        <img className="rent-object-overlay__image" src={imageUrl1} alt={description} />
        <RentObjectBasicProperties
          rentObject={rentObject}
          selectedBasicProperties={selectedBasicProperties}
        />
        <RentObjectActions>
          <Button back secondary onClick={closeOverlay} className="rent-object-overlay__back-button-desktop">
            <T code={BACK} />
          </Button>
          {actionButton}
        </RentObjectActions>
      </OverlaySection>

      <OverlaySection dark>
        {extraEquipment.length > 0
          ? (
            <>
              <OverlaySubHeading><T code={EXTRA_EQUIPMENT} /></OverlaySubHeading>
              <ExtraEquipment extraEquipment={extraEquipment} />
            </>
          )
          : null
        }
      <Button back secondary onClick={closeOverlay} className="rent-object-overlay__back-button-mobile">
      <T code={BACK} />
      </Button>
      </OverlaySection>
    </>
  );
};
RentObjectOverlayContent.propTypes = {
  closeOverlay: PropTypes.func,
  rentObject: rentObjectShape.isRequired,
  selectedBasicProperties: RentObjectBasicProperties.propTypes.selectedBasicProperties,
  actionButton: PropTypes.node
};

export class RentObjectDetailsButton extends Component {
  constructor(props) {
    super(props);

    this.overlayRef = createRef();
    this.openOverlay = () => {
      const overlay = this.overlayRef.current || {openOverlay: () => {}};
      overlay.openOverlay();
    };
    this.closeOverlay = () => {
      const overlay = this.overlayRef.current || {closeOverlay: () => {}};
      overlay.closeOverlay();
    };
  }

  render() {
    const {openOverlay} = this;
    const {rentObject, selectedBasicProperties, renderButton, actionButton} = this.props;
    const className = classnames("rent-object-details-button", this.props.className);

    return (
      <>
        {renderButton({className, openOverlay})}

        <Overlay ref={this.overlayRef} className="rent-object-details-button__overlay">
          {({closeOverlay}) => (
            <RentObjectOverlayContent
              closeOverlay={closeOverlay}
              rentObject={rentObject}
              selectedBasicProperties={selectedBasicProperties}
              actionButton={actionButton}
            />
          )}
        </Overlay>
      </>
    );
  }
}
RentObjectDetailsButton.propTypes = {
  className: PropTypes.string,
  rentObject: RentObjectOverlayContent.propTypes.rentObject,
  selectedBasicProperties: RentObjectOverlayContent.propTypes.selectedBasicProperties,
  renderButton: PropTypes.func.isRequired,
  actionButton: RentObjectOverlayContent.propTypes.actionButton
};
