import React, {Component, createRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {useField} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {trim} from "@awe-web/shared/lib/util/string.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {Checkbox, RadioButton} from "@awe-web/shared/lib/components/form.js";
import {Button, LabeledCheckbox} from "../../components/form.js";
import {Overlay, OverlayHeading, OverlaySection} from "../../components/overlay.js";
import {showCreditCardForm, deleteCreditCard} from "../actions.js";
import {getCreditCards} from "@awe-web/shared/lib/user/store.js";

import "./credit_cards.scss";

const VALID_TILL = "FSD0000171";
const REMOVE_CREDIT_CARD = "FSD0000220";
const CREDIT_CARD_IMAGE = "FSD0000349";

const CREDIT_CARD_CONFIRMATION = "FSD0000221";
const ADD_CREDIT_CARD = "FSD0000222";

const DELETE_CREDIT_CARD = "FSD0000220";
const DELETE_CREDIT_CARD_CONFIRMATION = "FSD0000300";
const DELETE_CREDIT_CARD_CONFIRM = "FSD0000299";
const DELETE_CREDIT_CARD_CANCEL = "FSD0000301";

const CreditCardComponent = (props) => {
  const {withDeleteButton, creditCard = {}, translate} = props;
  const {creditcardbrand, cardnumber, validTill} = creditCard;
  const className = classnames("credit-card", props.className);
  const creditCardImage = trim(translate(CREDIT_CARD_IMAGE));

  return (
    <div className={className} >
      <div className="credit-card__image-wrapper">
        <div
          className="credit-card__image-wrapper__image"
          style={{backgroundImage: `url(${creditCardImage})`}} />
      </div>
      <div className="credit-card__body">
        <div className="credit-card__number">
          <div>{creditcardbrand}</div>
          <div>{cardnumber}</div>
        </div>
        <div className="credit-card__valid-till">
          <T className="credit-card__valid-till__label" code={VALID_TILL} />
          <div>{validTill}</div>
        </div>
        {withDeleteButton
          ? <DeleteCreditCardButton creditCard={creditCard} />
          : null
        }
      </div>
    </div>
  );
};
CreditCardComponent.propTypes = {
  className: PropTypes.string,
  withDeleteButton: PropTypes.bool,
  creditCard: PropTypes.shape({
    id: PropTypes.number,
    creditcardbrand: PropTypes.string,
    cardnumber: PropTypes.string,
    validTill: PropTypes.string
  }),
  translate: PropTypes.func
};

const stateToCreditCardProps = createStateToProps({
  translate: newTranslate
});

const dispatchToCreditCardProps = (dispatch) => ({});

const CreditCard = connect(stateToCreditCardProps, dispatchToCreditCardProps)(CreditCardComponent);

const CreditCardListComponent = (props) => {
  const {creditCards} = props;
  const className = classnames("credit-card-list", props.className);

  return (
    <div className={className}>
      {creditCards.map(creditCard => (
        <CreditCard
          key={creditCard.id}
          withDeleteButton
          creditCard={creditCard}
          className="credit-card-list__credit-card"
        />
      ))}
    </div>
  );
};
CreditCardListComponent.propTypes = {
  className: PropTypes.string,
  creditCards: PropTypes.arrayOf(CreditCardComponent.propTypes.creditCard).isRequired
};

const stateToCreditCardListProps = createStateToProps({
  creditCards: getCreditCards
});

export const CreditCardList = connect(stateToCreditCardListProps)(CreditCardListComponent);

const DeleteCreditCardConfirmation = (props) => {
  const {creditCard, confirm, cancel} = props;

  return (
    <>
      <OverlayHeading><T code={DELETE_CREDIT_CARD} /></OverlayHeading>
      <OverlaySection>
        <p className="delete-credit-card-confirmation__notice"><T code={DELETE_CREDIT_CARD_CONFIRMATION} /></p>
        <CreditCard className="delete-credit-card-confirmation__credit-card" creditCard={creditCard} />
        <div className="delete-credit-card-confirmation__buttons">
          <Button primary continue className="delete-credit-card-confirmation__confirm-button" onClick={confirm}>
            <T code={DELETE_CREDIT_CARD_CONFIRM} />
          </Button>
          <Button secondary back onClick={cancel}><T code={DELETE_CREDIT_CARD_CANCEL} /></Button>
        </div>
      </OverlaySection>
    </>
  );
};
DeleteCreditCardConfirmation.propTypes = {
  creditCard: CreditCardComponent.propTypes.creditCard,
  confirm: PropTypes.func,
  cancel: PropTypes.func
};

class AddCreditCardComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxChecked: false
    };
    this.onChange = ({target: {checked}}) => {
      this.setState(() => ({checkboxChecked: checked}));
    };
    this.onClick = async () => {
      const {showCreditCardForm = () => {}} = this.props;
      await showCreditCardForm();
      this.setState(() => ({checkboxChecked: false}));
    };
  }

  render() {
    const {buttonClassName} = this.props;
    const className = classnames("add-credit-card", this.props.className);

    const {onChange, onClick} = this;
    const {checkboxChecked} = this.state;
    const buttonDisabled = !checkboxChecked;

    return (
      <div className={className}>
        <LabeledCheckbox className="add-credit-card__labeled-checkbox">
          <Checkbox checked={checkboxChecked} onChange={onChange} />
          <T className="checkbox-label" code={CREDIT_CARD_CONFIRMATION} />
        </LabeledCheckbox>
        <div className="add-credit-card__button-container">
          <Button secondary continue disabled={buttonDisabled} className={buttonClassName} onClick={onClick}>
            <T code={ADD_CREDIT_CARD} />
          </Button>
        </div>
      </div>
    );
  }
}
AddCreditCardComponent.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  showCreditCardForm: PropTypes.func
};

const dispatchToAddCreditCardProps = (dispatch) => ({
  showCreditCardForm: () => dispatch(showCreditCardForm())
});

export const AddCreditCard = connect(undefined, dispatchToAddCreditCardProps)(AddCreditCardComponent);

class DeleteCreditCardButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.overlayRef = createRef();
    this.openOverlay = () => {
      const overlay = this.overlayRef.current || {openOverlay: () => {}};
      overlay.openOverlay();
    };
  }

  render() {
    const {openOverlay} = this;
    const {creditCard, deleteCreditCard = () => {}} = this.props;
    const className = classnames("delete-credit-card-button", this.props.className);

    const confirmDeletion = closeOverlay => () => {
      closeOverlay();

      // Calling deleteCreditCard asynchronous because overlay fails
      // to close properly if we don't.
      setTimeout(deleteCreditCard, 0);
    };

    return (
      <>
        <Button tertiary className={className} onClick={openOverlay}><T code={REMOVE_CREDIT_CARD} /></Button>
        <Overlay ref={this.overlayRef} className="delete-credit-card-button__overlay">
          {({closeOverlay}) => (
            <DeleteCreditCardConfirmation
              creditCard={creditCard}
              confirm={confirmDeletion(closeOverlay)}
              cancel={closeOverlay} />
          )}
        </Overlay>
      </>
    );
  }
}
DeleteCreditCardButtonComponent.propTypes = {
  creditCard: DeleteCreditCardConfirmation.propTypes.creditCard,
  className: PropTypes.string
};

const dispatchToDeleteCreditCardButtonProps = (dispatch, props) => ({
  deleteCreditCard: () => dispatch(deleteCreditCard(props.creditCard.id))
});

const DeleteCreditCardButton = connect(undefined, dispatchToDeleteCreditCardButtonProps)(DeleteCreditCardButtonComponent);

export const CreditCardRadioButtons = (props) => {
  const {name, creditCards = []} = props;
  const className = classnames("credit-card-radio-buttons", props.className);

  const [{value, onChange}] = useField(name);

  const handleChange = event => {
    const {target} = event;
    onChange({target: {name: target.name, value: Number(target.value)}});
  };

  const options = creditCards.map(({id, cardnumber, validTill}) => (
    <label key={id} className="labeled-radio-button">
      <RadioButton
        className="labeled-radio-button__input"
        name={name}
        value={id}
        checked={id === value}
        onChange={handleChange}
      />
      <div>
        <div className="credit-card-radio-buttons__card-number radiobutton-label">{cardnumber}</div>
        <div>{validTill}</div>
      </div>
    </label>
  ));

  return options.length > 0
    ? (
      <div className={className}>
        {options}
      </div>
    )
    : null;
};
CreditCardRadioButtons.propTypes = {
  name: PropTypes.string,
  creditCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    cardnumber: PropTypes.string,
    validTill: PropTypes.string
  }))
};
