"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = void 0;

var _formatNumber = _interopRequireDefault(require("format-number"));

var formatCurrency = function formatCurrency(value) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$unit = options.unit,
      unit = _options$unit === void 0 ? "€" : _options$unit;
  return (0, _formatNumber["default"])({
    decimal: ",",
    integerSeparator: ".",
    round: 2,
    padRight: 2,
    suffix: " " + unit
  })(value);
};

exports.formatCurrency = formatCurrency;