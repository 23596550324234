import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {connect} from "react-redux";
import {useToggleVisibility} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {redirect} from "@awe-web/shared/lib/routing/actions.js";
import {Button} from "../../components/form.js";
import {Row, Col} from "../../components/grid.js";
import {B2B_SelectStationAndTime, B2C_SelectStationAndTime} from "../../routing/routes.js";
import {SelectedStation, SelectedTime, stationShape} from "./station.js";
import {SelectStationAndTimeForm} from "./select_station_and_time_form.js";
import {
  getCoStation,
  getCiStation,
  getCoDateTime,
  getCiDateTime,
  getFormattedCoDateTime,
  getFormattedCiDateTime
} from "../store.js";

import "./selected_station_and_time.scss";

const CHANGE_VALUES = "FSD0000112";
const CHECK_OUT_STATION = "FSD0000107";
const CHECK_OUT_DATE_TIME = "FSD0000108";
const CHECK_IN_STATION = "FSD0000109";
const CHECK_IN_DATE_TIME = "FSD0000110";
const CHANGE_STATION_AND_TIME = "FSD0000261";

const MinimalSelectedStationAndTime = (props) => {
  const {isComponentVisible, toggleComponentVisible} = useToggleVisibility();

  const {
    reservationPath,
    coStationShortDescr,
    formattedCoDateTime,
    formattedCiDateTime,
    withEditButton
  } = props;

  const summary = isComponentVisible
    ? <T className="minimal-selected-station-and-time__heading" code={CHANGE_STATION_AND_TIME} />
    : (
      <div>
        <div className="minimal-selected-station-and-time__station">{coStationShortDescr}</div>
        <div>{formattedCoDateTime} &ndash; {formattedCiDateTime}</div>
      </div>
    );
  const body = isComponentVisible
    ? <SelectStationAndTimeForm navigate={redirect} reservationPath={reservationPath} className="minimal-selected-station-and-time__form" />
    : null;
  const toggleButtonClassNames = classnames("minimal-selected-station-and-time__toggle-button", {
    "minimal-selected-station-and-time__toggle-button--expanded": isComponentVisible
  });
  const toggleButton = withEditButton
    ? <button className={toggleButtonClassNames} onClick={toggleComponentVisible}></button>
    : null;

  return (
    <div className="minimal-selected-station-and-time">
      <div className="minimal-selected-station-and-time__summary">
        {summary}
        {toggleButton}
      </div>
      {body}
    </div>
  );
};
MinimalSelectedStationAndTime.propTypes = {
  reservationPath: PropTypes.oneOf(["B2B", "B2C"]).isRequired,
  coStationShortDescr: PropTypes.string,
  formattedCoDateTime: PropTypes.string,
  formattedCiDateTime: PropTypes.string,
  withEditButton: PropTypes.bool
};

const SelectedStationAndTimeComponent = (props) => {
  const {
    reservationPath,
    withEditButton,
    editButtonLocation,
    coStation: {id: coStationId, shortdescr: coStationShortDescr},
    ciStation: {id: ciStationId, shortdescr: ciStationShortDescr},
    coDateTime,
    ciDateTime,
    formattedCoDateTime,
    formattedCiDateTime,
    translate
  } = props;
  const className = classnames("selected-station-and-time", props.className);

  const coStationLabel = translate(CHECK_OUT_STATION);
  const ciStationLabel = translate(CHECK_IN_STATION);
  const coDateTimeLabel = translate(CHECK_OUT_DATE_TIME);
  const ciDateTimeLabel = translate(CHECK_IN_DATE_TIME);

  const editLocation = editButtonLocation        ? editButtonLocation
                     : reservationPath === "B2B" ? B2B_SelectStationAndTime.location({coStationId, ciStationId, coDateTime, ciDateTime})
                     : /* else */                  B2C_SelectStationAndTime.location({coStationId, ciStationId, coDateTime, ciDateTime});

  const editButton = withEditButton
    ? (
      <Col desktop={{cols: 3, offset: 1}}>
        <Button
          className="selected-station-and-time__back-button"
          secondary
          back
          to={editLocation}
        >
          <T code={CHANGE_VALUES} />
        </Button>
      </Col>
    )
    : null;

  return (
    <div className={className}>
      <MinimalSelectedStationAndTime
        reservationPath={reservationPath}
        coStationShortDescr={coStationShortDescr}
        formattedCoDateTime={formattedCoDateTime}
        formattedCiDateTime={formattedCiDateTime}
        withEditButton={withEditButton}
      />

      <div className="selected-station-and-time__stations-and-times">
        <Row alignCenter>
          <Col desktop={{cols: 2}}>
            <SelectedStation
              className="selected-station-and-time__selected-station--co"
              label={coStationLabel}
              shortdescr={coStationShortDescr}
            />
          </Col>
          <Col desktop={{cols: 2}}>
            <SelectedTime
              className="selected-station-and-time__selected-time--co"
              label={coDateTimeLabel}
              formattedDateTime={formattedCoDateTime}
            />
          </Col>
          <Col desktop={{cols: 2}}>
            <SelectedStation
              className="selected-station-and-time__selected-station--ci"
              label={ciStationLabel}
              shortdescr={ciStationShortDescr}
            />
          </Col>
          <Col desktop={{cols: 2}}>
            <SelectedTime
              className="selected-station-and-time__selected-time--ci"
              label={ciDateTimeLabel}
              formattedDateTime={formattedCiDateTime}
            />
          </Col>
          {editButton}
        </Row>
      </div>
    </div>
  );
};
SelectedStationAndTimeComponent.propTypes = {
  className: PropTypes.string,
  reservationPath: PropTypes.oneOf(["B2B", "B2C"]).isRequired,
  withEditButton: PropTypes.bool,
  editButtonLocation: Button.propTypes.to,
  coStation: stationShape.isRequired,
  ciStation: stationShape.isRequired,
  coDateTime: PropTypes.instanceOf(Date).isRequired,
  ciDateTime: PropTypes.instanceOf(Date).isRequired,
  formattedCoDateTime: PropTypes.string.isRequired,
  formattedCiDateTime: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

const stateToProps = createStateToProps({
  coStation: getCoStation,
  ciStation: getCiStation,
  coDateTime: getCoDateTime,
  ciDateTime: getCiDateTime,
  formattedCoDateTime: getFormattedCoDateTime,
  formattedCiDateTime: getFormattedCiDateTime,
  translate: newTranslate
});

export const SelectedStationAndTime = connect(stateToProps)(SelectedStationAndTimeComponent);
