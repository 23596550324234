"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urlPath = void 0;

var urlPath = function urlPath(parts) {
  for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    params[_key - 1] = arguments[_key];
  }

  return params.map(encodeURIComponent).reduce(function (path, param, index) {
    return path + param + parts[index + 1];
  }, parts[0]);
};

exports.urlPath = urlPath;