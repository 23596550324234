import React from "react";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {SelectStationAndTimeForm} from "../../../stations/components/select_station_and_time_form.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {B2cPromotion} from "../../../promotion/components/promotion.js";

const SELECT_STATION_AND_TIME = "FSD0000080";

const SelectStationAndTime = (props) => {
  return (
    <div className="select-station-and-time">
      <ScreenSection main dark>
        <TypeArea>
          <ScreenHeading><T code={SELECT_STATION_AND_TIME} /></ScreenHeading>
          <SelectStationAndTimeForm navigate={navigate} reservationPath="B2C" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <B2cPromotion />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SelectStationAndTime.propTypes = {
};

export default SelectStationAndTime;
