import React, {Component, Fragment, createRef} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {
  rentObjectShape,
  RentObjectNameAndPrice,
  RentObjectBasicProperties,
  RentObjectActions
} from "./rent_object.js";
import {RentObjectDetailsButton} from "./rent_object_overlay.js";
import {Button} from "../../components/form.js";
import {InlineLoadingIndicator} from "../../components/loading_indicator.js";
import {getCurrentHeaderHeight} from "../../dom/store.js";

import "./rent_object_card.scss";

const SHOW_DETAILS = "FSD0000113";
const SELECT_RENT_OBJECT = "FSD0000114";
const RENT_OBJECTS_LOADING = "FSD0000357";

class RentObjectCard extends Component {
  constructor(props) {
    super(props);

    this.detailsButtonRef = createRef();

    this.handleCardClick = () => {
      this.detailsButtonRef.current.openOverlay();
    };

    this.handleSelectRentObject = event => {
      const {selectRentObject = () => {}} = this.props;

      // Prevent event from opening the overlay
      event.stopPropagation();
      this.detailsButtonRef.current.closeOverlay();
      selectRentObject();
    };
  }

  render() {
    const {handleCardClick, handleSelectRentObject} = this;
    const {rentObject, selectedBasicProperties} = this.props;
    const {description, imageUrl1} = rentObject;
    const className = classnames("rent-object-card", this.props.className);

    const selectButton = (
      <Button
        primary
        continue
        className="rent-object-card__select-button"
        onClick={handleSelectRentObject}
      >
        <T code={SELECT_RENT_OBJECT} />
      </Button>
    );

    return (
      <div className={className} onClick={handleCardClick}>
        <RentObjectNameAndPrice className="rent-object-card__heading" rentObject={rentObject} />

        <div className="rent-object-card__image">
          <img className="rent-object-card__image-tag" src={imageUrl1} alt={description} />
        </div>

        <div className="rent-object-details">
          <RentObjectBasicProperties
            rentObject={rentObject}
            selectedBasicProperties={selectedBasicProperties}
          />
          <RentObjectActions>
            <RentObjectDetailsButton
              ref={this.detailsButtonRef}
              rentObject={rentObject}
              selectedBasicProperties={selectedBasicProperties}
              renderButton={({className, openOverlay}) => (
                <Button
                  secondary
                  continue
                  className={className}
                  onClick={openOverlay}
                >
                  <T code={SHOW_DETAILS} />
                </Button>
              )}
              actionButton={selectButton} />
            {selectButton}
          </RentObjectActions>
        </div>
      </div>
    );
  }
}
RentObjectCard.propTypes = {
  className: PropTypes.string,
  rentObject: rentObjectShape.isRequired,
  selectedBasicProperties: RentObjectBasicProperties.propTypes.selectedBasicProperties,
  selectRentObject: PropTypes.func
};

class RentObjectCardListComponent extends Component {
  constructor(props) {
    super(props);

    this.cardMargin = 0;
    this.elementRef = createRef();

    this.scrollToCard = (elementId) => {
      const {headerHeight} = this.props;
      const anchorNode = this.elementRef.current.querySelector(`#${elementId}`);
      const top = anchorNode.offsetTop - headerHeight - this.cardMargin;

      window.scrollTo({
        top,
        behavior: "smooth"
      });
    };
  }

  componentDidMount() {
    const rentObjectCard = this.elementRef.current
      .querySelector(".rent-object-card-list__rent-object-card");

    if (rentObjectCard) {
      this.cardMargin = parseInt(window.getComputedStyle(rentObjectCard).marginBottom);
    }
  }

  render() {
    const {groupedRentObjects = {}, selectedBasicProperties, selectRentObject = () => {}} = this.props;
    const className = classnames("rent-object-card-list", this.props.className);

    return (
      <div ref={this.elementRef} className={className}>
        <InlineLoadingIndicator typePrefix="RENT_OBJECTS/RENT_OBJECTS" message={<T code={RENT_OBJECTS_LOADING} />}>
          {Object.keys(groupedRentObjects).map(elementId => (
            <Fragment key={elementId}>
              <div id={elementId} />

              {groupedRentObjects[elementId].map(rentObject => (
                <RentObjectCard
                  key={rentObject.id}
                  className="rent-object-card-list__rent-object-card"
                  rentObject={rentObject}
                  selectedBasicProperties={selectedBasicProperties}
                  selectRentObject={() => selectRentObject(rentObject.classificationId)} />
              ))}
            </Fragment>
          ))}
        </InlineLoadingIndicator>
      </div>
    );
  }
}
RentObjectCardListComponent.propTypes = {
  className: PropTypes.string,
  rentObjects: PropTypes.arrayOf(RentObjectCard.propTypes.rentObject),
  groupedRentObjects: PropTypes.objectOf(
    PropTypes.arrayOf(RentObjectCard.propTypes.rentObject)
  ),
  selectedBasicProperties: RentObjectCard.propTypes.selectedBasicProperties,
  selectRentObject: PropTypes.func,
  headerHeight: PropTypes.number
};

const rentObjectCardListStateToProps = createStateToProps({
  headerHeight: getCurrentHeaderHeight
});

export const RentObjectCardList = connect(
  rentObjectCardListStateToProps,
  null, // dispatchToProps
  null, // mergeProps
  {forwardRef: true} // https://react-redux.js.org/api/connect#forwardref-boolean
)(RentObjectCardListComponent);
