import React from "react";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";

const TITLE = "FSD0000282";
const DESCRIPTION = "FSD0000283";

const SignUpSuccess = (props) => {
  return (
    <div className="sign-up-success">
      <ScreenSection main dark>
        <TypeArea>
          <ScreenHeading>
            <T code={TITLE} />
          </ScreenHeading>

          <div><T code={DESCRIPTION} /></div>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};

export default SignUpSuccess;
