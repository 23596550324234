import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {ScreenSection} from "../../components/screen.js";
import {TypeArea} from "../../components/layout.js";
import {getFlashMessageCode} from "../store.js";

import "./flash_message.scss";

const FlashMessage = (props) => {
  const {flashMessage} = props;
  const className = classnames("flash-message", props.className);

  return flashMessage
    ? (
      <div className={className}>
        <p className="flash-message__text">{flashMessage}</p>
      </div>
    )
    : null;
};
FlashMessage.propTypes = {
  className: PropTypes.string,
  flashMessage: PropTypes.string
};

const ScreenFlashMessageComponent = (props) => {
  const {flashMessageCode, translate} = props;
  const className = classnames("screen-flash-message", props.className);

  return flashMessageCode
    ? (
      <ScreenSection className={className} dark>
        <TypeArea>
          <FlashMessage flashMessage={translate(flashMessageCode)} />
        </TypeArea>
      </ScreenSection>
    )
    : null;
};
ScreenFlashMessageComponent.propTypes = {
  className: PropTypes.string,
  flashMessageCode: PropTypes.string,
  translate: PropTypes.func
};

const stateToScreenFlashMessageProps = createStateToProps({
  flashMessageCode: getFlashMessageCode,
  translate: newTranslate
});

export const ScreenFlashMessage = connect(stateToScreenFlashMessageProps)(ScreenFlashMessageComponent);
