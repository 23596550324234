import React, {Component, useState, useEffect, forwardRef} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Field} from "formik";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {StationCardList} from "./station_card.js";
import {Overlay, OverlaySection, OverlayHeading} from "../../components/overlay.js";
import {TextInput, Selection, Button} from "../../components/form.js";
import {InlineLoadingIndicator} from "../../components/loading_indicator.js";

import "./station_selection.scss";

const BACK = "FSD0000105";
const STATIONS_LOADING = "FSD0000356";

export const StationSelectionOverlay = (props) => {
  const {
    open,
    heading,
    stations,
    placeholder,
    onSelect,
    onClose
  } = props;

  const [filterText, setFilterText] = useState("");
  const onFilterInputChange = (event) => {
    setFilterText(event.target.value);
  };

  // Clear input every time the overlay is opened
  useEffect(() => {
    if (open) {
      setFilterText("");
    }
  }, [open]);

  const className = classnames("station-selection-overlay", props.className);

  const filteredStations = stations
    .filter(station => station.shortdescr.toLowerCase().includes(filterText.toLowerCase()));

  return (
    <Overlay className={className} open={open} onClose={onClose}>
      <OverlayHeading>{heading}</OverlayHeading>
      <OverlaySection>
        <div className="station-selection-overlay__filter-input">
          <TextInput
            dark
            value={filterText}
            onChange={onFilterInputChange}
            placeholder={placeholder}
          />
        </div>
        <InlineLoadingIndicator typePrefix="STATIONS/STATIONS" message={<T code={STATIONS_LOADING} />}>
          <StationCardList
            className="station-selection-overlay__station-card-list"
            stations={filteredStations}
            onClick={onSelect}
          />
        </InlineLoadingIndicator>
        <Button secondary back className="station-selection-overlay__back-button" onClick={onClose}>
          <T code={BACK} />
        </Button>
      </OverlaySection>
    </Overlay>
  );
};
StationSelectionOverlay.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  heading: PropTypes.node,
  placeholder: PropTypes.string,
  stations: StationCardList.propTypes.stations,
  onSelect: PropTypes.func,
  onClose: PropTypes.func
};

export class StationSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOverlayVisible: false
    };

    this.openOverlay = () => this.setState(() => ({isOverlayVisible: true}));
    this.closeOverlay = () => {
      const {name, onBlur = () => {}} = this.props;
      this.setState(() => ({isOverlayVisible: false}));
      onBlur({target: {name}});
    };
    this.selectStation = stationId => {
      const {name, onChange = () => {}} = this.props;
      this.closeOverlay();
      onChange({target: {name, value: stationId}});
    };
  }

  render() {
    const {isOverlayVisible} = this.state;
    const {
      name,
      placeholder,
      overlayHeading,
      stations = [],
      value,
      error,
      onBlur,
      ...selectionProps
    } = this.props;
    const {openOverlay, closeOverlay, selectStation} = this;

    const className = classnames("station-selection", this.props.className);
    const selectionClassName = classnames("station-selection__selection", {
      "station-selection__selection--error": error
    });
    const selectedStation = stations.find(({id}) => id === value);
    const selectedStationDescription = selectedStation && selectedStation.shortdescr;

    return (
      <div className={className}>
        <Selection
          {...selectionProps}
          className={selectionClassName}
          name={name}
          placeholder={placeholder}
          placeholderClassName="station-selection__selection-placeholder"
          value={selectedStationDescription}
          error={error}
          onSelect={openOverlay}
        />

        <StationSelectionOverlay
          open={isOverlayVisible}
          heading={overlayHeading}
          stations={stations}
          placeholder={placeholder}
          onSelect={selectStation}
          onClose={closeOverlay}
        />
      </div>
    );
  }
}
StationSelection.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: Selection.propTypes.placeholder,
  overlayHeading: StationSelectionOverlay.propTypes.heading,
  stations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    shortdescr: PropTypes.string
  })),
  value: PropTypes.number,
  error: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};

export const FormikStationSelection = forwardRef((props, ref) => {
  return (
    <Field name={props.name}>
      {({field, meta}) => (
        <StationSelection
          {...field}
          {...props}
          ref={ref}
          error={meta.touched && meta.error}
        />
      )}
    </Field>
  );
});
FormikStationSelection.propTypes = StationSelection.propTypes;
