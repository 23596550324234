import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {loadDeliveryAddress} from "@awe-web/shared/lib/rent_contracts/actions.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {loadStations} from "@awe-web/shared/lib/stations/actions.js";
import {
  loadFormConfiguration,
  setFormConfigurationValues
} from "@awe-web/shared/lib/user/actions.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {selectKeys} from "@awe-web/shared/lib/util/object.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {provisioningTypes} from "@awe-web/shared/lib/rent_contracts/constants.js";
import {getProvisioningTypeB2b, getDeliveryAddress, getDriver} from "../../../rent_contracts/store.js";
import {getCompanyAddress} from "../../../user/store.js";
import {
  setProvisioningPickup,
  setProvisioningDelivery,
  setDriver,
  loadRentContractData
} from "../../../rent_contracts/actions.js";
import {B2B_LTR_SubmitRentContract} from "../../../routing/routes.js";

export const initialize = () => async (dispatch, getState) => {
  await Promise.all([
    dispatch(loadRentContractData({
      reservationPath: "B2B",
      additionalAction: (rentContract) => async (dispatch) => {
        await Promise.all([
          dispatch(loadStations([rentContract.type])),
          dispatch(loadDeliveryAddress(rentContract.id)),
          dispatch(loadFormConfiguration("personContractDriver"))
        ]);
        const driver = getDriver(getState());
        if (driver) {
          dispatch(setFormConfigurationValues("personContractDriver", {
            "Person:prefixKey": driver.get("prefixKey"),
            "Person:name": driver.get("name"),
            "Person:surname": driver.get("surname"),
            "Address:email": driver.get("email"),
            "Address:phone": driver.get("phone"),
            "employeeId": driver.get("employeeId"),
            "costCenter": driver.get("costCenter")
          }));
        }
      }
    })),
    dispatch(loadFormConfiguration("deliveryAddress07"))
  ]);

  const provisioningType = getProvisioningTypeB2b(getState());
  if (provisioningType === provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS) {
    const deliveryAddress = getDeliveryAddress(getState());
    dispatch(setFormConfigurationValues("deliveryAddress07", {
      "DeliveryAddress:surname": deliveryAddress.get("companyName"),
      "DeliveryContactPerson:name": deliveryAddress.get("name"),
      "DeliveryContactPerson:surname": deliveryAddress.get("surname"),
      "DeliveryAddress:street": deliveryAddress.get("street"),
      "DeliveryAddress:building": deliveryAddress.get("building"),
      "DeliveryAddress:zipCode": deliveryAddress.get("zipCode"),
      "DeliveryAddress:city": deliveryAddress.get("city"),
      "DeliveryAddress:countryCode": deliveryAddress.get("countryCode"),
      "DeliveryContactPerson:phone": deliveryAddress.get("phone")
    }));
  }
};

export const submit = (formValues) => async (dispatch, getState) => {
  const rentContractId = getRentContractId(getState());
  const mapDeliveryFormValues = newMapFormValues("deliveryAddress07")(getState());

  await dispatch(asyncAction({
    typePrefix: "SELECT_PROVISIONING/UPDATE_RENT_CONTRACT",
    loadPayload: async () => {
      await dispatch(setDriver(rentContractId, formValues));

      switch (formValues.provisioningType) {
        case provisioningTypes.PICKUP: {
          await dispatch(setProvisioningPickup(rentContractId));
          break;
        }

        case provisioningTypes.DELIVERY_TO_COMPANY_ADDRESS: {
          const companyAddress = getCompanyAddress(getState());
          const deliveryAddress = mapDeliveryFormValues({
            "DeliveryAddress:surname": companyAddress.get("companyName"),
            "DeliveryAddress:street": companyAddress.get("street"),
            "DeliveryAddress:building": companyAddress.get("building"),
            "DeliveryAddress:zipCode": companyAddress.get("zipCode"),
            "DeliveryAddress:city": companyAddress.get("city"),
            "DeliveryAddress:countryCode": companyAddress.get("countryCode"),
            "DeliveryContactPerson:name": "",
            "DeliveryContactPerson:surname": "",
            "DeliveryContactPerson:phone": ""
          });
          await dispatch(setProvisioningDelivery(rentContractId, deliveryAddress));
          break;
        }

        case provisioningTypes.DELIVERY_TO_CUSTOM_ADDRESS: {
          const deliveryAddress = mapDeliveryFormValues(selectKeys(formValues, [
            "DeliveryAddress:surname",
            "DeliveryContactPerson:name",
            "DeliveryContactPerson:surname",
            "DeliveryAddress:street",
            "DeliveryAddress:building",
            "DeliveryAddress:zipCode",
            "DeliveryAddress:city",
            "DeliveryAddress:countryCode",
            "DeliveryContactPerson:phone"
          ]));
          await dispatch(setProvisioningDelivery(rentContractId, deliveryAddress));
          break;
        }

        default:
          // nothing to do
      }
    }
  }));

  dispatch(navigate(B2B_LTR_SubmitRentContract.location({rentContractId})));
};
