import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {getReservationNo} from "@awe-web/shared/lib/rent_contracts/store.js";
import {getUserEmail} from "@awe-web/shared/lib/user/store.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenSection, ScreenHeading, ScreenLoading} from "../../../components/screen.js";
import {Button} from "../../../components/form.js";
import {TypeArea} from "../../../components/layout.js";
import {PickupNotice} from "../../../components/pickup_notice.js";
import {SaveTime} from "../../../components/save_time.js";
import {MyBmwRentDashboard} from "../../../routing/routes.js";
import {initialize} from "./actions.js";

import "./submit_rent_contract_success.scss";

const SCREEN_TITLE = "ALG0000113";
const RESERVATION_NUMBER = "FSD0000177";
const CONFIRMATION_TEXT = "FSD0000178";
const PROFILE_BUTTON = "FSD0000186";

const SubmitRentContractSuccessComponent = (props) => {
  const {
    initialize,
    reservationNo,
    email
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div className="b2c-ltr-submit-rent-contract-success">
      <ScreenSection main dark>
        <TypeArea>
          <Row>
            <Col
              desktop={{cols: 8, offset: 2}}
              className="b2c-ltr-submit-rent-contract-success__reservation-details"
            >
              <ScreenHeading>
                <T code={SCREEN_TITLE} />
              </ScreenHeading>

              <div className="b2c-ltr-submit-rent-contract-success__reservation-number">
                <div><T code={RESERVATION_NUMBER} /></div>
                <div>{reservationNo}</div>
              </div>

              <div className="b2c-ltr-submit-rent-contract-success__confirmation-text">
                <T code={CONFIRMATION_TEXT} data={{email: <b>{email}</b>}} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              desktop={{cols: 8, offset: 2}}
              className="b2c-ltr-submit-rent-contract-success__pickup-notice"
            >
              <PickupNotice reservationPath="B2C" />
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>

      <ScreenSection>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <SaveTime />
            </Col>
          </Row>

          <Row>
            <Col tablet={{cols: 4, offset: 2}} desktop={{cols: 4, offset: 4}}>
              <Button primary continue to={MyBmwRentDashboard.path()}>
                <T code={PROFILE_BUTTON} />
              </Button>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SubmitRentContractSuccessComponent.propTypes = {
  reservationNo: PropTypes.string,
  email: PropTypes.string,
  initialize: PropTypes.func
};

const stateToProps = createStateToProps({
  reservationNo: getReservationNo,
  email: getUserEmail
});

const dispatchToProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToProps,
  dispatchToProps
)(SubmitRentContractSuccessComponent);
