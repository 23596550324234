import React from "react";
import PropTypes from "prop-types";
import {Translation as T, withTranslation} from "@awe-web/shared/lib/l10n/components/translation.js";
import {B2B_SelectStationAndTime} from "../../../routing/routes.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {ScreenHeading, ScreenSection} from "../../../components/screen.js";
import {Button} from "../../../components/form.js";

import "./sign_up_request_success.scss";

const HEADING = "ALG0000074";
const IMAGE_URL = "ALG0000075";
const HEADING_THANKS = "ALG0000076";
const PROCESSING_NOTICE = "ALG0000077";
const BACK_TO_HOMEPAGE = "ALG0000078";

const SignUpRequestSuccessComponent = (props) => {
  const {translate, location: {state}} = props;
  const {email} = state || {};

  return (
    <ScreenSection className="sign-up-request-success" dark>
      <TypeArea>
        <ScreenHeading>{translate(HEADING)}</ScreenHeading>

        <Row>
          <Col tablet={{cols: 4}} desktop={{cols: 6}}>
            <img
              className="sign-up-request-success__image"
              src={translate(IMAGE_URL)}
              alt=""
            />
          </Col>
          <Col
            tablet={{cols: 4}} desktop={{cols: 6}}
            className="sign-up-request-success__text-content">
            <h2 className="sign-up-request-success__secondary-heading">
              {translate(HEADING_THANKS)}
            </h2>
            <p className="sign-up-request-success__processing-notice">
              <T code={PROCESSING_NOTICE} data={{email}} />
            </p>
            <Button
              className="sign-up-request-success__continue-button"
              secondary
              continue
              to={B2B_SelectStationAndTime.path()}
            >
              {translate(BACK_TO_HOMEPAGE)}
            </Button>
          </Col>
        </Row>
      </TypeArea>
    </ScreenSection>
  );
};
SignUpRequestSuccessComponent.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string
    })
  }),
  translate: PropTypes.func,
  email: PropTypes.string
};

export default withTranslation(SignUpRequestSuccessComponent);
