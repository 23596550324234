import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {ScreenSection, ScreenHeading, ScreenLoading,} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {Row, Col} from "../../../components/grid.js";
import {SignUpConfirmedForm} from "./sign_up_confirmed_form.js";
import {initialize} from "./actions.js";

import "./sign_up_confirmed.scss";

const REGISTRATION_SUCCESSFUL = "FSD0000210";

const SignUpConfirmedComponent = (props) => {
  const {initialize, location: {state}} = props;
  const {rentContractId} = state || {};

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  return (
    <div className="sign-up-confirmed">
      <ScreenSection dark>
        <TypeArea>
          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <ScreenHeading>
                <T code={REGISTRATION_SUCCESSFUL} />
              </ScreenHeading>
            </Col>
          </Row>          
        </TypeArea>
      </ScreenSection>      
      <ScreenSection main dark>
        <TypeArea>
          <SignUpConfirmedForm rentContractId={rentContractId} />
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SignUpConfirmedComponent.propTypes = {
  initialize: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      rentContractId: PropTypes.number
    })
  })
};

const stateToSignUpConfirmedProps = createStateToProps({
  translate: newTranslate
});

const dispatchToSignUpConfirmedProps = (dispatch) => ({
  initialize: () => dispatch(initialize())
});

export default connect(
  stateToSignUpConfirmedProps,
  dispatchToSignUpConfirmedProps
)(SignUpConfirmedComponent);
