import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {navigate} from "@awe-web/shared/lib/routing/actions.js";
import {getRentContract} from "@awe-web/shared/lib/rent_contracts/store.js";
import {getLoggedIn} from "@awe-web/shared/lib/user/store.js";
import {
  loadRentContractData,
  setDeductibleAndMileageOptions,
  setRentObjectProperties
} from "../../../rent_contracts/actions.js";
import {B2B_STR_SelectDriver, B2B_STR_SelectExtrasConfirm} from "../../../routing/routes.js";

export const initialize = () => async (dispatch) => {
  await dispatch(loadRentContractData({reservationPath: "B2B"}));
};

export const submit = (formValues) => async (dispatch, getState) => {
  const rentContract = getRentContract(getState());
  const rentContractJs = rentContract.toJS();
  const rentContractId = rentContract.get("id");

  const {
    deductible, // single id
    mileage,    // map of ids to value (boolean or string)
    equipment   // map of ids to value (boolean or string)
  } = formValues;
  const mileageIds = Object.entries(mileage)
    .reduce((acc, [id, value]) => value ? [...acc, Number(id)] : acc, []);

  await dispatch(asyncAction({
    typePrefix: "SELECT_EXTRAS/UPDATE_RENT_CONTRACT",
    loadPayload: () => Promise.all([
      dispatch(setDeductibleAndMileageOptions(rentContractJs, deductible, mileageIds)),
      dispatch(setRentObjectProperties(rentContractJs, equipment))
    ])
  }));

  const nextRoute = getLoggedIn(getState())
    ? B2B_STR_SelectDriver
    : B2B_STR_SelectExtrasConfirm;
  dispatch(navigate(nextRoute.location({rentContractId})));
};
