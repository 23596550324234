import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  FormikLabeledField,
  FormikTextInput,
  FormikSelect
} from "../../components/form.js";

import "./select_driver_form.scss";

export const SelectDriverForm = (props) => {
  const {
    isDriverFieldRequired,
    getDriverFieldLabel,
    getDriverSelectFieldOptions,
    getDriverFieldPlaceholder
  } = props;
  const className = classnames("select-driver-form", props.className);

  return (
    <div className={className}>
      <FormikLabeledField
        name="Person:prefixKey"
        as={FormikSelect}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        options={getDriverSelectFieldOptions("Person:prefixKey")}
      />

      <FormikLabeledField
        name="Person:name"
        as={FormikTextInput}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        placeholder={getDriverFieldPlaceholder("Person:name")}
      />

      <FormikLabeledField
        name="Person:surname"
        as={FormikTextInput}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        placeholder={getDriverFieldPlaceholder("Person:surname")}
      />

      <FormikLabeledField
        name="Address:email"
        as={FormikTextInput}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        placeholder={getDriverFieldPlaceholder("Address:email")}
        email={true}
      />

      <FormikLabeledField
        name="Address:phone"
        as={FormikTextInput}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        placeholder={getDriverFieldPlaceholder("Address:phone")}
      />

      <FormikLabeledField
        name="employeeId"
        as={FormikTextInput}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        placeholder={getDriverFieldPlaceholder("employeeId")}
      />

      <FormikLabeledField
        name="costCenter"
        as={FormikTextInput}
        isFieldRequired={isDriverFieldRequired}
        getFieldLabel={getDriverFieldLabel}
        placeholder={getDriverFieldPlaceholder("costCenter")}
      />
    </div>
  );
};
SelectDriverForm.propTypes = {
  // getDriverFormFieldDefaultValues: PropTypes.func,
  // validateDriver: PropTypes.func,
  isDriverFieldRequired: PropTypes.func,
  getDriverFieldLabel: PropTypes.func,
  getDriverSelectFieldOptions: PropTypes.func,
  getDriverFieldPlaceholder: PropTypes.func,
};
