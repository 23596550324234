import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";

import "./pagination.scss";

const PREV = "ALG0000178";
const NEXT = "ALG0000177";

export const Pagination = (props) => {
  const {
    currentPage,
    numberOfPages,
    onPrevClick,
    onNextClick
  } = props;
  const className = classnames("pagination", props.className);

  return (
    <div className={className}>
      <button
        disabled={currentPage === 1}
        className="pagination__prev-button"
        onClick={onPrevClick}
      >
        <T className="pagination__label" code={PREV} />
      </button>
      <div>
        <span className="pagination__current-page">{currentPage}</span>
        <span className="pagination__divider">/</span>
        <span>{numberOfPages}</span>
      </div>
      <button
        disabled={currentPage === numberOfPages}
        className="pagination__next-button"
        onClick={onNextClick}
      >
        <T className="pagination__label" code={NEXT} />
      </button>
    </div>
  );
};
Pagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func
};
