"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = reducer;
exports.newDateToAbbreviatedWeekdayName = exports.newDateToMonthName = exports.createDynamicListSelector = exports.newTranslationAvailable = exports.newTranslate = exports.getCurrentLocale = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _immutable = require("immutable");

var _reselect = require("reselect");

function reducer(state, action) {
  switch (action.type) {
    case "L10N/LOCALE_SET":
      return state.set("locale", action.payload.locale).set("translations", (0, _immutable.fromJS)(action.payload.translations));

    default:
      return state;
  }
}

var getTranslations = function getTranslations(state) {
  return state.get("translations") || (0, _immutable.Map)();
};

var getTranslationCodes = (0, _reselect.createSelector)([getTranslations], function (translations) {
  return (0, _immutable.List)(translations.keys());
});

var getCurrentLocale = function getCurrentLocale(state) {
  return state.get("locale");
};

exports.getCurrentLocale = getCurrentLocale;
var newTranslate = (0, _reselect.createSelector)([getTranslations], function (translations) {
  return function (code) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return translations.get(code, "MISSING code \"".concat(code, "\"")).replace(/\{(.*?)\}/g, function (_, key) {
      return key in data ? data[key] : "{".concat(key, "}");
    });
  };
});
exports.newTranslate = newTranslate;
var newTranslationAvailable = (0, _reselect.createSelector)([getTranslations], function (translations) {
  return function (code) {
    var translation = translations.get(code);
    return typeof translation === "string" && translation.trim().length > 0;
  };
});
exports.newTranslationAvailable = newTranslationAvailable;

var createDynamicListSelector = function createDynamicListSelector(mapping) {
  return (0, _reselect.createSelector)([getTranslationCodes, getTranslations], function (translationCodes, translations) {
    var suffix = /_(\d+)$/;

    var codePrefix = function codePrefix(code) {
      return code.replace(suffix, "");
    };

    var codeSuffix = function codeSuffix(code) {
      return suffix.exec(code)[1];
    };

    var mappingKeys = Object.keys(mapping);
    var objects = translationCodes // Remove all codes that don't have an id suffix
    .filter(function (code) {
      return code.match(suffix);
    }) // Remove all codes that are not part of the mapping
    .filter(function (code) {
      return mappingKeys.includes(codePrefix(code));
    }) // Group codes by id suffix
    .groupBy(codeSuffix).entrySeq() // Convert each group of translation codes into mapped objects
    .map(function (_ref) {
      var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
          id = _ref2[0],
          group = _ref2[1];

      return group.reduce(function (acc, code) {
        return acc.set(mapping[codePrefix(code)], translations.get(code).trim());
      }, (0, _immutable.Map)([["id", id]]));
    }) // Sort objects by id
    .sortBy(function (map) {
      return parseInt(map.get("id"), 10);
    }); // Convert sequence to immutable List

    return (0, _immutable.List)(objects);
  });
};

exports.createDynamicListSelector = createDynamicListSelector;

var newDateToMonthName = function newDateToMonthName(mlCodes) {
  return (0, _reselect.createSelector)([newTranslate], function (translate) {
    return function (date) {
      var month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"][date.getMonth()];
      var mlCode = mlCodes[month];
      return translate(mlCode);
    };
  });
};

exports.newDateToMonthName = newDateToMonthName;

var newDateToAbbreviatedWeekdayName = function newDateToAbbreviatedWeekdayName(mlCodes) {
  return (0, _reselect.createSelector)([newTranslate], function (translate) {
    return function (date) {
      var weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][date.getDay()];
      var mlCode = mlCodes[weekday];
      return translate(mlCode);
    };
  });
};

exports.newDateToAbbreviatedWeekdayName = newDateToAbbreviatedWeekdayName;