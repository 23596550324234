import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {B2C_LTR_SelectRentObject} from "../../../routing/routes.js";

import {ScreenSection, ScreenHeading, ScreenLoading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {SelectedStationAndTime} from "../../../stations/components/selected_station_and_time.js";
import {SelectedRentObject} from "../../../rent_objects/components/selected_rent_object.js";
import {Row, Col} from "../../../components/grid.js";
import {Button} from "../../../components/form.js";
import {
  getCoStationId,
  getCiStationId,
  getCiDateTime,
  getCoDateTime
} from "../../../stations/store.js";
import {
  getLtrMileageOptions,
  getLtrSelectedMileageOptionId,
  getLtrDeductibleOptions,
  getLtrEquipmentOptions,
  getLtrSelectedEquipmentOptions,
  newGetLtrSelectExtrasFormValuesToPrices,
  newGetLtrSelectExtrasFormValuesToTotalPrice
} from "../../../rent_contracts/store.js";
import {LtrSelectExtrasForm} from "../../../rent_contracts/components/ltr_select_extras_form.js";
import {initialize, submit} from "./actions.js";

import "./select_extras.scss";

const BACK = "ALG0000030";
const SCREEN_HEADING = "FSD0000125";
const PRICES_NOTICE = "ALG0000244";
const INCLUDING_VAT = "FSD0000263";

const SelectExtrasComponent = (props) => {
  const {
    initialize,
    coStationId,
    ciStationId,
    coDateTime,
    ciDateTime,
    mileageOptions,
    deductibleOptions,
    equipmentOptions,
    selectedMileageOption,
    selectedEquipmentOptions,
    getPrices,
    getTotalPrice,
    translate,
    onSubmit
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const enabledDeductible = deductibleOptions.find(({enabled}) => enabled);
  const initialValues = {
    mileage: selectedMileageOption,
    deductible: enabledDeductible && enabledDeductible.id,
    equipment: selectedEquipmentOptions
  };

  return (
    <div className="b2c-ltr-select-extras">
      <ScreenSection dark>
        <TypeArea>
          <SelectedStationAndTime reservationPath="B2C" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <SelectedRentObject
            className="b2c-ltr-select-extras__selected-rent-object"
            selectedBasicProperties="B2C"
            action={(
              <Button
                secondary
                back
                to={B2C_LTR_SelectRentObject.location({coStationId, ciStationId, coDateTime, ciDateTime})}
              >
                <T code={BACK} />
              </Button>
            )}
          />
        </TypeArea>
      </ScreenSection>

      <ScreenSection className="b2c-ltr-select-extras__title">
        <TypeArea>
          <Row>
            <Col desktop={{offset: 1, cols: 10}}>
              <ScreenHeading className="b2c-ltr-select-extras__screen-heading">
                <T code={SCREEN_HEADING} />
              </ScreenHeading>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <LtrSelectExtrasForm
            {...formikProps}
            reservationPath="B2C"
            mileageOptions={mileageOptions}
            deductibleOptions={deductibleOptions}
            equipmentOptions={equipmentOptions}
            totalPriceHint={translate(INCLUDING_VAT)}
            translate={translate}
            getPrices={getPrices}
            getTotalPrice={getTotalPrice}
          />
        )}
      </Formik>

      <TypeArea>
        <ScreenSection className="b2c-ltr-select-extras__prices-notice">
          <Row>
            <Col desktop={{offset: 1}}>
              <T code={PRICES_NOTICE} />
            </Col>
          </Row>
        </ScreenSection>
      </TypeArea>
    </div>
  );
};
SelectExtrasComponent.propTypes = {
  translate: PropTypes.func,
  initialize: PropTypes.func.isRequired,
  coStationId: PropTypes.number,
  ciStationId: PropTypes.number,
  coDateTime: PropTypes.instanceOf(Date),
  ciDateTime: PropTypes.instanceOf(Date),
  mileageOptions: LtrSelectExtrasForm.propTypes.mileageOptions,
  deductibleOptions: LtrSelectExtrasForm.propTypes.deductibleOptions,
  equipmentOptions: LtrSelectExtrasForm.propTypes.equipmentOptions,
  getPrices: LtrSelectExtrasForm.propTypes.getPrices,
  getTotalPrice: LtrSelectExtrasForm.propTypes.getTotalPrice,
  selectedMileageOption: PropTypes.number,
  selectedDeductibleOption: PropTypes.number,
  selectedEquipmentOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])),
  onSubmit: PropTypes.func
};

const stateToSelectExtrasProps = createStateToProps({
  coStationId: getCoStationId,
  ciStationId: getCiStationId,
  coDateTime: getCoDateTime,
  ciDateTime: getCiDateTime,
  mileageOptions: getLtrMileageOptions,
  deductibleOptions: getLtrDeductibleOptions,
  equipmentOptions: getLtrEquipmentOptions,
  selectedMileageOption: getLtrSelectedMileageOptionId,
  selectedEquipmentOptions: getLtrSelectedEquipmentOptions,
  getPrices: newGetLtrSelectExtrasFormValuesToPrices,
  getTotalPrice: newGetLtrSelectExtrasFormValuesToTotalPrice,
  translate: newTranslate
});

const dispatchToSelectExtrasProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmit: (values) => dispatch(submit(values))
});

export default connect(
  stateToSelectExtrasProps,
  dispatchToSelectExtrasProps
)(SelectExtrasComponent);
