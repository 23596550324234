"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.newContext = void 0;

var _history = require("history");

var _api = require("./api.js");

var _track_event = require("./analytics/track_event.js");

var newContext = function newContext(config) {
  return {
    history: (0, _history.createBrowserHistory)(),
    now: function now() {
      return new Date();
    },
    api: (0, _api.newApi)({
      fetch: window.fetch.bind(window),
      config: config
    }),
    window: window,
    document: window.document,
    env: process.env,
    trackEvent: _track_event.trackEvent
  };
};

exports.newContext = newContext;