import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {B2C_STR_SelectRentObject} from "../../../routing/routes.js";
import {StrSelectExtrasForm} from "../../../rent_contracts/components/str_select_extras_form.js";

import {ScreenSection, ScreenLoading} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {SelectedStationAndTime} from "../../../stations/components/selected_station_and_time.js";
import {SelectedRentObject} from "../../../rent_objects/components/selected_rent_object.js";
import {Row, Col} from "../../../components/grid.js";
import {Button} from "../../../components/form.js";
import {
  FormikSingleChoiceOptions,
  FormikMultiChoiceOptions
} from "../../../components/option.js";

import {initialize, submit} from "./actions.js";

import {
  getStrMileageOptions,
  getStrDeductibleOptions,
  getStrEquipmentOptions,
  getStrSelectedEquipmentOptions,
  getStrSelectedMileageOptions,
  newGetStrSelectExtrasFormValuesToTotalPrice
} from "../../../rent_contracts/store.js";
import {
  getCoStationId,
  getCiStationId,
  getCoDateTime,
  getCiDateTime
} from "../../../stations/store.js";

import "./select_extras.scss";

const BACK = "ALG0000030";
const INCLUDING_VAT = "FSD0000263";
const PRICES_NOTICE = "ALG0000244";

const SelectExtrasComponent = (props) => {
  const {
    initialize,
    coStationId,
    ciStationId,
    coDateTime,
    ciDateTime,
    mileageOptions,
    deductibleOptions,
    equipmentOptions,
    selectedMileageOptions,
    selectedEquipmentOptions,
    formValuesToTotalPrice,
    translate,
    onSubmit
  } = props;

  const isReady = useInitialize(initialize);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const enabledDeductible = deductibleOptions.find(({enabled}) => enabled);
  const initialValues = {
    mileage: selectedMileageOptions,
    deductible: enabledDeductible && enabledDeductible.id,
    equipment: selectedEquipmentOptions
  };

  return (
    <div className="b2c-str-select-extras">
      <ScreenSection dark>
        <TypeArea>
          <SelectedStationAndTime reservationPath="B2C" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <SelectedRentObject
            className="b2c-str-select-extras__selected-rent-object"
            selectedBasicProperties="B2C"
            action={(
              <Button
                secondary
                back
                to={B2C_STR_SelectRentObject.location({coStationId, ciStationId, coDateTime, ciDateTime})}
              >
                <T code={BACK} />
              </Button>
            )}
          />
        </TypeArea>
      </ScreenSection>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <StrSelectExtrasForm
            {...formikProps}
            reservationPath="B2C"
            basePriceHint={translate(INCLUDING_VAT)}
            mileageOptions={mileageOptions}
            deductibleOptions={deductibleOptions}
            equipmentOptions={equipmentOptions}
            formValuesToTotalPrice={formValuesToTotalPrice}
            translate={translate}
          />
        )}
      </Formik>

      <TypeArea>
        <ScreenSection className="b2c-str-select-extras__prices-notice">
          <Row>
            <Col desktop={{offset: 1}}>
              <T code={PRICES_NOTICE} />
            </Col>
          </Row>
        </ScreenSection>
      </TypeArea>
    </div>
  );
};
SelectExtrasComponent.propTypes = {
  translate: PropTypes.func,
  initialize: PropTypes.func.isRequired,
  coStationId: PropTypes.number,
  ciStationId: PropTypes.number,
  coDateTime: PropTypes.instanceOf(Date),
  ciDateTime: PropTypes.instanceOf(Date),
  mileageOptions: FormikMultiChoiceOptions.propTypes.options,
  deductibleOptions: FormikSingleChoiceOptions.propTypes.options,
  equipmentOptions: FormikMultiChoiceOptions.propTypes.options,
  selectedMileageOptions: PropTypes.objectOf(PropTypes.bool),
  selectedEquipmentOptions: PropTypes.objectOf(PropTypes.bool),
  formValuesToTotalPrice: StrSelectExtrasForm.propTypes.formValuesToTotalPrice,
  onSubmit: PropTypes.func
};

const stateToProps = createStateToProps({
  coStationId: getCoStationId,
  ciStationId: getCiStationId,
  coDateTime: getCoDateTime,
  ciDateTime: getCiDateTime,
  mileageOptions: getStrMileageOptions,
  deductibleOptions: getStrDeductibleOptions,
  equipmentOptions: getStrEquipmentOptions,
  selectedMileageOptions: getStrSelectedMileageOptions,
  selectedEquipmentOptions: getStrSelectedEquipmentOptions,
  formValuesToTotalPrice: newGetStrSelectExtrasFormValuesToTotalPrice,
  translate: newTranslate
});

const dispatchToProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmit: (values) => dispatch(submit(values))
});

export default connect(stateToProps, dispatchToProps)(SelectExtrasComponent);
