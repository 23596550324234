import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Formik} from "formik";
import {createStateToProps} from "@awe-web/shared/lib/util/redux.js";
import {useInitialize} from "@awe-web/shared/lib/util/react.js";
import {
  newValidate,
  newGetInitialValues,
  newIsFieldRequired,
  newGetFieldLabel,
  newGetFieldPlaceholder,
  newGetSelectFieldOptions
} from "@awe-web/shared/lib/user/store.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {getRentContractId} from "@awe-web/shared/lib/rent_contracts/store.js";
import {Translation as T} from "@awe-web/shared/lib/l10n/components/translation.js";
import {B2B_STR_SelectExtras} from "../../../routing/routes.js";
import {Row, Col} from "../../../components/grid.js";
import {
  ScreenSection,
  ScreenHeading,
  ScreenSectionHeading,
  ScreenLoading
} from "../../../components/screen.js";
import {TypeArea} from "../../../components/layout.js";
import {Button, RequiredNotice} from "../../../components/form.js";
import {SelectedStationAndTime} from "../../../stations/components/selected_station_and_time.js";
import {SelectedRentObject} from "../../../rent_objects/components/selected_rent_object.js";
import {SelectDriverForm} from "../../../rent_contracts/components/select_driver_form.js";
import {initialize, submit} from "./actions.js";

import "./select_driver.scss";

const SCREEN_HEADING = "ALG0000065";
const BACK = "ALG0000030";
const DRIVER_FORM_HEADING = "ALG0000066";
const IMAGE = "ALG0000241";
const SUBMIT = "ALG0000072";
const NOTICE = "FSD0000209";
const PRICES_NOTICE = "ALG0000029";

const Form = (props) => {
  const {
    translate,
    handleSubmit,

    isDriverFieldRequired,
    getDriverFieldLabel,
    getDriverFieldPlaceholder,
    getDriverSelectFieldOptions
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col desktop={{cols: 8, offset: 2}}>
          <ScreenHeading><T code={SCREEN_HEADING} /></ScreenHeading>
        </Col>
      </Row>

      <Row className="b2b-str-select-driver__form-row">
        <Col desktop={{cols: 4, offset: 2}}>
          <ScreenSectionHeading light>
            <T code={DRIVER_FORM_HEADING} />
          </ScreenSectionHeading>

          <SelectDriverForm
            className="b2b-str-select-driver__fieldset"
            isDriverFieldRequired={isDriverFieldRequired}
            getDriverFieldLabel={getDriverFieldLabel}
            getDriverFieldPlaceholder={getDriverFieldPlaceholder}
            getDriverSelectFieldOptions={getDriverSelectFieldOptions}
          />
        </Col>
        <Col className="b2b-str-select-driver__image-col" desktop={{cols: 4}}>
          <img src={translate(IMAGE)} alt="" />
        </Col>
      </Row>

      <Row className="b2b-str-select-driver__form-row">
        <Col desktop={{cols: 4, offset: 6}}>
          <Button primary submit continue><T code={SUBMIT} /></Button>
        </Col>
      </Row>
    </form>
  );
};
Form.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  isDriverFieldRequired: PropTypes.func.isRequired,
  getDriverFieldLabel: PropTypes.func.isRequired,
  getDriverFieldPlaceholder: PropTypes.func.isRequired,
  getDriverSelectFieldOptions: PropTypes.func.isRequired
};

const SelectDriverComponent = (props) => {
  const {
    translate,
    rentContractId,

    getDriverFormFieldDefaultValues,
    validateDriver,
    isDriverFieldRequired,
    getDriverFieldLabel,
    getDriverFieldPlaceholder,
    getDriverSelectFieldOptions,

    initialize,
    onSubmit,
    location
  } = props;

  const isReady = useInitialize(initialize, [location]);
  if (!isReady) {
    return <ScreenLoading />;
  }

  const initialValues = getDriverFormFieldDefaultValues({
    "Person:prefixKey": "",
    "Person:name": "",
    "Person:surname": "",
    "Address:email": "",
    "Address:phone": "",
    "employeeId": "",
    "costCenter": ""
  });

  return (
    <div className="b2b-str-select-driver">
      <ScreenSection dark>
        <TypeArea>
          <SelectedStationAndTime reservationPath="B2B" />
        </TypeArea>
      </ScreenSection>

      <ScreenSection light>
        <TypeArea>
          <SelectedRentObject
            className="b2b-str-select-driver__selected-rent-object"
            selectedBasicProperties="B2B"
            action={(
              <Button
                secondary
                back
                to={B2B_STR_SelectExtras.location({rentContractId})}
              >
                <T code={BACK} />
              </Button>
            )}
          />
        </TypeArea>
      </ScreenSection>

      <ScreenSection>
        <TypeArea>
          <Formik
            initialValues={initialValues}
            validate={validateDriver}
            onSubmit={onSubmit}
          >
            {(formikProps) => (
              <Form
                {...formikProps}
                translate={translate}

                isDriverFieldRequired={isDriverFieldRequired}
                getDriverFieldLabel={getDriverFieldLabel}
                getDriverFieldPlaceholder={getDriverFieldPlaceholder}
                getDriverSelectFieldOptions={getDriverSelectFieldOptions}
              />
            )}
          </Formik>

          <Row>
            <Col desktop={{cols: 8, offset: 2}}>
              <RequiredNotice className="b2b-str-select-driver__required-notice">
                <T code={NOTICE} />
              </RequiredNotice>
              <div><T code={PRICES_NOTICE} /></div>
            </Col>
          </Row>
        </TypeArea>
      </ScreenSection>
    </div>
  );
};
SelectDriverComponent.propTypes = {
  translate: PropTypes.func,
  rentContractId: PropTypes.number,
  getDriverFormFieldDefaultValues: PropTypes.func,
  validateDriver: PropTypes.func,
  isDriverFieldRequired: Form.propTypes.isDriverFieldRequired,
  getDriverFieldLabel: Form.propTypes.getDriverFieldLabel,
  getDriverFieldPlaceholder: Form.propTypes.getDriverFieldPlaceholder,
  getDriverSelectFieldOptions: Form.propTypes.getDriverSelectFieldOptions,
  initialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const stateToSelectDriverProps = createStateToProps({
  translate: newTranslate,
  rentContractId: getRentContractId,
  getDriverFormFieldDefaultValues: newGetInitialValues("personContractDriver"),
  validateDriver: newValidate("personContractDriver"),
  isDriverFieldRequired: newIsFieldRequired("personContractDriver"),
  getDriverFieldLabel: newGetFieldLabel("personContractDriver"),
  getDriverFieldPlaceholder: newGetFieldPlaceholder("personContractDriver"),
  getDriverSelectFieldOptions: newGetSelectFieldOptions("personContractDriver")
});

const dispatchToSelectDriverProps = (dispatch) => ({
  initialize: () => dispatch(initialize()),
  onSubmit: (formValues) => dispatch(submit(formValues))
});

export default connect(
  stateToSelectDriverProps,
  dispatchToSelectDriverProps
)(SelectDriverComponent);
