"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCurrentDateTime = void 0;

var _date = require("../util/date.js");

var setCurrentDateTime = function setCurrentDateTime() {
  return function (dispatch, getState, context) {
    dispatch({
      type: "TIME/CURRENT_DATE_TIME_UPDATED",
      payload: {
        currentDateTime: (0, _date.toIsoString)(context.now())
      }
    });
  };
};

exports.setCurrentDateTime = setCurrentDateTime;