import {asyncAction} from "@awe-web/shared/lib/util/redux.js";
import {
  loadFormConfiguration,
  setEBillingMailAddress,
  deleteEBillingMailAddress,
  setDeviantBillingAddress
} from "@awe-web/shared/lib/user/actions.js";
import {newTranslate} from "@awe-web/shared/lib/l10n/store.js";
import {newMapFormValues} from "@awe-web/shared/lib/user/store.js";
import {getBillDeliveryType, getEBillingMailAddress} from "../../../user/store.js";
import {ensureUserLoggedIn, loadDefaultBankAccount} from "../../../user/actions.js";
import {openModal} from "../../../app/actions.js";

const EMAIL_ERROR = "ALG0000154";
const BILLING_ADDRESS_SUCCESS_HEADING = "ALG0000155";
const BILLING_ADDRESS_SUCCESS_TEXT_1 = "ALG0000156";
const BILLING_ADDRESS_SUCCESS_TEXT_2 = "ALG0000157";
const BILLING_ADDRESS_SUCCESS_DISMISS = "ALG0000163";
const DELIVERY_SUCCESS_HEADING = "ALG0000158";
const DELIVERY_SUCCESS_DISMISS = "ALG0000163";
const DELIVERY_SUCCESS_E_MAIL_TEXT_1 = "ALG0000159";
const DELIVERY_SUCCESS_E_MAIL_TEXT_2 = "ALG0000160";
const DELIVERY_SUCCESS_MAIL_TEXT_1 = "ALG0000161";
const DELIVERY_SUCCESS_MAIL_TEXT_2 = "ALG0000162";

export const initialize = () => async (dispatch, getState) => {
  const translate = newTranslate(getState());

  await Promise.all([
    dispatch(loadDefaultBankAccount()),
    dispatch(loadFormConfiguration("deviantBillingAddress"))
  ]);

  dispatch({
    type: "USER/FORM_CONFIGURATION_LOADED",
    payload: {
      groupName: "billDelivery",
      formConfiguration: [
        {
          items: [
            {
              name: "billDeliveryType",
              value: getBillDeliveryType(getState()),
              xtype: "textfield",
              allowBlank: false
            },
            {
              name: "eBillingMailAddress",
              value: getEBillingMailAddress(getState()) || "",
              xtype: "textfield",
              allowBlank: false,
              emptyText: translate(EMAIL_ERROR)
            }
          ]
        }
      ]
    }
  });
};

export const submitBillingAddress = (formValues) => async (dispatch, getState) => {

  const translate = newTranslate(getState());
  const mapFormValues = newMapFormValues("deviantBillingAddress")(getState());
  const normalizedValues = mapFormValues(formValues);
  await dispatch(asyncAction({
    typePrefix: "USER/UPDATE_PROFILE",
    loadPayload: async () => {
      await dispatch(ensureUserLoggedIn());
      await dispatch(setDeviantBillingAddress(normalizedValues));
    }
  }));
  await dispatch(openModal(
    translate(BILLING_ADDRESS_SUCCESS_HEADING),
    translate(BILLING_ADDRESS_SUCCESS_TEXT_1) + "\n\n" + translate(BILLING_ADDRESS_SUCCESS_TEXT_2),
    translate(BILLING_ADDRESS_SUCCESS_DISMISS)
  ));
};

export const submitBillDelivery = (formValues) => async (dispatch, getState) => {
  const {billDeliveryType, eBillingMailAddress} = formValues;
  const translate = newTranslate(getState());

  if (billDeliveryType === "EMAIL") {
    await dispatch(asyncAction({
      typePrefix: "USER/UPDATE_PROFILE",
      loadPayload: async () => {
        await dispatch(ensureUserLoggedIn());
        await dispatch(setEBillingMailAddress(eBillingMailAddress));
      }
    }));
    await dispatch(openModal(
      translate(DELIVERY_SUCCESS_HEADING),
      translate(DELIVERY_SUCCESS_E_MAIL_TEXT_1) + "\n\n" + translate(DELIVERY_SUCCESS_E_MAIL_TEXT_2),
      translate(DELIVERY_SUCCESS_DISMISS)
    ));
  }
  else {
    await dispatch(asyncAction({
      typePrefix: "USER/UPDATE_PROFILE",
      loadPayload: async () => {
        await dispatch(ensureUserLoggedIn());
        await dispatch(deleteEBillingMailAddress());
      }
    }));
    await dispatch(openModal(
      translate(DELIVERY_SUCCESS_HEADING),
      translate(DELIVERY_SUCCESS_MAIL_TEXT_1) + "\n\n" + translate(DELIVERY_SUCCESS_MAIL_TEXT_2),
      translate(DELIVERY_SUCCESS_DISMISS)
    ));
  }
};
