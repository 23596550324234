export const setCurrentScrollPosition = () => (dispatch, getState, context) => {
  const currentScrollPosition = context.window.scrollY
    || context.window.document.documentElement.scrollTop;
  dispatch({
    type: "WINDOW/CURRENT_SCROLL_POSITION_UPDATED",
    payload: {
      currentScrollPosition
    }
  });
};

export const setCurrentViewportWidth = () => (dispatch, getState, context) => {
  dispatch({
    type: "WINDOW/CURRENT_VIEWPORT_WIDTH_UPDATED",
    payload: {
      currentViewportWidth: context.window.innerWidth
    }
  });
};
