import objectPath from "object-path";
import {getAnalyticsEnv, getAnalyticsGeoRegion, getInitialLocale} from "@awe-web/shared/lib/config/store.js";
import {localeToLang} from "@awe-web/shared/lib/l10n/util.js";
import {getCurrentLocale} from "@awe-web/shared/lib/l10n/store.js";
import {formatTimezoneOffset} from "@awe-web/shared/lib/util/date.js";

export const getDataLayer = (page, variant, referrer, state, context) => ({
  version: "bmw ceddl 20210128",

  accounts: [
    {
      account: "BMW Group 2.0"
    }
  ],

  component: [
    {
      componentInfo: {
        componentID: "rent > vehicle rental > bmw rent",
        componentName: "bmw rent"
      },
      category: {
        primaryCategory: "vehicle rental",
        subCategory1: "rental cars",
        componentType: "standalone",
        application: "web"
      },
      attributes: {
        componentVersion: context.env.REACT_APP_VERSION || "na"
      }
    }
  ],

  page: {
    pageInfo: {
      pageID: page.id || "na",
      pageName: page.name || "na",
      author: "Alphabet Fleetservices GmbH",
      destinationURL: objectPath.get(context, ["document", "location", "href"], "na"),
      referringURL: referrer || "na",
      sysEnv: getAnalyticsEnv(state),
      variant: variant || "na",
      version: "na",
      language: localeToLang(getCurrentLocale(state) || getInitialLocale(state)) || "na",
      geoRegion: getAnalyticsGeoRegion(state)
    },
    category: {
      primaryCategory: "bmw rent",
      subCategory1: page.subCategory1 || "na",
      subCategory2: page.subCategory2 || "na",
      pageType: page.type || "na"
    },
    attributes: {
      brand: "bmw",
      currencyCode: "EUR",
      timeZone: formatTimezoneOffset(context.now().getTimezoneOffset()),
      localpageName: page.name || "na"
    }
  }
});
